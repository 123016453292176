import { useContext } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { SiteFinanceFilterContext } from '../../../SiteFinanceContext';

import { EVENT_DATE, REVENUE_DATE, VISIT_DATE } from './DateTypeSelectorConstants';

export const DateTypeSelector = () => {
  const { filterData, setFewFilterProperty } = useContext(SiteFinanceFilterContext);
  return (
    <Box display="flex" alignItems="center" marginTop="10px">
      <Typography sx={{ marginRight: '16px' }}>Date Type:</Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={filterData.filterDateType}
        onChange={event => setFewFilterProperty({ filterDateType: event.target.value })}
        defaultValue="female"
        name="radio-buttons-group"
        row
      >
        <FormControlLabel value={REVENUE_DATE} control={<Radio />} label="Revenue" sx={{ marginBottom: 0 }} />
        <FormControlLabel value={VISIT_DATE} control={<Radio />} label="Visit" sx={{ marginBottom: 0 }} />
        <FormControlLabel value={EVENT_DATE} control={<Radio />} label="Event" sx={{ marginBottom: 0 }} />
      </RadioGroup>
    </Box>
  );
};
