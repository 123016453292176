import React, { useCallback, useEffect } from 'react';
import { some } from 'lodash/collection';
import { isEmpty, isNull } from 'lodash/lang';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { useCurrentUser } from '../../../../../../root/Container/CurrentUserContainer';
import { PS_ANSWERED, PS_NOT_ANSWERED } from '../constants';
import { getHiddenTargetFieldIdsByTriggerFieldValue } from '../services/conditionalLogicServices';
import {
  canFieldBeHighlightedForReview,
  clearHiddenField,
  collectAllQuestionsField,
  updateQuestionsField
} from '../services/fieldsServices';

export default function useTableItemGroupField(hiddenFieldsIds, setEncounterTableItemGroup) {
  const user = useCurrentUser();

  const updateFields = useCallback(
    function(iteratee, predicate) {
      setEncounterTableItemGroup(function(prevState) {
        return updateQuestionsField(prevState, iteratee, predicate);
      });
    },
    [setEncounterTableItemGroup]
  );

  const updateFieldById = useCallback(
    function(fieldUid, iteratee) {
      updateFields(iteratee, function(field) {
        return field.fieldUid === fieldUid;
      });
    },
    [updateFields]
  );

  const fieldOnChange = useCallback(
    function(fieldValue, fieldUid) {
      updateFieldById(fieldUid, function(field) {
        const performedStatus = !isNull(fieldValue) ? PS_ANSWERED : PS_NOT_ANSWERED;
        if (performedStatus === PS_ANSWERED) {
          field.fieldComment = null;
        }
        return {
          ...field,
          performedStatus,
          fieldValue
        };
      });
    },
    [updateFieldById]
  );

  const fileListOnChange = useCallback(
    function(fileList, fieldValue, filesResponse, fieldUid) {
      updateFieldById(fieldUid, function(field) {
        const performedStatus = !isNull(fileList) || !isNull(filesResponse) ? PS_ANSWERED : PS_NOT_ANSWERED;
        if (performedStatus === PS_ANSWERED) {
          field.fieldComment = null;
        }
        return {
          ...field,
          performedStatus,
          fileList,
          fieldValue,
          filesResponse
        };
      });
    },
    [updateFieldById]
  );

  const toggleFieldOnChange = useCallback(
    function(fieldValue, fieldUid, originConditionalLogicAndRequiredFieldsMaps, encounterTableItemGroup) {
      const conditionalLogicForToggle = getHiddenTargetFieldIdsByTriggerFieldValue(
        originConditionalLogicAndRequiredFieldsMaps[1],
        fieldUid,
        fieldValue
      );

      const allHiddenFileFields = collectAllQuestionsField(encounterTableItemGroup).filter(
        field =>
          field.fieldType === 'file' &&
          (!isEmpty(field?.filesResponse) || !isEmpty(field?.fileList)) &&
          some(conditionalLogicForToggle, id => id === field.fieldUid)
      );
      if (fieldValue && conditionalLogicForToggle && !isEmpty(allHiddenFileFields)) {
        const toggleField = collectAllQuestionsField(encounterTableItemGroup).filter(
          field => field.fieldUid === fieldUid
        );
        const hiddenFieldsName = allHiddenFileFields.reduce((sum, field, index) => {
          return index === 0 ? `${field.fieldName}` : `${sum}, ${field.fieldName}`;
        }, '');
        ModalBoxes.confirm({
          title: 'Confirmation',
          content: (
            <div>
              Updating the <b>{toggleField[0].fieldName}</b> will result in the previously uploaded file(s) to be
              deleted from the field(s) <b>{hiddenFieldsName}</b> upon saving this form.
              <br />
              <br />
              Are you sure you want to update <b>{toggleField[0].fieldName}</b>?
            </div>
          ),
          confirmButton: 'Yes',
          cancelButton: 'No'
        })
          .then(() => {
            fieldOnChange(fieldValue, fieldUid);
          })
          .catch(() => {});
      } else {
        fieldOnChange(fieldValue, fieldUid);
      }
    },
    [fieldOnChange]
  );

  const requiredAttentionOnChange = useCallback(
    function(requiredAttention, fieldUid) {
      updateFieldById(fieldUid, function(field) {
        return {
          ...field,
          requiredAttention
        };
      });
    },
    [updateFieldById]
  );

  const toggleAllRequiredAttention = useCallback(
    function(requiredAttention) {
      updateFields(
        function(field) {
          return {
            ...field,
            requiredAttention
          };
        },
        function(field) {
          return canFieldBeHighlightedForReview(field, hiddenFieldsIds, user.activeRole);
        }
      );
    },
    [updateFields, user, hiddenFieldsIds]
  );

  useEffect(
    function() {
      updateFields(function(field) {
        return clearHiddenField(field, hiddenFieldsIds);
      });
    },
    [updateFields, hiddenFieldsIds]
  );

  return {
    fieldOnChange,
    requiredAttentionOnChange,
    toggleAllRequiredAttention,
    fileListOnChange,
    toggleFieldOnChange
  };
}
