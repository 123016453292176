import React from 'react';

import Select from '../../common/data-entry/Select';

export function SSUSelect({
  studies,
  sites,
  handleSiteChange,
  studiesForPcn,
  handleStudyChange,
  handlePcnChange,
  initialStudy,
  initialSite,
  pcnFlag,
  customSelectedValueTemplateFunction,
  studyValid,
  pcnValid,
  siteValid
}) {
  return (
    <>
      <Select
        label="Study"
        dataSource={studies}
        optionLabelKey="studyName"
        optionValueKey="uniqueIdentifier"
        onChange={handleStudyChange}
        searchable
        value={initialStudy}
        valid={!studyValid}
        data-testid="study-selector"
      />

      {pcnFlag && (
        <Select
          label="PCN"
          dataSource={studiesForPcn}
          optionLabelKey="projectCode"
          optionValueKey="uniqueIdentifier"
          onChange={handlePcnChange}
          searchable
          value={initialStudy}
          customSelectedValueTemplateFunction={customSelectedValueTemplateFunction}
          valid={!pcnValid}
          data-testid="pcn-selector"
        />
      )}
      <Select
        label="Site"
        optionLabelKey="siteName"
        optionValueKey="uniqueIdentifier"
        dataSource={sites}
        onChange={handleSiteChange}
        value={initialSite}
        searchable
        valid={!siteValid}
        data-testid="site-selector"
      />
    </>
  );
}
