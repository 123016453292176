import { findIndex } from 'lodash';
import { uniqBy } from 'lodash/array';
import { some } from 'lodash/collection';
import { isEmpty, isEqual, isObject } from 'lodash/lang';

import {
  createConditionFieldAnswerNames,
  extractConditionFieldAnswerNames
} from '../ConditionalLogicSetupModal/services';

export const fillHideAllConditionalLogicForFirstItem = (itemList, fieldsFromNextSection = null) => {
  const conditionIndex = itemList[1]
    ? findIndex(itemList[1].fieldConfigurationResponse, ['conditionFieldName', itemList[0].name])
    : findIndex(fieldsFromNextSection[0].fieldConfigurationResponse, ['conditionFieldName', itemList[0].name]);
  const hideAllAnswer = itemList[1]
    ? extractConditionFieldAnswerNames(itemList[1].fieldConfigurationResponse[conditionIndex].conditionFieldAnswerNames)
    : extractConditionFieldAnswerNames(
        fieldsFromNextSection[0].fieldConfigurationResponse[conditionIndex].conditionFieldAnswerNames
      );
  itemList[0].hideAllConfiguration = {
    hideAllFieldsBelow: true,
    hideAllFieldsBelowOnAnswer: hideAllAnswer
  };
  return itemList;
};

export const fillConditionalLogic = (fieldsForFill, itemIndexWithHideAll, configurationForFill, fillAll = false) => {
  let filledValues = fieldsForFill;
  itemIndexWithHideAll.forEach((elementIndex, i) => {
    filledValues = filledValues.map((field, index) => {
      if (index > elementIndex || fillAll) {
        const previousConfiguration = field?.fieldConfigurationResponse ? field.fieldConfigurationResponse : [];
        return {
          ...field,
          fieldConfigurationResponse: uniqBy(
            [configurationForFill[i], ...previousConfiguration],
            element => element?.conditionFieldName
          )
        };
      } else if (index < elementIndex) {
        const filteredConfiguration = field.fieldConfigurationResponse
          ? field.fieldConfigurationResponse.filter(
              configuration => configuration.conditionFieldName !== configurationForFill[i].conditionFieldName
            )
          : null;
        return {
          ...field,
          fieldConfigurationResponse: filteredConfiguration
        };
      } else {
        return field;
      }
    });
  });
  return filledValues;
};

export const updateConditionalLogicForItems = (generalFieldsForUpdate, fieldListForUpdate) => {
  let finalResultGeneralFields;
  let finalResultTableFields;

  const itemIndexWithHideAllGeneral = generalFieldsForUpdate
    .map((field, index) => (isObject(field.hideAllConfiguration) ? index : null))
    .filter(el => el !== null);
  const itemIndexWithHideAllTable = fieldListForUpdate
    .map((field, index) => (isObject(field.hideAllConfiguration) ? index : null))
    .filter(el => el !== null);

  const configurationForFillGeneral = itemIndexWithHideAllGeneral.length
    ? itemIndexWithHideAllGeneral.map(elementIndex => ({
        conditionFieldAnswerNames: [
          generalFieldsForUpdate[elementIndex].hideAllConfiguration.hideAllFieldsBelowOnAnswer
        ],
        isHideCondition: true,
        conditionFieldName: generalFieldsForUpdate[elementIndex].name
      }))
    : null;

  const configurationForFillTable = itemIndexWithHideAllTable.length
    ? itemIndexWithHideAllTable.map(elementIndex => ({
        conditionFieldAnswerNames: createConditionFieldAnswerNames(
          fieldListForUpdate[elementIndex].hideAllConfiguration.hideAllFieldsBelowOnAnswer
        ),
        isHideCondition: true,
        conditionFieldName: fieldListForUpdate[elementIndex].name
      }))
    : null;

  if (!isEmpty(configurationForFillGeneral) && !isEmpty(configurationForFillTable)) {
    finalResultGeneralFields = fillConditionalLogic(
      generalFieldsForUpdate,
      itemIndexWithHideAllGeneral,
      configurationForFillGeneral
    );
    finalResultTableFields = fillConditionalLogic(
      fieldListForUpdate,
      itemIndexWithHideAllGeneral,
      configurationForFillGeneral,
      true
    );
    finalResultTableFields = fillConditionalLogic(
      finalResultTableFields,
      itemIndexWithHideAllTable,
      configurationForFillTable
    );
    return [finalResultGeneralFields, finalResultTableFields];
  }
  if (!isEmpty(configurationForFillGeneral) && isEmpty(configurationForFillTable)) {
    finalResultGeneralFields = fillConditionalLogic(
      generalFieldsForUpdate,
      itemIndexWithHideAllGeneral,
      configurationForFillGeneral
    );
    finalResultTableFields = fillConditionalLogic(
      fieldListForUpdate,
      itemIndexWithHideAllGeneral,
      configurationForFillGeneral,
      true
    );
    return [finalResultGeneralFields, finalResultTableFields];
  }
  if (isEmpty(configurationForFillGeneral) && !isEmpty(configurationForFillTable)) {
    finalResultGeneralFields = generalFieldsForUpdate;
    finalResultTableFields = fillConditionalLogic(
      fieldListForUpdate,
      itemIndexWithHideAllTable,
      configurationForFillTable
    );
    return [finalResultGeneralFields, finalResultTableFields];
  }
  return [generalFieldsForUpdate, fieldListForUpdate];
};

export const sortFieldsByIsCheckedAndUpdateSequence = fields => {
  return fields
    .sort((a, b) => {
      if (a.isChecked && !b.isChecked) {
        return -1;
      }
      if (!a.isChecked && b.isChecked) {
        return 1;
      }
      return 0;
    })
    .map((field, index) => ({ ...field, sequence: index + 1 }));
};

export const allItemsIncludeConfiguration = (fields, nameForCheck) => {
  let allItemsIncludeConfiguration = true;
  fields.forEach((item, index) => {
    if (findIndex(item.fieldConfigurationResponse, ['conditionFieldName', nameForCheck]) < 0 && index !== 0) {
      allItemsIncludeConfiguration = false;
    }
  });
  return allItemsIncludeConfiguration;
};

export const removeConfigurationAndHideAll = (
  fieldListForUpdate,
  configurationNameForRemove,
  removeHideAll = false
) => {
  return fieldListForUpdate.map(field => ({
    ...field,
    fieldConfigurationResponse: field?.fieldConfigurationResponse
      ? field.fieldConfigurationResponse.filter(
          configuration => configuration.conditionFieldName !== configurationNameForRemove
        )
      : null,
    hideAllConfiguration: removeHideAll && field.name === configurationNameForRemove ? null : field.hideAllConfiguration
  }));
};

export const generateConditionalLogicForSaving = fieldListForSaving => {
  return fieldListForSaving
    .filter(field => !isEmpty(field.fieldConfigurationResponse))
    .map(item => {
      return item.fieldConfigurationResponse.map(element => ({
        conditionFieldAnswerNames: element.conditionFieldAnswerNames,
        isHideCondition: element.isHideCondition,
        conditionFieldName: element.conditionFieldName,
        conditionFieldSequence:
          fieldListForSaving[findIndex(fieldListForSaving, ['name', element.conditionFieldName])].sequence,
        customFieldName: item.name,
        customFieldSequence: item.sequence
      }));
    })
    .flat();
};

export const checkConditionalLogicForThisItem = (allFields, fieldName) => {
  let oneOfItemHasConditionalWithName = false;
  allFields.forEach(field => {
    if (some(field.fieldConfigurationResponse, ['conditionFieldName', fieldName])) {
      oneOfItemHasConditionalWithName = true;
    }
  });
  return oneOfItemHasConditionalWithName;
};

export const preparedLabels = labels => {
  return labels
    .map(item => ({
      ...item,
      id: `label_${item.name}`
    }))
    .sort((a, b) => a.sequence - b.sequence);
};

export const prepareFields = fields => {
  return fields.map((field, index) => ({
    ...field,
    sequence: index + 1,
    fieldConfigurationResponse: field.fieldConfigurationList
  }));
};

export const fillHideAll = (generalFields, fieldList) => {
  let updatedFieldList = fieldList;
  let updatedGeneralFields = generalFields;
  if (
    updatedGeneralFields[0]?.inputType === 'radio' &&
    allItemsIncludeConfiguration([...updatedGeneralFields, ...updatedFieldList], updatedGeneralFields[0].name)
  ) {
    updatedGeneralFields = fillHideAllConditionalLogicForFirstItem(updatedGeneralFields, updatedFieldList);
  }
  if (
    updatedFieldList[0].inputType === 'radio' &&
    updatedFieldList.length > 1 &&
    allItemsIncludeConfiguration([...updatedFieldList], updatedFieldList[0].name)
  ) {
    updatedFieldList = fillHideAllConditionalLogicForFirstItem(updatedFieldList);
  }
  return [updatedGeneralFields, updatedFieldList];
};

export const renameConfiguration = (configurationList, oldName, newName) => {
  const configurationForUpdate = configurationList ? configurationList : [];
  return configurationForUpdate.map(configuration => ({
    ...configuration,
    conditionFieldName: configuration.conditionFieldName === oldName ? newName : configuration.conditionFieldName
  }));
};

export const calculateCodeDefinitionChangesFromNewTerminologyVersion = (currentForm, newForm) => {
  const changedCodeDefinitionsMap = new Map();
  currentForm
    .filter(currentItemDefinition => currentItemDefinition.isChecked && currentItemDefinition.type === 'OPTIONAL')
    .forEach(currentItemDefinition => {
      const currentCodeDefinitions = currentItemDefinition?.codeDefinationList.map(
        codeDefinition => codeDefinition.decode
      );
      const newCodeDefinitions = newForm.optional[0].itemDefinitionList
        .find(newItemDefinition => newItemDefinition.cdashAliasName === currentItemDefinition.cdashAliasName)
        ?.codeDefinationList.map(codeDefinition => codeDefinition.decode);
      if (!isEqual(currentCodeDefinitions.sort(), newCodeDefinitions.sort())) {
        changedCodeDefinitionsMap.set(currentItemDefinition.question, [currentCodeDefinitions, newCodeDefinitions]);
      }
    });

  return changedCodeDefinitionsMap;
};

export const updateOptionList = (currentFieldList, fieldListWithNewOptions) => {
  return currentFieldList.map(field => {
    if (field.type === 'OPTIONAL') {
      const fieldWithNewValue = fieldListWithNewOptions.filter(newField => newField.name === field.name);
      return {
        ...field,
        codeDefinationList: fieldWithNewValue[0].codeDefinationList,
        ctListName: fieldWithNewValue[0].ctListName
      };
    }
    return { ...field };
  });
};
