import React from 'react';

import { Amount } from '../../../../../shared/amount-view/Amount/Amount';
import { stylesContent } from '../../EncounterInvoiceTemplateStyles';

export const ReimbursementItem = ({ item }) => {
  return (
    <div
      style={{ ...stylesContent.invoiceItem, display: 'flex', gap: '3px', paddingLeft: '40px' }}
      className="row-item"
      data-testid="reimbursement-item"
    >
      {`${item?.type || '—'}`}
      <Amount coinsAmount={item.amount} showDollarSign={true} />
    </div>
  );
};
