export const prepareCustomItem = (customItemParams, sequence = 1) => {
  const {
    name,
    CDASH,
    itemType,
    codeDefinationList,
    textBlockValue,
    toggleValue,
    selectedControlledTermListName,
    styledFieldName
  } = customItemParams;
  const defaultCustomItem = {
    name,
    styledFieldName,
    cdashAliasName: CDASH?.cdashigVariableLabel || null,
    domain: CDASH?.cdashigVariable || null,
    question: name,
    inputValue: '',
    inputType: itemType,
    sequence,
    codeDefinationList: [],
    isRepeat: true,
    isChecked: true,
    type: 'CUSTOM',
    tableGeneralField: false
  };
  const simpleCustomItems = [
    'date',
    'checkbox',
    'file',
    'singleFile',
    'textbox',
    'textarea',
    'reminder',
    'textBlockLong',
    'time'
  ];
  if (simpleCustomItems.includes(itemType)) {
    return defaultCustomItem;
  }
  if (itemType === 'label') {
    return {
      ...defaultCustomItem,
      inputValue: textBlockValue,
      value: textBlockValue
    };
  }
  if (itemType === 'radio') {
    let newCodeDefinationList;
    const prepearedDefinitonValue = {
      codeListOid: '',
      extensible: '',
      inputType: 'radio'
    };
    switch (toggleValue.name) {
      case 'CLINSIG':
        newCodeDefinationList = [
          {
            ...prepearedDefinitonValue,
            name: 'Clinically Significant',
            codedValue: 'CS',
            decode: 'CS',
            sequence: 1
          },
          {
            ...prepearedDefinitonValue,
            name: 'Not Clinically Significant',
            codedValue: 'NCS',
            decode: 'NCS',
            sequence: 2
          }
        ];
        break;
      case 'NORMABNM':
        newCodeDefinationList = [
          {
            ...prepearedDefinitonValue,
            name: 'Normal',
            codedValue: 'NORM',
            decode: 'NORM',
            sequence: 1
          },
          {
            ...prepearedDefinitonValue,
            name: 'Abnormal',
            codedValue: 'ABNORM',
            decode: 'ABNORM',
            sequence: 2
          }
        ];
        break;
      case 'NY':
        newCodeDefinationList = [
          {
            ...prepearedDefinitonValue,
            name: 'Yes',
            codedValue: 'Y',
            decode: 'Y',
            sequence: 1
          },
          {
            ...prepearedDefinitonValue,
            name: 'No',
            codedValue: 'N',
            decode: 'N',
            sequence: 2
          }
        ];
        break;
      default:
        newCodeDefinationList = [];
    }
    return {
      ...defaultCustomItem,
      codeDefinationList: newCodeDefinationList
    };
  }
  if (itemType === 'dropdownCT' || itemType === 'dropdown') {
    const preparedDropdownValue = codeDefinationList.map(item => ({ ...item, codedValue: item.name }));
    return {
      ...defaultCustomItem,
      codeDefinationList: preparedDropdownValue,
      ctListName: itemType === 'dropdownCT' ? selectedControlledTermListName : null,
      isExtensible: itemType === 'dropdownCT' ? 1 : 2
    };
  }
  if (itemType === 'multiselectCT' || itemType === 'multiselect') {
    const preparedDropdownValue = codeDefinationList.map(item => ({
      ...item,
      codedValue: item.name,
      inputType: 'multiselect'
    }));
    return {
      ...defaultCustomItem,
      codeDefinationList: preparedDropdownValue,
      ctListName: itemType === 'multiselectCT' ? selectedControlledTermListName : null,
      isExtensible: itemType === 'multiselectCT' ? 1 : 2
    };
  }
  return null;
};
