import React, { useCallback, useMemo } from 'react';
import { uniqBy } from 'lodash/array';
import { sortBy } from 'lodash/collection';

import { SSUSelect } from '../../../../../../SSUFilter/SSUSelect';

export const StudyPCNSiteWorklistSelector = ({
  onSiteChange,
  onStudyChange,
  onPcnChange,
  studyId,
  siteId,
  pcnFlag,
  studySiteList,
  requiredFieldsNotSelected
}) => {
  const extractNecessaryListFromStudySite = useCallback((studySiteList, uniqueIdentifier, filterIdentifier, name) => {
    const studySiteFilteredByIdentifier = studySiteList.filter(
      ssu => !uniqueIdentifier || uniqueIdentifier === ssu[filterIdentifier]
    );
    const list = studySiteFilteredByIdentifier.map(ssu => ssu[name]);
    const uniqueList = uniqBy(list, 'uniqueIdentifier');
    return sortBy(uniqueList, `${name}Name`);
  }, []);

  const studies = useMemo(
    function() {
      return extractNecessaryListFromStudySite(studySiteList, siteId, 'siteIdentifier', 'study');
    },
    [extractNecessaryListFromStudySite, studySiteList, siteId]
  );

  const studiesForPcn = useMemo(
    function() {
      return sortBy(
        uniqBy(
          studySiteList
            .filter(({ study }) => (studyId ? study.uniqueIdentifier === studyId : true))
            .filter(({ site }) => (siteId ? site.uniqueIdentifier === siteId : true))
            .map(({ study }) => study)
            .filter(study => study.projectCode),
          'uniqueIdentifier'
        ),
        'studyName'
      );
    },
    [studyId, siteId, studySiteList]
  );

  const sites = useMemo(
    function() {
      return extractNecessaryListFromStudySite(studySiteList, studyId, 'studyIdentifier', 'site');
    },
    [extractNecessaryListFromStudySite, studyId, studySiteList]
  );
  const initialStudy = useMemo(
    function() {
      return studies.find(({ uniqueIdentifier }) => uniqueIdentifier === studyId);
    },
    [studyId, studies]
  );
  const initialSite = useMemo(
    function() {
      return sites.find(({ uniqueIdentifier }) => uniqueIdentifier === siteId);
    },
    [siteId, sites]
  );

  const studyPcnOrNoPCNMessage = useCallback(
    studies => studies.map(study => (study.projectCode ? study.projectCode : 'No PCN')),
    []
  );

  return (
    <SSUSelect
      handleSiteChange={onSiteChange}
      handleStudyChange={onStudyChange}
      handlePcnChange={study => onPcnChange(study, sites)}
      studies={studies}
      studiesForPcn={studiesForPcn}
      sites={sites}
      initialStudy={initialStudy}
      initialSite={initialSite}
      pcnFlag={pcnFlag}
      customSelectedValueTemplateFunction={studyPcnOrNoPCNMessage}
      studyValid={requiredFieldsNotSelected}
      pcnValid={requiredFieldsNotSelected}
    />
  );
};
