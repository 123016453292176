import React, { useEffect, useState } from 'react';

import Input from '../../../../../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../../common/general/ButtonGroup';
import { RichTextEditor } from '../../../../../../../../../common/RichTextFormat/editor/RichTextEditor';
import { resolveStyledFieldName } from '../../../../../../../../../common/RichTextFormat/viewer/TextFieldResolver';
import { STYLED_INPUT_QUESTIONS } from '../../../../../../../../../constants/inputTypes';

import './EditCDASHModal.scss';

const EditCDASHModal = ({
  question,
  updatedQuestion,
  styledFieldName,
  updateCDASHQuestion,
  itemType,
  general,
  itemIndex,
  headerText,
  modalBox
}) => {
  const [newItemQuestion, setNewItemQuestion] = useState('');
  const [newStyledFieldName, setNewStyledFieldName] = useState('');

  useEffect(() => {
    if (updatedQuestion) {
      setNewItemQuestion(updatedQuestion);
    } else if (headerText === 'Edit Item') {
      setNewItemQuestion('');
    } else {
      setNewItemQuestion(question);
    }
  }, [headerText, question, updatedQuestion]);

  const isDisabled = () => {
    if (STYLED_INPUT_QUESTIONS.includes(itemType)) {
      return styledFieldName === newStyledFieldName;
    }
    return !newItemQuestion && !updatedQuestion;
  };

  return (
    <>
      <ModalBoxes.Header>{headerText}</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="edit-cdash-label-wrapper">
          <div className="edit-cdash-label">Original Item Name</div>
          <Input className="" type="text" validate={false} value={question} disabled={true} />
        </div>
        <div className="edit-cdash-label-wrapper">
          <div className="edit-cdash-label">New Item Name</div>
          {STYLED_INPUT_QUESTIONS.includes(itemType) ? (
            <RichTextEditor
              className={'new-table-item-group'}
              value={resolveStyledFieldName(styledFieldName, question)}
              onChange={(htmlString, rawTextString) => {
                setNewItemQuestion(rawTextString);
                setNewStyledFieldName(htmlString);
              }}
            />
          ) : (
            <Input
              type="text"
              validate={false}
              value={newItemQuestion}
              onChange={({ target: { value } }) => {
                setNewItemQuestion(value);
              }}
              disabled={false}
              required
            />
          )}
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave} disabled={isDisabled()}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function onClose() {
    modalBox.close();
  }
  function onSave() {
    updateCDASHQuestion(newItemQuestion.trim(), newStyledFieldName, itemIndex, general);
    modalBox.close();
  }
};

EditCDASHModal.className = 'edit-cdash-modal';
EditCDASHModal.size = 'w950';

export default EditCDASHModal;
