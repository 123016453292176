import { first } from 'lodash/array';
import { reduce, some } from 'lodash/collection';
import { isEmpty, isNil } from 'lodash/lang';

import NotificationManager from '../../../../../../common/notifications/NotificationManager';

import { getFieldByName } from './services';

export function doMainValidation(conditions, domainTargetFields) {
  return new Promise(function(resolve, reject) {
    const error = validateUntilError(conditions, domainTargetFields);
    if (error) {
      reject(error);
    }
    resolve();
  });
}

function validateUntilError(conditions, domainTargetFields) {
  return reduce(
    conditions,
    function(valid, { conditionFieldName, conditionFieldAnswer }) {
      if (!conditionFieldName || isEmpty(conditionFieldAnswer) || some(conditionFieldAnswer, isNil)) {
        return new Error('One or more conditions are incomplete. Ensure all required fields are filled.');
      }
      if (domainTargetFields.includes(conditionFieldName)) {
        return new Error('One or more conditions have conflicts with domain rules.');
      }
      return valid;
    },
    null
  );
}

export function oldValidation(conditions, allAccessibleFields, fName) {
  const a = hasCircularDependency(conditions, allAccessibleFields, fName);
  if (a) {
    NotificationManager.error('You cannot save conditional logic with a circular dependency');
    return false;
  }
  return true;
}

export function hasCircularDependency(conditions, allAccessibleFields, fName) {
  /*TODO: draft, need to rewrite*/
  const firstCondition = first(conditions);

  if (isEmpty(firstCondition) || isEmpty(allAccessibleFields)) {
    return null;
  }

  const { conditionFieldName } = firstCondition;

  const conditionField = getFieldByName(allAccessibleFields, conditionFieldName);

  return reg(conditionField, conditionField.name, fName);

  function reg(conditionField, conditionFieldName, fName) {
    if (isEmpty(conditionField) || isEmpty(conditionField.fieldConfigurationResponse)) {
      return false;
    }
    if (conditionField.name === fName) {
      return true;
    }

    if (conditionField.fieldConfigurationResponse.conditionField.name === fName) {
      return true;
    }

    return reg(conditionField.fieldConfigurationResponse.conditionField, conditionFieldName, fName);
  }
}
