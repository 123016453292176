import { groupBy } from 'lodash';
import moment from 'moment/moment';

import {
  PARENT,
  SITE_CREDIT,
  SITE_CREDIT_WH,
  SITE_DEBIT,
  SITE_DEBIT_WH,
  WITHHOLDING
} from '../../SitePayments/SitePaymentsTable/SitePaymentsConstants';

export const calculateUnpaidAmountForEvents = events => {
  return events.reduce((acc, curr) => acc + curr.invoiceAmount - curr.reconcileTotalAmountPaid, 0);
};

export const calculateUnpaidWithholdingAmountForEvents = events => {
  return events.reduce(
    (acc, curr) => acc + curr.clientAmountWithOverhead - curr.clientAmountWithHolding - curr.reconcileTotalAmountPaid,
    0
  );
};

export const filterEventsByInvoiceDateRangeInDays = (events, start, end) => {
  const startDate = moment().subtract(start, 'days');
  const endDate = moment().subtract(end, 'days');
  return events.filter(({ momentInvoiceDate, momentParentInvoiceDate, adjustmentSequence }) => {
    const invoiceDate = adjustmentSequence ? momentParentInvoiceDate : momentInvoiceDate;
    return invoiceDate.isBefore(startDate) && (!end || invoiceDate.isAfter(endDate));
  });
};

export const filterWithholdingEventsByInvoiceDateRangeInDays = (events, start, end) => {
  const startDate = moment().subtract(start, 'days');
  const endDate = moment().subtract(end, 'days');
  return events.filter(({ momentInvoiceDate, momentWithholdingInvoiceDate, adjustmentSequence }) => {
    const invoiceDate = adjustmentSequence ? momentWithholdingInvoiceDate : momentInvoiceDate;
    return invoiceDate.isBefore(startDate) && (!end || invoiceDate.isAfter(endDate));
  });
};

export const isWithholdingType = sitePaymentType => {
  return [WITHHOLDING, SITE_CREDIT_WH, SITE_DEBIT_WH].includes(sitePaymentType);
};

export const isParentType = sitePaymentType => {
  return [PARENT, SITE_CREDIT, SITE_DEBIT].includes(sitePaymentType);
};

export const prepareDataForCalculation = data => {
  const groupedData = groupBy(data, e => `${e.eventNumber}-${e.sitePaymentType}`);
  return data.map(
    ({
      id,
      invoiceDate,
      siteAmount,
      withholdingAmount,
      sitePaymentType,
      invoiceAmount,
      sitePaymentStatus,
      reconcileTotalAmountPaid,
      eventNumber,
      adjustmentSequence,
      sitePaidAmount,
      clientAmount,
      clientAmountWithHolding,
      clientAmountWithOverhead
    }) => {
      const parentInvoiceDate = groupedData[`${eventNumber}-${PARENT}`]?.[0]?.invoiceDate;
      const withholdingInvoiceDate = groupedData[`${eventNumber}-${WITHHOLDING}`]?.[0]?.invoiceDate;

      const momentParentInvoiceDate = moment(parentInvoiceDate);
      const momentWithholdingInvoiceDate = moment(withholdingInvoiceDate);
      const momentInvoiceDate = moment(invoiceDate);

      return {
        id,
        momentParentInvoiceDate,
        momentWithholdingInvoiceDate,
        momentInvoiceDate,
        invoiceDate,
        siteAmount,
        withholdingAmount,
        sitePaymentType,
        invoiceAmount,
        sitePaymentStatus,
        reconcileTotalAmountPaid,
        eventNumber,
        adjustmentSequence,
        sitePaidAmount,
        clientAmount,
        clientAmountWithHolding,
        clientAmountWithOverhead
      };
    }
  );
};
