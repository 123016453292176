import React, { Fragment, useContext, useState } from 'react';

import Loader from '../../../../../../../common/elements/Loader/Loader';

import PatientContentConfirmation from './PatientContentConfirmation/PatientContentConfirmation';
import { InstructionContext } from './InstructionContextProvider';
import InstructionItem from './InstructionItem';
import { stopEventAnd } from './PatientContentTabs';

export function Instructions() {
  const [deleteInstructionCandidateId, setDeleteInstructionCandidateId] = useState(null);
  const { instructions, editInstruction, deleteInstructionByFileId, canUserAddInstructions, isLoading } = useContext(
    InstructionContext
  );

  return (
    <React.Fragment>
      {!isLoading && !deleteInstructionCandidateId && (
        <div className="patient-instructions-items">
          {instructions.map(({ key: { id }, instruction }) => {
            return (
              <InstructionItem
                key={id}
                id={id}
                instruction={instruction}
                canUserAddInstructions={canUserAddInstructions}
                setDeleteInstructionCandidateId={setDeleteInstructionCandidateId}
                editInstruction={editInstruction}
              />
            );
          })}
        </div>
      )}
      {!isLoading && deleteInstructionCandidateId && (
        <PatientContentConfirmation
          message={<Fragment>Are you sure you want to delete this instruction?</Fragment>}
          onConfirm={stopEventAnd(() => {
            deleteInstructionByFileId(deleteInstructionCandidateId).then(stopDeleting);
          })}
          onDeny={stopEventAnd(stopDeleting)}
        />
      )}

      {isLoading && (
        <div className="patient-files-loader">
          <Loader />
        </div>
      )}
    </React.Fragment>
  );

  function stopDeleting() {
    setDeleteInstructionCandidateId(null);
  }
}
