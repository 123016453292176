import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { SiteFinanceApi, StudySiteApi } from '../../../../api';
import useSessionStorage from '../../../../common/hooks/useSessionStorage';
import { onRequestError } from '../../../../services/handlers';

import { REVENUE_DATE } from './SiteFinanceHeader/SiteFinanceFilters/DateTypeSelector/DateTypeSelectorConstants';
import { getSsuIdsByStudyIdsAndSiteIds } from './SiteFinanceServices';

export const SiteFinanceFilterContext = createContext(null);
export const SiteFinanceTableContext = createContext(null);

export const SiteFinanceProvider = ({ children }) => {
  const firstLoading = useRef(true);
  const firstSSULoading = useRef(true);
  const [savedFilters, setSavedFilters] = useSessionStorage('SITE_FINANCE_FILTERS', {});
  const [lifetimeData, setLifetimeData] = useState({
    totalEarnedInvoiceAmount: 0,
    screenFailuresRatio: 0,
    enrolledPatientsCount: 0,
    avgMonthlyEarning: 0
  });
  const emptyFilters = useMemo(
    () => ({
      studyName: null,
      siteName: null,
      siteId: null,
      studyId: null,
      projectCode: null,
      startDate: moment()
        .subtract(1, 'months')
        .startOf('day')
        .isBefore(moment().startOf('year'))
        ? moment()
            .subtract(1, 'months')
            .startOf('day')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 1 })
        : moment()
            .startOf('year')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 1 }),
      endDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }),
      filterDateType: REVENUE_DATE,
      isInvoiced: true,
      isPaid: true,
      isAll: true
    }),
    []
  );

  const initialFilterData = useMemo(() => {
    return isEmpty(savedFilters)
      ? emptyFilters
      : {
          ...savedFilters,
          startDate: moment(savedFilters.startDate),
          endDate: moment(savedFilters.endDate)
        };
  }, [emptyFilters, savedFilters]);

  const [filterData, setFilterData] = useState(initialFilterData);
  const [ssus, setSsus] = useState([]);
  const [tableData, setTableData] = useState([]);

  const setFewFilterProperty = useCallback(
    filters => {
      setFilterData(prevFilters => ({
        ...prevFilters,
        ...filters
      }));
    },
    [setFilterData]
  );

  const updatePage = useCallback(() => {
    const studyIds = filterData.studyId ? [filterData.studyId] : [];
    const siteIds = filterData.siteId ? [filterData.siteId] : [];
    const ssuIds = getSsuIdsByStudyIdsAndSiteIds(ssus, studyIds, siteIds);
    if (filterData.isAll || (!filterData.isInvoiced && !filterData.isPaid && !filterData.isAll)) {
      setFewFilterProperty({ isAll: true, isInvoiced: true, isPaid: true });
    }
    const eventStatusFilters = filterData.isAll
      ? { isInvoiced: false, isPaid: false }
      : { isInvoiced: filterData.isInvoiced, isPaid: filterData.isPaid };
    const filters = {
      startDate: filterData.startDate,
      endDate: filterData.endDate,
      filterDateType: filterData.filterDateType,
      studyIds,
      siteIds,
      ssuIds,
      ...eventStatusFilters
    };
    firstLoading.current = false;
    SiteFinanceApi.getSiteFinanceEvents(filters).then(({ data }) => {
      setTableData(data);
      setSavedFilters(filterData);
    }, onRequestError);
    SiteFinanceApi.getLifetimeAmounts({
      studyIds,
      siteIds,
      ssuIds
    }).then(({ data }) => {
      setLifetimeData(data);
    }, onRequestError);
  }, [filterData, setFewFilterProperty, setSavedFilters, ssus]);

  const resetFilters = useCallback(() => {
    setFilterData(emptyFilters);
  }, [emptyFilters]);

  const filterContextValue = useMemo(
    () => ({
      filterData,
      setFewFilterProperty,
      updatePage,
      resetFilters,
      ssus
    }),
    [filterData, setFewFilterProperty, updatePage, resetFilters, ssus]
  );

  const tableContextValue = useMemo(
    () => ({
      tableData,
      lifetimeData,
      firstLoading: firstLoading.current
    }),
    [lifetimeData, tableData]
  );

  useEffect(() => {
    if (!isEmpty(savedFilters) && !isEmpty(ssus) && firstLoading.current) {
      firstLoading.current = false;
      updatePage();
    }
  }, [savedFilters, ssus, updatePage]);

  useEffect(() => {
    if (firstSSULoading.current) {
      firstSSULoading.current = false;
      StudySiteApi.getStudySitesWithFinAccess()
        .then(({ data }) => {
          setSsus(data);
          const uniqueSites = [...new Set(data.map(ssu => ssu.site.uniqueIdentifier))];
          if (isEmpty(savedFilters) && uniqueSites.length === 1) {
            setFewFilterProperty({
              siteId: uniqueSites[0],
              siteName: data.map(ssu => ssu.site).filter(site => site.uniqueIdentifier === uniqueSites[0])[0].siteName
            });
          }
          const availableStudiesIncludeSavedStudy = data.some(
            ssu => ssu.study.uniqueIdentifier === savedFilters.studyId
          );
          const availableSitesIncludeSavedSite = data.some(ssu => ssu.site.uniqueIdentifier === savedFilters.siteId);

          if (
            (savedFilters.studyId && !availableStudiesIncludeSavedStudy) ||
            (savedFilters.siteId && !availableSitesIncludeSavedSite)
          ) {
            setFewFilterProperty({ studyId: null, siteId: null, studyName: null, siteName: null });
            setSavedFilters({});
          }
        })
        .catch(e => onRequestError(e));
    }
  }, [savedFilters, setFewFilterProperty, setSavedFilters, ssus]);

  return (
    <SiteFinanceFilterContext.Provider value={filterContextValue}>
      <SiteFinanceTableContext.Provider value={tableContextValue}>{children}</SiteFinanceTableContext.Provider>
    </SiteFinanceFilterContext.Provider>
  );
};

export const withSiteFinanceContext = Component => props => {
  return (
    <SiteFinanceProvider>
      <Component {...props} />
    </SiteFinanceProvider>
  );
};
