import React, { Component } from 'react';
import { connect } from 'react-redux';

import Select from '../../../../common/data-entry/Select';

class PatientStatusSelect extends Component {
  onChangeStatus = item => {
    this.props.setPatientStatus(item?.name);
  };

  render() {
    return (
      <Select
        label="Patient Status"
        clearSearchOnSelection
        dataSource={[
          { id: 'PRE-SCREENING', name: 'Pre-screening' },
          { id: 'PRE-SCREENED', name: 'Pre-screened' }
        ]}
        optionLabelKey="name"
        optionValueKey="id"
        onChange={this.onChangeStatus}
        searchable
        searchPlaceholder="Patient Status"
        clearable={false}
        required={this.props.requiredField}
        disabled={this.props.disabled}
        validate={false}
        value={{ id: 'IDENTIFIED', name: 'Identified' }}
      />
    );
  }
}

export default connect(null, null)(PatientStatusSelect);
