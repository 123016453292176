export const INITIAL_PATIENT_STATUSES = [
  { id: 'Pre-screened', name: 'Pre-screened' },
  { id: 'Pre-screening', name: 'Pre-screening' },
  { id: 'Identified', name: 'Identified' }
];

export const patientStatusMap = [
  { id: 'IDENTIFIED', name: 'Identified' },
  { id: 'PRE-SCREENED', name: 'Pre-screened' },
  { id: 'CONSENTED', name: 'Consented' },
  { id: 'SCREENED', name: 'Screened' },
  { id: 'RUN IN', name: 'Run In' },
  { id: 'RANDOMIZED', name: 'Randomized' },
  { id: 'FOLLOW-UP', name: 'Follow-up' },
  { id: 'COMPLETED', name: 'Completed' },
  { id: 'CANCELED', name: 'Canceled' },
  { id: 'PRE-SCREEN FAILED', name: 'Pre-screen Failed' },
  { id: 'RUN IN FAILED', name: 'Run In Failed' },
  { id: 'WITHDRAWN', name: 'Withdrawn' },
  { id: 'SCREEN FAILED', name: 'Screen Failed' },
  { id: 'TRANSFERRING', name: 'Transferred' },
  { id: 'PRE-SCREENING', name: 'Pre-screening' },
  { id: 'EARLY TERMINATION', name: 'Early Termination' }
];

export const PAYMENT_TYPE_PHYSICAL = 'PHYSICAL';
export const PAYMENT_TYPE_DIGITAL = 'DIGITAL';

export const paymentTypeMap = {
  [PAYMENT_TYPE_PHYSICAL]: 'Physical',
  [PAYMENT_TYPE_DIGITAL]: 'Digital'
};
