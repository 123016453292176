import React from 'react';
import jsPDF from 'jspdf';
import { isEmpty, isFunction } from 'lodash';
import { uniqBy, uniqWith } from 'lodash/array';
import uuid from 'uuid/v4';

import './PDFFonts/Roboto-Regular-normal';
import './PDFFonts/Roboto-Medium-normal';

import { FinInvoiceApi, FinLedgerEventApi } from '../../../../../api';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { DOWNLOAD_HAS_NOT_YET_CLEARED_VIRUS_SCAN } from '../../../../../constants/notificationMessages';
import { base64Decode, removeQuotesIfExist } from '../../../../../services/string';
import { generateUrlByKey } from '../../../../root/router';
import { CREDIT_MEMO, DEBIT_MEMO, PARENT, WITHHOLDING } from '../InvoiceTable/TableConstants';

import { EmailDetailsModal } from './InvoiceHistory/EmailDetailsModal/EmailDetailsModal';
import { SendMailModal } from './SendMailModal/SendMailModal';
import { DRAFT, EXPENSE_FILE, REIMBURSEMENT_FILE } from './CreateInvoiceConstants';

export const allInvoiceItemsFromSameSsu = preparedInvoiceItems => {
  return uniqBy(preparedInvoiceItems, e => e.ssuId).length === 1;
};

export const areAnyInvoicedItem = preparedInvoiceItems => {
  return preparedInvoiceItems.every(item => !item.invoiceNumber);
};

export const allInvoiceItemsWithSameClient = preparedInvoiceItems => {
  return uniqBy(preparedInvoiceItems, e => e.finClientId).length === 1;
};

export const resolveTerm = terms => {
  switch (terms) {
    case 'Upon Receipt':
    case 'Net 30':
      return 30;
    case 'Net 45':
      return 45;
    case 'Net 60':
      return 60;
    case 'Net 90':
      return 90;
    default:
      return 30;
  }
};

export const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

export function payeeToString(payee) {
  return `${payee?.name} ${payee?.address1}, ${payee?.address2} ${payee?.city}, ${payee?.state} ${payee?.zipCode}`;
}

export const transformedPayees = payees => {
  return payees?.map(el => (
    <div className="r-ss-selected-label" key={el?.id}>
      {payeeToString(el)}
    </div>
  ));
};

export const openSendmailModal = async ({
  currentInvoiceType,
  invoiceNumber,
  initialSubject,
  initialMailText,
  initialFiles,
  closeCreateInvoiceAfterSave,
  emailTo,
  emailFrom,
  emailCc,
  emailBcc,
  emailModalBoxHeader,
  saveNewInvoice,
  cancelEmailSanding,
  loadInvoice,
  clientName,
  billToList,
  invoiceId,
  setInvoiceHistory,
  createInvoiceItems,
  setInvoiceNumber,
  setSavingType
}) => {
  const expenseAttachmentIds = await getExpenseAttachmentIds(createInvoiceItems);
  if (expenseAttachmentIds?.length > 0) {
    const invoiceId = createInvoiceItems?.createdInvoiceDetails?.id;
    initialFiles = await uploadAttachments(
      expenseAttachmentIds,
      initialFiles,
      setInvoiceNumber,
      invoiceId,
      setSavingType,
      EXPENSE_FILE
    );
  }
  if (createInvoiceItems?.originalLedgerEvents?.some(event => event?.budgetEventType === 'Patient Reimbursement')) {
    const reimbursementAttachmentIds = createInvoiceItems.originalLedgerEvents
      .flatMap(event => event.reimbursementItems)
      .flatMap(item => item.fileIds);
    const invoiceId = createInvoiceItems?.createdInvoiceDetails?.id;
    initialFiles = await uploadAttachments(
      reimbursementAttachmentIds,
      initialFiles,
      setInvoiceNumber,
      invoiceId,
      setSavingType,
      REIMBURSEMENT_FILE
    );
  }
  ModalBoxes.open({
    component: (
      <SendMailModal
        invoiceNumber={invoiceNumber}
        initialSubject={initialSubject}
        initialMailText={initialMailText}
        initialFiles={initialFiles}
        closeCreateInvoiceAfterSave={closeCreateInvoiceAfterSave}
        emailFrom={emailFrom}
        emailTo={!Array.isArray(emailTo) ? [emailTo] : emailTo}
        emailCc={Array.isArray(emailCc) ? emailCc : []}
        emailBcc={!isEmpty(emailBcc) ? emailBcc : []}
        emailModalBoxHeader={emailModalBoxHeader}
        saveNewInvoice={saveNewInvoice}
        cancelEmailSanding={cancelEmailSanding}
        loadInvoice={loadInvoice}
        currentInvoiceType={currentInvoiceType}
        clientName={clientName}
        billToList={billToList}
        setInvoiceHistory={setInvoiceHistory}
        invoiceId={invoiceId}
      />
    ),
    size: 'w650',
    className: 'invoice-email-modal',
    onClose: () => isFunction(cancelEmailSanding) && cancelEmailSanding()
  });
};

async function getExpenseAttachmentIds(createInvoiceItems) {
  const expenseVariableLedgerEventIds =
    createInvoiceItems?.originalLedgerEvents
      ?.filter(item => item.eventType === 'EXPENSE_VARIABLE')
      .map(item => item.id) ?? [];

  if (expenseVariableLedgerEventIds.length === 0) return [];

  const { data } = await FinInvoiceApi.getExpenseAttachmentIdsForInvoice(expenseVariableLedgerEventIds);
  return data;
}

function uploadAttachments(attachmentIds, initialFiles, setInvoiceNumber, invoiceId, setSavingType, uploadFileType) {
  return new Promise(resolve => {
    if (attachmentIds?.length > 0) {
      let promises = [];
      let errorDisplayed = false;

      attachmentIds.forEach(id =>
        promises.push(
          (uploadFileType === EXPENSE_FILE
            ? FinInvoiceApi.uploadAttachmentForExpense(id, false)
            : FinInvoiceApi.uploadAttachmentForReimbursement(id, false)
          )
            .then(({ data, headers }) => {
              let reader = new FileReader();
              return new Promise(resolve => {
                reader.onload = () => {
                  const content = getBlobContent(reader.result);
                  headers['content-disposition'] = decodeFilename(headers['content-disposition']);
                  resolve({
                    attachment: true,
                    fileName: removeQuotesIfExist(
                      headers['content-disposition'].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
                    ),
                    content,
                    size: atob(content).length,
                    contentType: data.type,
                    id: uuid()
                  });
                };
                reader.readAsDataURL(data);
              });
            })
            .catch(error => {
              if (error.response && error.response.status === 409 && !errorDisplayed) {
                NotificationManager.error(DOWNLOAD_HAS_NOT_YET_CLEARED_VIRUS_SCAN);
                errorDisplayed = true;
                if (!invoiceId) {
                  setInvoiceNumber(DRAFT);
                  setSavingType(null);
                }
                return Promise.reject();
              }
            })
        )
      );

      Promise.all(promises).then(files => {
        const allFiles = [...initialFiles, ...files];
        resolve(
          uniqWith(
            allFiles,
            (firstValue, secondValue) =>
              firstValue.content === secondValue.content && firstValue.fileName === secondValue.fileName
          )
        );
      });
    } else {
      resolve(initialFiles);
    }
  });
}

export const openMailDetailsModal = (
  emailId,
  filePreview,
  invoiceNumber,
  emailModalBoxHeader,
  currentInvoiceType,
  billToList,
  invoiceId,
  setInvoiceHistory
) => {
  ModalBoxes.open({
    component: (
      <EmailDetailsModal
        emailId={emailId}
        filePreview={filePreview}
        invoiceNumber={invoiceNumber}
        emailModalBoxHeader={emailModalBoxHeader}
        currentInvoiceType={currentInvoiceType}
        billToList={billToList}
        setInvoiceHistory={setInvoiceHistory}
        invoiceId={invoiceId}
      />
    ),
    size: 'w650',
    className: 'invoice-email-details-modal'
  });
};

export const decodeFilename = header => {
  const fileNameEncoded = header.match(/filename="([^"]+)"/)[1];
  const fileNameDecoded = base64Decode(fileNameEncoded);
  return header.replace(/filename="[^"]+"/g, `filename="${fileNameDecoded}"`);
};

export const openFile = (type, attachmentId, fileViewer) => {
  return fileViewer.openFileByPromise(
    new Promise((resolve, reject) => {
      FinInvoiceApi.getAttachedFile(type, attachmentId)
        .then(({ data: blob, headers }) => {
          resolve({
            data: blob,
            headers: {
              'content-disposition': decodeFilename(headers['content-disposition'])
            }
          });
        })
        .catch(err => {
          reject(err);
        });
    })
  );
};

export const generateEmailPDF = (emailTemplateRef, afterGenerate, attachments, linkAreas) => {
  const doc = new jsPDF({
    unit: 'px',
    hotfixes: ['px_scaling']
  });
  doc.setFont('Roboto-Regular', 'normal');
  doc.setFont('Roboto-Medium', 'normal');
  doc.html(emailTemplateRef.current, {
    async callback(doc) {
      linkAreas.forEach((area, index) =>
        doc.link(area.x, area.y, area.width, area.height, {
          url: `http://${window.location.host}${generateUrlByKey('Invoice.Email Attachment', {
            attachmentId: attachments[index]?.fileId
          })}`
        })
      );
      const file = doc.output('datauristring');
      afterGenerate(file);
    },
    autoPaging: 'text',
    filename: 'EmailDetails',
    margin: [20, 30, 20, 30]
  });
};

export const downloadFile = (fileUrl, fileName) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const splitEmailAddresses = emailData => {
  const newEmailData = { ...emailData };

  ['to', 'cc', 'bcc'].forEach(field => {
    if (!Array.isArray(newEmailData[field]) && newEmailData[field]) {
      newEmailData[field] = newEmailData[field].split(', ');
    }
  });

  return newEmailData;
};

export const resendEmail = (
  emailData,
  invoiceNumber,
  emailModalBoxHeader,
  currentInvoiceType,
  billToList,
  invoiceId,
  setInvoiceHistory
) => {
  let promises = [];
  const allAttachments = emailData.attachments.map(attachment => attachment.fileId);
  const processedEmailData = splitEmailAddresses(emailData);

  allAttachments.forEach(id =>
    promises.push(
      FinInvoiceApi.getAttachedFile('SEND', id, false).then(async ({ data, headers }) => {
        let reader = new FileReader();
        return new Promise(resolve => {
          reader.onload = () => {
            const content = getBlobContent(reader.result);
            headers['content-disposition'] = decodeFilename(headers['content-disposition']);
            return resolve({
              attachment: true,
              fileName: removeQuotesIfExist(
                headers['content-disposition'].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
              ),
              content,
              size: atob(content).length,
              contentType: data.type,
              id: uuid()
            });
          };
          reader.readAsDataURL(data);
        });
      })
    )
  );
  Promise.all(promises).then(files =>
    openSendmailModal({
      currentInvoiceType,
      invoiceNumber,
      initialSubject: processedEmailData.subject,
      initialMailText: processedEmailData.body,
      initialFiles: files,
      emailTo: processedEmailData.to,
      emailFrom: processedEmailData.from,
      emailCc: processedEmailData.cc,
      emailBcc: processedEmailData.bcc,
      emailModalBoxHeader,
      billToList,
      invoiceId,
      setInvoiceHistory
    })
  );
};

export const getFileForSandingEmail = (fileName, file) => {
  const content = getBlobContent(file);
  return [
    {
      attachment: false,
      fileName: `${fileName}`,
      content,
      size: atob(content).length,
      contentType: 'application/pdf',
      id: uuid()
    }
  ];
};

export const getBlobContent = fileUrlString => fileUrlString.split(',')[1];

export const resolveEventSuffix = (eventNumber, eventType) => {
  switch (eventType) {
    case PARENT:
      return eventNumber;
    case WITHHOLDING:
      return eventNumber + '-WH';
    case CREDIT_MEMO:
      return eventNumber + '-CM';
    case DEBIT_MEMO:
      return eventNumber + '-DM';
    default:
      return eventNumber;
  }
};

export const getNInvoiceNumbers = n => {
  const requests = Array.apply(null, Array(n)).map(() =>
    FinLedgerEventApi.generateInvoiceNumber().then(({ data }) => data)
  );
  return Promise.all(requests);
};

export const createPDF = async html => {
  const doc = new jsPDF({
    format: [612, 791.001],
    unit: 'px',
    hotfixes: ['px_scaling']
  });
  doc.setFont('Roboto-Regular', 'normal');
  doc.setFont('Roboto-Medium', 'normal');
  await doc.html(html, {
    autoPaging: 'text',
    width: 389,
    windowWidth: 389
  });
  return doc.output('datauristring');
};

export const createMultiplePDFFiles = async refMap => {
  const refArray = Array.from(refMap, ([name, value]) => ({ ref: value, eventId: name }));
  const docsOutput = [];
  const createPDFS = async index => {
    const doc = new jsPDF({
      format: [612, 791.001],
      unit: 'px',
      hotfixes: ['px_scaling']
    });
    doc.setFont('Roboto-Regular', 'normal');
    doc.setFont('Roboto-Medium', 'normal');
    docsOutput.push({ content: doc, eventId: refArray[index].eventId });
    await docsOutput[index].content.html(refArray[index].ref, {
      autoPaging: 'text',
      width: 389,
      windowWidth: 389
    });
    docsOutput[index].content = await docsOutput[index].content.output('datauristring');
    if (index < refArray.length - 1) {
      await createPDFS(index + 1);
    }
  };
  await createPDFS(0);
  return docsOutput;
};
