import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../../constants/dateFormat';
import { travelTimeList } from '../../EncountersSection/TravelSection/travelConstants';

export default function PreferredTravelDateAndTime({ values, errors, actions, readOnly = false }) {
  const { setFieldValue, handleChange, handleCheckboxGroupChange } = actions;
  const { departureDate, returnDate, preferredDepartureAirport, preferredArrivalAirport, preferredTravelTime } = values;
  return (
    <Grid container spacing={2} alignItems="flex-start" sx={{ pt: 3 }}>
      <Grid xs={12}>
        <Typography variant="subtitle2">Preferred Travel Date and Time</Typography>
      </Grid>
      <Grid xs="auto">
        <DesktopDatePicker
          disabled={readOnly}
          disablePast={true}
          name="departureDate"
          value={departureDate}
          timezone="system"
          onChange={function(date) {
            setFieldValue('departureDate', date);
          }}
          label="Departure date"
          format={DD_SLASH_MMM_SLASH_YYYY}
          views={['year', 'month', 'day']}
          slotProps={{
            textField: {
              variant: 'standard',
              fullWidth: true,
              error: Boolean(errors.departureDate),
              helperText: errors.departureDate
            }
          }}
        />
      </Grid>
      <Grid xs="auto">
        <DesktopDatePicker
          disabled={readOnly}
          disablePast={true}
          name="returnDate"
          value={returnDate}
          timezone="system"
          onChange={function(date) {
            setFieldValue('returnDate', date);
          }}
          label="Return date"
          format={DD_SLASH_MMM_SLASH_YYYY}
          views={['year', 'month', 'day']}
          slotProps={{
            textField: {
              variant: 'standard',

              fullWidth: true,
              error: Boolean(errors.returnDate),
              helperText: errors.returnDate
            }
          }}
        />
      </Grid>
      <Grid xs="auto">
        <TextField
          disabled={readOnly}
          name="preferredDepartureAirport"
          value={preferredDepartureAirport}
          onChange={handleChange}
          fullWidth
          label="Preferred Departure Airport"
          variant="standard"
          autoComplete="off"
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      <Grid xs="auto">
        <TextField
          disabled={readOnly}
          name="preferredArrivalAirport"
          value={preferredArrivalAirport}
          onChange={handleChange}
          fullWidth
          label="Preferred Arrival Airport"
          variant="standard"
          autoComplete="off"
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      <Grid xs></Grid>
      <Grid xs={12}>
        <FormControl disabled={readOnly} component="fieldset" variant="standard">
          <FormGroup row>
            {travelTimeList.map(function({ id, name }) {
              return (
                <FormControlLabel
                  key={id}
                  sx={{ margin: 0 }}
                  control={
                    <Checkbox
                      checked={preferredTravelTime.includes(id)}
                      onChange={handleCheckboxGroupChange}
                      value={id}
                      name="preferredTravelTime"
                    />
                  }
                  label={name}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}
