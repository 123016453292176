import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  createStudyEnrollmentActionCreator,
  updateStudyEnrollmentsActionCreator
} from '../../../../../actions/finance/forecasting/forecastingActions';
import Input from '../../../../../common/data-entry/Input';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';

const StudyEnrolmentPredictionReasonDialog = ({ modalBox, ...props }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState(props.reason || '');
  const [validationMessage, setValidationMessage] = useState(reason.trim().length === 0 ? 'Reason is required' : null);

  const handleReasonChange = useCallback(event => {
    const { value } = event.target;
    setReason(value);
    setValidationMessage(value.trim().length === 0 ? 'Reason is required' : null);
  }, []);

  const onSave = useCallback(() => {
    const action = props.id
      ? updateStudyEnrollmentsActionCreator({ ...props, reason })
      : createStudyEnrollmentActionCreator({ ...props, reason });

    dispatch(action);
    modalBox.close();
  }, [dispatch, props, reason, modalBox]);

  const remainingToEnroll = useMemo(() => (props.expectedCountSiv || props.expectedCountPsv) - props.enrolledCount, [
    props.expectedCountSiv,
    props.expectedCountPsv,
    props.enrolledCount
  ]);

  const predictedToEnroll = useMemo(
    () =>
      props.enrollmentPredictionValues.reduce((acc, epv) => {
        const value = Number(epv.value);
        return acc + (isNaN(value) ? 0 : value);
      }, 0),
    [props.enrollmentPredictionValues]
  );

  return (
    <>
      <ModalBoxes.Body>
        <div className="container">
          <div className="row">
            <div className="col">Remaining to enroll: {remainingToEnroll}</div>
          </div>
          <div className="row">
            <div className="col">Predicted to enroll: {predictedToEnroll}</div>
          </div>
          <div className="text-box-field">
            <Input
              label="Reason"
              name="reason"
              value={reason}
              onChange={handleReasonChange}
              validationMessage={validationMessage}
              maxLength={1000}
            />
            <div className="text-box-counter">{`${reason.length}/1000`}</div>
          </div>
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Close
          </Button>
          <Button onClick={onSave} disabled={!reason.trim()}>
            Save
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};

StudyEnrolmentPredictionReasonDialog.className = 'enrollment-reason-dialog';
StudyEnrolmentPredictionReasonDialog.size = 'w800';

export default StudyEnrolmentPredictionReasonDialog;
