import { find, forEach, map, reduce } from 'lodash/collection';
import { isArray, isBoolean, isEmpty, isEqual } from 'lodash/lang';
import { get } from 'lodash/object';

export function collectConditionalLogic(standardConditionalLogic, allFields, fieldIdKey) {
  const nameKeyMap = new Map(allFields.map(f => [f.name, f]));
  const triggerFieldNamePath = fieldIdKey === 'key' ? 'conditionField.question' : 'conditionFieldName';

  return reduce(
    allFields,
    function(accumulator, field) {
      const targetFieldId = field[fieldIdKey];
      const targetFieldName = field.name;

      Object.assign(accumulator, getStandardConfigurations(targetFieldId, targetFieldName));

      const configurationResponse = field.fieldConfigurationResponse;

      if (isEmpty(configurationResponse)) {
        return accumulator;
      }

      const configurationList = isArray(configurationResponse) ? configurationResponse : [configurationResponse];

      forEach(configurationList, function(configuration) {
        const triggerFieldName = get(configuration, triggerFieldNamePath);

        const triggerField = nameKeyMap.get(triggerFieldName);

        if (!triggerField) {
          return;
        }

        const triggerFieldId = triggerField?.[fieldIdKey];

        const answers = map(configuration.conditionFieldAnswerNames, function(answer) {
          return find(triggerField.codeDefinationList, ['name', answer]).decode;
        });

        const isHideCondition = isBoolean(configuration.isHideCondition) ? configuration.isHideCondition : true;

        if (isEmpty(accumulator[triggerFieldId])) {
          accumulator[triggerFieldId] = {
            sourceIdentifier: triggerFieldId,
            required: false,
            conditionalLogicFieldsConfigs: []
          };
        }

        const triggerFieldConditions = accumulator[triggerFieldId];

        const matchedCondition = find(
          triggerFieldConditions.conditionalLogicFieldsConfigs,
          condition => condition.isHideCondition === isHideCondition && isEqual(condition.answers, answers)
        );

        if (isEmpty(matchedCondition)) {
          triggerFieldConditions.conditionalLogicFieldsConfigs.push({
            answers,
            isHideCondition,
            questions: [targetFieldId]
          });
          return;
        }

        matchedCondition.questions.push(targetFieldId);
      });

      return accumulator;
    },
    {}
  );
  function getStandardConfigurations(fieldId, fieldName) {
    const standardConfiguration = standardConditionalLogic?.[fieldName];
    if (standardConfiguration) {
      const { required, conditionalLogicFieldsConfigs } = standardConfiguration;
      return {
        [fieldId]: {
          sourceIdentifier: fieldId,
          required: required,
          conditionalLogicFieldsConfigs: map(conditionalLogicFieldsConfigs, function(config) {
            return {
              ...config,
              questions: map(config.questions, name => nameKeyMap.get(name)?.[fieldIdKey])
            };
          })
        }
      };
    }
    return {};
  }
}
