import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';

import { generateCondition } from '../services';

import ConfigurationTableRow from './ConfigurationTableRow/ConfigurationTableRow';

export default function ConfigurationTable({
  allAccessibleFields,
  domainTargetFields,
  conditions,
  domainConditions,
  conditionsMap,
  domainConditionsMap,
  multiple,
  hasDomainConditions,
  setConditions
}) {
  return (
    <TableContainer component={Paper} sx={{ mt: 3, overflow: 'initial' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={150}>Condition</TableCell>
            <TableCell>When</TableCell>
            <TableCell width={250}>Value</TableCell>
            <TableCell width={36}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {domainConditions.map(function(condition) {
            const { conditionId } = condition;
            return (
              <ConfigurationTableRow
                type="DOMAIN"
                key={conditionId}
                allAccessibleFields={allAccessibleFields}
                domainTargetFields={domainTargetFields}
                condition={condition}
                conditionsMap={conditionsMap}
                domainConditionsMap={domainConditionsMap}
                setConditions={setConditions}
              />
            );
          })}
          {conditions.map(function(condition) {
            const { conditionId, hideAllFields } = condition;
            return (
              <ConfigurationTableRow
                type={hideAllFields ? 'ALL' : 'PLAIN'}
                key={conditionId}
                allAccessibleFields={allAccessibleFields}
                domainTargetFields={domainTargetFields}
                condition={condition}
                conditionsMap={conditionsMap}
                domainConditionsMap={domainConditionsMap}
                setConditions={setConditions}
                hasDomainConditions={hasDomainConditions}
              />
            );
          })}
        </TableBody>
        {(multiple || (hasDomainConditions && conditions.length < 1)) && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                <Button variant="outlined" startIcon={<AddIcon />} size="small" onClick={addNewCondition}>
                  Add
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );

  function addNewCondition() {
    setConditions(function(state) {
      return [...state, generateCondition()];
    });
  }
}
