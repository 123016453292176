import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { orderBy } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import { TaskApi } from '../../../../../api';
import EncounterLogCheckApi from '../../../../../api/patient/EncounterLogCheckApi';
import InvestigatorSignatureApi from '../../../../../api/patient/InvestigatorSignatureApi';
import LogTableApi from '../../../../../api/patient/LogTableApi';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import useSessionStorage from '../../../../../common/hooks/useSessionStorage';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { GROUP_SIGN_EXCEPTION } from '../../../../../constants/notificationMessages';
import resolveStudyGroupAssignAndGroupAssignGroups from '../../../../../services/groupAssignStudyGroupsSetvice';
import { onRequestError } from '../../../../../services/handlers';
import openKeepIsHidden from '../../../../root/Container/Layout/Tasks/openKeepIsHidden';
import { generateUrlByKey, useCurrentRoute } from '../../../../root/router';
import { showEncounterColumn } from '../../../setup/shared/ElementSetupNew/defaultLogCheckOptions';
import { AddCommentModal } from '../../shared/Review/ReviewContent/ItemGroupElement/AddCommentModal/AddCommentModal';

import { defaultPage, defaultPageSize, defaultSorted, LOGS_PAGE_VALUES } from './constants';

export const LogsContext = React.createContext(null);

export function LogsPageContextProvider({ children }) {
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const [sessionStorage, setSessionStorage] = useSessionStorage(LOGS_PAGE_VALUES, {});
  const showLogCheck = currentRoute.key.includes('Patient Studies.Encounter Details.Logs');
  const [page, setPage] = useState(sessionStorage.page || defaultPage);
  const [pageSize, setPageSize] = useState(sessionStorage.pageSize || defaultPageSize);
  const [sorted, setSorted] = useState(sessionStorage.sorted || defaultSorted);
  const patientId = currentRoute.params?.patientId;
  const ssuPatientId = currentRoute.params?.ssuPatientId;
  const newPatientItemGroupId = currentRoute.params?.patientItemGroupId;
  const patientEncounterId = currentRoute.params?.patientEncounterId;
  const reviewPatientItemGroupId = currentRoute.params?.reviewPatientItemGroupId;
  const [patientItemGroupId, setPatientItemGroupId] = useState(sessionStorage.patientItemGroupId || null);
  const [currentItemGroupTemplateVersionId, setCurrentItemGroupTemplateVersionId] = useState(null);
  const [currentStudyId, setCurrentStudyId] = useState(null);
  const [currentStudySiteId, setCurrentStudySiteId] = useState(null);
  const [directions, setDirections] = useState(null);
  const [currentItemGroupName, setCurrentItemGroupName] = useState(null);
  const [itemGroupData, setItemGroupData] = useState({});
  const [itemGroupDomainCode, setItemGroupDomainCode] = useState(null);
  const [showEncounter, setShowEncounter] = useState(true);
  const [patientInfo, setPatientInfo] = useState(null);
  const [logCheck, setLogCheck] = useState(null);
  const [logCheckAnswers, setLogCheckAnswers] = useState(null);
  const [itemGroupTemplateId, setItemGroupTemplateId] = useState(null);
  const [isInActiveProtocol, setInActiveProtocol] = useState(null);
  const studySiteStatus = patientInfo?.studySiteStatus;
  const [historyForRow, setHistoryForRow] = useState(null);
  const [groupAssigns, setGroupAssigns] = useState(null);
  const [permissionType, setPermissionType] = useState(null);
  const commentIsReadonly = 'ALLOW_READ' === permissionType;
  const onEditRow = row => {
    navigate(
      generateUrlByKey(`${currentRoute.key}.Row`, {
        patientId,
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId: newPatientItemGroupId,
        reviewPatientItemGroupId: reviewPatientItemGroupId,
        rowId: row.rowId,
        itemGroupTemplateVersionId: row.itemGroupTemplateVersionId
      })
    );
  };
  const onEditComment = ({ original }, commentAnswer, event) => {
    if (isEmpty(commentAnswer) && commentIsReadonly) {
      return;
    }
    if (showLogCheck && original.aeStatuses[original.rowId] !== 'CANCELED') {
      const snapshotData = {
        studyId: currentStudyId,
        sitePatientEncounterId: original?.associatedEncounter?.encounterInstanceId,
        whenWasItDone: original.dateRecorded,
        rowId: original.rowId,
        rowVersion: original.data?.Comment?.rowVersion,
        commentAnswer: {
          stringValue: commentAnswer
        }
      };
      ModalBoxes.open({
        component: (
          <AddCommentModal
            context={{
              ssuPatientId,
              reviewItemGroupId: newPatientItemGroupId
            }}
            updateData={loadLogDataGeneral}
            snapshotData={snapshotData}
            isLogItemGroup={true}
            itemGroupTemplateId={itemGroupTemplateId}
            itemGroupTemplateVersionId={original.itemGroupTemplateVersionId}
            readonly={commentIsReadonly}
          />
        )
      });
      event.stopPropagation();
    }
  };

  const addNewLog = () => {
    navigate(
      generateUrlByKey(`${currentRoute.key}.Row`, {
        patientId,
        ssuPatientId,
        patientEncounterId,
        patientItemGroupId: newPatientItemGroupId,
        reviewPatientItemGroupId: reviewPatientItemGroupId,
        rowId: 'newRow',
        itemGroupTemplateVersionId: currentItemGroupTemplateVersionId
      })
    );
  };

  const getAllRelatedTasksForLog = useCallback(
    function() {
      TaskApi.getTasksByPatientIdForLogs(currentRoute.params.ssuPatientId, currentRoute.params.patientItemGroupId).then(
        ({ data }) => {
          if (!isEmpty(data)) {
            openKeepIsHidden(data[0].taskId, data[0].ssuId);
          }
        }
      );
    },
    [currentRoute.params.patientItemGroupId, currentRoute.params.ssuPatientId]
  );

  const prepareAndSetLogData = useCallback(data => {
    data.logData.tableData = orderBy(data.logData.tableData, 'logRowNumber', 'desc');
    data.logData.tableData = data.logData.tableData.map(e => {
      e.dateRecorded = moment(e.dateRecorded).format('DD/MMM/yyyy');
      Object.keys(e.data).forEach(key => {
        if (e.data[key] && e.data[key].answer?.indexOf('$$###$$') > 0) {
          const join = e.data[key].answer.split('$$###$$').join(', ');
          e.data[key].answer = join.slice(0, join.length - 2);
        }
      });
      e.aeStatuses = data?.logData?.aeStatuses;
      e.changedAnswers = data?.logData?.changedAnswers[e.rowId];
      return e;
    });
    setItemGroupData(data.logData);
    setItemGroupDomainCode(data.itemGroupDomainCode);
    setLogCheck(data.logCheck);
    setCurrentStudyId(data.studyId);
    setCurrentStudySiteId(data.studySiteId);
    setDirections(data.directions);
    setCurrentItemGroupName(data.itemGroupName);
    setCurrentItemGroupTemplateVersionId(data.currentItemGroupTemplateVersionId);
    setItemGroupTemplateId(data.itemGroupTemplateId);
    setPatientInfo(data.userInfoResponse);
    setInActiveProtocol(data.inActiveProtocol);
    setPermissionType(data.permissionType);
  }, []);

  useEffect(() => {
    setShowEncounter(showEncounterColumn(itemGroupDomainCode));
  }, [itemGroupDomainCode]);

  useEffect(() => {
    if (patientItemGroupId !== newPatientItemGroupId) {
      setPatientItemGroupId(newPatientItemGroupId);
      setPage(defaultPage);
      setPageSize(defaultPageSize);
      setSorted(defaultSorted);
      setSessionStorage({
        page: defaultPage,
        pageSize: defaultPageSize,
        sorted: defaultSorted,
        patientItemGroupId: newPatientItemGroupId
      });
    }
  }, [newPatientItemGroupId, patientItemGroupId, sessionStorage.patientItemGroupId, setSessionStorage]);

  const loadLogDataWithLogCheck = useCallback(() => {
    const params = {
      ssuPatient: ssuPatientId,
      itemGroupId: newPatientItemGroupId
    };
    LogTableApi.getLogTableWithLogCheck(params).then(({ data }) => {
      EncounterLogCheckApi.getLogCheckAnswers(
        data.studyId,
        ssuPatientId,
        patientEncounterId,
        data.itemGroupTemplateId,
        data.originItemGroupTemplateVersionId
      ).then(({ data: answers }) => {
        prepareAndSetLogData(data);
        setLogCheckAnswers(answers);
      }, onRequestError);
    });
  }, [patientEncounterId, newPatientItemGroupId, ssuPatientId, prepareAndSetLogData]);

  const loadLogData = useCallback(() => {
    const params = {
      ssuPatient: ssuPatientId,
      itemGroupTemplateId: newPatientItemGroupId
    };
    LogTableApi.getLogTable(params).then(({ data }) => {
      prepareAndSetLogData(data);
    });
  }, [newPatientItemGroupId, ssuPatientId, prepareAndSetLogData]);

  const loadLogDataGeneral = useCallback(() => {
    if (showLogCheck) {
      loadLogDataWithLogCheck();
    } else {
      loadLogData();
    }
  }, [showLogCheck, loadLogDataWithLogCheck, loadLogData]);

  useEffect(() => {
    getAllRelatedTasksForLog();
  }, [getAllRelatedTasksForLog]);

  useEffect(
    function() {
      loadLogDataGeneral();
      resolveStudyGroupAssignAndGroupAssignGroups(currentStudyId).then(res => {
        setGroupAssigns(res.groups);
      });
    },
    [currentRoute, currentStudyId, loadLogDataGeneral]
  );

  const longAnswers = useMemo(() => {
    let longAnswers = new Set();

    if (itemGroupData?.columnList && itemGroupData?.tableData) {
      itemGroupData?.columnList.forEach(e => {
        itemGroupData?.tableData.forEach(ans => {
          if (ans.data[e.key]?.answer?.length > 15) {
            longAnswers.add(e.key);
          }
        });
      });
    }
    return [...longAnswers];
  }, [itemGroupData?.columnList, itemGroupData?.tableData]);

  const signAll = (req, token) => {
    const request = {
      loggedUserId: req.loggedUserId,
      loggedUserRole: req.loggedUserRole,
      groupAssignId: req.groupAssignId,
      reason: req.reason,
      rowsMetaData: itemGroupData?.tableData
        .filter(row => row.aeStatuses[row.rowId] === 'PI_REVIEW')
        .map(e => {
          return { itemGroupTemplateVersionId: e.itemGroupTemplateVersionId, rowId: e.rowId };
        })
    };
    return InvestigatorSignatureApi.signAdverseEventLogRows(
      currentStudyId,
      ssuPatientId,
      itemGroupTemplateId,
      currentItemGroupTemplateVersionId,
      request,
      token
    )
      .then(() => {
        navigate(generateUrlByKey(currentRoute.parent.key, currentRoute.params));
        NotificationManager.success('Rows signed successfully');
      })
      .catch(err => onRequestError(err, { customMessage: GROUP_SIGN_EXCEPTION }));
  };

  return (
    <LogsContext.Provider
      value={{
        studySiteStatus,
        isInActiveProtocol,
        itemGroupData,
        showLogCheck,
        patientInfo,
        logCheck,
        addNewLog,
        page,
        setPage: page => {
          setPage(page);
          setSessionStorage({ ...sessionStorage, page });
        },
        pageSize,
        setPageSize: pageSize => {
          setPageSize(pageSize);
          setSessionStorage({ ...sessionStorage, pageSize });
        },
        sorted,
        setSorted: sorted => {
          setSorted(sorted);
          setSessionStorage({ ...sessionStorage, sorted });
        },
        logCheckAnswers,
        showEncounter,
        currentStudySiteId,
        itemGroupDomainCode,
        onEditRow,
        signAll,
        onEditComment,
        longAnswers,
        historyForRow,
        setHistoryForRow,
        itemGroupTemplateId,
        currentItemGroupTemplateVersionId,
        groupAssigns,
        directions,
        currentItemGroupName,
        permissionType
      }}
    >
      {children}
    </LogsContext.Provider>
  );
}

export function WithLogsPageContext(Component) {
  return function WrapperComponent(props) {
    return (
      <LogsPageContextProvider>
        <Component {...props} />
      </LogsPageContextProvider>
    );
  };
}
