import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { some } from 'lodash/collection';
import moment from 'moment/moment';

import { PatientStatusApi, PreScreenApi, StudySiteApi } from '../../../../api';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import useSessionStorage from '../../../../common/hooks/useSessionStorage';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { EXPORT_FAILED, PATIENT_STATUS_UPDATED } from '../../../../constants/notificationMessages';
import { onFileSave, onRequestError } from '../../../../services/handlers';
import { mapStudySite } from '../../../../services/studySiteMapper';
import PatientStatusChangeModal from '../../patient-source/Patients/PatientInfo/PatientInfoSidebar/PatientStatusChangeModal';

import { screeningStatuses } from './WorklistTable/WorklistTableConstants';
import { prepareRenderData } from './worklistService';

export const WorklistContext = createContext(null);

export const WorklistProvider = ({ children }) => {
  const firstSSULoading = useRef(true);
  const [savedFilters, setSavedFilters] = useSessionStorage('PRESCREEN_WORKLIST_FILTERS', {});
  const [tableData, setTableData] = useState([]);
  const [itemGroupAccessors, setItemGroupAccessors] = useState([]);
  const [requiredFieldsNotSelected, setRequiredFieldsNotSelected] = useState(false);

  const emptyFilters = useMemo(
    () => ({
      studyName: null,
      siteName: null,
      siteId: null,
      studyId: null,
      projectCode: null,
      prescreenStatus: [],
      startDate: moment('2019-01-01').set({ hour: 0, minute: 0, second: 0, millisecond: 1 }),
      endDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
    }),
    []
  );

  const [workListFilteringData, setWorkListFilteringData] = useState(emptyFilters);

  const [studySiteList, setStudySiteList] = useState([]);

  const updatePage = useCallback(
    (filteringData = workListFilteringData) => {
      if (filteringData.studyId) {
        const data = {
          ...filteringData,
          prescreenStatus: filteringData.prescreenStatus.map(status => status.id)
        };
        PreScreenApi.filterWorkListPatients(data).then(res => {
          const renderData = prepareRenderData(res.data);
          setTableData(renderData.patients);
          setItemGroupAccessors(prevState =>
            isEqual(renderData.itemGroupAccessors, prevState) ? prevState : renderData.itemGroupAccessors
          );
          setSavedFilters(filteringData);
        });
      } else {
        if (!firstSSULoading.current) {
          setRequiredFieldsNotSelected(true);
        }
      }
    },
    [firstSSULoading, setSavedFilters, workListFilteringData]
  );

  useEffect(() => {
    if (firstSSULoading.current) {
      firstSSULoading.current = false;
      StudySiteApi.getStudySiteWithNonProtocolEncounter()
        .then(({ data }) => {
          const mappedData = data.map(ssu => mapStudySite(ssu));
          setStudySiteList(mappedData);
          if (!isEmpty(savedFilters) && some(mappedData, ssu => ssu.study.uniqueIdentifier === savedFilters.studyId)) {
            const preparedFilters = {
              ...savedFilters,
              startDate: moment(savedFilters.startDate),
              endDate: moment(savedFilters.endDate)
            };
            setWorkListFilteringData(preparedFilters);
            updatePage(preparedFilters);
          }
        })
        .catch(e => onRequestError(e));
    }
  }, [firstSSULoading, savedFilters, updatePage]);

  const downloadCSV = useCallback(() => {
    PreScreenApi.exportPatients(workListFilteringData.studyId, workListFilteringData.siteId)
      .then(onFileSave)
      .catch(() => {
        NotificationManager.error(EXPORT_FAILED);
      });
  }, [workListFilteringData]);

  const resetFilters = useCallback(() => {
    setWorkListFilteringData(emptyFilters);
  }, [emptyFilters]);

  const openDropStatusModal = useCallback(
    (initialStatus, prevStatus, sitePatientId) => {
      const updateTableData = (sitePatientId, newStatus, renderData) => {
        const updatedRenderData = renderData.map(row =>
          row.sitePatientId === sitePatientId ? { ...row, status: newStatus } : row
        );
        setTableData(updatedRenderData);
      };

      const updatePreScreenStatus = (sitePatientId, status, reason, comment) => {
        return PatientStatusApi.changeStatus(sitePatientId, {
          statusCodeId: status?.id,
          withdrawReason: reason?.id,
          comment,
          statusChangeLocation: 'PRE_SCREEN_WORKLIST',
          statusDate: moment().format('YYYY-MM-DD')
        });
      };

      const getPreScreenStatuses = status => {
        return screeningStatuses.map(screeningStatus => screeningStatus.id).includes(status.id);
      };

      updateTableData(sitePatientId, initialStatus.code, tableData);
      const patientStatusesChangeModal = ModalBoxes.open({
        component: (
          <PatientStatusChangeModal
            initialStatus={initialStatus}
            allowedStatusesPredicate={getPreScreenStatuses}
            updateStatus={(status, reason, comment) =>
              updatePreScreenStatus(sitePatientId, status, reason, comment).then(() => {
                NotificationManager.success(PATIENT_STATUS_UPDATED);
                patientStatusesChangeModal.close();
                updatePage();
              }, onRequestError)
            }
          />
        ),
        onClose: () => {
          updateTableData(sitePatientId, prevStatus, tableData);
        }
      });
    },
    [tableData, updatePage]
  );

  const updateStatus = useCallback(
    (sitePatientId, prevStatus, newStatusCode) => {
      const initialStatus = screeningStatuses.find(e => e.code === newStatusCode);
      if (initialStatus?.dropStatus) {
        openDropStatusModal(initialStatus, prevStatus, sitePatientId);
      } else {
        PatientStatusApi.changeStatus(sitePatientId, {
          statusCodeId: initialStatus.id,
          statusChangeLocation: 'PRE_SCREEN_WORKLIST',
          statusDate: moment().format('YYYY-MM-DD')
        }).then(() => {
          NotificationManager.success(PATIENT_STATUS_UPDATED);
          updatePage();
        }, onRequestError);
      }
    },
    [openDropStatusModal, updatePage]
  );

  const value = useMemo(
    () => ({
      tableData,
      itemGroupAccessors,
      updateStatus,
      workListFilteringData,
      setWorkListFilteringData,
      downloadCSV,
      studySiteList,
      updatePage,
      resetFilters,
      requiredFieldsNotSelected,
      setRequiredFieldsNotSelected
    }),
    [
      tableData,
      itemGroupAccessors,
      updateStatus,
      workListFilteringData,
      downloadCSV,
      studySiteList,
      updatePage,
      resetFilters,
      requiredFieldsNotSelected,
      setRequiredFieldsNotSelected
    ]
  );

  return <WorklistContext.Provider value={value}>{children}</WorklistContext.Provider>;
};

export const withWorklistContext = Component => props => {
  return (
    <WorklistProvider>
      <Component {...props} />
    </WorklistProvider>
  );
};
