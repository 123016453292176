import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { omit } from 'lodash';
import { uniqBy } from 'lodash/array';
import { sortBy } from 'lodash/collection';
import { isEqual } from 'lodash/lang';

import { StudySiteApi } from '../../../../../../../api';
import MultiSelect from '../../../../../../../common/data-entry/MultiSelect/MultiSelect';
import Button from '../../../../../../../common/general/Button';
import { SiteCreditApplicationContext } from '../SiteCreditApplicationContext';

export const SiteCreditApplicationFilters = () => {
  const { selectedStudies, setSelectedStudies, fetchEligibleEventsForApplyCredit } = useContext(
    SiteCreditApplicationContext
  );
  const [studies, setStudies] = useState([]);
  const {
    state: {
      row: { siteId, studyId }
    }
  } = useLocation();

  const extractNecessaryListFromStudySite = useCallback((uniqueIdentifier, filterIdentifier, name, studySiteList) => {
    const studySiteFilteredByIdentifier = studySiteList.filter(
      ssu => !uniqueIdentifier || uniqueIdentifier === ssu[filterIdentifier]
    );
    const list = studySiteFilteredByIdentifier.map(ssu => ssu[name]);
    const uniqueList = uniqBy(list, 'uniqueIdentifier');
    return sortBy(uniqueList, `${name}Name`);
  }, []);

  useEffect(() => {
    StudySiteApi.getAllStudySitesAndMap().then(({ data }) => {
      const studies = extractNecessaryListFromStudySite(siteId, 'siteIdentifier', 'study', data).map(study => ({
        ...study,
        projectCode: study.projectCode ? study.projectCode : 'No PCN',
        id: study.uniqueIdentifier
      }));
      const initialStudy = [{ ...studies.find(ssu => ssu.id === studyId), checked: 1 }];
      setStudies(studies);
      setSelectedStudies(initialStudy ? initialStudy : []);
    });
  }, [extractNecessaryListFromStudySite, setSelectedStudies, siteId, studyId]);

  const studiesForStudy = useMemo(() => {
    return studies.map(study => ({ ...study, name: study.studyName }));
  }, [studies]);

  const studiesForPCN = useMemo(() => {
    return studies.map(study => ({ ...study, name: study.projectCode }));
  }, [studies]);

  return (
    <div className="general-header-wrapper">
      <MultiSelect
        data-testid="site-credit-application-study-select"
        label="Study"
        disabled={false}
        dataSource={studiesForStudy}
        onChange={value => {
          const newValues = value.map(study => omit(study, 'name'));
          if (isEqual(newValues, selectedStudies)) {
            return;
          }
          setSelectedStudies(newValues ?? []);
        }}
        value={selectedStudies?.map(study => ({ ...study, name: study?.studyName })) ?? []}
        searchable={true}
        clearSearchOnSelection
        validate={false}
      />
      <MultiSelect
        data-testid="site-credit-application-pcn-select"
        label="PCN"
        disabled={false}
        dataSource={studiesForPCN}
        onChange={value => {
          const newValues = value.map(study => omit(study, 'name'));
          if (isEqual(newValues, selectedStudies)) {
            return;
          }
          setSelectedStudies(newValues ?? []);
        }}
        value={selectedStudies?.map(study => ({ ...study, name: study?.projectCode })) ?? []}
        searchable={true}
        clearSearchOnSelection
        validate={false}
      />
      <Button
        className="mt-2"
        size="h40"
        onClick={fetchEligibleEventsForApplyCredit}
        data-testid="site-credit-application-apply-filters-button"
      >
        Apply
      </Button>
    </div>
  );
};
