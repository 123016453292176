import React from 'react';
import { connect } from 'react-redux';

import {
  createStudyEnrollmentActionCreator,
  updateStudyEnrollmentsActionCreator
} from '../../../../../actions/finance/forecasting/forecastingActions';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import StudyEnrollmentPredictionReasonDialog from '../StudyEnrollmentPredicitonReasonDialog/StudyEnrollmentPredictionReasonDialog';

import './SaveStudyEnrollment.scss';

function SaveStudyEnrollment({ updateEnrollmentState, createEnrollmentState, enrollment }) {
  const openReasonDialog = function() {
    ModalBoxes.open({
      size: 'w800',
      title: `${enrollment.study.studyName} ${enrollment.site.siteName}`,
      component: <StudyEnrollmentPredictionReasonDialog {...enrollment} />
    });
  };

  function checkIfDataIsValidToSave() {
    const enrollmentPredictionCount = enrollment.enrollmentPredictionValues.reduce(
      (acc, { value }) => acc + (Number(value) || 0),
      0
    );
    const remainingCount = (enrollment.expectedCountSiv || enrollment.expectedCountPsv) - enrollment.enrolledCount;

    if (enrollmentPredictionCount !== remainingCount) {
      openReasonDialog();
    } else if (enrollment.id === '') {
      createEnrollmentState({ ...enrollment, ...{ reason: '' } });
    } else {
      updateEnrollmentState({ ...enrollment, ...{ reason: '' } });
    }
  }

  return (
    <div className="edit-btn-group  pull-right plcustom ml-auto">
      <i className="material-icons float-right" title="Save" onClick={checkIfDataIsValidToSave}>
        save
      </i>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateEnrollmentState: studyEnrollment => {
      dispatch(updateStudyEnrollmentsActionCreator(studyEnrollment));
    },
    createEnrollmentState: studyEnrollment => {
      dispatch(createStudyEnrollmentActionCreator(studyEnrollment));
    }
  };
};

export default connect(null, mapDispatchToProps)(SaveStudyEnrollment);
