import { isArray } from 'lodash';
import { compact } from 'lodash/array';
import { some } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

export function shouldTargetFieldsBeVisible(
  { isHideCondition, answers },
  normalizedTriggerFieldValue,
  hiddenByEmptyValue
) {
  const valueIsEmpty = isEmpty(normalizedTriggerFieldValue);

  if (valueIsEmpty) {
    return !hiddenByEmptyValue;
  }

  if (doesFieldValueMatchConditionAnswers(answers, normalizedTriggerFieldValue)) {
    return !isHideCondition;
  }

  return isHideCondition;
}

function doesFieldValueMatchConditionAnswers(conditionAnswers, triggerFieldValue) {
  if (isArray(triggerFieldValue)) {
    return some(conditionAnswers, i => triggerFieldValue.includes(i));
  }

  return conditionAnswers.includes(triggerFieldValue);
}

export function normalizeFieldValue(fieldValue) {
  if (isEmpty(fieldValue)) {
    return null;
  }
  const parsedValue = tryToParseValueAsArray(fieldValue);
  if (isArray(parsedValue)) {
    if (parsedValue.length === 1) {
      return parsedValue[0];
    }
    if (isEmpty(parsedValue)) {
      return null;
    }
  }
  return parsedValue;
}

function tryToParseValueAsArray(fieldValue) {
  try {
    const parsedValueAsJson = JSON.parse(fieldValue);
    if (isArray(parsedValueAsJson)) {
      return parsedValueAsJson;
    }
  } catch (e) {}

  if (fieldValue.includes('$$###$$')) {
    return compact(fieldValue.split('$$###$$'));
  }

  return fieldValue;
}
