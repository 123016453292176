import React, { useEffect, useState } from 'react';

import Checkbox from '../../../../common/data-entry/InputSelectors/Checkbox';
import { getInitialMilestoneTypes } from '../FiltersProvider';

import './EventStatusSelector.scss';

const Types = [
  { checked: true, id: 1, code: 'PSV', name: 'Pre-Study Visit (PSV)' },
  { checked: true, id: 2, code: 'AWD', name: 'Award date' },
  { checked: true, id: 3, code: 'BDH', name: 'Business Development Handover' },
  { checked: true, id: 4, code: 'INT', name: 'Client Introductory Call' },
  { checked: true, id: 5, code: 'REC', name: 'Regulatory Documents Received' },
  { checked: true, id: 6, code: 'SUB', name: 'Regulatory Documents Completed' },
  { checked: true, id: 7, code: 'EPM', name: 'Enrollment Planning Meeting' },
  { checked: true, id: 8, code: 'KOM', name: 'Internal Kickoff Meeting' },
  { checked: true, id: 9, code: 'IRBS', name: 'Initial IRB Submission' },
  { checked: true, id: 10, code: 'IRBA', name: 'Initial IRB Approval' },
  { checked: true, id: 11, code: 'CON', name: 'Contract Executed' },
  { checked: true, id: 12, code: 'WOR', name: 'Site Work Order Executed' },
  { checked: true, id: 13, code: 'BUI', name: 'IntElligo Build Completed' },
  { checked: true, id: 14, code: 'LAB', name: 'Lab Kits Received' },
  { checked: true, id: 15, code: 'LAU', name: 'ElliLaunch' },
  { checked: true, id: 16, code: 'SIV', name: 'Site Initiation Visit (SIV)' },
  { checked: true, id: 17, code: 'SGL', name: 'Sponsor Greenlight' },
  { checked: true, id: 18, code: 'FPI', name: 'First Patient In' },
  { checked: true, id: 19, code: 'FPA', name: 'First Patient Administered IP' },
  { checked: true, id: 20, code: 'LPI', name: 'Last Patient In' },
  { checked: true, id: 21, code: 'LPV', name: 'Last Patient, Last Visit' },
  { checked: true, id: 22, code: 'DBL', name: 'Database Lock' },
  { checked: true, id: 23, code: 'COV', name: 'Close Out Visit' },
  { checked: true, id: 24, code: 'SC', name: 'Study Closed' }
];

export function MilestoneEventTypeSelector({ onChange }) {
  const [milestoneEventTypes, setMilestoneEventTypes] = useState(Types);
  const [eventStatusInitialized, setEventStatusInitialized] = useState(false);

  useEffect(() => {
    if (!eventStatusInitialized) {
      const initialMilestoneTypes = getInitialMilestoneTypes();
      if (initialMilestoneTypes) {
        milestoneEventTypes.forEach(e => {
          e.checked = initialMilestoneTypes.includes(e.code);
        });
      }
      setMilestoneEventTypes(milestoneEventTypes);
      onChange(milestoneEventTypes);
      setEventStatusInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestoneEventTypes, getInitialMilestoneTypes, eventStatusInitialized]);

  const change = option => {
    option.checked = !option.checked;
    const updatedTypes = [...milestoneEventTypes];
    setMilestoneEventTypes(updatedTypes);
    onChange(updatedTypes);
  };

  const onSelectAll = () => {
    if (milestoneEventTypes.some(e => e.checked)) {
      milestoneEventTypes.forEach(e => {
        e.checked = false;
      });
    } else {
      milestoneEventTypes.forEach(e => {
        e.checked = true;
      });
    }
    const updatedTypes = [...milestoneEventTypes];
    setMilestoneEventTypes(updatedTypes);
    onChange(updatedTypes);
  };

  return (
    <div className="calendar-event-status-selector-container pl-3">
      <Checkbox
        label="Select all"
        key="SELECT_ALL"
        onChange={onSelectAll}
        indeterminate={milestoneEventTypes.some(e => e.checked) && milestoneEventTypes.some(e => !e.checked)}
        checked={!milestoneEventTypes.some(e => !e.checked)}
      />
      <Checkbox.Group direction="column">
        {milestoneEventTypes.map(option => {
          return (
            <Checkbox key={option.id} label={option.name} onChange={() => change(option)} checked={option.checked} />
          );
        })}
      </Checkbox.Group>
    </div>
  );
}
