import React, { useEffect, useState } from 'react';
import { some } from 'lodash/collection';
import { isEqual } from 'lodash/lang';

import { FormBuilderApi } from '../../../../../../../../api';
import Input from '../../../../../../../../common/data-entry/Input';
import Select from '../../../../../../../../common/data-entry/Select';
import TextArea from '../../../../../../../../common/data-entry/TextArea';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../../../common/notifications/NotificationManager';
import { RichTextEditor } from '../../../../../../../../common/RichTextFormat/editor/RichTextEditor';
import { STYLED_INPUT_QUESTIONS } from '../../../../../../../../constants/inputTypes';
import {
  COMMENT_FIELD_IS_NOT_AVAILABLE,
  DUPLICATE_CUSTOM_OPTION_VALUES_NOT_ALLOWED,
  ITEM_ALREADY_EXISTS
} from '../../../../../../../../constants/notificationMessages';
import { onRequestError } from '../../../../../../../../services/handlers';
import { CUSTOM_FIELD_TYPES, CUSTOM_TOGGLE_SETUP_DROPDOWN_VALUE } from '../../TableSetupConstants';
import ControlledTermList from '../TableFieldItem/EditControlledTermListModal/ControlledTermList';
import OptionInput from '../TableFieldItem/EditControlledTermListModal/OptionInput';
import DragAndDropFinalOptionList from '../TableFieldItem/EditCustomOptionListModal/DrafAndDropFinalOptionList/DragAndDropFinalOptionList';
import {
  deleteOptionFromList,
  onDragEnd,
  toggleEditModeForLabel,
  updateItemName
} from '../TableFieldItem/EditCustomOptionListModal/EditCustomOptionListModal';

import './AddCustomItemModal.scss';

const AddCustomItemModal = ({
  addNewCustomFieldItem,
  protocolId,
  terminologyVersionId,
  isTemplateMode,
  fieldList,
  generalFields,
  modalBox
}) => {
  const [selectedAnswerType, setSelectedAnswerType] = useState(null);
  const [itemName, setItemName] = useState('');
  const [styledFieldName, setStyledFieldName] = useState('');
  const [mappingList, setMappingList] = useState([]);
  const [selectedMappingItem, setSelectedMappingItem] = useState(null);
  const [finalOptionsList, setFinalOptionsList] = useState([]);
  const [textBlockValue, setTextBlockValue] = useState('');
  const [selectedToggleValue, setSelectedToggleValue] = useState(null);
  const [selectedControlledTermListName, setSelectedControlledTermListName] = useState(null);

  useEffect(() => {
    FormBuilderApi.getAllCdashMapping().then(({ data }) => {
      setMappingList(data);
    }, onRequestError);
  }, []);

  const addNewOptionInFinalList = newValue => {
    if (some(finalOptionsList, ['name', newValue])) {
      NotificationManager.error(DUPLICATE_CUSTOM_OPTION_VALUES_NOT_ALLOWED);
    } else {
      const newOptionItem = {
        codedValue: null,
        codeOid: '',
        decode: newValue,
        inputType: 'dropdown',
        name: newValue,
        sequence: finalOptionsList.length + 1
      };
      setFinalOptionsList([...finalOptionsList, newOptionItem]);
    }
  };

  return (
    <>
      <ModalBoxes.Header>Add Custom Item</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="field-wrapper">
          <div className="label">Answer Type</div>
          <Select
            placeholder="Select"
            clearSearchOnSelection
            dataSource={CUSTOM_FIELD_TYPES}
            optionLabelKey="name"
            optionValueKey="value"
            onChange={newType => {
              if (!isEqual(selectedAnswerType, newType)) {
                setSelectedAnswerType(newType);
              }
            }}
            keepOpenOnSelection={false}
            closeOnSelectedOptionClick
            deselectOnSelectedOptionClick={false}
            disabled={false}
            validate={false}
            clearable={false}
            value={selectedAnswerType}
            className="custom-item-dropdown"
            required
          />
        </div>
        <div className="field-wrapper">
          <div className="label">Field Name</div>
          {selectedAnswerType && STYLED_INPUT_QUESTIONS.includes(selectedAnswerType.value) ? (
            <RichTextEditor
              className={'new-table-item-group create'}
              onChange={(htmlString, rawTextString) => {
                setItemName(rawTextString);
                setStyledFieldName(htmlString);
              }}
            />
          ) : (
            <Input
              className="custom-item-dropdown"
              type="text"
              validate={false}
              value={itemName}
              onChange={({ target: { value } }) => {
                setItemName(value);
              }}
              disabled={false}
              required
            />
          )}
        </div>
        {(selectedAnswerType?.value === 'dropdownCT' || selectedAnswerType?.value === 'multiselectCT') && (
          <ControlledTermList
            inputType={selectedAnswerType.value}
            protocolId={protocolId}
            terminologyVersionId={terminologyVersionId}
            isTemplateMode={isTemplateMode}
            setFinalControlledTermListForSave={setFinalOptionsList}
            setSelectedControlledTermListName={setSelectedControlledTermListName}
          />
        )}
        {(selectedAnswerType?.value === 'dropdown' || selectedAnswerType?.value === 'multiselect') && (
          <>
            <OptionInput addNewCustomOptionInFinalList={addNewOptionInFinalList} label="Custom Option List" />
            <DragAndDropFinalOptionList
              onDragEnd={result => onDragEnd(result, finalOptionsList, setFinalOptionsList)}
              finalOptionList={finalOptionsList}
              deleteOptionFromList={index => deleteOptionFromList(index, finalOptionsList, setFinalOptionsList)}
              toggleEditModeForLabel={itemIndex =>
                toggleEditModeForLabel(itemIndex, finalOptionsList, setFinalOptionsList)
              }
              updateItemName={(oldName, newName) =>
                updateItemName(oldName, newName, finalOptionsList, setFinalOptionsList)
              }
            />
          </>
        )}
        {selectedAnswerType?.value === 'label' && (
          <div className="field-wrapper">
            <div className="label">Text Block Value</div>
            <TextArea
              className="custom-item-dropdown"
              name=""
              value={textBlockValue}
              required
              onChange={({ target: { value } }) => {
                setTextBlockValue(value);
              }}
            />
          </div>
        )}
        {selectedAnswerType?.value === 'radio' && (
          <div className="field-wrapper">
            <div className="label">Value</div>
            <Select
              placeholder="Select drop status"
              clearSearchOnSelection
              dataSource={CUSTOM_TOGGLE_SETUP_DROPDOWN_VALUE}
              optionLabelKey="name"
              optionValueKey="id"
              onChange={newType => {
                if (!isEqual(selectedToggleValue, newType)) {
                  setSelectedToggleValue(newType);
                }
              }}
              keepOpenOnSelection={false}
              closeOnSelectedOptionClick
              deselectOnSelectedOptionClick={false}
              disabled={false}
              validate={false}
              clearable={false}
              value={selectedToggleValue}
              className="custom-item-dropdown"
              required
            />
          </div>
        )}
        <div className="field-wrapper">
          <div className="label">CDASH Mapping</div>
          <Select
            placeholder="Select"
            clearSearchOnSelection
            dataSource={mappingList}
            optionLabelKey="cdashigVariableLabel"
            optionValueKey="id"
            onChange={newMapping => {
              if (!isEqual(setSelectedMappingItem, newMapping)) {
                setSelectedMappingItem(newMapping);
              }
            }}
            searchable
            keepOpenOnSelection={false}
            closeOnSelectedOptionClick
            deselectOnSelectedOptionClick={false}
            disabled={false}
            validate={false}
            clearable={false}
            value={selectedMappingItem}
            className="custom-item-dropdown"
          />
        </div>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority={'medium'} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );

  function validateNewCustomItem() {
    let isValid = true;
    if (
      some(fieldList, ['name', itemName.trim()]) ||
      some(fieldList, ['question', itemName.trim()]) ||
      some(generalFields, ['name', itemName.trim()]) ||
      some(generalFields, ['question', itemName.trim()]) ||
      some(fieldList, ['updatedQuestion', itemName.trim()]) ||
      some(generalFields, ['updatedQuestion', itemName.trim()])
    ) {
      isValid = false;
      NotificationManager.error(ITEM_ALREADY_EXISTS);
    }
    if (itemName.trim() === 'Comment') {
      isValid = false;
      NotificationManager.error(COMMENT_FIELD_IS_NOT_AVAILABLE);
    }
    if (!itemName.trim()) {
      isValid = false;
      NotificationManager.error('Field Name is required');
    }
    if (!selectedAnswerType) {
      isValid = false;
      NotificationManager.error('Answer Type is required');
    }
    if (
      (selectedAnswerType?.value === 'dropdown' ||
        selectedAnswerType?.value === 'dropdownCT' ||
        selectedAnswerType?.value === 'multiselect' ||
        selectedAnswerType?.value === 'multiselectCT') &&
      !finalOptionsList.length
    ) {
      isValid = false;
      NotificationManager.error('Final Option List is required');
    }
    if (
      (selectedAnswerType?.value === 'multiselectCT' || selectedAnswerType?.value === 'dropdownCT') &&
      !selectedControlledTermListName
    ) {
      isValid = false;
      NotificationManager.error('Controlled Term List is required');
    }
    if (selectedAnswerType?.value === 'label' && !textBlockValue.trim()) {
      isValid = false;
      NotificationManager.error('Text Block Value is required');
    }
    if (selectedAnswerType?.value === 'radio' && !selectedToggleValue) {
      isValid = false;
      NotificationManager.error('Value is required');
    }
    return isValid;
  }

  function onClose() {
    modalBox.close();
  }
  function onSave() {
    if (validateNewCustomItem()) {
      const finalOptionListForSave = [...finalOptionsList];
      finalOptionListForSave.forEach(element => delete element.edit);
      addNewCustomFieldItem({
        itemType: selectedAnswerType.value,
        name: itemName.trim(),
        styledFieldName: styledFieldName,
        CDASH: selectedMappingItem,
        codeDefinationList: finalOptionListForSave,
        textBlockValue,
        toggleValue: selectedToggleValue,
        selectedControlledTermListName
      });
      modalBox.close();
    }
  }
};

AddCustomItemModal.className = 'add-custom-item-modal';
AddCustomItemModal.size = 'w950';

export default AddCustomItemModal;
