import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Button, TextField } from '@mui/material';
import { findIndex, isEmpty, sortBy } from 'lodash';

import StatusApiService from '../../../../../../../api/patient/PatientStatusApi';
import PatientStatusApi from '../../../../../../../api/patient/PatientStatusApi';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { BULK_PATIENT_STATUS_UPDATED } from '../../../../../../../constants/notificationMessages';
import { onRequestError } from '../../../../../../../services/handlers';
import { screeningStatuses } from '../../WorklistTableConstants';

export const BulkPatientStatusChangeModal = ({ selectedSitePatientsIds, modalBox, ssuId, updatePage }) => {
  const [preScreenStatuses, setPreScreenStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [comment, setComment] = useState('');

  useEffect(() => {
    StatusApiService.getAllStatuses().then(({ data }) => {
      const preScreenStatuses = sortBy(
        data
          .filter(status => status.preScreeningStatus)
          .map(status => ({ ...status, index: findIndex(screeningStatuses, el => el.name === status.name) })),
        'index'
      );

      setPreScreenStatuses(preScreenStatuses);
    });
  }, []);

  const updateStatus = useCallback(() => {
    const dataForUpdate = {
      sitePatientIds: selectedSitePatientsIds,
      status: selectedStatus?.id,
      reason: selectedReason?.id,
      comment: comment.trim()
    };
    PatientStatusApi.updateStatuses(ssuId, dataForUpdate)
      .then(() => {
        NotificationManager.success(BULK_PATIENT_STATUS_UPDATED);
        updatePage();
        modalBox.close();
      })
      .catch(onRequestError);
  }, [comment, modalBox, selectedReason?.id, selectedSitePatientsIds, selectedStatus?.id, ssuId, updatePage]);

  const commentRequired = useMemo(
    () =>
      selectedStatus?.id === 'CANCELED' ||
      (selectedStatus?.id === 'PRE-SCREEN FAILED' && selectedReason?.name === 'Other'),
    [selectedReason?.name, selectedStatus?.id]
  );

  const updateButtonDisabled = useMemo(
    () =>
      isEmpty(selectedStatus) ||
      (selectedStatus?.reasons?.length > 0 && (isEmpty(selectedReason) || (!comment.trim() && commentRequired))),
    [comment, commentRequired, selectedReason, selectedStatus]
  );

  const maxCommentLength = 2000;

  return (
    <>
      <ModalBoxes.Body>
        <Autocomplete
          fullWidth
          options={preScreenStatuses}
          onChange={(event, newValue) => {
            setSelectedStatus(newValue);
            setSelectedReason(null);
            setComment('');
          }}
          value={selectedStatus}
          getOptionKey={option => option.id}
          getOptionLabel={option => option.name}
          clearIcon={false}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label="Patient Status"
              margin="normal"
              required
              data-testid="patient-status-dropdown"
            />
          )}
        />
        {selectedStatus?.reasons?.length ? (
          <>
            <Autocomplete
              fullWidth
              options={selectedStatus.reasons}
              onChange={(event, newValue) => setSelectedReason(newValue)}
              value={selectedReason}
              getOptionKey={option => option.id}
              getOptionLabel={option => option.name}
              clearIcon={false}
              renderInput={params => (
                <TextField {...params} variant="standard" label="Reason" margin="normal" required />
              )}
              data-testid="status-reason-dropdown"
            />
            <TextField
              fullWidth
              multiline
              value={comment}
              onChange={({ target: { value } }) => setComment(value ?? '')}
              variant="standard"
              label="Comment"
              margin="normal"
              required={commentRequired}
              inputProps={{ maxLength: maxCommentLength }}
              helperText={`${comment?.length ?? 0}/${maxCommentLength}`}
              sx={{ '.MuiFormHelperText-root': { textAlign: 'end' } }}
              data-testid="status-comment-input"
            />
          </>
        ) : null}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <Button priority="medium" size="h28" onClick={modalBox.close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          priority="high"
          size="h28"
          onClick={updateStatus}
          disabled={updateButtonDisabled}
          data-testid="patient-status-update-button"
        >
          Update
        </Button>
      </ModalBoxes.Footer>
    </>
  );
};
