import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment/moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { MANAGE_REVENUE_EVENTS } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { toBill } from '../../../../../services/financial';
import { ACTIONS_BUTTON } from '../../NewInvoice/InvoiceTable/TableConstants';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { DefaultFinanceCell } from '../../shared/FinanseTableMUI/DefaultFinanceCell';
import { FinanceTableMUI } from '../../shared/FinanseTableMUI/FinanceTableMUI';
import {
  eventNumberComparator,
  formatDateForCSV,
  formatDateWithoutUTC,
  prepareNameForCSV
} from '../../shared/FinanseTableMUI/FinanceTableServices';
import { RevenueContext } from '../RevenueContext';

import { RevenueTableRowMenu } from './RevenueTableRowMenu/RevenueTableRowMenu';

export const RevenueTable = () => {
  const { ledgerEvents } = useContext(RevenueContext);

  const getTogglableColumns = useCallback(
    columns => columns.filter(column => !['actions'].includes(column.field)).map(column => column.field),
    []
  );

  const stateFromLocalStorage = JSON.parse(localStorage?.getItem('REVENUE_TABLE_CONFIGURATION'));

  const [pinnedColumns, setPinnedColumns] = useState(
    stateFromLocalStorage?.pinnedColumns || {
      right: [ACTIONS_BUTTON]
    }
  );

  const handlePinnedColumnsChange = useCallback(updatedPinnedColumns => {
    setPinnedColumns({
      ...updatedPinnedColumns,
      right: [...updatedPinnedColumns.right.filter(column => column !== ACTIONS_BUTTON), ACTIONS_BUTTON]
    });
  }, []);

  const columns = useMemo(() => {
    const columns = [
      {
        field: 'eventNumber',
        headerName: 'Event ID',
        flex: 1,
        minWidth: 120,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row }) => {
          if (row.adjustmentSequence === 0) {
            return `${row.eventNumber}`;
          }
          return `${row.eventNumber}.${row.adjustmentSequence}`;
        }
      },
      {
        field: 'studyName',
        headerName: 'Study',
        flex: 1,
        minWidth: 105,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'siteName',
        headerName: 'Site',
        flex: 1,
        minWidth: 95,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'patientId',
        headerName: 'Patient ID',
        flex: 1,
        minWidth: 130,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'subjectId',
        headerName: 'Subject ID',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => (value != null ? `[${value}]` : '')
      },
      {
        field: 'encounterName',
        headerName: 'Encounter',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'elligoEventType',
        headerName: 'Budget Event Type',
        flex: 1,
        minWidth: 190,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'itemGroupName',
        headerName: 'Item Group',
        flex: 1,
        minWidth: 140,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'eventName',
        headerName: 'Event Name',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'eventDate',
        headerName: 'Event Date',
        flex: 1,
        minWidth: 140,
        type: 'date',
        valueFormatter: ({ value }) => formatDateForCSV(value),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row }) => {
          const content = moment(row.eventDate).format(DD_SLASH_MMM_SLASH_YYYY);
          return (
            <div className="MuiDataGrid-cellContent" title={content}>
              {content}
            </div>
          );
        }
      },
      {
        field: 'revenueDate',
        headerName: 'Revenue Date',
        flex: 1,
        minWidth: 155,
        type: 'date',
        valueFormatter: formatDateWithoutUTC,
        valueGetter: revenueDateValueGetter,
        renderCell: revenueDateRenderCell
      },
      {
        field: 'clientAmountWithOverhead',
        headerName: 'Gross',
        flex: 1,
        minWidth: 110,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.clientAmountWithOverhead),
        renderCell: ({ row }) => (
          <Amount coinsAmount={row.clientAmountWithOverhead} showDollarSign className="MuiDataGrid-cellContent" />
        )
      },
      {
        field: 'netRevenueAmount',
        headerName: 'Net',
        flex: 1,
        minWidth: 95,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.netRevenueAmount),
        renderCell: ({ row }) => (
          <Amount coinsAmount={row.netRevenueAmount} showDollarSign className="MuiDataGrid-cellContent" />
        )
      },
      {
        field: 'expenseVariableComment',
        headerName: 'Expense Comments',
        flex: 1,
        minWidth: 95,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'nonInvoiceable',
        headerName: 'Non-Invoiceable',
        minWidth: 180,
        valueGetter: ({ value }) => (value ? 'Yes' : 'No')
      }
    ];
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      resizable: false,
      width: 50,
      renderCell: ({ row }) => <RevenueTableRowMenu row={row} />
    };
    return userHasAccessTo(MANAGE_REVENUE_EVENTS) ? [...columns, actionColumn] : columns;
  }, []);

  return (
    <FinanceTableMUI
      data-testid="revenue-table"
      localStorageTableName="REVENUE"
      rows={ledgerEvents}
      columns={columns}
      footerElementsCount={ledgerEvents.length}
      disableRowSelectionOnClick
      sx={{ padding: '0 40px 0 40px' }}
      getTogglableColumns={getTogglableColumns}
      initialState={{
        columns: {
          columnVisibilityModel: { expenseVariableComment: false, nonInvoiceable: false }
        },
        sorting: {
          sortModel: [{ field: 'revenueDate', sort: 'desc' }]
        },
        pinnedColumns
      }}
      onPinnedColumnsChange={handlePinnedColumnsChange}
      pinnedColumns={pinnedColumns}
      tableVersion="1.01"
    />
  );
};

export const revenueDateRenderCell = ({ row }) => {
  const content = `${moment(row.revenueDate).format(DD_SLASH_MMM_SLASH_YYYY)}${row.revenueDateEdited ? '*' : ''}`;
  return (
    <div className="MuiDataGrid-cellContent" title={content}>
      {content}
    </div>
  );
};

export const revenueDateValueGetter = ({ value }) => value && new Date(value);
