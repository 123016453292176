import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import SiteCreditApi from '../../../../../../api/finance/SiteCreditApi';
import { onRequestError } from '../../../../../../services/handlers';
import {
  isCompleteStatus,
  isOpenStatus,
  isPaidStatus,
  isPartialStatus,
  isPastDueStatus
} from '../../../NewInvoice/InvoiceTable/InvoiceTableService';
import { COMPLETE, OPEN, PAID, PARTIAL, PAST_DUE } from '../../../NewInvoice/InvoiceTable/TableConstants';
import { SitePaymentsContext } from '../../SitePaymentsContext';
import { PARENT, SITE_CREDIT, SITE_CREDIT_WH, WITHHOLDING } from '../SitePaymentsConstants';

export const SiteCreditApplicationContext = React.createContext(null);

export function SiteCreditApplication({ children }) {
  const {
    state: { row }
  } = useLocation();

  const navigate = useNavigate();
  const { applyFilter } = useContext(SitePaymentsContext);
  const [selectedEventForApply, setSelectedEventForApply] = useState([]);
  const [eligibleEventsForApplyCredit, setEligibleEventsForApplyCredit] = useState([]);
  const [selectedStudies, setSelectedStudies] = useState([]);
  const [comment, setComment] = useState('');
  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    const parentEvent = eligibleEventsForApplyCredit?.find(event => {
      const isSiteCreditHasParent = row.sitePaymentType === SITE_CREDIT && event.sitePaymentType === PARENT;
      const isSiteCreditWhHasParentWh = row.sitePaymentType === SITE_CREDIT_WH && event.sitePaymentType === WITHHOLDING;

      return (
        event.eventNumber === row.eventNumber &&
        event.adjustmentSequence === 0 &&
        (isSiteCreditHasParent || isSiteCreditWhHasParentWh)
      );
    });

    if (parentEvent) {
      setSelectedEventForApply([parentEvent.itemSiteId]);
    }
  }, [eligibleEventsForApplyCredit, row?.eventNumber, row.sitePaymentType]);

  const calculateSelectedAmount = useCallback((selectedEventForApply, eligibleEventsForApplyCredit) => {
    return eligibleEventsForApplyCredit
      ?.filter(item => selectedEventForApply.includes(item.itemSiteId))
      .reduce((accumulator, currentValue) => accumulator + (currentValue.siteAmount - currentValue.sitePaidAmount), 0);
  }, []);

  const fetchEligibleEventsForApplyCredit = useCallback(() => {
    const studyIds = selectedStudies.map(study => study.id);
    return SiteCreditApi.getEligibleEvents(row.siteId, studyIds).then(({ data }) => {
      const eligibleEvents = data.map(item => {
        function getInvoicePaymentStatus(row) {
          const modifiedRow = {
            ...row,
            amountPaid: row.reconcileTotalAmountPaid
          };
          if (modifiedRow.adjustmentSequence !== 0) return '—';
          else if (isCompleteStatus(modifiedRow)) return COMPLETE;
          else if (isOpenStatus(modifiedRow)) return OPEN;
          else if (isPastDueStatus(modifiedRow)) return PAST_DUE;
          else if (isPartialStatus(modifiedRow)) return PARTIAL;
          else if (isPaidStatus(modifiedRow)) return PAID;
          return '';
        }
        return { ...item, invoicePaymentStatus: getInvoicePaymentStatus(item) };
      });
      setEligibleEventsForApplyCredit(eligibleEvents);
    }, onRequestError);
  }, [row.siteId, selectedStudies]);

  const onApply = useCallback(() => {
    const eligibleEvents = eligibleEventsForApplyCredit
      .filter(event => selectedEventForApply.includes(event.itemSiteId))
      .map(element => ({
        itemSiteId: element.itemSiteId,
        invoicePaymentStatus: element.invoicePaymentStatus,
        amount: element.siteAmount - element.sitePaidAmount,
        revenueDate: element.revenueDate
      }));
    const request = { itemSiteId: row?.itemSiteId, comment, eligibleEvents };
    SiteCreditApi.applySiteCredit(request).then(() => {
      applyFilter();
      navigate('/site-payments');
    }, onRequestError);
  }, [applyFilter, comment, eligibleEventsForApplyCredit, navigate, row?.itemSiteId, selectedEventForApply]);

  useEffect(() => {
    if (row?.siteId && !isEmpty(selectedStudies) && firstLoading) {
      fetchEligibleEventsForApplyCredit().then(() => setFirstLoading(false));
    }
  }, [row?.siteId, fetchEligibleEventsForApplyCredit, firstLoading, selectedStudies]);

  const creditToApply = useMemo(() => row?.sitePaidAmount - row?.siteAmount ?? 0, [
    row?.siteAmount,
    row?.sitePaidAmount
  ]);

  return (
    <SiteCreditApplicationContext.Provider
      value={{
        selectedEventForApply,
        setSelectedEventForApply,
        calculateSelectedAmount,
        creditToApply,
        eligibleEventsForApplyCredit,
        selectedStudies,
        setSelectedStudies,
        fetchEligibleEventsForApplyCredit,
        setComment,
        onApply
      }}
    >
      {children}
    </SiteCreditApplicationContext.Provider>
  );
}

export function withSiteCreditApplicationContext(Component) {
  return function WrapperComponent(props) {
    return (
      <SiteCreditApplication>
        <Component {...props} />
      </SiteCreditApplication>
    );
  };
}
