import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHomePageDashboardsConfig } from 'actions/homePageDashboardsConfigActions';
import { UserApiApi, VersionApi } from 'api';
import { useAuth0, useBouncer } from 'bouncer';
import ModalBoxes from 'common/feedback/ModalBoxes/ModalBoxes';
import ModalBoxesContainer from 'common/feedback/ModalBoxes/ModalBoxesContainer/ModalBoxesContainer';
import useAppInfo from 'common/hooks/useAppInfo';
import * as accesses from 'constants/accessToPages';
import { CURRENT_USER_LAST_ACTIVE_ROLE, SESSION_ID } from 'constants/localStorageConstants';
import * as operations from 'constants/userOperations';
import { VIEW_AND_EDIT_HOME_PAGE_DASHBOARDS_WIDGET_CONFIG } from 'constants/userOperations';
import {
  ROLE_CLINICAL_QUALITY_COORDINATOR,
  ROLE_CRA,
  ROLE_ELLIGO_MANAGEMENT,
  ROLE_EXTERNAL_AUDITOR,
  ROLE_FINANCE,
  ROLE_FINANCE_ADMINISTRATOR,
  ROLE_FINANCE_ANALYST,
  ROLE_OPERATION_MANAGER,
  ROLE_OPERATIONS_ANALYST,
  ROLE_PATIENT_LIAISON,
  ROLE_PATIENT_REPRESENTATIVE,
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_RECRUITER,
  ROLE_SITE_COORDINATOR,
  ROLE_SITE_FINANCIAL_COORDINATOR,
  ROLE_STUDY_ADMINISTRATOR,
  ROLE_STUDY_LEAD,
  ROLE_STUDY_MANAGER,
  ROLE_SUB_INVESTIGATOR,
  ROLE_SYSTEM_ADMINISTRATOR
} from 'constants/userRoles';
import { pull } from 'lodash/array';
import { flatMap, forEach, includes, reduce } from 'lodash/collection';
import { isArray, isEmpty } from 'lodash/lang';
import moment from 'moment';
import { update as updateCurrentUser } from 'store/currentUser/actions';

import { financeAccessRoles } from '../../../constants/financeAccessRoles';
import TermsOfUseModal from '../login/TermsOfUseModal/TermsOfUseModal';

import NoUser from './NoUser';

const {
  ACTIVITY,
  ACTIVITY_REPORTS,
  BSS,
  CALENDAR,
  CAS,
  CRA_REVIEW,
  CUSTOM_REPORTS,
  ENROLMENTS,
  EXPORT_CASE_REPORT_FORMS,
  FINANCE,
  FINANCE_REPORTS,
  GLOBAL_SEARCH,
  HELP,
  INVOICE,
  RECONCILE,
  LOAD_PATIENTS,
  MILESTONES_DASHBORDS,
  DASHBOARDS,
  PATIENT_INFO,
  PATIENT_PROFILE,
  PI_REVIEW,
  PI_WORK_LIST,
  PL,
  LOGS,
  PLA,
  PRE_SCREEN,
  PROJECTED_REVENUE,
  PS,
  SAS,
  SBS,
  SITE_PAYMENTS,
  READY_FOR_APPROVAL,
  SM_REVIEW,
  SS,
  ST,
  STD,
  STUDY_ENROLMENTS,
  STUDY_EXPENSES,
  STUDY_REPORTS,
  TIMELINE,
  TRANSACTION_LEDGER,
  CALENDAR_INVITATIONS_PREFERENCES,
  VAS,
  WORK_LIST,
  REVENUE,
  MANAGE_TASKS,
  TASK_WORK_LIST,
  ITEM_GROUP_TEMPLATES,
  PRE_SCREENING_REPORTS,
  USER_PREFERENCES,
  NOTIFICATION_PREFERENCES,
  HOME_PAGE_DASHBOARDS,
  SCHEDULING_WORK_LIST,
  PROTOCOL_GROUPS_SETUP,
  SM_WORKLIST,
  LEADERSHIP_METRICS_REPORTS,
  SSU_METRICS_REPORTS,
  CRA_AUDIT_REPORT,
  AUDIT_LOG_ALL_STUDY_DATA_REPORT,
  SSU_KPI_DASHBOARD,
  OPEN_ENCOUNTERS_DASHBOARD,
  ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
  ROLES_FINANCE_AND_FINANCE_ANALYST,
  SITE_PERFORMANCE_DASHBOARD,
  PATIENT_BULK_UPDATE,
  REIMBURSEMENT_WORKLIST,
  REIMBURSEMENT_REQUEST,
  INVOICE_DETAILS_EXPORT_REPORT,
  TRAVEL_REQUEST,
  TRAVEL_REQUEST_WORKLIST,
  PATIENT_STIPEND_WORKLIST,
  SITE_CREDIT_APPLICATION,
  SITE_FINANCE
} = accesses;

const accessToPagesMap = {
  [ROLE_SYSTEM_ADMINISTRATOR]: Object.values(accesses),
  [ROLE_STUDY_ADMINISTRATOR]: [
    ACTIVITY,
    ACTIVITY_REPORTS,
    FINANCE_REPORTS,
    MILESTONES_DASHBORDS,
    PLA,
    PS,
    SS,
    ST,
    STD,
    DASHBOARDS,
    PROTOCOL_GROUPS_SETUP,
    STUDY_REPORTS,
    TRANSACTION_LEDGER,
    TIMELINE,
    CRA_REVIEW,
    ITEM_GROUP_TEMPLATES,
    PRE_SCREENING_REPORTS,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD
  ],
  [ROLE_PRINCIPAL_INVESTIGATOR]: [
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    PI_WORK_LIST,
    PL,
    LOGS,
    PATIENT_INFO,
    PATIENT_PROFILE,
    PS,
    STUDY_REPORTS,
    HELP,
    CALENDAR,
    GLOBAL_SEARCH,
    CALENDAR_INVITATIONS_PREFERENCES,
    PI_REVIEW,
    SM_REVIEW,
    EXPORT_CASE_REPORT_FORMS,
    MANAGE_TASKS,
    TASK_WORK_LIST,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    HOME_PAGE_DASHBOARDS,
    SCHEDULING_WORK_LIST,
    ACTIVITY,
    SS,
    PRE_SCREEN,
    WORK_LIST,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD,
    REIMBURSEMENT_REQUEST,
    TRAVEL_REQUEST,
    SITE_FINANCE
  ],
  [ROLE_SUB_INVESTIGATOR]: [
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    PI_WORK_LIST,
    PL,
    LOGS,
    PATIENT_INFO,
    PATIENT_PROFILE,
    PS,
    STUDY_REPORTS,
    HELP,
    CALENDAR,
    GLOBAL_SEARCH,
    CALENDAR_INVITATIONS_PREFERENCES,
    PI_REVIEW,
    SM_REVIEW,
    EXPORT_CASE_REPORT_FORMS,
    MANAGE_TASKS,
    TASK_WORK_LIST,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    HOME_PAGE_DASHBOARDS,
    SCHEDULING_WORK_LIST,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD,
    REIMBURSEMENT_REQUEST,
    TRAVEL_REQUEST
  ],
  [ROLE_STUDY_LEAD]: [
    PRE_SCREEN,
    ACTIVITY,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    CUSTOM_REPORTS,
    FINANCE_REPORTS,
    PI_WORK_LIST,
    SM_WORKLIST,
    PLA,
    PL,
    LOGS,
    PATIENT_INFO,
    PATIENT_PROFILE,
    PS,
    SS,
    STUDY_ENROLMENTS,
    STUDY_EXPENSES,
    FINANCE,
    ENROLMENTS,
    PROJECTED_REVENUE,
    STUDY_REPORTS,
    TIMELINE,
    WORK_LIST,
    HELP,
    CALENDAR,
    GLOBAL_SEARCH,
    CALENDAR_INVITATIONS_PREFERENCES,
    PI_REVIEW,
    SM_REVIEW,
    CRA_REVIEW,
    EXPORT_CASE_REPORT_FORMS,
    TASK_WORK_LIST,
    PRE_SCREENING_REPORTS,
    USER_PREFERENCES,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD
  ],
  [ROLE_STUDY_MANAGER]: [
    PRE_SCREEN,
    ACTIVITY,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    PI_WORK_LIST,
    SM_WORKLIST,
    PL,
    LOGS,
    PATIENT_INFO,
    PATIENT_PROFILE,
    PLA,
    PS,
    SS,
    STUDY_EXPENSES,
    STUDY_REPORTS,
    STUDY_ENROLMENTS,
    WORK_LIST,
    HELP,
    CALENDAR,
    GLOBAL_SEARCH,
    CALENDAR_INVITATIONS_PREFERENCES,
    PI_REVIEW,
    SM_REVIEW,
    CRA_REVIEW,
    EXPORT_CASE_REPORT_FORMS,
    MANAGE_TASKS,
    TASK_WORK_LIST,
    PRE_SCREENING_REPORTS,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    HOME_PAGE_DASHBOARDS,
    SCHEDULING_WORK_LIST,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD,
    REIMBURSEMENT_REQUEST,
    TRAVEL_REQUEST,
    SITE_FINANCE
  ],
  [ROLE_CRA]: [
    PLA,
    PS,
    CRA_REVIEW,
    ACTIVITY,
    SS,
    MANAGE_TASKS,
    TASK_WORK_LIST,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    CRA_AUDIT_REPORT,
    DASHBOARDS
  ],
  [ROLE_EXTERNAL_AUDITOR]: [
    PLA,
    PS,
    CRA_REVIEW,
    ACTIVITY,
    SS,
    MANAGE_TASKS,
    TASK_WORK_LIST,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    CRA_AUDIT_REPORT,
    DASHBOARDS
  ],
  [ROLE_FINANCE_ANALYST]: [
    PS,
    PL,
    LOGS,
    PATIENT_INFO,
    PATIENT_PROFILE,
    SS,
    TIMELINE,
    STUDY_ENROLMENTS,
    ACTIVITY,
    STUDY_EXPENSES,
    ST,
    SBS,
    BSS,
    CAS,
    SAS,
    VAS,
    FINANCE,
    TRANSACTION_LEDGER,
    REVENUE,
    INVOICE,
    RECONCILE,
    SITE_PAYMENTS,
    READY_FOR_APPROVAL,
    ENROLMENTS,
    PROJECTED_REVENUE,
    STUDY_REPORTS,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    FINANCE_REPORTS,
    CALENDAR,
    HELP,
    GLOBAL_SEARCH,
    TASK_WORK_LIST,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD,
    ROLES_FINANCE_AND_FINANCE_ANALYST,
    REIMBURSEMENT_REQUEST,
    INVOICE_DETAILS_EXPORT_REPORT,
    TRAVEL_REQUEST,
    SITE_CREDIT_APPLICATION,
    SITE_FINANCE
  ],
  [ROLE_FINANCE_ADMINISTRATOR]: [
    GLOBAL_SEARCH,
    LOGS,
    PS,
    PL,
    PATIENT_INFO,
    PATIENT_PROFILE,
    CALENDAR,
    SS,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    BSS,
    CAS,
    FINANCE,
    FINANCE_REPORTS,
    ROLES_FINANCE_AND_FINANCE_ANALYST,
    REVENUE,
    INVOICE,
    TRANSACTION_LEDGER,
    RECONCILE,
    ENROLMENTS,
    STUDY_ENROLMENTS,
    PROJECTED_REVENUE,
    SAS,
    SBS,
    ST,
    STUDY_REPORTS,
    VAS,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD,
    SITE_PAYMENTS,
    READY_FOR_APPROVAL,
    REIMBURSEMENT_REQUEST,
    TRAVEL_REQUEST,
    TASK_WORK_LIST,
    INVOICE_DETAILS_EXPORT_REPORT,
    HELP,
    TIMELINE,
    STUDY_EXPENSES,
    ACTIVITY,
    SITE_CREDIT_APPLICATION,
    SITE_FINANCE
  ],
  [ROLE_FINANCE]: [
    ACTIVITY,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    ENROLMENTS,
    FINANCE,
    FINANCE_REPORTS,
    INVOICE,
    SITE_PAYMENTS,
    REVENUE,
    PROJECTED_REVENUE,
    STUDY_ENROLMENTS,
    STUDY_EXPENSES,
    STUDY_REPORTS,
    ST,
    SBS,
    BSS,
    TRANSACTION_LEDGER,
    CALENDAR,
    TASK_WORK_LIST,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD,
    ROLES_FINANCE_AND_FINANCE_ANALYST,
    INVOICE_DETAILS_EXPORT_REPORT
  ],
  [ROLE_ELLIGO_MANAGEMENT]: [
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    CUSTOM_REPORTS,
    FINANCE_REPORTS,
    STUDY_REPORTS,
    TIMELINE,
    FINANCE,
    PROJECTED_REVENUE,
    CALENDAR,
    LEADERSHIP_METRICS_REPORTS,
    SSU_METRICS_REPORTS,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD
  ],
  [ROLE_RECRUITER]: [
    PRE_SCREEN,
    LOAD_PATIENTS,
    PL,
    PATIENT_INFO,
    PATIENT_PROFILE,
    PS,
    WORK_LIST,
    HELP,
    DASHBOARDS,
    CALENDAR,
    GLOBAL_SEARCH,
    MANAGE_TASKS,
    TASK_WORK_LIST,
    PRE_SCREENING_REPORTS,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD
  ],
  [ROLE_OPERATION_MANAGER]: [
    PRE_SCREEN,
    WORK_LIST,
    PS,
    PL,
    LOGS,
    PATIENT_INFO,
    PATIENT_PROFILE,
    EXPORT_CASE_REPORT_FORMS,
    CRA_REVIEW,
    PLA,
    PI_WORK_LIST,
    SM_WORKLIST,
    SS,
    TIMELINE,
    STUDY_ENROLMENTS,
    STUDY_EXPENSES,
    STUDY_REPORTS,
    ACTIVITY,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    CALENDAR,
    HELP,
    GLOBAL_SEARCH,
    CALENDAR_INVITATIONS_PREFERENCES,
    MANAGE_TASKS,
    TASK_WORK_LIST,
    PRE_SCREENING_REPORTS,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    HOME_PAGE_DASHBOARDS,
    SCHEDULING_WORK_LIST,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    SSU_METRICS_REPORTS,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD,
    REIMBURSEMENT_REQUEST,
    TRAVEL_REQUEST,
    SITE_FINANCE
  ],
  [ROLE_PATIENT_LIAISON]: [
    PRE_SCREEN,
    PATIENT_BULK_UPDATE,
    WORK_LIST,
    PS,
    PL,
    LOGS,
    PATIENT_INFO,
    PATIENT_PROFILE,
    SS,
    TIMELINE,
    STUDY_ENROLMENTS,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    CUSTOM_REPORTS,
    STUDY_REPORTS,
    CALENDAR,
    HELP,
    GLOBAL_SEARCH,
    TASK_WORK_LIST,
    PRE_SCREENING_REPORTS,
    MANAGE_TASKS,
    LOAD_PATIENTS,
    SCHEDULING_WORK_LIST,
    EXPORT_CASE_REPORT_FORMS,
    USER_PREFERENCES,
    CALENDAR_INVITATIONS_PREFERENCES,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD
  ],
  [ROLE_PATIENT_REPRESENTATIVE]: [
    PRE_SCREEN,
    LOAD_PATIENTS,
    WORK_LIST,
    PS,
    PL,
    LOGS,
    PATIENT_INFO,
    PATIENT_PROFILE,
    SS,
    TIMELINE,
    STUDY_REPORTS,
    STUDY_ENROLMENTS,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    CALENDAR,
    HELP,
    GLOBAL_SEARCH,
    CALENDAR_INVITATIONS_PREFERENCES,
    MANAGE_TASKS,
    TASK_WORK_LIST,
    PRE_SCREENING_REPORTS,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD
  ],
  [ROLE_OPERATIONS_ANALYST]: [
    PRE_SCREEN,
    WORK_LIST,
    PS,
    PL,
    LOGS,
    PLA,
    CRA_REVIEW,
    PI_WORK_LIST,
    SM_WORKLIST,
    PATIENT_INFO,
    PATIENT_PROFILE,
    EXPORT_CASE_REPORT_FORMS,
    SS,
    TIMELINE,
    STUDY_ENROLMENTS,
    ACTIVITY,
    STUDY_EXPENSES,
    FINANCE,
    ENROLMENTS,
    PROJECTED_REVENUE,
    STUDY_REPORTS,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    FINANCE_REPORTS,
    CALENDAR,
    HELP,
    GLOBAL_SEARCH,
    PI_REVIEW,
    SM_REVIEW,
    TASK_WORK_LIST,
    MANAGE_TASKS,
    PRE_SCREENING_REPORTS,
    HOME_PAGE_DASHBOARDS,
    USER_PREFERENCES,
    SCHEDULING_WORK_LIST,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD,
    REIMBURSEMENT_REQUEST,
    TRAVEL_REQUEST
  ],
  [ROLE_CLINICAL_QUALITY_COORDINATOR]: [
    PS,
    PL,
    LOGS,
    PATIENT_INFO,
    PATIENT_PROFILE,
    EXPORT_CASE_REPORT_FORMS,
    SM_WORKLIST,
    PI_WORK_LIST,
    TASK_WORK_LIST,
    MANAGE_TASKS,
    ACTIVITY,
    SS,
    STUDY_REPORTS,
    PRE_SCREENING_REPORTS,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    FINANCE_REPORTS,
    CALENDAR,
    HELP,
    GLOBAL_SEARCH,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD
  ],
  [ROLE_SITE_COORDINATOR]: [
    PL,
    PATIENT_INFO,
    PATIENT_PROFILE,
    TASK_WORK_LIST,
    SS,
    DASHBOARDS,
    HOME_PAGE_DASHBOARDS,
    STUDY_REPORTS,
    HELP,
    ACTIVITY,
    GLOBAL_SEARCH,
    CALENDAR_INVITATIONS_PREFERENCES,
    MANAGE_TASKS,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    REIMBURSEMENT_REQUEST,
    TRAVEL_REQUEST
  ],
  [ROLE_SITE_FINANCIAL_COORDINATOR]: [
    PRE_SCREEN,
    ACTIVITY,
    ACTIVITY_REPORTS,
    DASHBOARDS,
    MILESTONES_DASHBORDS,
    PI_WORK_LIST,
    SM_WORKLIST,
    PL,
    LOGS,
    PATIENT_INFO,
    PATIENT_PROFILE,
    PLA,
    PS,
    SS,
    STUDY_EXPENSES,
    STUDY_REPORTS,
    STUDY_ENROLMENTS,
    WORK_LIST,
    HELP,
    CALENDAR,
    GLOBAL_SEARCH,
    CALENDAR_INVITATIONS_PREFERENCES,
    PI_REVIEW,
    SM_REVIEW,
    CRA_REVIEW,
    EXPORT_CASE_REPORT_FORMS,
    MANAGE_TASKS,
    TASK_WORK_LIST,
    PRE_SCREENING_REPORTS,
    USER_PREFERENCES,
    NOTIFICATION_PREFERENCES,
    HOME_PAGE_DASHBOARDS,
    SCHEDULING_WORK_LIST,
    AUDIT_LOG_ALL_STUDY_DATA_REPORT,
    OPEN_ENCOUNTERS_DASHBOARD,
    SSU_KPI_DASHBOARD,
    ALL_USERS_OTHER_THAN_CRA_DASHBOARD,
    SITE_PERFORMANCE_DASHBOARD,
    REIMBURSEMENT_REQUEST,
    REIMBURSEMENT_WORKLIST,
    FINANCE,
    TRANSACTION_LEDGER,
    TRAVEL_REQUEST,
    TRAVEL_REQUEST_WORKLIST,
    PATIENT_STIPEND_WORKLIST,
    SITE_FINANCE
  ]
};

export const rolesAccessToPageMap = reduce(
  accessToPagesMap,
  function(accumulator, accesses, role) {
    accesses.forEach(function(access) {
      if (!isArray(accumulator[access])) {
        accumulator[access] = [];
      }
      accumulator[access].push(role);
    });
    return accumulator;
  },
  {}
);

const writeLoginTime = async () => {
  const res = await UserApiApi.writeLogInTime();
  if (res?.data?.response) {
    localStorage.setItem(SESSION_ID, res.data.response);
  }
};

// returns array of 4 boolean values, where the first value is whether there was a network error in checking whether
// the user has logged in before, the second value is whether a sync is required before DS is aware of users roles,
// the third value is whether the terms of use were accepted, and the third value is whether a new login time was
// written to backend
// usage:
// const [networkError, requiresSync, acceptedTermsOfUse, wroteLoginTime] = await checkTermsOfUse();
const checkTermsOfUse = async () => {
  let networkError = false,
    requiresSync = false,
    acceptedTermsOfUse = false,
    wroteLoginTime = false;
  try {
    const firstLogin = await UserApiApi.checkFirstTimeLogin();
    if (firstLogin?.data?.response) {
      try {
        await ModalBoxes.confirm({
          content: <TermsOfUseModal />,
          className: 'terms-of-use-modal',
          title: 'IntElligo® Terms of Use',
          confirmButton: 'Accept'
        });
        await writeLoginTime();
        acceptedTermsOfUse = true;
        wroteLoginTime = true;
      } catch (e) {
        console.log(e);
      }
    } else {
      acceptedTermsOfUse = true;
    }
  } catch (err) {
    networkError = true;
    if (err?.response?.status === 404) {
      console.log(err?.response?.data?.name);
      if ((err?.response?.data?.name || '').includes('UserRequiresSync')) {
        requiresSync = true;
      }
    }
  }
  return [networkError, requiresSync, acceptedTermsOfUse, wroteLoginTime];
};

// Write login time if we don't have a SESSION_ID (some UUID corresponding to a login/logout time in backend for
// given user) in local storage or if we do have a "sessionId" and the given Auth0 token was issued within the
// last 10 seconds. If we have a SESSION_ID in local storage and a token issued more than 10 seconds ago, we do not
// write a new login time, since we assume the user reloaded page or closed and reopened tab, but is still using
// the same token, which didn't require login.
const checkLoginTime = async tokenIssuedAt => {
  const tenSecondsAgo = moment().subtract(10, 'seconds');
  const sessionId = localStorage.getItem(SESSION_ID);
  let shouldWriteLogInTime;

  // if we have existing sessionId in localStorage
  if (sessionId) {
    const tokenIssuedAtMoment = moment(tokenIssuedAt);
    // check if auth0 token was issued within the last 10 seconds
    shouldWriteLogInTime = tokenIssuedAtMoment.isAfter(tenSecondsAgo);
  } else {
    // We should always write new login time if we don't currently have a sessionId in local storage
    shouldWriteLogInTime = true;
  }

  if (shouldWriteLogInTime) {
    await writeLoginTime();
  }
};

export function CurrentUserContainer({ children }) {
  const dispatch = useDispatch();
  const appInfo = useAppInfo();

  const groupsSetupEnabled = appInfo.features.groupsSetupEnabled;
  const taskEnabled = appInfo.features.taskEnabled;
  const pendoEnabled = appInfo.features.pendoEnabled;
  const landingPageEnabled = appInfo.features.homePageDashboardsEnabled;
  const sitePaymentsEnabled = appInfo.features.sitePaymentsEnabled;
  const patientTravelEnabled = appInfo.features.patientTravelEnabled;
  const state = useSelector(state => state);
  const currentUser = state?.currentUser;
  const homePageDashboardsConfig = state?.homePageDashboardsConfig;
  useAppVersionCheck();
  const [isUnknownUser, setIsUnknownUser] = useState(false);
  const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(false);
  const [checkedLoginTime, setCheckedLoginTime] = useState(false);
  const { isLoading, isAuthenticated, getIdTokenClaims } = useAuth0();
  const { bouncerLogout } = useBouncer();

  useEffect(() => {
    (async () => {
      if (!checkedLoginTime && !isLoading && isAuthenticated) {
        // used to debounce to prevent multiple runs of this function
        setCheckedLoginTime(true);

        const [networkError, requiresSync, acceptedTOU, wroteLoginTime] = await checkTermsOfUse();

        if (networkError) {
          setIsUnknownUser(true);
          setNeedsSync(requiresSync);
        } else if (!acceptedTOU) {
          bouncerLogout();
        } else if (!wroteLoginTime) {
          const claims = await getIdTokenClaims();
          const tokenIssuedAt = (claims?.iat || 0) * 1000;
          await checkLoginTime(tokenIssuedAt);
        }

        setAcceptedTermsOfUse(acceptedTOU);
      }
    })();

    return () => {};
  }, [checkedLoginTime, isLoading, isAuthenticated, getIdTokenClaims, bouncerLogout]);

  const [needsSync, setNeedsSync] = useState(false);
  useEffect(
    function() {
      (async function() {
        try {
          if (acceptedTermsOfUse) {
            const response = await UserApiApi.getUserInfo(),
              {
                roles,
                personnelIdentifier,
                email,
                firstName,
                lastName,
                operationsMapping,
                department,
                title,
                operationScopes,
                financeAccess
              } = response.data,
              activeRole = getActiveRole(roles),
              accessToPages = accessToPagesMap[activeRole];

            if (!taskEnabled) {
              pull(accessToPages, TASK_WORK_LIST);
              pull(accessToPages, MANAGE_TASKS);
              forEach(operationsMapping, function(o) {
                pull(o, operations.MANAGE_TASKS_GLOBAL);
                pull(o, operations.VIEW_TASKS_CALENDAR);
              });
            }

            if (!patientTravelEnabled) {
              pull(accessToPages, TRAVEL_REQUEST);
              forEach(operationsMapping, function(o) {
                pull(o, operations.MANAGE_TRAVEL_PREFERENCES);
                pull(o, operations.VIEW_TRAVEL_PREFERENCES);
              });
            }

            if (!groupsSetupEnabled) {
              pull(accessToPages, PROTOCOL_GROUPS_SETUP);
            }

            if (!landingPageEnabled) {
              pull(accessToPages, HOME_PAGE_DASHBOARDS);
            }

            if (!patientTravelEnabled) {
              pull(accessToPages, TRAVEL_REQUEST_WORKLIST);
            }

            if (!sitePaymentsEnabled) {
              pull(accessToPages, SITE_PAYMENTS);
              pull(accessToPages, READY_FOR_APPROVAL);
            }

            if (financeAccessRoles.includes(activeRole) && !financeAccess[activeRole].financeDetailsAccess) {
              pull(accessToPages, SITE_FINANCE);
            }

            validateAllowedOperations(operationsMapping);

            if (
              !localStorage.getItem(CURRENT_USER_LAST_ACTIVE_ROLE) ||
              localStorage.getItem(CURRENT_USER_LAST_ACTIVE_ROLE) !== activeRole
            ) {
              localStorage.setItem(CURRENT_USER_LAST_ACTIVE_ROLE, activeRole);
            }

            dispatch(
              updateCurrentUser({
                personnelIdentifier,
                email,
                firstName,
                lastName,
                activeRole,
                roles,
                accessToPages,
                operationsMapping,
                operationScopes,
                department,
                title,
                financeAccess,
                roleBasedId: `${personnelIdentifier}-${activeRole}`
              })
            );
            if (
              landingPageEnabled &&
              operationsMapping[activeRole].includes(VIEW_AND_EDIT_HOME_PAGE_DASHBOARDS_WIDGET_CONFIG)
            ) {
              await dispatch(getHomePageDashboardsConfig());
            }
          }
        } catch (err) {
          console.log(err);
          setIsUnknownUser(true);
        }
      })();
    },
    [
      acceptedTermsOfUse,
      dispatch,
      taskEnabled,
      pendoEnabled,
      landingPageEnabled,
      groupsSetupEnabled,
      sitePaymentsEnabled,
      patientTravelEnabled
    ]
  );

  useEffect(function() {
    window.addEventListener('storage', localStorageChangeHandler);
    return function() {
      window.removeEventListener('storage', localStorageChangeHandler);
    };
  }, []);

  return renderChildren(currentUser, landingPageEnabled, homePageDashboardsConfig) ? (
    children
  ) : (
    <React.Fragment>
      <NoUser isUnknownUser={isUnknownUser} needsSync={needsSync} />
      <ModalBoxesContainer />
    </React.Fragment>
  );
}

function renderChildren(currentUser, landingPageEnabled, homePageDashboardsConfig) {
  if (
    !isEmpty(currentUser) &&
    landingPageEnabled &&
    currentUser.operationsMapping[currentUser.activeRole].includes(VIEW_AND_EDIT_HOME_PAGE_DASHBOARDS_WIDGET_CONFIG)
  ) {
    return !isEmpty(homePageDashboardsConfig);
  } else {
    return !isEmpty(currentUser);
  }
}

export function useCurrentUser() {
  return useSelector(({ currentUser }) => currentUser);
}

function getActiveRole(roles) {
  const currentUserLastActiveRole = localStorage.getItem(CURRENT_USER_LAST_ACTIVE_ROLE);
  if (currentUserLastActiveRole && roles.includes(currentUserLastActiveRole)) {
    return currentUserLastActiveRole;
  }
  return roles[0];
}

function localStorageChangeHandler(e) {
  if (e.key === CURRENT_USER_LAST_ACTIVE_ROLE) {
    window.location.reload();
  }
}

function useAppVersionCheck() {
  const appInfo = useAppInfo();
  useEffect(
    function() {
      VersionApi.getVersion().then(({ data }) => {
        if (appInfo?.displayAppVersion !== data?.displayAppVersion) {
          window.location.reload();
        }
      });
    },
    [appInfo]
  );
}

function validateAllowedOperations(operationsMapping) {
  flatMap(operationsMapping).forEach(function(operation) {
    if (!includes(operations, operation)) {
      throw new Error(`'${operation}' not declared in 'constants/userOperations.js!'`);
    }
  });
}
