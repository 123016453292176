import { useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { trimTextContentFromAnchor } from '@lexical/selection';
import { $restoreEditorState } from '@lexical/utils';
import { $getSelection, $isRangeSelection, RootNode } from 'lexical';

export const OnCharsCountPlugin = ({ maxSize }) => {
  const [editor] = useLexicalComposerContext();
  const [count, setCount] = useState(0);

  useEffect(() => {
    let lastStoredState = null;
    return editor.registerNodeTransform(RootNode, rootNode => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }
      const prevState = editor.getEditorState();
      const prevTextSize = prevState.read(() => rootNode.getTextContentSize());

      const textSize = rootNode.getTextContentSize();
      if (prevTextSize !== textSize) {
        const delta = textSize - maxSize;
        const anchor = selection.anchor;

        if (delta > 0) {
          if (prevTextSize === maxSize && lastStoredState !== prevState) {
            lastStoredState = prevState;
            $restoreEditorState(editor, prevState);
          } else {
            trimTextContentFromAnchor(editor, anchor, delta);
          }
        } else {
          setCount(textSize);
        }
      }
    });
  }, [editor, maxSize]);

  return (
    <div className="text-size-counter">
      {count}/{maxSize}
    </div>
  );
};
