import * as React from 'react';
import { useContext, useMemo } from 'react';
import moment from 'moment/moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { toBill } from '../../../../../services/financial';
import { patientStatusMap } from '../../../patient-source/Patients/constants';
import { calculateSponsorStatus } from '../../NewInvoice/InvoiceTable/InvoiceTableService';
import { PARENT, WITHHOLDING } from '../../NewInvoice/InvoiceTable/TableConstants';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { DefaultFinanceCell } from '../../shared/FinanseTableMUI/DefaultFinanceCell';
import { FinanceTableMUI } from '../../shared/FinanseTableMUI/FinanceTableMUI';
import { prepareNameForCSV } from '../../shared/FinanseTableMUI/FinanceTableServices';
import { eventNumberComparator, resolveEventSuffix } from '../../SitePayments/SitePaymentServices';
import {
  SITE_CREDIT,
  SITE_CREDIT_WH,
  SITE_DEBIT,
  SITE_DEBIT_WH,
  SitePaymentStatus
} from '../../SitePayments/SitePaymentsTable/SitePaymentsConstants';
import {
  sitePaymentsDateRenderCell,
  sitePaymentsDateValueFormatter,
  sitePaymentsValueGetter
} from '../../SitePayments/SitePaymentsTable/SitePaymentsTable';
import {
  PaymentStatusIcon,
  SitePaymentStatusIcon,
  StatusCell
} from '../../SitePayments/SitePaymentsTable/StatusCell/StatusCell';
import { SiteFinanceTableContext } from '../SiteFinanceContext';
import { isWithholdingType } from '../SiteFinanceInfo/SiteFinanceInfoServices';

import { SubjectIdCell } from './SubjectIdCell/SubjectIdCell';

export const SiteFinanceTable = () => {
  const { tableData, firstLoading } = useContext(SiteFinanceTableContext);

  const columns = useMemo(() => {
    return [
      {
        field: 'studyName',
        headerName: 'Study',
        flex: 1,
        minWidth: 110,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'pcn',
        headerName: 'PCN',
        flex: 1,
        minWidth: 100,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'siteName',
        headerName: 'Site',
        flex: 1,
        minWidth: 100,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'subjectId',
        headerName: 'Subject ID',
        flex: 1,
        minWidth: 145,
        valueFormatter: ({ value }) => (value != null ? `[${value}]` : ''),
        renderCell: SubjectIdCell
      },
      {
        field: 'epochName',
        headerName: 'Epoch',
        flex: 1,
        minWidth: 110,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'encounterName',
        headerName: 'Encounter',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'itemGroupName',
        headerName: 'Procedure',
        flex: 1,
        minWidth: 140,
        renderCell: DefaultFinanceCell,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'eventName',
        headerName: 'Event Name',
        flex: 1,
        minWidth: 150,
        valueFormatter: ({ value }) => prepareNameForCSV(value)
      },
      {
        field: 'eventDate',
        headerName: 'Event Date',
        flex: 1,
        minWidth: 150,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row }) => {
          const content = moment(row.eventDate).format(DD_SLASH_MMM_SLASH_YYYY);
          return (
            <div className="MuiDataGrid-cellContent" title={content}>
              {content}
            </div>
          );
        }
      },
      {
        field: 'revenueDate',
        headerName: 'Revenue Date',
        flex: 1,
        minWidth: 150,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { revenueDate } }) => (
          <div>{revenueDate ? moment(revenueDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        flex: 1,
        minWidth: 150,
        type: 'date',
        valueFormatter: ({ value }) => (value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '-'),
        valueGetter: ({ value }) => value && new Date(value),
        renderCell: ({ row: { invoiceDate } }) => (
          <div>{invoiceDate ? moment(invoiceDate).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
        )
      },
      {
        field: 'invoiceAmount',
        headerName: 'Invoice Amount',
        flex: 1,
        minWidth: 170,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.invoiceAmount),
        renderCell: ({ row }) =>
          row.adjustmentSequence !== 0 ? '—' : <Amount coinsAmount={row.invoiceAmount} showDollarSign />
      },
      {
        field: 'paymentStatus',
        headerName: 'Sponsor Payment Status',
        flex: 1,
        minWidth: 170,
        valueGetter: ({ row }) => calculateSponsorStatus(row),
        valueFormatter: ({ value }) => prepareNameForCSV(value),
        renderCell: ({ value }) => (
          <StatusCell value={value} statusWidth="80px">
            <PaymentStatusIcon status={value} />
          </StatusCell>
        )
      },
      {
        field: 'siteAmount',
        headerName: 'Site Amount',
        flex: 1,
        minWidth: 150,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.siteAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.siteAmount} showDollarSign />
      },
      {
        field: 'withholdingAmount',
        headerName: 'Withholding Amount',
        flex: 1,
        minWidth: 200,
        type: 'number',
        valueGetter: ({ row }) => (isWithholdingType(row.sitePaymentType) ? null : toBill(row.withholdingAmount)),
        renderCell: ({ row, value }) =>
          isWithholdingType(row.sitePaymentType) ? (
            <DefaultFinanceCell value={value} />
          ) : (
            <Amount coinsAmount={row.withholdingAmount} showDollarSign />
          )
      },
      {
        field: 'sitePaymentType',
        headerName: 'Adjustment',
        flex: 1,
        minWidth: 150,
        valueGetter: ({ value }) => {
          switch (value) {
            case SITE_CREDIT:
              return 'Site Credit';
            case SITE_DEBIT:
              return 'Site Debit';
            case WITHHOLDING:
            case SITE_CREDIT_WH:
            case SITE_DEBIT_WH:
              return 'Withholding';
            case PARENT:
              return 'N/A';
            default:
              return '—';
          }
        }
      },
      {
        field: 'sitePaidAmount',
        headerName: 'Site Paid Amount',
        flex: 1,
        minWidth: 200,
        type: 'number',
        valueGetter: ({ row }) => toBill(row.sitePaidAmount),
        renderCell: ({ row }) => <Amount coinsAmount={row.sitePaidAmount} showDollarSign />
      },
      {
        field: 'sitePaymentStatus',
        headerName: 'Site Payment Status',
        flex: 1,
        minWidth: 170,
        valueGetter: ({ row }) => SitePaymentStatus[row.sitePaymentStatus],
        renderCell: ({ value }) => (
          <StatusCell value={value} statusWidth="110px">
            <SitePaymentStatusIcon status={value} />
          </StatusCell>
        )
      },
      {
        field: 'sitePaymentDate',
        headerName: 'Site Payment Date',
        flex: 1,
        minWidth: 160,
        type: 'date',
        valueFormatter: sitePaymentsDateValueFormatter,
        valueGetter: sitePaymentsValueGetter,
        renderCell: sitePaymentsDateRenderCell
      },
      {
        field: 'siteBillNumber',
        headerName: 'Site Bill #',
        flex: 1,
        minWidth: 150,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'eventNumber',
        headerName: 'Event ID',
        flex: 1,
        minWidth: 125,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row }) => {
          if (row.adjustmentSequence === 0) {
            return resolveEventSuffix(row.eventNumber, row.sitePaymentType);
          }
          return resolveEventSuffix(`${row.eventNumber}.${row.adjustmentSequence}`, row.sitePaymentType);
        }
      },
      {
        field: 'investigators',
        headerName: 'Investigator',
        flex: 1,
        minWidth: 180,
        valueGetter: ({ row }) => (row?.investigators ? row.investigators : 'N/A'),
        renderCell: DefaultFinanceCell
      },
      {
        field: 'patientId',
        headerName: 'Patient ID',
        flex: 1,
        minWidth: 135,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'adjustmentMemo',
        headerName: 'Adjustment Memo',
        flex: 1,
        minWidth: 175,
        renderCell: DefaultFinanceCell
      },
      {
        field: 'currentPatientStatus',
        headerName: 'Current Patient Status',
        flex: 1,
        minWidth: 155,
        valueGetter: ({ row }) => convertPatientStatus(row.currentPatientStatus),
        renderCell: DefaultFinanceCell
      },
      {
        field: 'eventPatientStatus',
        headerName: 'Event Patient Status',
        flex: 1,
        minWidth: 155,
        valueGetter: ({ row }) => convertPatientStatus(row.eventPatientStatus),
        renderCell: DefaultFinanceCell
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice Number',
        flex: 1,
        minWidth: 120,
        sortComparator: eventNumberComparator,
        valueGetter: ({ row: { invoiceNumber, sitePaymentType } }) =>
          invoiceNumber ? resolveEventSuffix(invoiceNumber, sitePaymentType) : '',
        renderCell: DefaultFinanceCell
      },
      {
        field: 'visitDate',
        headerName: 'Visit Date',
        flex: 1,
        minWidth: 150,
        type: 'date',
        valueFormatter: sitePaymentsDateValueFormatter,
        valueGetter: sitePaymentsValueGetter,
        renderCell: ({ row: { visitDate } }) => <DefaultDateRenderCell date={visitDate} />
      }
    ];
  }, []);

  return (
    <>
      <FinanceTableMUI
        localStorageTableName="SITE_FINANCE"
        getRowId={row => row.itemSiteId}
        data-testid="site-finance-table"
        rows={tableData}
        columns={columns}
        disableRowSelectionOnClick
        footerElementsCount={tableData.length}
        initialState={{
          columns: {
            columnVisibilityModel: {
              eventNumber: false,
              investigators: false,
              patientId: false,
              adjustmentMemo: false,
              currentPatientStatus: false,
              eventPatientStatus: false,
              invoiceNumber: false,
              visitDate: false
            }
          }
        }}
        localeText={{ noRowsLabel: firstLoading ? 'Make selections to display results' : 'No results to display' }}
        columnsFilterSort="asc"
        tableVersion="1.01"
      />
    </>
  );
};

function convertPatientStatus(statusId) {
  const status = patientStatusMap.find(status => status.id === statusId);
  return status ? status.name : null;
}

export const DefaultDateRenderCell = ({ date }) => (
  <div>{date ? moment.utc(date).format(DD_SLASH_MMM_SLASH_YYYY) : '—'}</div>
);
