import { useContext, useMemo } from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import { Box, SvgIcon } from '@mui/material';

import { ReactComponent as PatientListIcon } from '../../../../../../images/icons/patient-list-icon.svg';
import { SiteFinanceTableContext } from '../../SiteFinanceContext';
import { SiteFinanceInfoItem } from '../SiteFinanceInfoItem/SiteFinanceInfoItem';
import { isParentType, isWithholdingType } from '../SiteFinanceInfoServices';

export const QuickStatsPopover = ({ tableData }) => {
  const { lifetimeData } = useContext(SiteFinanceTableContext);

  const earnedSiteAmount = useMemo(() => {
    return tableData
      .filter(ledgerEvent => !isWithholdingType(ledgerEvent.sitePaymentType))
      .reduce((acc, curr) => acc + curr.siteAmount, 0);
  }, [tableData]);

  const amountWithheldFromPayment = useMemo(() => {
    const parentEventIds = tableData
      .filter(ledgerEvent => {
        return isParentType(ledgerEvent.sitePaymentType) && ['PAID', 'APPLIED'].includes(ledgerEvent.sitePaymentStatus);
      })
      .map(el => el.id);
    const withholdingEvents = tableData.filter(ledgerEvent => isWithholdingType(ledgerEvent.sitePaymentType));
    const withholdingEventsForParent = withholdingEvents.filter(ledgerEvent => {
      return parentEventIds.includes(ledgerEvent.id) && !['PAID', 'APPLIED'].includes(ledgerEvent.sitePaymentStatus);
    });
    return withholdingEventsForParent.reduce((acc, curr) => acc + curr.siteAmount, 0);
  }, [tableData]);

  return (
    <Box padding="16px" display="flex" gap="16px" flexWrap="wrap" maxWidth="1050px">
      <SiteFinanceInfoItem
        icon={<MonetizationOnIcon />}
        title="Total Earned (Lifetime):"
        amount={lifetimeData.totalEarnedInvoiceAmount}
      />
      <SiteFinanceInfoItem icon={<MonetizationOnIcon />} title="Earned Site Amount:" amount={earnedSiteAmount} />
      <SiteFinanceInfoItem
        icon={<MonetizationOnIcon />}
        title="Amount Withheld from Payment:"
        amount={amountWithheldFromPayment}
      />
      <SiteFinanceInfoItem
        icon={<RunningWithErrorsIcon />}
        title="Screen Failures Ratio:"
        value={`${lifetimeData.screenFailuresRatio} %`}
      />
      <SiteFinanceInfoItem
        icon={
          <SvgIcon>
            <PatientListIcon />
          </SvgIcon>
        }
        title="Enrollment:"
        value={lifetimeData.enrolledPatientsCount}
      />
      <SiteFinanceInfoItem
        icon={<MonetizationOnIcon />}
        title="Avg. Monthly Earning:"
        amount={lifetimeData.avgMonthlyEarning}
      />
    </Box>
  );
};
