import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { IconButton, Typography } from '@mui/material';
import { grey, lightBlue } from '@mui/material/colors';
import ModalBoxes from 'common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from 'common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from 'constants/dateFormat';
import { has } from 'lodash/object';
import moment from 'moment-timezone';
import { toBill } from 'services/financial';

import { generateUrlByKey } from 'components/root/router';

import { PatientStipendWorklistApi } from '../../../../api';
import EditNoteModal from '../shared/worklist-table-note/EditNoteModal/EditNoteModal';
import ViewNoteModal from '../shared/worklist-table-note/ViewNoteModal/ViewNoteModal';

import StatusChangeDropdown from './StatusChangeDropdown/StatusChangeDropdown';
import { stipendStatusMap, STP_DENIED, STP_IN_REVIEW } from './constants';

export default function useDataGrid() {
  const [gridData, setGridData] = useState([]);

  const columns = useMemo(
    () => [
      {
        field: 'patientId',
        headerName: 'Patient ID',
        flex: 1,
        renderCell({ value, row }) {
          return (
            <Link
              to={generateUrlByKey('Patients.Patient Profile.Patient Studies', {
                patientId: row.patientUniqueIdentifier,
                ssuPatientId: row.ssuPatientId
              })}
            >
              {value}
            </Link>
          );
        }
      },
      {
        field: 'mrn',
        headerName: 'MRN',
        flex: 1
      },
      {
        field: 'eventNumber',
        headerName: 'EventID',
        flex: 1,
        valueGetter({ value, row }) {
          if (row?.adjustmentSequence) {
            return `${value}.${row.adjustmentSequence}`;
          } else {
            return value;
          }
        }
      },
      {
        field: 'approvedAt',
        headerName: 'Approved On',
        flex: 1,
        valueGetter({ value }) {
          return value ? new Date(value) : null;
        },
        renderCell({ value }) {
          return value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '';
        },
        type: 'date',
        align: 'center'
      },
      {
        field: 'approvedBy',
        headerName: 'Approved By',
        flex: 1
      },
      {
        field: 'studyName',
        headerName: 'Study',
        flex: 1
      },
      {
        field: 'siteName',
        headerName: 'Site',
        flex: 1
      },
      {
        field: 'encounterName',
        headerName: 'Encounter',
        flex: 1
      },
      {
        field: 'encounterDate',
        headerName: 'Encounter date',
        flex: 1,
        valueGetter({ value }) {
          return value ? new Date(value) : null;
        },
        renderCell({ value }) {
          return value ? moment(value).format(DD_SLASH_MMM_SLASH_YYYY) : '';
        },
        type: 'date',
        align: 'center'
      },
      {
        field: 'subjectId',
        headerName: 'SubjectID',
        flex: 1
      },
      {
        field: 'stipendName',
        headerName: 'Stipend name',
        flex: 1
      },
      {
        field: 'totalAmount',
        headerName: 'Amount',
        flex: 1,
        type: 'number',
        maxWidth: 100,
        valueGetter({ value }) {
          return toBill(value);
        },
        renderCell({ value }) {
          return `$${value}`;
        }
      },
      {
        field: 'note',
        headerName: 'Note',
        flex: 1,
        maxWidth: 100,
        renderCell({ value, row }) {
          if (!row.stipendOversight) {
            return;
          }
          return (
            <IconButton
              onClick={function() {
                ModalBoxes.open({
                  component: !row?.stipendApproved ? (
                    <EditNoteModal
                      row={row}
                      onSave={function(note, markAsRead) {
                        return PatientStipendWorklistApi.updateRow(row.id, {
                          note,
                          markAsRead
                        });
                      }}
                    />
                  ) : (
                    <ViewNoteModal row={row} />
                  ),
                  onClose(data) {
                    if (has(data, 'note') && has(data, 'isNoteRead')) {
                      setGridData(function(state) {
                        return state.map(function(r) {
                          if (r.id !== row.id) return r;
                          return {
                            ...r,
                            ...data
                          };
                        });
                      });
                    }
                  }
                });
              }}
            >
              {value && !row?.isNoteRead ? (
                <MarkEmailUnreadIcon htmlColor={lightBlue[500]} />
              ) : (
                <EmailIcon htmlColor={grey[500]} />
              )}
            </IconButton>
          );
        }
      },
      {
        field: 'status',
        headerName: 'Action',
        flex: 1,
        minWidth: 200,
        valueGetter({ value }) {
          return stipendStatusMap[value] || '';
        },
        renderCell({ value, row }) {
          if ([STP_IN_REVIEW, STP_DENIED].includes(row.status) && row.stipendOversight) {
            return (
              <StatusChangeDropdown
                row={row}
                onChangeSuccess={function(data) {
                  NotificationManager.success(
                    data?.status === STP_DENIED ? `Payment denied` : `Stipend approved successfully`
                  );
                  setGridData(function(state) {
                    return state.map(function(r) {
                      if (r.id !== row.id) return r;
                      return { ...r, ...data };
                    });
                  });
                }}
              />
            );
          }
          return <Typography variant="body1">{value}</Typography>;
        }
      }
    ],
    []
  );

  return [columns, gridData, setGridData];
}
