import React, { useCallback } from 'react';
import { Divider, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { isNil } from 'lodash/lang';

export default function HideAllFieldsConfiguration({ hideAllFieldsConfig, setHideAllFieldsConfig }) {
  const { allowed, enabled, answer, options } = hideAllFieldsConfig || {};

  const patchHideAllFieldsConfig = useCallback(
    function(patch) {
      setHideAllFieldsConfig(function(hideAllFieldsConfig) {
        return { ...hideAllFieldsConfig, ...patch };
      });
    },
    [setHideAllFieldsConfig]
  );

  if (!allowed) {
    return null;
  }

  return (
    <>
      <Typography variant="body1" gutterBottom>
        Hide full row based on selected Value?
      </Typography>
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={!!enabled}
        exclusive
        fullWidth
        sx={{ width: 130 }}
        onChange={function(event, enabled) {
          if (isNil(enabled)) {
            return;
          }
          patchHideAllFieldsConfig({ enabled });
        }}
      >
        <ToggleButton value={false}>No</ToggleButton>
        <ToggleButton value={true}>Yes</ToggleButton>
      </ToggleButtonGroup>

      {enabled && (
        <>
          <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
            When to hide:
          </Typography>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={answer}
            exclusive
            fullWidth
            sx={{ width: 130 }}
            onChange={function(event, answer) {
              if (isNil(answer)) {
                return;
              }
              patchHideAllFieldsConfig({ answer });
            }}
          >
            {options.map(function({ name, value }) {
              return (
                <ToggleButton key={value} value={value}>
                  {name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </>
      )}
      <Divider variant="middle" sx={{ my: 3 }} />
    </>
  );
}
