import { useCallback, useMemo } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { Box, Typography } from '@mui/material';

import { SiteFinanceInfoItem } from '../SiteFinanceInfoItem/SiteFinanceInfoItem';
import {
  calculateUnpaidWithholdingAmountForEvents,
  filterWithholdingEventsByInvoiceDateRangeInDays,
  isWithholdingType
} from '../SiteFinanceInfoServices';

export const WithholdingAmountsPerAgedARPopover = ({ tableData }) => {
  const withholdingEventsForPerAgedAR = useMemo(() => {
    return tableData.filter(ledgerEvent => {
      const isPaidStatus = ledgerEvent.reconcileTotalAmountPaid >= ledgerEvent.invoiceAmount;
      return isWithholdingType(ledgerEvent.sitePaymentType) && isPaidStatus === false && !!ledgerEvent.invoiceDate;
    });
  }, [tableData]);

  const withholdingAdjustmentAndParentEventsForPerAgedAR = useMemo(() => {
    const invoicedParentEventNumbers = new Set(
      withholdingEventsForPerAgedAR.filter(el => el.invoiceDate).map(el => el.eventNumber)
    );
    return tableData.filter(ledgerEvent => {
      return isWithholdingType(ledgerEvent.sitePaymentType) && invoicedParentEventNumbers.has(ledgerEvent.eventNumber);
    });
  }, [tableData, withholdingEventsForPerAgedAR]);

  const unpaidInvoiceAmountForWithholdingEvents = useMemo(() => {
    return calculateUnpaidWithholdingAmountForEvents([...withholdingAdjustmentAndParentEventsForPerAgedAR]);
  }, [withholdingAdjustmentAndParentEventsForPerAgedAR]);

  const unpaidInvoiceAmountForWithholdingEventsByDateRange = useCallback(
    (start, end) => {
      const unpaidWithholdingEventsByDateRange = filterWithholdingEventsByInvoiceDateRangeInDays(
        withholdingAdjustmentAndParentEventsForPerAgedAR,
        start,
        end
      );
      return calculateUnpaidWithholdingAmountForEvents(unpaidWithholdingEventsByDateRange);
    },
    [withholdingAdjustmentAndParentEventsForPerAgedAR]
  );

  const unpaidInvoiceByDateRange = useMemo(
    () => ({
      unpaidWithholdingInvoice030Days: unpaidInvoiceAmountForWithholdingEventsByDateRange(0, 30),
      unpaidWithholdingInvoice3060Days: unpaidInvoiceAmountForWithholdingEventsByDateRange(30, 60),
      unpaidWithholdingInvoice6090Days: unpaidInvoiceAmountForWithholdingEventsByDateRange(60, 90),
      unpaidWithholdingInvoice90Days: unpaidInvoiceAmountForWithholdingEventsByDateRange(90, 0)
    }),
    [unpaidInvoiceAmountForWithholdingEventsByDateRange]
  );

  return (
    <Box padding="16px" display="flex" flexDirection="column">
      <Typography variant="h6">Withholding Amounts per Aged AR</Typography>
      <Box display="flex" gap="16px" flexWrap="wrap">
        <SiteFinanceInfoItem
          icon={<MoneyOffIcon />}
          title="Unpaid Invoice:"
          amount={unpaidInvoiceAmountForWithholdingEvents}
        />
        <SiteFinanceInfoItem
          icon={<DateRangeIcon />}
          title="0-30 Days:"
          amount={unpaidInvoiceByDateRange.unpaidWithholdingInvoice030Days}
        />
        <SiteFinanceInfoItem
          icon={<DateRangeIcon />}
          title="30-60 Days:"
          amount={unpaidInvoiceByDateRange.unpaidWithholdingInvoice3060Days}
        />
        <SiteFinanceInfoItem
          icon={<DateRangeIcon />}
          title="60-90 Days:"
          amount={unpaidInvoiceByDateRange.unpaidWithholdingInvoice6090Days}
        />
        <SiteFinanceInfoItem
          icon={<DateRangeIcon />}
          title=">90 Days:"
          amount={unpaidInvoiceByDateRange.unpaidWithholdingInvoice90Days}
        />
      </Box>
    </Box>
  );
};
