import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import { isEmpty } from 'lodash/lang';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { MANAGE_BUDGETS } from '../../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../../services/auth';
import { toBill, toCoins } from '../../../../../../../services/financial';
import { onRequestError } from '../../../../../../../services/handlers';
import BudgetEventControlButtons from '../BudgetEventControlButtons';
import { PASS_THRU } from '../ExpenseType';

import './AddOrEditExpenseVariableEventModal.scss';

export const AddOrEditExpenseVariableEventModal = ({
  modalBox,
  currentStudyBudget,
  saveEvent,
  eventForUpdate,
  managementFeePercent
}) => {
  const [eventName, setEventName] = useState('');
  const [radioValue, setRadioValue] = useState(PASS_THRU);
  const [nonInvoiceable, setNonInvoiceable] = useState(false);
  const [managementFee, setManagementFee] = useState(+managementFeePercent > 0);
  const [maximumAllowance, setMaximumAllowance] = useState(false);
  const [maximumAmount, setMaximumAmount] = useState('0');

  const isEditingForbidden =
    (currentStudyBudget && !currentStudyBudget.editPossibility) || !userHasAccessTo(MANAGE_BUDGETS);

  const dataForSave = useMemo(() => {
    return {
      id: eventForUpdate?.id,
      name: eventName.trim(),
      maxAllowanceAmount: maximumAllowance ? toCoins(maximumAmount) : null,
      maxAllowanceType: radioValue,
      type: 'EXPENSE_VARIABLE',
      managementFee,
      nonInvoiceable
    };
  }, [eventForUpdate?.id, eventName, maximumAllowance, maximumAmount, nonInvoiceable, radioValue, managementFee]);

  useEffect(() => {
    if (eventForUpdate) {
      setEventName(eventForUpdate.name.trim());
      setMaximumAllowance(!!eventForUpdate.maxAllowanceAmount);
      setMaximumAmount(eventForUpdate.maxAllowanceAmount ? toBill(eventForUpdate.maxAllowanceAmount) : '0');
      setRadioValue(eventForUpdate.maxAllowanceType);
      setNonInvoiceable(eventForUpdate.nonInvoiceable);
      setManagementFee(eventForUpdate.managementFee);
    }
  }, [eventForUpdate]);

  const isFormValid = useMemo(() => {
    if (!eventName) {
      return false;
    }
    const amount = toCoins(maximumAmount);
    return !(maximumAllowance && (!amount || amount < 0));
  }, [eventName, maximumAllowance, maximumAmount]);

  const save = useCallback(() => {
    return saveEvent(dataForSave);
  }, [dataForSave, saveEvent]);

  const onSave = useCallback(() => {
    save().then(() => modalBox.close(), onRequestError);
  }, [modalBox, save]);

  const onSaveAndContinue = useCallback(() => {
    save().then(() => {
      setEventName('');
      setMaximumAllowance(false);
      setMaximumAmount('0');
      setRadioValue(PASS_THRU);
      setNonInvoiceable(false);
      setManagementFee(+managementFeePercent > 0);
    }, onRequestError);
  }, [managementFeePercent, save]);

  const maxEventNameLength = useMemo(() => 500, []);
  return (
    <>
      <ModalBoxes.Body>
        <TextField
          label="Event Name"
          variant="standard"
          required
          multiline
          disabled={isEditingForbidden}
          value={eventName}
          onChange={({ target: { value } }) => setEventName(value)}
          inputProps={{ maxLength: maxEventNameLength }}
          helperText={`${eventName?.length ?? 0}/${maxEventNameLength}`}
          sx={{ '.MuiFormHelperText-root': { textAlign: 'end' } }}
        />
        <div>
          <FormControl variant="standard">
            <InputLabel>Allowance Type </InputLabel>
            <Select
              autoWidth
              value={maximumAllowance}
              onChange={({ target: { value } }) => {
                if (!value) {
                  setMaximumAmount('0');
                }
                setMaximumAllowance(value);
              }}
              disabled={isEditingForbidden}
              data-testid="allowance-type"
            >
              <MenuItem value={false}>No maximum allowance</MenuItem>
              <MenuItem value={true}>Maximum allowance</MenuItem>
            </Select>
          </FormControl>
        </div>
        {maximumAllowance && (
          <TextField
            sx={{ width: '174px' }}
            label="Maximum Amount"
            required
            variant="standard"
            InputProps={{
              inputComponent: NumericFormatCustom
            }}
            value={maximumAmount}
            onChange={({ target: { value } }) => setMaximumAmount(value)}
            disabled={isEditingForbidden}
          />
        )}
        <div>
          <FormControl disabled={isEditingForbidden}>
            <RadioGroup
              row
              value={radioValue}
              onChange={({ target: { value } }) => setRadioValue(value)}
              name="radio-buttons-group"
            >
              <FormControlLabel value="PASS_THRU" control={<Radio data-testid="pass-thru-radio" />} label="Pass Thru" />
              <FormControlLabel
                value="DIRECT_COST"
                control={<Radio data-testid="direct-cost-radio" />}
                label="Direct Cost"
              />
            </RadioGroup>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={nonInvoiceable}
                onChange={({ target: { checked } }) => setNonInvoiceable(checked)}
                disabled={isEditingForbidden}
              />
            }
            label="Non-Invoiceable"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={managementFee}
                onChange={({ target: { checked } }) => setManagementFee(checked)}
                disabled={isEditingForbidden || +managementFeePercent === 0}
              />
            }
            label="Management Fee"
          />
        </div>
      </ModalBoxes.Body>
      <BudgetEventControlButtons
        onClose={modalBox.close}
        onSave={onSave}
        isValidForm={() => isFormValid}
        currentStudyBudget={currentStudyBudget}
        onSaveAndContinue={isEmpty(eventForUpdate) && onSaveAndContinue}
      />
    </>
  );
};

export const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});
