export const prescreenStatuses = [
  {
    name: 'Chart Review',
    id: 'Chart Review'
  },
  {
    name: 'Ready for Outreach',
    id: 'Ready for Outreach'
  },
  {
    name: 'Contact Attempt',
    id: 'Contact Attempt'
  },
  {
    name: 'Callback Requested',
    id: 'Callback Requested'
  },
  {
    name: 'Ready for Scheduling',
    id: 'Ready for Scheduling'
  },
  {
    name: 'Scheduled for Screening',
    id: 'Scheduled for Screening'
  }
];

export const prescreenFailedStatus = {
  name: 'Pre-screen Failed',
  id: 'PRE_SCREEN_FAILED'
};

export const prescreenFailedStatuses = [
  'Age',
  'BMI',
  'ConMeds',
  'Deceased',
  'Exclusion',
  'Inclusion',
  'Lab Values',
  'Med History',
  'Not Available',
  'Not Interested',
  'Transportation Limitations',
  'Unresponsive',
  'Other'
];
