import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

export const AutocompleteFilterSelector = ({ item, applyValue, options }) => {
  const handleFilterChange = (event, newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Autocomplete
      options={options}
      value={item.value}
      onChange={handleFilterChange}
      renderInput={params => <TextField {...params} variant="standard" label="Status" placeholder="Status value" />}
    />
  );
};
