import axios from 'axios';
import { mapValues } from 'lodash/object';

const ROOT = 'api/v1/formbuilder';

const FormBuilderApi = {
  getAllStandardDomains() {
    return axios.get(`${ROOT}/domains`);
  },
  getStandardDomainForProtocol(data) {
    return axios.get(`${ROOT}/protocol/${data.protocolid}/domains/${data.domainid}`);
  },
  saveFormDomain(parameter, data) {
    return axios.post(`${ROOT}/studies/${parameter.studyId}/domains/${parameter.domainId}`, data, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    });
  },
  saveCustomDomain(parameter, data) {
    return axios.post(`${ROOT}/studies/${parameter.studyId}/domain/${parameter.domainId}/custom`, data);
  },
  getCTLists(protocolId) {
    return axios.get(`${ROOT}/ctlist/${protocolId}`);
  },
  getCTListsForTerminologyVersionGroup(terminologyVersionGroupNumber) {
    return axios.get(`${ROOT}/ctlist/terminology-version-group/${terminologyVersionGroupNumber}`);
  },
  getAllCdashMapping(data) {
    return axios.get(`${ROOT}/cdashMapping`, data);
  },
  getStandardDomainFormForTerminologyVersionGroup(domainIdentifier, terminologyVersionGroupNumber) {
    return axios.get(`${ROOT}/domains/${domainIdentifier}/terminology-version-group/${terminologyVersionGroupNumber}`);
  },
  getStandardConditionalLogicForDomain(domainIdentifier) {
    return axios.get(`${ROOT}/domains/${domainIdentifier}/conditional-logic`).then(function({ data }) {
      /*TODO: Change DTO on the backend side according to this converter.*/
      return mapValues(data, function({ fieldsToHide, hideWhenAnswer }, sourceIdentifier) {
        return {
          sourceIdentifier,
          required: true,
          conditionalLogicFieldsConfigs: [
            {
              answers: [hideWhenAnswer],
              isHideCondition: true,
              questions: fieldsToHide
            }
          ]
        };
      });
    });
  }
};

export default FormBuilderApi;
