import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { omit } from 'lodash';
import { every } from 'lodash/collection';
import { cloneDeep, isEmpty, isEqual, isFunction } from 'lodash/lang';

import PatientDetailsApi from '../../../../../../../api/patient/PatientDetailsApi';
import UserApiApi from '../../../../../../../api/patient/UserApiApi';
import EncounterTableApi from '../../../../../../../api/study-data/EncounterTableApi';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { NON_PROTOCOL } from '../../../../../../../constants/encounterConstants';
import {
  PROCESS_CONDITION_MISMATCH,
  PROCESS_INTERRUPTED_BY_THE_USER
} from '../../../../../../../constants/errorMessages';
import { DATA_SAVED, SELECT_REQUIRED } from '../../../../../../../constants/notificationMessages';
import { CLOSED } from '../../../../../../../constants/ssuStatuses';
import {
  MANAGE_PRE_SCREEN_WORK_LIST,
  MANAGE_PROTOCOL_ENCOUNTER_DATA
} from '../../../../../../../constants/userOperations';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../../../../constants/userRoles';
import { userHasAccessTo, userHasRole } from '../../../../../../../services/auth';
import { onRequestError } from '../../../../../../../services/handlers';
import HistoryBlock, { TABLE_ITEM_GROUP } from '../../../../../../root/Container/HistoryBlock';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import { ALLOW_READ } from '../../../../../setup/Protocol/ProtocolSetup/ProtocolGroupsSetup/permissionType';
import { EncounterPageContext } from '../../../NewEncounterPage/NewEncounterPageContext';
import { generateOriginConditionalLogicAndRequiredFieldsMaps } from '../services/conditionalLogicServices';
import { collectAllQuestionsField, updateQuestionsFieldWithUid } from '../services/fieldsServices';

import processCertifyCopy from './processCertifyCopy';
import processChangedItems from './processChangedItems';
import processCheckReview from './processCheckReview';
import processDeleteFiles from './processDeleteFiles';
import processMissingItems from './processMissingItems';
import processValidateWhoDidIt from './processValidateWhoDidIt';
import saveEncounterTableItemGroup from './saveEncounterTableItemGroup';
import useRequiredAndHiddenFieldsIds from './useRequiredAndHiddenFieldsIds';
import useTableItemGroupField from './useTableItemGroupField';

const TableItemGroupStateContext = React.createContext(null);
const TableItemGroupActionsContext = React.createContext(null);

export function TableItemGroupProvider({
  children,
  isPreviewMod,
  encounterTableItemGroupForPreview,
  conditionalLogicAndRequiredFieldsValidation,
  permissionType
}) {
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();
  const { ssuPatientId, patientEncounterId, patientItemGroupId } = currentRoute.params;
  const [patientDetails, setPatientDetails] = useState({});
  const [whoDidItList, setWhoDidItList] = useState([]);

  const [initialWhoDidIt, setInitialWhoDidIt] = useState(false);

  const [originConditionalLogicAndRequiredFieldsMaps, setOriginConditionalLogicAndRequiredFieldsMaps] = useState([]);
  const [originEncounterTableItemGroup, setOriginEncounterTableItemGroup] = useState({});
  const [encounterTableItemGroup, setEncounterTableItemGroup] = useState({});
  const [isCertifiedCopyWasNotConfirmed, setIsCertifiedCopyWasNotConfirmed] = useState([]);
  const [validationError, setValidationError] = useState(false);

  const { studySiteStatus } = patientDetails;
  const { encounterType } = encounterTableItemGroup;

  const NewEncounterPageContext = useContext(EncounterPageContext);
  const fieldsWasChanged = useMemo(() => {
    return !isEqual(encounterTableItemGroup, omit(originEncounterTableItemGroup, 'whoDidItIsPredefined'));
  }, [encounterTableItemGroup, originEncounterTableItemGroup]);

  const [requiredFieldsIds, hiddenFieldsIds] = useRequiredAndHiddenFieldsIds(
    encounterTableItemGroup,
    originConditionalLogicAndRequiredFieldsMaps
  );
  const {
    fieldOnChange,
    requiredAttentionOnChange,
    toggleAllRequiredAttention,
    fileListOnChange,
    toggleFieldOnChange
  } = useTableItemGroupField(hiddenFieldsIds, setEncounterTableItemGroup);

  const resolveInitialData = useCallback(
    function() {
      if (isPreviewMod) {
        return Promise.resolve({
          encounterTableItemGroup: encounterTableItemGroupForPreview,
          conditionalLogicAndRequiredFieldsValidation: conditionalLogicAndRequiredFieldsValidation
        });
      }
      return EncounterTableApi.getEncounterTableItemGroup(ssuPatientId, patientEncounterId, patientItemGroupId).then(
        function({ data }) {
          return data;
        }
      );
    },
    [
      ssuPatientId,
      patientEncounterId,
      patientItemGroupId,
      isPreviewMod,
      encounterTableItemGroupForPreview,
      conditionalLogicAndRequiredFieldsValidation
    ]
  );

  const loadEncounterTableItemGroup = useCallback(
    function() {
      return resolveInitialData().then(function({
        encounterTableItemGroup,
        conditionalLogicAndRequiredFieldsValidation
      }) {
        const preparedData = updateQuestionsFieldWithUid(encounterTableItemGroup);
        setOriginConditionalLogicAndRequiredFieldsMaps(
          generateOriginConditionalLogicAndRequiredFieldsMaps(
            collectAllQuestionsField(cloneDeep(preparedData)),
            conditionalLogicAndRequiredFieldsValidation
          )
        );
        setOriginEncounterTableItemGroup(cloneDeep(preparedData));
        setInitialWhoDidIt(preparedData.whoDidItId);
        setEncounterTableItemGroup(cloneDeep(preparedData));
      });
    },
    [resolveInitialData]
  );

  useEffect(
    function() {
      if (!isPreviewMod) {
        PatientDetailsApi.getPatientDetails(ssuPatientId).then(function({ data: patientDetailsResponse }) {
          setPatientDetails(patientDetailsResponse);
        });
        UserApiApi.getListOfActiveUsers(ssuPatientId, patientItemGroupId).then(res => {
          setWhoDidItList(res.data.response);
        });
      }
      loadEncounterTableItemGroup();
    },
    [ssuPatientId, loadEncounterTableItemGroup, isPreviewMod, patientItemGroupId]
  );

  const isAbleToSave = useMemo(
    function() {
      if (isEmpty(encounterTableItemGroup)) {
        return false;
      }
      const allRequiredFieldsFilled = every(collectAllQuestionsField(encounterTableItemGroup), function({
        fieldUid,
        fieldValue
      }) {
        if (requiredFieldsIds.includes(fieldUid)) {
          return !isEmpty(fieldValue);
        }
        return true;
      });
      return allRequiredFieldsFilled && !isEmpty(encounterTableItemGroup.whoDidItId);
    },
    [requiredFieldsIds, encounterTableItemGroup]
  );

  const isPreviewOnly = useMemo(
    function() {
      if (permissionType === ALLOW_READ) {
        return true;
      }
      const isAllowedToManageEncounter =
        encounterType === NON_PROTOCOL
          ? userHasAccessTo(MANAGE_PRE_SCREEN_WORK_LIST)
          : userHasRole(ROLE_SYSTEM_ADMINISTRATOR) ||
            (studySiteStatus !== CLOSED && userHasAccessTo(MANAGE_PROTOCOL_ENCOUNTER_DATA));
      if (isPreviewMod) {
        return false;
      }
      return !isAllowedToManageEncounter;
    },
    [encounterType, isPreviewMod, studySiteStatus, permissionType]
  );

  const isEditMod = useMemo(
    function() {
      return !isEmpty(originEncounterTableItemGroup?.generalRowId);
    },
    [originEncounterTableItemGroup]
  );

  const whoDidItOnChange = useCallback(
    function(whoDidItId, predefined = false) {
      if (predefined) {
        setInitialWhoDidIt(originEncounterTableItemGroup.whoDidItId);
        setOriginEncounterTableItemGroup(function(state) {
          return { ...state, whoDidItId };
        });
      } else {
        setOriginEncounterTableItemGroup(function(state) {
          return { ...state, whoDidItIsPredefined: initialWhoDidIt };
        });
      }
      setEncounterTableItemGroup(function(state) {
        return { ...state, whoDidItId };
      });
    },
    [originEncounterTableItemGroup.whoDidItId, initialWhoDidIt]
  );

  const whenWasItDoneOnChange = useCallback(function(whenWasItDone) {
    setEncounterTableItemGroup(function(state) {
      return { ...state, whenWasItDone };
    });
  }, []);

  const onSave = useCallback(
    async function(skipProcessMissingItems = false) {
      try {
        await processValidateWhoDidIt(whoDidItList, encounterTableItemGroup);

        const stageZeta = await processCheckReview(encounterTableItemGroup, setIsCertifiedCopyWasNotConfirmed);
        const stageEpsilon = await processChangedItems(stageZeta, originEncounterTableItemGroup);
        const stageDelta = await processMissingItems(stageEpsilon, hiddenFieldsIds, skipProcessMissingItems);
        const stageGamma = await processCertifyCopy(stageDelta);

        /* TODO: will be good to handle if some files in the deleting process throw an error. Now is empty catch */
        await processDeleteFiles(stageGamma, originEncounterTableItemGroup, currentRoute).catch(() => {});

        await saveEncounterTableItemGroup(isEditMod, ssuPatientId, patientEncounterId, patientItemGroupId, stageGamma);

        NotificationManager.success(DATA_SAVED);
      } catch (error) {
        if (![PROCESS_INTERRUPTED_BY_THE_USER, PROCESS_CONDITION_MISMATCH].includes(error?.message)) {
          onRequestError(error);
        }
        throw error;
      }
    },
    [
      whoDidItList,
      hiddenFieldsIds,
      ssuPatientId,
      patientEncounterId,
      patientItemGroupId,
      encounterTableItemGroup,
      originEncounterTableItemGroup,
      isEditMod,
      currentRoute
    ]
  );

  useEffect(() => {
    if (!fieldsWasChanged || isPreviewMod) return;

    return HistoryBlock.block(TABLE_ITEM_GROUP, function(discard) {
      if (isAbleToSave) {
        validationError && setValidationError(false);
        onSave(true)
          .then(() => {
            HistoryBlock.unblock(TABLE_ITEM_GROUP);
            isFunction(NewEncounterPageContext.onSubmit) && NewEncounterPageContext.onSubmit(false);
          })
          .catch(() => {})
          .finally(function() {
            discard();
          });
      } else {
        /* TODO: will be good to ask the user discard changes or not. */
        setValidationError(true);
        NotificationManager.error(SELECT_REQUIRED);
      }
      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsWasChanged, isAbleToSave, isPreviewMod, onSave, validationError]);

  const onSubmit = useCallback(
    function() {
      onSave()
        .then(function() {
          HistoryBlock.unblock(TABLE_ITEM_GROUP);
          isFunction(NewEncounterPageContext.onSubmit) && NewEncounterPageContext.onSubmit(false);
          navigate(generateUrlByKey(currentRoute.key, currentRoute.params), { replace: true });
        })
        .catch(() => {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSave]
  );

  return (
    <TableItemGroupStateContext.Provider
      value={{
        isAbleToSave,
        isPreviewOnly: !!(isPreviewOnly || encounterTableItemGroup?.notPerformed),
        whoDidItList,
        patientDetails,
        encounterTableItemGroup,
        requiredFieldsIds,
        hiddenFieldsIds,
        originConditionalLogicAndRequiredFieldsMaps,
        isCertifiedCopyWasNotConfirmed,
        validationError
      }}
    >
      <TableItemGroupActionsContext.Provider
        value={{
          onSubmit,
          whoDidItOnChange,
          whenWasItDoneOnChange,
          fieldOnChange,
          requiredAttentionOnChange,
          toggleAllRequiredAttention,
          fileListOnChange,
          toggleFieldOnChange,
          setIsCertifiedCopyWasNotConfirmed
        }}
      >
        {children}
      </TableItemGroupActionsContext.Provider>
    </TableItemGroupStateContext.Provider>
  );
}

export function useTableItemGroupState() {
  const context = useContext(TableItemGroupStateContext);
  if (context === undefined) {
    throw new Error('useTableItemGroupState must be used within a TableItemGroupProvider');
  }
  return context;
}

export function useTableItemGroupActions() {
  const context = useContext(TableItemGroupActionsContext);
  if (context === undefined) {
    throw new Error('useTableItemGroupActions must be used within a TableItemGroupProvider');
  }
  return context;
}
