import React, { useMemo } from 'react';
import cx from 'classnames';
import DOMPurify from 'dompurify';

import './StyledTextViewer.scss';

export function StyledTextViewer({ value, className }) {
  const sanitizedValue = useMemo(() => {
    return DOMPurify.sanitize(value, { FORBID_TAGS: ['a'] });
  }, [value]);
  const markup = useMemo(() => {
    return { __html: sanitizedValue };
  }, [sanitizedValue]);

  return <div className={cx('styled-text-view-wrapper', className)} dangerouslySetInnerHTML={markup} />;
}
