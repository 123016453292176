import { useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { map } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { collectFieldValue, getFieldByName, getFieldLabel } from '../../../services';

export default function ConfigurationTableFieldSelect({ accessibleFields, updateCondition, condition, disabled }) {
  const { conditionFieldName, conditionFieldAnswer } = condition;
  const [options, selectedValue] = useMemo(
    function() {
      const options = map(accessibleFields, function(field) {
        return {
          name: field.name,
          label: getFieldLabel(field)
        };
      });
      return [options, getFieldByName(options, conditionFieldName) || null];
    },
    [accessibleFields, conditionFieldName]
  );

  return (
    <Autocomplete
      value={selectedValue}
      disabled={disabled}
      onChange={function({ target }, option) {
        const patch = { conditionFieldName: option?.name || null };
        const fieldValue = collectFieldValue(accessibleFields, patch.conditionFieldName);
        const fieldValueOptions = fieldValue?.options?.map(({ value }) => value);
        if (
          isEmpty(conditionFieldAnswer) ||
          (fieldValueOptions && conditionFieldAnswer?.some(value => !fieldValueOptions?.includes(value)))
        ) {
          patch.conditionFieldAnswer = fieldValue.type === 'radio' ? [fieldValueOptions[0]] : null;
        }
        updateCondition(patch);
      }}
      fullWidth
      getOptionKey={option => option?.name}
      disablePortal
      options={options}
      renderInput={params => <TextField {...params} name="encounter" variant="outlined" size="small" required />}
      noOptionsText="No available fields"
    />
  );
}
