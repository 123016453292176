import React, { useMemo } from 'react';
import { filter, groupBy, sortBy } from 'lodash/collection';
import moment from 'moment';

import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { toFormattedFinanceBill } from '../../../../../../services/financial';
import { reimbursementItemTypesMap } from '../../../../patient-source/Patients/PatientInfo/EncountersSection/PaymentsSection/reimbursementConstants';
import { eventTypeLabel } from '../../../../setup/Budget/BudgetDetails/budgetConstant';

import { InvoicePageGenerator } from './InvoicePageGenerator';

export const EncounterInvoiceTemplate = ({
  invoices,
  invoiceNumber,
  comment,
  terms,
  payee,
  contact,
  investigator,
  remit,
  balanceDue,
  totalPages,
  setTotalPages,
  billTo,
  invoiceType,
  adjustmentDate
}) => {
  const normalizedGroups = useMemo(() => {
    const invoicesWishGroupNames = sortBy(
      invoices.map(budgetEvent => {
        const subGroupName = `${
          budgetEvent?.subjectNumber ? `Subject ID ${budgetEvent.subjectNumber}` : `Patient ID ${budgetEvent.patientId}`
        } ${budgetEvent?.encounterDate ? `(${moment(budgetEvent.encounterDate).format('DD-MMM-YYYY')})` : '—'}`;

        if (budgetEvent.encounterType === 'UNEXPECTED') {
          const groupName = `Unscheduled ${budgetEvent.encounterName?.replace(/^Unexpected:/, '') || '—'}`;
          return { ...budgetEvent, groupName, subGroupName };
        }
        if (budgetEvent.encounterType === 'SITUATIONAL') {
          const groupName = `Situational ${budgetEvent.encounterName?.replace(/^Situational:/, '') || '—'}`;
          return { ...budgetEvent, groupName, subGroupName };
        }

        const hasEncounter = !!budgetEvent.encounterName;
        const groupName = hasEncounter
          ? `${budgetEvent?.epochName || '—'}-${budgetEvent?.encounterName}`
          : `${budgetEvent?.name || ''}: Rate ${toFormattedFinanceBill(budgetEvent.invoiceAmount)}`;
        return { ...budgetEvent, groupName, subGroupName: hasEncounter ? subGroupName : null };
      }),
      [
        el => !el.encounterName,
        el => el.groupName?.toLowerCase(),
        el => el.subGroupName?.toLowerCase(),
        el => el.subjectNumber?.toLowerCase(),
        el => el.name?.toLowerCase()
      ]
    );

    const invoicesWishFilteredStipends = filter(
      invoicesWishGroupNames,
      event =>
        (event.budgetEventType === 'Patient Stipend' && event.invoiceAmount !== 0) ||
        event.budgetEventType !== 'Patient Stipend'
    );

    const values = Object.values(groupBy(invoicesWishFilteredStipends, ({ groupName }) => groupName))
      .reduce((acc, group) => {
        let groupWithSubGroup;
        if (group[0].subGroupName) {
          const groupedBySubGroup = groupBy(group, ({ subGroupName }) => subGroupName);
          groupWithSubGroup = Object.values(groupedBySubGroup).reduce((acc, subGroup) => {
            return [
              ...acc,
              {
                typeSubHeader: true,
                groupName: group[0].groupName,
                subGroupName: subGroup[0].subGroupName,
                subGroupAmount: subGroup.reduce((acc, item) => acc + item.invoiceAmount, 0)
              },
              ...subGroup.map(item => ({ ...item, typeSubItem: true }))
            ];
          }, []);
        } else {
          groupWithSubGroup = group;
        }
        return [
          ...acc,
          {
            typeHeader: true,
            inseparable: !!group[0].subGroupName,
            groupName: group[0].groupName,
            groupLength: getGroupLength(groupWithSubGroup),
            groupAmount: getGroupAmount(groupWithSubGroup),
            amountForItem: group[0].invoiceAmount,
            headerBudgetEventType: group[0].budgetEventType
          },
          ...groupWithSubGroup
        ];
      }, [])
      .flatMap(el => {
        if (el.budgetEventType === eventTypeLabel.PATIENT_REIMBURSEMENT) {
          return [
            el,
            ...el.reimbursementItems.map(reimbursement => ({
              ...reimbursement,
              templateRowType: 'REIMBURSEMENT_ITEM',
              groupName: el.groupName,
              type: reimbursementItemTypesMap[reimbursement.type],
              ...el
            }))
          ];
        }
        return el;
      });
    return [...values, { typeBalance: true, comment, balanceDue }];
  }, [balanceDue, comment, invoices]);

  return (
    <InvoicePageGenerator
      normalizedGroups={normalizedGroups}
      pageNumber={1}
      totalPages={totalPages}
      setTotalPages={setTotalPages}
      study={invoices?.[0]?.studyName}
      pcn={invoices?.[0]?.pcnName}
      investigator={investigator}
      site={invoices?.[0]?.siteName}
      siteNumber={invoices?.[0]?.siteNumber}
      invoiceNumber={invoiceNumber}
      invoiceDate={
        adjustmentDate
          ? moment(adjustmentDate).format(DD_SLASH_MMM_SLASH_YYYY)
          : moment().format(DD_SLASH_MMM_SLASH_YYYY)
      }
      terms={terms}
      payee={payee}
      contact={contact}
      remit={remit}
      billTo={billTo}
      invoiceType={invoiceType}
    />
  );
};

const getGroupLength = group => {
  const subGroupLength = group.filter(item => item.typeSubHeader).length;
  if (subGroupLength) {
    return subGroupLength;
  } else {
    return group.length;
  }
};

export const getGroupAmount = group => {
  const subGroupsPresent = group.filter(item => item.typeSubHeader);
  if (subGroupsPresent.length) {
    return subGroupsPresent.reduce((acc, item) => acc + item.subGroupAmount, 0);
  } else {
    return null;
  }
};
