import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash/lang';

import { eventTypeLabel } from '../../../../setup/Budget/BudgetDetails/budgetConstant';
import { CREDIT_MEMO, DEBIT_MEMO } from '../../InvoiceTable/TableConstants';

import { InvoiceHeader } from './InvoiceHeader/InvoiceHeader';
import { InvoiceItemsList } from './InvoiceItemsList/InviceItemsList';
import { styles } from './EncounterInvoiceTemplateStyles';

export const InvoicePageGenerator = ({
  normalizedGroups,
  pageNumber,
  totalPages,
  setTotalPages,
  study,
  pcn,
  investigator,
  site,
  siteNumber,
  invoiceNumber,
  invoiceDate,
  terms,
  payee,
  remit,
  contact,
  billTo,
  invoiceType
}) => {
  const mainRef = useRef();
  const shadowRef = useRef();

  const [maxIndex, setMaxIndex] = useState(null);

  const getMax = useCallback((elements, height) => {
    const lastIndex = elements.length - 1;
    for (let i = 0, w = 0; i <= lastIndex; ++i) {
      w = w + elements[i];
      if (w > height) {
        return i - 1;
      }
    }
    return lastIndex;
  }, []);

  const cutEventsForPages = useCallback((groups, maxIndex) => {
    if (groups[maxIndex]?.typeHeader) {
      return { currentPage: groups.slice(0, maxIndex), nextPage: groups.slice(maxIndex) };
    } else if (groups[maxIndex]?.typeSubHeader) {
      return { currentPage: groups.slice(0, maxIndex - 1), nextPage: groups.slice(maxIndex - 1) };
    } else if (
      groups[maxIndex]?.budgetEventType === eventTypeLabel.PATIENT_REIMBURSEMENT &&
      groups[maxIndex]?.templateRowType !== 'REIMBURSEMENT_ITEM'
    ) {
      const normalizedMaxIndex = groups[maxIndex - 2]?.typeHeader ? maxIndex - 2 : maxIndex - 1;
      return { currentPage: groups.slice(0, normalizedMaxIndex), nextPage: groups.slice(normalizedMaxIndex) };
    } else {
      return { currentPage: groups.slice(0, maxIndex + 1), nextPage: groups.slice(maxIndex + 1) };
    }
  }, []);

  const getQuantityAndAmountForGroup = useCallback((element, itemsForPage) => {
    if (element?.subGroupName) {
      const subGroups = itemsForPage.filter(
        budgetEvent => budgetEvent.groupName === element.groupName && budgetEvent?.typeSubHeader
      );
      const subElements = itemsForPage.filter(
        budgetEvent =>
          budgetEvent.subGroupName === element.subGroupName &&
          budgetEvent.groupName === element.groupName &&
          !budgetEvent?.typeSubHeader &&
          budgetEvent.templateRowType !== 'REIMBURSEMENT_ITEM'
      );
      const groupCount = subGroups.length;
      const groupAmount = subGroups.reduce((acc, item) => acc + item.subGroupAmount, 0);
      const subGroupAmount = subElements.reduce((acc, item) => acc + item.invoiceAmount, 0);
      return { subGroupAmount, groupCount, groupAmount };
    } else {
      const groupCount = itemsForPage.filter(
        bugetEvent => bugetEvent.groupName === element.groupName && bugetEvent.invoiceAmount === element.invoiceAmount
      ).length;
      return { subGroupCount: null, subGroupAmount: null, groupCount, groupAmount: null };
    }
  }, []);

  const listForCurrentPage = useMemo(() => {
    const { currentPage } = cutEventsForPages(normalizedGroups, maxIndex);
    const lastElement = currentPage[currentPage.length - 1];
    if (maxIndex) {
      const elementsWithNormalizedInvoiceAmounts = currentPage.map(budgetEvent => {
        if (
          budgetEvent.budgetEventType === eventTypeLabel.PATIENT_REIMBURSEMENT &&
          budgetEvent.groupName === lastElement.groupName &&
          budgetEvent.subGroupName === lastElement.subGroupName &&
          budgetEvent.id === lastElement.id &&
          lastElement.templateRowType === 'REIMBURSEMENT_ITEM' &&
          budgetEvent.reimbursementEventSuffix === '(cont.)'
        ) {
          return {
            ...budgetEvent,
            invoiceAmount: 0
          };
        }
        return budgetEvent;
      });
      const elementsWithNormalizedSubHeaders = elementsWithNormalizedInvoiceAmounts.map(budgetEvent => {
        if (
          budgetEvent?.typeSubHeader &&
          budgetEvent.subGroupName === lastElement.subGroupName &&
          budgetEvent.groupName === lastElement.groupName
        ) {
          const { subGroupAmount } = getQuantityAndAmountForGroup(lastElement, elementsWithNormalizedInvoiceAmounts);
          return {
            ...budgetEvent,
            subGroupAmount: subGroupAmount
          };
        }
        return budgetEvent;
      });

      return elementsWithNormalizedSubHeaders.map(budgetEvent => {
        if (budgetEvent?.typeHeader && budgetEvent.groupName === lastElement.groupName && !budgetEvent.inseparable) {
          const { groupAmount, groupCount } = getQuantityAndAmountForGroup(
            lastElement,
            elementsWithNormalizedSubHeaders
          );
          return {
            ...budgetEvent,
            groupAmount,
            groupLength: groupCount
          };
        }
        return budgetEvent;
      });
    }

    return currentPage;
  }, [cutEventsForPages, getQuantityAndAmountForGroup, maxIndex, normalizedGroups]);

  const listPorNextPage = useMemo(() => {
    const { nextPage } = cutEventsForPages(normalizedGroups, maxIndex);
    const firstElement = nextPage[0];
    if (!isEmpty(nextPage) && !firstElement.typeHeader && !firstElement.typeBalance && maxIndex) {
      const { subGroupAmount, groupCount } = getQuantityAndAmountForGroup(firstElement, nextPage);
      if (!!firstElement.subGroupName) {
        if (firstElement.typeSubHeader) {
          return [
            {
              typeHeader: true,
              inseparable: true,
              groupName: firstElement.groupName,
              groupHeaderSuffix: '(cont.)'
            },
            ...nextPage
          ];
        } else if (firstElement.templateRowType === 'REIMBURSEMENT_ITEM') {
          return [
            {
              typeHeader: true,
              inseparable: true,
              groupName: firstElement.groupName,
              groupHeaderSuffix: '(cont.)'
            },
            {
              typeSubHeader: true,
              groupName: firstElement.groupName,
              subGroupName: firstElement.subGroupName,
              subGroupAmount
            },
            {
              ...firstElement,
              templateRowType: null,
              reimbursementEventSuffix: '(cont.)'
            },
            ...nextPage
          ];
        } else {
          return [
            {
              typeHeader: true,
              inseparable: true,
              groupName: firstElement.groupName,
              groupHeaderSuffix: '(cont.)'
            },
            {
              typeSubHeader: true,
              groupName: firstElement.groupName,
              subGroupName: firstElement.subGroupName,
              subGroupAmount
            },
            ...nextPage
          ];
        }
      } else {
        return [
          {
            typeHeader: true,
            groupName: firstElement.groupName,
            groupHeaderSuffix: '(cont.)',
            groupLength: groupCount,
            amountForItem: firstElement.invoiceAmount,
            headerBudgetEventType: firstElement.budgetEventType
          },
          ...nextPage
        ];
      }
    }
    return nextPage;
  }, [cutEventsForPages, getQuantityAndAmountForGroup, maxIndex, normalizedGroups]);

  useLayoutEffect(() => {
    if (!mainRef.current || !shadowRef.current) {
      return;
    }
    updateSize();

    function updateSize() {
      const { clientHeight } = mainRef.current;
      const shadowItems = shadowRef.current.querySelectorAll('.row-item');
      const shadowItemsHeight = [...shadowItems].map(({ offsetHeight }) => offsetHeight);

      setMaxIndex(getMax(shadowItemsHeight, clientHeight));
    }
  });

  useEffect(() => {
    if (isEmpty(listPorNextPage)) {
      setTotalPages(pageNumber);
    }
  }, [listPorNextPage, pageNumber, setTotalPages]);

  return (
    <>
      <div style={styles.page} data-testid="invoice-pdf-page">
        <InvoiceHeader
          study={study}
          pcn={pcn}
          investigator={investigator?.split(', ').join(' ')}
          site={site}
          siteNumber={siteNumber}
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          terms={terms}
          payee={payee}
          contact={contact}
          remit={remit}
          pageNumber={pageNumber}
          billTo={billTo}
          lastPageWithoutTable={listForCurrentPage.length === 1}
          invoiceType={invoiceType}
        />
        <div style={styles.section} ref={mainRef}>
          <InvoiceItemsList items={listForCurrentPage} invoiceType={invoiceType} />
        </div>
        <div style={styles.pageCountWrapper}>
          {pageNumber === totalPages && ![CREDIT_MEMO, DEBIT_MEMO].includes(invoiceType) && (
            <div style={styles.pageCountWrapper.bankingInformation}>
              <div>{payee?.name}</div>
              <div>{payee?.bankName}</div>
              <div>{`Routing: ${payee?.bankRoutingNumber}`}</div>
              <br />
              <div>{`Account: ${payee?.bankAccountNumber}`}</div>
              <div>{`SWIFT: ${payee?.bankSwiftCode}`}</div>
            </div>
          )}
          <div style={styles.pageCountWrapper.pageCount}>
            {pageNumber} of {totalPages}
          </div>
        </div>
      </div>

      <div style={styles.hiddenPage} className="invoice-generator-hidden">
        <div style={styles.section} ref={shadowRef}>
          <InvoiceItemsList items={normalizedGroups} invoiceType={invoiceType} />
        </div>
      </div>

      {!isEmpty(listPorNextPage) && (
        <InvoicePageGenerator
          normalizedGroups={listPorNextPage}
          pageNumber={pageNumber + 1}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          study={study}
          pcn={pcn}
          investigator={investigator}
          site={site}
          siteNumber={siteNumber}
          invoiceNumber={invoiceNumber}
          invoiceDate={invoiceDate}
          terms={terms}
          payee={payee}
          contact={contact}
          remit={remit}
          billTo={billTo}
          invoiceType={invoiceType}
        />
      )}
    </>
  );
};
