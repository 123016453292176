import { uniq } from 'lodash/array';
import { flatMap, includes, map, reduce } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import {
  normalizeFieldValue,
  shouldTargetFieldsBeVisible
} from 'services/item-group/conditional-logic/conditionalLogicUtils';

export function generateOriginConditionalLogicAndRequiredFieldsMaps(
  allQuestions,
  conditionalLogicAndRequiredFieldsValidation
) {
  const [allRequiredFields, conditionalLogicMap] = splitAndPrepareConditionalLogicAndRequiredFieldsValidation(
    conditionalLogicAndRequiredFieldsValidation
  );

  return reduce(
    allQuestions,
    function(accumulator, { fieldUid, protocolItemDefinitionId, fieldLabel }) {
      const [originRequiredFieldIds, originConditionalLogicMap] = accumulator;
      const matchedConditionalLogic = conditionalLogicMap[protocolItemDefinitionId];

      if (matchedConditionalLogic) {
        originConditionalLogicMap[fieldUid] = map(matchedConditionalLogic, function(condition) {
          const { answers, isHideCondition, questions } = condition;
          return {
            answers,
            isHideCondition,
            questions: allQuestions
              .filter(function(question) {
                if (!questions.includes(question.protocolItemDefinitionId)) {
                  return false;
                }
                if (!fieldLabel) {
                  return true;
                }
                return fieldLabel === question.fieldLabel;
              })
              .map(({ fieldUid }) => fieldUid)
          };
        });
      }

      if (includes(allRequiredFields, protocolItemDefinitionId)) {
        originRequiredFieldIds.push(fieldUid);
      }

      return accumulator;
    },
    [[], {}]
  );
}

function splitAndPrepareConditionalLogicAndRequiredFieldsValidation(conditionalLogicAndRequiredFieldsValidation) {
  /*TODO: temp service, should be moved to BE side*/
  return reduce(
    conditionalLogicAndRequiredFieldsValidation,
    function(accumulator, { required, conditionalLogicFieldsConfigs }, id) {
      const [requiredFields, conditionalLogicMap] = accumulator;
      if (required) {
        requiredFields.push(id);
      }
      if (!isEmpty(conditionalLogicFieldsConfigs)) {
        conditionalLogicMap[id] = conditionalLogicFieldsConfigs;
      }
      return accumulator;
    },
    [[], {}]
  );
}

export function calculateHiddenFieldIds(allQuestions, originConditionalLogicMap) {
  return uniq(
    flatMap(allQuestions, function({ fieldUid, fieldValue }) {
      return getHiddenTargetFieldIdsByTriggerFieldValue(originConditionalLogicMap, fieldUid, fieldValue);
    })
  );
}

export function getHiddenTargetFieldIdsByTriggerFieldValue(originConditionalLogicMap, fieldUid, fieldValue) {
  const normalizedTriggerFieldValue = normalizeFieldValue(fieldValue);

  return flatMap(originConditionalLogicMap[fieldUid], function(condition) {
    const isConditionSatisfied = !shouldTargetFieldsBeVisible(condition, normalizedTriggerFieldValue, false);

    if (isConditionSatisfied) {
      return condition.questions;
    }

    return [];
  });
}
