import { useCallback, useEffect, useRef, useState } from 'react';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatColorTextTwoToneIcon from '@mui/icons-material/FormatColorTextTwoTone';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import RedoIcon from '@mui/icons-material/Redo';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import UndoIcon from '@mui/icons-material/Undo';
import { IconButton } from '@mui/material';
import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  DEPRECATED_$isGridSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND
} from 'lexical';

import { IconWithColorPicker } from '../../../inputTextFormat/ColorPicker/IconWithColorPicker';

const LowPriority = 1;

function Divider() {
  return <div className="divider" />;
}

export function RichTextEditorToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [blockType, setBlockType] = useState('paragraph');
  const [fontColor, setFontColor] = useState('#000');

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = selection.anchor.getNode();
      const element = node.getKey() === 'root' ? node : node.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(node, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'));
    }
  }, [editor]);

  const onBulletList = useCallback(() => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  }, [blockType, editor]);

  const onNumberedList = useCallback(() => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  }, [blockType, editor]);

  const formatIndent = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
        const currentPadding = $getSelectionStyleValueForProperty(selection, 'padding-left');
        const addIndentLevel = currentPadding => {
          return `${+currentPadding.replace('px', '') + 30}px`;
        };
        $patchStyleText(selection, {
          display: 'block',
          'padding-left': addIndentLevel(currentPadding)
        });
      }
    });
  };

  const formatOutdent = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
        const currentPadding = $getSelectionStyleValueForProperty(selection, 'padding-left');
        if (+currentPadding.replace('px', '') >= 30) {
          const addIndentLevel = currentPadding => {
            return `${+currentPadding.replace('px', '') - 30}px`;
          };
          $patchStyleText(selection, {
            display: 'block',
            'padding-left': addIndentLevel(currentPadding)
          });
        }
      }
    });
  };

  const onFontColorSelect = useCallback(
    value => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $patchStyleText(selection, { color: value });
        }
      });
    },
    [editor]
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        payload => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        payload => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  return (
    <div className="text-toolbar" ref={toolbarRef}>
      <IconButton
        disabled={!canUndo}
        className="toolbar-item"
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
      >
        <UndoIcon fontSize="small" />
      </IconButton>
      <IconButton
        disabled={!canRedo}
        className="toolbar-item"
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
      >
        <RedoIcon fontSize="small" />
      </IconButton>
      <Divider />
      <IconButton
        className={'toolbar-item ' + (isBold ? 'active' : '')}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
      >
        <FormatBoldIcon fontSize="small" />
      </IconButton>
      <IconButton
        className={'toolbar-item ' + (isItalic ? 'active' : '')}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
      >
        <FormatItalicIcon fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        className={'toolbar-item ' + (isUnderline ? 'active' : '')}
      >
        <FormatUnderlinedIcon fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
        }}
        className={'toolbar-item ' + (isStrikethrough ? 'active' : '')}
      >
        <StrikethroughSIcon fontSize="small" />
      </IconButton>
      <Divider />
      <IconWithColorPicker
        Icon={ColorIconWrapper}
        data-testid={'text-color-button'}
        onChange={onFontColorSelect}
        color={fontColor}
        className="toolbar-item"
      />
      <Divider />
      <IconButton className="toolbar-item">
        <FormatListNumberedIcon
          fontSize="small"
          onClick={onNumberedList}
          sx={{
            color: blockType === 'ol' ? '#323232' : '#6E6E6E'
          }}
        />
      </IconButton>
      <IconButton className="toolbar-item">
        <FormatListBulletedIcon
          fontSize="small"
          onClick={onBulletList}
          sx={{
            color: blockType === 'ul' ? '#323232' : '#6E6E6E'
          }}
        />
      </IconButton>
      <Divider />
      <IconButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        className="toolbar-item"
      >
        <FormatAlignLeftIcon fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className="toolbar-item"
      >
        <FormatAlignCenterIcon fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className="toolbar-item"
      >
        <FormatAlignRightIcon fontSize="small" />
      </IconButton>
      <IconButton
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        className="toolbar-item"
      >
        <FormatAlignJustifyIcon fontSize="small" />
      </IconButton>
      <IconButton className="toolbar-item" onClick={formatIndent}>
        <FormatIndentIncreaseIcon fontSize="small" />
      </IconButton>
      <IconButton className="toolbar-item" onClick={formatOutdent}>
        <FormatIndentDecreaseIcon fontSize="small" />
      </IconButton>
    </div>
  );
}

function ColorIconWrapper() {
  return <FormatColorTextTwoToneIcon fontSize="small" />;
}
