import React from 'react';
import { TextField } from '@mui/material';

export const ExpenseCommentInput = ({ comment, onChange }) => {
  const maxCommentLength = 255;
  return (
    <TextField
      label="Comment"
      variant="standard"
      value={comment}
      multiline
      onChange={onChange}
      inputProps={{ maxLength: maxCommentLength, 'data-testid': 'new-comment-input' }}
      helperText={`${comment?.length ?? 0}/${maxCommentLength}`}
      sx={{ '.MuiFormHelperText-root': { textAlign: 'end' } }}
    />
  );
};
