import axios from 'axios';

const ROOT = 'api/v1/finance/site-credits';

const SiteCreditApi = {
  getEligibleEvents(siteId, studyIds) {
    return axios.post(`${ROOT}/eligible-events`, { siteId, studyIds });
  },
  getHistory(itemSiteId, sitePaymentType) {
    return axios.get(`${ROOT}/history/${itemSiteId}/${sitePaymentType}`);
  },
  applySiteCredit(request) {
    return axios.put(`${ROOT}/apply`, request);
  },
  reverseSiteCredit(historyItemId) {
    return axios.put(`${ROOT}/history/${historyItemId}/reverse`);
  },
  reverseAllSiteCredits(historyItemIds) {
    return axios.put(`${ROOT}/history/reverse-all`, historyItemIds);
  }
};

export default SiteCreditApi;
