import { first } from 'lodash/array';
import { isEmpty } from 'lodash/lang';

import { CLT_HIDE } from './constants';
import { getFieldByName } from './services';

export function generateDataForSave(conditions, allAccessibleFields, hideAllFieldsConfig) {
  const data = {
    configuration: generateFieldConfigurationResponse(conditions, allAccessibleFields),
    hideAllConfiguration: null
  };

  if (hideAllFieldsConfig.enabled && hideAllFieldsConfig.answer) {
    data.hideAllConfiguration = { hideAllFieldsBelow: true, hideAllFieldsBelowOnAnswer: hideAllFieldsConfig.answer };
  }

  return data;
}

function generateFieldConfigurationResponse(conditions, allAccessibleFields) {
  if (isEmpty(conditions) || isEmpty(allAccessibleFields)) {
    return null;
  }

  return conditions.map(function(condition) {
    const { conditionFieldName, conditionFieldAnswer, conditionType } = condition;

    const conditionField = getFieldByName(allAccessibleFields, conditionFieldName);

    return {
      conditionField,
      conditionFieldName,
      conditionFieldAnswerNames: conditionFieldAnswer,
      isHideCondition: conditionType === CLT_HIDE
    };
  });
}

export function generateFieldConfigurationResponseByConditions(conditions, allAccessibleFields) {
  const firstCondition = first(conditions);

  if (isEmpty(firstCondition) || isEmpty(allAccessibleFields)) {
    return null;
  }

  const { conditionFieldName, conditionFieldAnswer, conditionFieldSequence, conditionType } = firstCondition;

  const conditionField = getFieldByName(allAccessibleFields, conditionFieldName);

  return {
    visibility: false,
    conditionField,
    conditionFieldName,
    conditionFieldAnswerNames: conditionFieldAnswer,
    isHideCondition: conditionType === CLT_HIDE,
    whenToHide: conditionFieldAnswer,
    conditionFieldSequence
  };
}
