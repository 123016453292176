import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import moment from 'moment/moment';

import Icon from '../../../../../../../common/general/Icon';

import './CommentSection.scss';

const maxCommentLength = 255;

export const CommentSection = ({
  editCommentMode,
  editedComment,
  setEditedComment,
  comments,
  setEditCommentMode,
  isUserHaveAccessToManageExpenses
}) => {
  const getNormalizedDateForComment = useCallback(
    date => (date ? moment(date).format('DD/MMM/YYYY, h:mm A') : 'N/A'),
    []
  );

  return (
    <div className="comment-section">
      <div className="current-comment">
        <span className="item-label">Comments</span>
        {editCommentMode && (
          <TextField
            label="Comment"
            variant="standard"
            value={editedComment}
            multiline
            onChange={({ target: { value } }) => setEditedComment(value)}
            inputProps={{ maxLength: maxCommentLength }}
            helperText={`${editedComment?.length ?? 0}/${maxCommentLength}`}
            sx={{ marginTop: '10px', '.MuiFormHelperText-root': { textAlign: 'end' } }}
            data-testid="addNewCommentTextarea"
          />
        )}
        {isUserHaveAccessToManageExpenses && (
          <div
            className="edit-comment-icon"
            onClick={() => {
              setEditedComment('');
              setEditCommentMode(!editCommentMode);
            }}
          >
            + Add
          </div>
        )}
      </div>
      {comments?.map((comment, index) => (
        <div className="prev-comment-item" key={index + 'comment'}>
          <Icon className="prev-comment-icon" suit="material-outline">
            forum
          </Icon>
          <div>
            {getNormalizedDateForComment(comment?.createdAt)} by {comment?.userName}: {comment?.comment}
          </div>
        </div>
      ))}
    </div>
  );
};
