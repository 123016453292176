import React, { useContext, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { findIndex } from 'lodash/array';
import { isEmpty, isObject } from 'lodash/lang';

import IconButton from '../../../../../../../../common/buttons/IconButton';
import Checkbox from '../../../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Icon from '../../../../../../../../common/general/Icon';
import TextFieldResolver from '../../../../../../../../common/RichTextFormat/viewer/TextFieldResolver';
import StyledTooltip from '../../../../../../../../common/widgets/StyledToolTip';
import { scBlue100 } from '../../../../../../../../constants/systemColors';
import { TableSetupContext } from '../../../../../../../../contexts/TableSetupContext';
import ConditionalLogicSetupModal from '../../../ConditionalLogicSetupModal/ConditionalLogicSetupModal';
import { collectDataForNewTableIg } from '../../../ConditionalLogicSetupModal/dataCollector';
import { InvestigatorFieldsCheckbox } from '../../../InvestigatorFields/InvestigatorFieldsCheckbox/InvestigatorFieldsCheckbox';
import { customClassObject } from '../../../ItemGroupSetup/customClassObject';
import FieldPreview from '../../../ItemGroupSetup/ItemGroupSetupContent/CustomFieldsGenerator/FieldPreview';
import { checkConditionalLogicForThisItem } from '../../TableSetupServices';
import EditCDASHModal from '../TableFieldItem/EditCDASHModal/EditCDASHModal';
import EditFormAnswer from '../TableFieldItem/EditFormAnswer/EditFormAnswer';

import './TableFieldCustomItem.scss';

const TableFieldCustomItem = ({ item, itemIndex, className, general }) => {
  const tableSetupContext = useContext(TableSetupContext);

  const alertForItem = useMemo(() => {
    let alertMessage = null;
    if (!isEmpty(item.fieldConfigurationResponse)) {
      item.fieldConfigurationResponse.forEach(configuration => {
        const toggleIndexInFieldList = findIndex(tableSetupContext.fieldList, [
          'name',
          configuration.conditionFieldName
        ]);
        const toggleIndexInGeneralFields = findIndex(tableSetupContext.generalFields, [
          'name',
          configuration.conditionFieldName
        ]);
        if (
          configuration.conditionFieldName &&
          ((general && toggleIndexInGeneralFields > itemIndex) ||
            toggleIndexInFieldList > itemIndex ||
            (general && toggleIndexInGeneralFields < 0))
        ) {
          alertMessage = `Toggle "${configuration.conditionFieldName}" must be before controlled item`;
        }
      });
    }
    if (isObject(item.hideAllConfiguration) && itemIndex) {
      alertMessage = `This toggle must be first in the list`;
    }
    return alertMessage;
  }, [
    general,
    item.fieldConfigurationResponse,
    item.hideAllConfiguration,
    itemIndex,
    tableSetupContext.fieldList,
    tableSetupContext.generalFields
  ]);

  useEffect(() => {
    tableSetupContext.updateAlertForItem(itemIndex, !!alertForItem, general);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertForItem]);

  const editCDASHItem = () => {
    ModalBoxes.open({
      component: (
        <EditCDASHModal
          question={item.question}
          updatedQuestion={item.updatedQuestion}
          styledFieldName={item.styledFieldName}
          itemType={item.inputType}
          general={general}
          updateCDASHQuestion={tableSetupContext.updateCustomItemQuestion}
          itemIndex={itemIndex}
          headerText="Edit Custom Field"
        />
      )
    });
  };

  const deleteToggleWithConfiguration = (uncheckItem = false) => {
    ModalBoxes.confirm({
      title: 'Toggle Configuration',
      content: uncheckItem
        ? 'Unchecking this toggle will remove field configurations in this Item Group. Uncheck anyway?'
        : 'Deleting this toggle will remove field configurations in this Item Group. Delete anyway?',
      confirmButton: 'Yes',
      cancelButton: 'No',
      className: 'edit-custom-field-logic-modal'
    })
      .then(() => {
        if (uncheckItem) {
          tableSetupContext.toggleCheckForField(itemIndex, general, true);
        } else {
          tableSetupContext.deleteCustomItem(item.name, true);
        }
      })
      .catch(e => {});
  };

  const editCustomFieldLogic = () => {
    const conditionalLogicSetupModal = ModalBoxes.open({
      component: (
        <ConditionalLogicSetupModal
          data={collectDataForNewTableIg(item, tableSetupContext.generalFields, tableSetupContext.fieldList)}
          onSave={function({ configuration, hideAllConfiguration }) {
            tableSetupContext.addConditionalLogicForItem(itemIndex, general, configuration, hideAllConfiguration);
            conditionalLogicSetupModal.close();
          }}
        />
      )
    });
  };

  return (
    <div className={className}>
      <div className="column big">
        <IconButton
          className="glyphicon glyphicon-edit edit-name"
          suit="glyphicon"
          color="blue"
          title="Edit CDASH Item"
          onClick={editCDASHItem}
        >
          edit
        </IconButton>
        <Checkbox
          color={scBlue100}
          checked={item.isChecked}
          onChange={() => {
            if (
              item?.inputType === 'radio' &&
              checkConditionalLogicForThisItem(
                [...tableSetupContext.fieldList, ...tableSetupContext.generalFields],
                item.name
              ) &&
              item.isChecked
            ) {
              deleteToggleWithConfiguration(true);
            } else {
              tableSetupContext.toggleCheckForField(itemIndex, general);
            }
          }}
        />
        <TextFieldResolver
          fieldType={item.inputType}
          fieldName={item.name}
          styledFieldName={item.styledFieldName}
          className={'new-table-item-group'}
        />
        {alertForItem && (
          <StyledTooltip className="tooltip-table-setup-alert" overlay={<div>{alertForItem}</div>} placement="top">
            <Icon className="conditional-logic-alert">warning</Icon>
          </StyledTooltip>
        )}
      </div>
      <div className="column big" />
      <div className="column big">
        <FieldPreview
          data={item}
          customClassObject={{
            ...customClassObject,
            reminder: {
              containerClass: 'custom-field-reminder',
              elementClass: ''
            },
            checkbox: {
              containerClass: 'custom-field-checkbox',
              elementClass: ''
            }
          }}
          itemDefIndex={itemIndex}
          onChangeLabelTextHandler={event => tableSetupContext.updateTextBlockValue(event, itemIndex, general)}
        />
        <EditFormAnswer
          itemIndex={itemIndex}
          inputType={item.inputType}
          isExtensible={item.isExtensible}
          general={general}
        />
        <IconButton
          className={cx('edit-conditional-configuration-icon', { hide_all_configuration: item?.hideAllConfiguration })}
          title="Edit Conditional Logic"
          color={!isEmpty(item.fieldConfigurationResponse) || item?.hideAllConfiguration ? 'red' : 'blue'}
          onClick={editCustomFieldLogic}
        >
          app_registration
        </IconButton>
      </div>
      <div className="column normal">
        <input
          type="text"
          readOnly
          value={item.sequence || ''}
          title="Form Item Sequence"
          className="form-control item-sequence"
        />
      </div>
      <div className="column short">
        <InvestigatorFieldsCheckbox
          item={item}
          onInvestigatorFieldChange={value => {
            tableSetupContext.toggleInvestigatorField(value, itemIndex, general);
          }}
        />
      </div>
      <div className="column short">
        {!general && (
          <Checkbox
            color={scBlue100}
            checked={item.tableGeneralField || false}
            onChange={() => tableSetupContext.toggleGeneralForField(itemIndex)}
          />
        )}
        <div
          className="delete-option-item"
          onClick={() => {
            if (general) {
              tableSetupContext.deleteFieldItemFromGeneral(item.name);
            } else {
              if (
                item?.inputType === 'radio' &&
                checkConditionalLogicForThisItem(
                  [...tableSetupContext.fieldList, ...tableSetupContext.generalFields],
                  item.name
                )
              ) {
                deleteToggleWithConfiguration();
              } else {
                tableSetupContext.deleteCustomItem(item.name);
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default TableFieldCustomItem;
