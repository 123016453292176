import { useMemo, useReducer } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { defaultAddress } from '../../../../../../../services/address';
import { TR_DRAFT } from '../../EncountersSection/TravelSection/travelConstants';

import reducer from './reducer';

const initialState = {
  status: TR_DRAFT,
  visitDate: null,
  visitStartTime: null,
  visitEndTime: null,
  requestedServices: [],
  departureDate: null,
  returnDate: null,
  preferredDepartureAirport: '',
  preferredArrivalAirport: '',
  preferredTravelTime: [],
  pickupAddress: {
    type: 'PATIENT_HOME_ADDRESS',
    ...defaultAddress
  },
  pickupAddressComments: '',
  dropOffAddress: {
    type: 'OTHER',
    ...defaultAddress
  },
  dropOffAddressComments: '',
  comments: ''
};

export default function useTravelRequestState(travelRequest, encounterInfo, travelPreferences) {
  const values = { ...initialState, ...travelRequest };

  if (!travelRequest && encounterInfo.encounterDate) {
    values.visitDate = moment(encounterInfo.encounterDate);
  }

  if (!travelRequest && !isEmpty(travelPreferences?.airlinePreferences?.preferredTravelTime)) {
    values.preferredTravelTime = travelPreferences?.airlinePreferences?.preferredTravelTime;
  }

  const [state, dispatch] = useReducer(reducer, {
    values,
    errors: {}
  });

  const actions = useMemo(
    function() {
      return {
        setFieldValue,
        setCheckboxGroupValue,
        handleChange,
        handleCheckboxGroupChange,
        setAddressField,
        setAddressFields,
        setCountryId,
        setCity,
        setAddressType,
        setErrors
      };
      function setFieldValue(name, value) {
        dispatch({ type: 'SET_FIELD_VALUE', payload: { name, value } });
      }
      function setCheckboxGroupValue(name, value) {
        dispatch({ type: 'SET_CHECKBOX_GROUP_VALUE', payload: { name, value } });
      }
      function handleChange({ target }) {
        setFieldValue(target.name, target.value);
      }
      function handleCheckboxGroupChange({ target }) {
        setCheckboxGroupValue(target.name, { id: target.value, checked: target.checked });
      }

      function setAddressField(path, value) {
        dispatch({ type: 'SET_ADDRESS_FIELD', payload: { path, value } });
      }

      function setAddressFields(path, value) {
        dispatch({ type: 'SET_ADDRESS_FIELDS', payload: { path, value } });
      }

      function setCountryId(path, value) {
        dispatch({ type: 'SET_COUNTRY_ID', payload: { path, value } });
      }

      function setCity(path, value) {
        dispatch({ type: 'SET_CITY', payload: { path, value } });
      }

      function setAddressType(payload) {
        dispatch({ type: 'SET_ADDRESS_TYPE', payload });
      }

      function setErrors(payload) {
        dispatch({ type: 'SET_ERRORS', payload });
      }
    },
    [dispatch]
  );

  return [state, actions];
}
