import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import ModalBoxes from '../../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';
import { useCurrentRoute } from '../../../../../../../root/router';
import { CUST } from '../../../itemGroupsConstants/domainCodes';
import { CHECKBOX, RADIO } from '../../../itemGroupsConstants/inputTypes';
import { CUSTOM } from '../../../itemGroupsConstants/itemGroupFields';

import SubElementModal from './SubElementModal';

export default function AddCustomField({
  protocolId,
  domainArray,
  inputs,
  selectedOnly,
  updateInputsAndPreviewData,
  changeSequenceCounter,
  sequenceCounter,
  terminologyVersionGroupNumber
}) {
  const { name } = useCurrentRoute();
  function getCodeDefinationList(data, fullVal) {
    return data
      ? data.map((subData, index) => {
          return {
            codeId: 0,
            codeOid: '',
            name:
              subData.inputType === CHECKBOX
                ? fullVal.fieldName.value
                : subData.inputType === RADIO
                ? subData.name
                : subData.codedValue,
            codelist_defination_id: subData.codelist_defination_id,
            codeListOid: '',
            inputType: subData.inputType,
            codedValue: subData.inputType === CHECKBOX ? fullVal.fieldName.value : subData.codedValue,
            decode: subData.inputType === CHECKBOX ? fullVal.fieldName.value : subData.codedValue,
            term_ref: '',
            extensible: '',
            language: '',
            sequence: index + 1
          };
        })
      : [];
  }

  function getItemDefinitionList(data) {
    return data
      ? data.map(subData => {
          subData.answerType.value = subData.answerType.value === 'labelText' ? 'label' : subData.answerType.value;

          const itemDefinition = {
            name: subData.fieldName.value,
            cdashAliasName: subData.answerCdash.value,
            ctListName: data?.ctListName,
            domain: domainArray && domainArray[0],
            question: subData.fieldName.value,
            styledFieldName: subData.fieldName.htmlValue,
            inputValue:
              subData.answerType.value === 'label' && subData.answerType.labelVal ? subData.answerType.labelVal : '',
            inputType: subData.answerType.value,
            sequence: Number(sequenceCounter),
            ref_oid: '',
            codeDefinationList: getCodeDefinationList(
              subData.answerType && subData.answerType.codeDefinationList,
              subData
            ),
            isRepeat: true,
            value: subData.answerType.labelVal,
            uniqueIdentifier: null,
            isChecked: true,
            type: CUSTOM.toUpperCase(),
            isExtensible: ['dropdownCT', 'multiselectCT', 'dropdown', 'multiselect'].includes(subData.answerType.value)
              ? ['dropdownCT', 'multiselectCT'].includes(subData.answerType.value)
                ? 1
                : 2
              : 0
          };
          changeSequenceCounter(++sequenceCounter);
          return itemDefinition;
        })
      : [];
  }

  function getFormLabelData(data) {
    return {
      labelId: 0,
      formLabelName: data.formLabel ? data.formLabel : '',
      isRepeat: false,
      sequence: 1,
      customItemDefinationList: getItemDefinitionList(data.subElementformDataList),
      itemDefinitionList: [],
      uniqueIdentifier: null
    };
  }

  function incrementOptionalFieldsSequence(prevSt, formLabelData) {
    if (selectedOnly) {
      let itemSequence = formLabelData.customItemDefinationList[0].sequence;
      prevSt[0].optional[0].itemDefinitionList.forEach(el => {
        if (el.sequence === itemSequence) {
          ++itemSequence;
          el.sequence = itemSequence;
        }
      });
    }
  }

  function openSubElementModal(e) {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    const modalBox = ModalBoxes.open({
      component: (
        <SubElementModal
          terminologyVersionGroupNumber={terminologyVersionGroupNumber}
          pageName={name}
          onSave={data => {
            let prevSt = cloneDeep(inputs);
            const formLabelData = getFormLabelData(data);
            if (prevSt.length === 0) {
              prevSt = [
                {
                  name: domainArray[0],
                  formview: 'grid',
                  domainId: 0,
                  domainCode: CUST,
                  custom: [formLabelData]
                }
              ];
            } else if (prevSt[0].hasOwnProperty(CUSTOM.toLowerCase())) {
              prevSt[0].custom.push(formLabelData);
            } else {
              prevSt[0].custom = [];
              prevSt[0].custom.push(formLabelData);
            }
            incrementOptionalFieldsSequence(prevSt, formLabelData);
            updateInputsAndPreviewData(prevSt);
            ModalBoxes.close(modalBox.id);
          }}
          protocolId={protocolId}
        />
      )
    });
  }

  return (
    <Button size="h28" id="addCustomField" onClick={openSubElementModal}>
      <Icon>add</Icon>
      Custom Item
    </Button>
  );
}
