import React, { useMemo } from 'react';
import { cloneDeep } from 'lodash/lang';
import uuid from 'uuid/v4';

import { DirectionSection } from '../../../../patient-source/Patients/Logs/DirectionSection';
import TableView from '../../DomainTable/DomainTable';
import ItemGroupFormPreview from '../../ItemGroupFormPreview/ItemGroupFormPreview';
import IcfSetup from '../ICF/IcfSetup';
import { getPredefineColumnValues } from '../itemGroupSetupService';
import { LogCheckPreviewNew } from '../LogCheckSetup/LogCheckPreviewNew';

import { customClassObject } from './customClassObject';
import { dynamicClassesForFieldName } from './itemGroupSetupRenderHelpService';

export function RenderForm({ formData, displayTableType, elementIcon, inputs, isLogCheckSetupEnabled }) {
  const clonedFormData = useMemo(() => {
    const cloned = cloneDeep(formData);

    if (elementIcon === 'Procedure' && cloned.hasOwnProperty('columnList') && cloned?.columnList?.length > 0) {
      cloned.columnList = [];
    }
    cloned.labelList?.forEach(function(field) {
      if (field.inputType === 'radio') {
        field.inputValue = 0;
      }
      if (!field.key) {
        /**
         * TODO: Temporary solution.
         * When the item group has not yet been saved, the custom fields do not have assigned unique IDs at this stage.
         * Should be removed in scope of ED-11265.
         */
        field.key = uuid();
      }
    });
    return cloned;
  }, [formData, elementIcon]);

  const predefinedTableData = {
    predefinedColumnName: inputs[0] ? inputs[0].predefinedColumnName : '',
    predefinedColumnValues: getPredefineColumnValues(inputs[0])
  };
  if (Object.keys(clonedFormData).length === 0) {
    return <div className="alert alert-info p-1 text-center">Please Add Items</div>;
  }
  return (
    <React.Fragment>
      {isLogCheckSetupEnabled && (
        <LogCheckPreviewNew logCheckOptions={clonedFormData.logCheckOptions} domain={clonedFormData.domainCode} />
      )}

      {clonedFormData.domainCode === 'ICF' && elementIcon === 'ICF' && (
        <IcfSetup
          informedConsentTemplate={clonedFormData.informedConsentTemplate}
          onChangeLanguage={e => {
            if (clonedFormData.informedConsentTemplate) {
              clonedFormData.informedConsentTemplate.languageId = e?.id || null;
            } else {
              clonedFormData.informedConsentTemplate = { languageId: e?.id || null };
            }
          }}
          onChangeType={e => {
            if (clonedFormData.informedConsentTemplate) {
              clonedFormData.informedConsentTemplate.typeId = e?.id || null;
            } else {
              clonedFormData.informedConsentTemplate = { typeId: e?.id || null };
            }
          }}
        />
      )}
      {isLogCheckSetupEnabled && <DirectionSection directions={clonedFormData.directions} isPreview={true} />}
      {clonedFormData.columnList && clonedFormData.columnList.length > 0 ? (
        <TableView
          displayElementIconTableType={displayTableType}
          predefinedTableData={predefinedTableData}
          tableData={[clonedFormData]}
          elementIcon={elementIcon}
        />
      ) : (
        <div>
          <div className="form-group pt-2 row col-xs-12 col-md-12 position-relative ml-0 mr-0 p-0">
            <ItemGroupFormPreview
              formData={clonedFormData}
              dynamicClassesForFieldName={dynamicClassesForFieldName}
              customClassObject={customClassObject}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
