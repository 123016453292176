import { orderBy } from 'lodash/collection';

import common from '../../../../common/common';

import { CUSTOM, OPTIONAL } from './ElementSetupNew/itemGroupsConstants/itemGroupFields';

export function mapItemGroupDomainResponseToPreviewDto(formData) {
  const columnList = [];
  let optionalDataList = [];
  let customDataList = [];
  if (formData.hasOwnProperty(OPTIONAL) && formData.optional && formData.optional.length > 0) {
    formData.optional.forEach((formLabel, index) => {
      optionalDataList = formLabel.itemDefinitionList.filter(item => {
        return item.isTable && item.isTable === true;
      });
    });
  }
  if (formData.hasOwnProperty(CUSTOM.toLowerCase()) && formData.custom && formData.custom.length > 0) {
    const getMergeCustomList = common.getSortedBySequenceFields(formData.custom);
    customDataList =
      getMergeCustomList.length > 0 &&
      getMergeCustomList.filter(item => {
        return item.isTable && item.isTable === true;
      });
  }
  const columnMergeArray = [].concat(optionalDataList, customDataList);
  orderBy(columnMergeArray, 'sequence', 'asc').forEach(item => {
    columnList.push(item.updatedQuestion ? item.updatedQuestion : item.question);
  });

  if (columnList.length > 0) {
    formData.columnList = columnList;
    formData.formview = 'table';
  }
  formData.labelList = [].concat(formData.optional || [], formData.custom || []);
  formData.labelList.forEach(label => {});
}
