import React from 'react';

import Multiselect from './Multiselect';
import ToggleField from './ToggleField';

export default function ConfigurationTableValueSelect({ fieldValue, condition, updateCondition, disabled }) {
  const { type } = fieldValue;

  if (type === 'radio') {
    return (
      <ToggleField
        fieldValue={fieldValue}
        condition={condition}
        updateCondition={updateCondition}
        disabled={disabled}
      />
    );
  }

  if (['multiselect', 'multiselectCT', 'dropdown', 'dropdownCT'].includes(type)) {
    return (
      <Multiselect
        fieldValue={fieldValue}
        condition={condition}
        updateCondition={updateCondition}
        disabled={disabled}
      />
    );
  }

  return type;
}
