import { useMemo } from 'react';
import { map } from 'lodash/collection';

import MultiSelect from '../../../../../../../../../common/data-entry/MultiSelect/MultiSelect';

export default function Multiselect({ fieldValue, condition, updateCondition, disabled }) {
  const { options } = fieldValue;
  const { conditionFieldAnswer } = condition;

  const _options = useMemo(
    function() {
      return map(options, function({ value, name }) {
        return {
          id: value,
          name
        };
      });
    },
    [options]
  );

  const selectedValues = useMemo(
    function() {
      return _options.filter(({ id }) => conditionFieldAnswer?.includes(id));
    },
    [_options, conditionFieldAnswer]
  );

  return (
    <MultiSelect
      style={{
        maxWidth: '218px'
      }}
      disabled={disabled}
      searchable
      clearSearchOnSelection
      dataSource={_options}
      onChange={selectedOptions => {
        updateCondition({
          conditionFieldAnswer: map(selectedOptions, ({ id }) => id) || null
        });
      }}
      placeholder="Select"
      value={selectedValues}
      validate={false}
    />
  );
}
