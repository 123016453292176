import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash/lang';
import { read, utils } from 'xlsx';

import { LoadPatientsApi, StudySiteApi } from '../../../../api';
import Input from '../../../../common/data-entry/Input';
import Button from '../../../../common/general/Button';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import { CLOSED, TREATMENT } from '../../../../constants/ssuStatuses';
import { ROLE_SYSTEM_ADMINISTRATOR } from '../../../../constants/userRoles';
import { userHasRole } from '../../../../services/auth';
import { onFileSave, onRequestErrorByStatus } from '../../../../services/handlers';
import { doesStringContainNullByte } from '../../../../services/string';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { SSUFilter } from '../../../SSUFilter/SSUFilter';
import { SSUPCNFilter } from '../../../SSUFilter/SSUPCNFilter';
import PatientSourceSelect from '../../patient-source/Patients/PatientSourceSelect';
import PatientStatusSelect from '../../patient-source/Patients/PatientStatusSelect';

import LoadPatientsResult from './LoadPatientsResult';
import LoadPatientsTable from './LoadPatientsTable';
import { validateAndFormatPatientData } from './LoadPatientUtilities';

import './LoadPatients.scss';

const LOAD_PATIENTS = 'LOAD_PATIENTS';
const DESIRED_MAPPING = Object.freeze({
  'last name': 'lastName',
  'first name': 'firstName',
  'middle name': 'middleName',
  dob: 'dob',
  'date of birth': 'dob',
  address: 'address1',
  'address 1': 'address1',
  address2: 'address2',
  'address 2': 'address2',
  city: 'city',
  state: 'state',
  zip: 'zip',
  country: 'country',
  phone: 'phone',
  'phone number': 'phone',
  'phone type': 'phoneType',
  email: 'email',
  mrn: 'mrn',
  sex: 'sex',
  pronouns: 'pronouns',
  height: 'height',
  weight: 'weight',
  'sms opt in': 'optIn',
  'primary language': 'primaryLanguage',
  races: 'races',
  ethnicities: 'ethnicities',
  'preferred contact method': 'preferredContactMethod',
  instructions: 'instructions'
});

const readFile = (file, onRead) => {
  const reader = new FileReader();
  reader.onload = upload => {
    const data = upload.target.result?.split(',')[1];
    const workbook = read(data);
    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    const rawJson = utils.sheet_to_json(firstSheet, { blankrows: true, headers: 1, raw: false });
    onRead(data, rawJson);
  };
  reader.readAsDataURL(file);
};

const LoadPatients = () => {
  const [patientSource, setPatientSource] = useState('');
  const [patientStatus, setPatientStatus] = useState('Identified');
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [response, setResponse] = useState(null);
  const [ssu, setSsu] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [issues, setIssues] = useState(new Map());
  const [uploadDisabled, setUploadDisabled] = useState(true);

  const hasFileBeenRead = useRef(false);
  const isDataValidated = useRef(false);

  useEffect(() => {
    if (!isEmpty(patientData) && !isDataValidated.current) {
      const { issues: updatedIssues, patientData: updatedFileJSON } = validateAndFormatPatientData(issues, patientData);

      setIssues(updatedIssues);
      if (JSON.stringify(updatedFileJSON) !== JSON.stringify(patientData)) {
        setPatientData(updatedFileJSON);
      }

      isDataValidated.current = true;
    }
  }, [patientData, isDataValidated, issues]);

  const resetFileState = () => {
    setFile(null);
    setFileData(null);
    setPatientData(null);
    setIssues([]);
    setResponse(null);
  };

  const onChangeFileHandler = evt => {
    const file = evt.target.files[0];
    if (!file) {
      resetFileState();
      return;
    }

    if (doesStringContainNullByte(file?.name)) {
      NotificationManager.error('The file name format is unacceptable. Please rename the file and re-upload');
      return;
    }

    isDataValidated.current = false;
    hasFileBeenRead.current = false;
    setFile(file);
    setIssues([]);
  };

  const checkIfFileEmpty = useCallback(data => {
    return isEmpty(data);
  }, []);

  const cleanHeaders = useCallback(json => {
    const normalizeKey = key => key.toLowerCase().replace(/_/g, ' ');
    const expectedKeys = Object.values(DESIRED_MAPPING).map(key => key.toLowerCase());

    const transformKeys = obj =>
      Object.entries(obj).reduce((acc, [key, value]) => {
        if (key.startsWith('__EMPTY')) {
          throw new Error('Missing header row');
        }

        const normalizedKey = normalizeKey(key);
        acc[DESIRED_MAPPING[normalizedKey] || key] = value;
        return acc;
      }, {});
    const cleaned = json.map(transformKeys);
    if (!cleaned.some(row => Object.keys(row).some(key => expectedKeys.includes(key.toLowerCase())))) {
      throw new Error('Invalid header row');
    }
    return cleaned;
  }, []);

  useEffect(() => {
    if (file instanceof File && !hasFileBeenRead.current) {
      readFile(file, (data, rawJson) => {
        setFileData(data);
        if (checkIfFileEmpty(rawJson)) {
          NotificationManager.error(
            'The file could not be read; ensure there is a header row present and that the file is not empty, and try again'
          );
          return;
        }

        let cleaned;
        try {
          cleaned = cleanHeaders(rawJson);
        } catch {
          NotificationManager.error('The file is missing a header row. Please include headers and try again.');
          return;
        }

        if (cleaned) {
          const trimmedData = cleaned.reduce((acc, row, index) => {
            if (!Object.keys(row).every(key => row[key] === '')) {
              const trimmedRow = {};
              for (const [key, value] of Object.entries(row)) {
                if (!Object.values(DESIRED_MAPPING).includes(key)) {
                  continue;
                }
                trimmedRow[key] = typeof value === 'string' ? value.trim() : value;
              }
              acc.push({ ...trimmedRow, row: index + 2 }); // Maintain original row number
            }
            return acc;
          }, []);
          setPatientData(trimmedData);
        }
      });
      hasFileBeenRead.current = true;
    }
  }, [file, cleanHeaders, checkIfFileEmpty]);

  const uploadFile = () => {
    if (!isEmpty(issues)) {
      return;
    }
    const { studyIdentifier, uniqueIdentifier } = ssu;
    const data = {
      fileData,
      type: file.type,
      fileUploadName: file.name,
      studyIdentifier,
      patientOriginIdentifier: patientSource,
      patientStatus: patientStatus,
      statusChangeLocation: LOAD_PATIENTS,
      patientData
    };
    LoadPatientsApi.importFromFileAndSaveToS3(uniqueIdentifier, data).then(
      response => {
        setResponse(response.data);
      },
      err => onRequestErrorByStatus(err, 'response.data.message')
    );
  };

  const downloadSample = () => {
    LoadPatientsApi.downloadSampleFile().then(onFileSave);
  };

  const selectSource = ({ id }) => {
    setPatientSource(id);
  };

  const loadSsuNotInTreatmentStatus = () => {
    return StudySiteApi.getAllStudySitesAndMap().then(({ data: ssus }) => {
      return ssus.filter(
        ({ siteStatus }) => userHasRole(ROLE_SYSTEM_ADMINISTRATOR) || ![TREATMENT, CLOSED].includes(siteStatus)
      );
    });
  };

  const selectSsu = ssus => {
    const selectedSSU = ssus.length === 1 ? ssus[0] : null;
    setSsu(selectedSSU);
  };

  useEffect(() => {
    const shouldDisable =
      isEmpty(ssu) ||
      isEmpty(patientSource) ||
      isEmpty(patientStatus) ||
      isEmpty(patientData) ||
      !(file instanceof File) ||
      !isEmpty(issues);
    setUploadDisabled(shouldDisable);
  }, [ssu, file, patientSource, patientData, issues, patientStatus]);

  return (
    <div className="load-patients">
      <PageInfoHeader>
        <div className="general-header-group-container general-header-wrapper">
          <SSUFilter handleSSUFilterChange={selectSsu} ssuProvider={loadSsuNotInTreatmentStatus}>
            <SSUPCNFilter isRequired={true} />
          </SSUFilter>
          <PatientStatusSelect setPatientStatus={setPatientStatus} disabled={false} requiredField={true} />
          <PatientSourceSelect setPatientSource={selectSource} disabled={false} requiredField={true} />
          <Input.File
            name="patientsFileUpload"
            fileName={file?.name || ''}
            onChangeFileHandler={onChangeFileHandler}
            acceptedUploadTypes={[
              '.xls',
              'application/vnd.ms-excel',
              '.xlsx',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              '.csv',
              'text/csv'
            ].join()}
            required
          />
          <Button
            id="patient-file-upload-button"
            className="load-btn"
            disabled={uploadDisabled}
            onClick={uploadFile}
            size="h56"
          >
            Upload File
          </Button>
          <Button id="download-sample-file-button" className="load-btn" onClick={downloadSample} size="h56">
            Sample File
          </Button>
        </div>
      </PageInfoHeader>
      {patientData && !response && !isEmpty(issues) && <LoadPatientsTable issues={issues} />}
      {response && isEmpty(issues) && <LoadPatientsResult response={response} />}
    </div>
  );
};

export default LoadPatients;
