import { PATIENT_STIPEND_WORKLIST } from 'constants/accessToPages';

import StipendWorklist from './PatientStipendWorklist';

export const patientStipendWorklistRouterConfig = {
  name: 'Patient Stipends',
  path: '/patient-stipends',
  rootBreadcrumbName: 'Finance',
  component: StipendWorklist,
  access: [PATIENT_STIPEND_WORKLIST]
};
