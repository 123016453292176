import { useCallback, useEffect, useMemo } from 'react';
import PatientStipendWorklistApi from 'api/patient/PatientStipendWorklistApi';
import Loader from 'common/elements/Loader/Loader';
import { debounce } from 'lodash/function';
import { isEmpty } from 'lodash/lang';

import ApplyAndResetButtons from 'components/ApplyAndResetButtons/ApplyAndResetButtons';
import BasicMultiselect from 'components/eui/EuiFiltersContext/controls/BasicMultiselect/BasicMultiselect';
import StudySiteMultiselect from 'components/eui/EuiFiltersContext/controls/StudySiteMultiselect/StudySiteMultiselect';
import { useEuiFiltersDispatch, useEuiFiltersState } from 'components/eui/EuiFiltersContext/EuiFiltersContext';
import { setInitialStateProcessor } from 'components/eui/EuiFiltersContext/processors';
import { FRS_READY } from 'components/eui/EuiFiltersContext/reducer';
import { PageInfoHeader } from 'components/PageInfoHeader/PageInfoHeader';

import { INITIAL_FILTERS_STATE } from '../constants';

export default function FiltersSection({ gridData, onApply, applyDisabled }) {
  const state = useEuiFiltersState();
  const dispatch = useEuiFiltersDispatch();

  const { schema } = state;

  const status = state.schema?.status;

  const getStudySites = useCallback(function() {
    return PatientStipendWorklistApi.getStudySites().then(({ data: source }) => ({ source }));
  }, []);

  const debounceCache = useMemo(function() {
    return debounce(cb => cb(), 500);
  }, []);

  useEffect(
    function() {
      if (status !== FRS_READY) {
        return;
      }
      debounceCache(function() {
        dispatch({
          type: 'PERFORM',
          processor: cacheFiltersProcessor
        });
      });
    },
    [debounceCache, dispatch, gridData, status]
  );

  useEffect(
    function() {
      if (status) return;
      dispatch({
        type: 'PERFORM',
        processor: setInitialStateProcessor,
        payload: {
          ...INITIAL_FILTERS_STATE
        }
      });
    },
    [dispatch, status]
  );

  if (isEmpty(schema)) return null;

  return (
    <PageInfoHeader>
      <div className="general-header-wrapper" style={{ minHeight: 71 }}>
        <BasicMultiselect
          uniqKey="statuses"
          state={state}
          dispatch={dispatch}
          label="Status"
          data-testid="status-filter"
        />
        <StudySiteMultiselect uniqKey="ssu" state={state} dispatch={dispatch} getStudySites={getStudySites} />
        {status === FRS_READY ? (
          <ApplyAndResetButtons applyClassName="apply-btn" onApply={onApply} applyDisabled={applyDisabled} />
        ) : (
          <Loader dark={true} />
        )}
      </div>
    </PageInfoHeader>
  );
}

function cacheFiltersProcessor(state) {
  const cache = {
    statuses: {
      value: state?.statuses?.value
    },
    ssu: {
      selectedStudyMap: state?.ssu?.selectedStudyMap,
      selectedSiteMap: state?.ssu?.selectedSiteMap
    }
  };

  sessionStorage.setItem(state.cacheKey, JSON.stringify(cache));
  return state;
}
