import React from 'react';
import { isEmpty } from 'lodash/lang';
import * as PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import { FILE_TYPES } from '../../../../../../../constants/inputTypes';
import { CustomCell } from '../../../../Patients/Logs/LogsTableService';
import { FileView } from '../ItemGroupElement/FileView/FileView';
import { isAnswerPopulated } from '../reviewContentService';

import './TableCellAnswer.scss';

export default function TableCellAnswer({ answer, isAnswerEdited }) {
  const answerContent = resolveAnswerContent(answer, isAnswerEdited);

  return <div className="table-cell-answer-layout">{answerContent}</div>;

  function resolveAnswerContent(answer, isAnswerEdited) {
    if (!isAnswerPopulated(answer) && answer?.type !== 'EMPTY') {
      let resolvedAnswer;
      let shortAnswer;

      const answerType = answer?.type;
      if (answerType === 'NOT_ASKED') {
        resolvedAnswer = `Not Asked: ${answer.emptyReason}`;
        shortAnswer = 'Not Asked';
      }
      if (answerType === 'UNAVAILABLE') {
        resolvedAnswer = `Unavailable: ${answer.emptyReason}`;
        shortAnswer = 'Unavailable';
      }
      if (answerType === 'NO_SENSE') {
        resolvedAnswer = 'Unavailable';
        shortAnswer = 'Unavailable';
      }
      if (answerType === 'EMPTY') {
        resolvedAnswer = '';
        shortAnswer = '';
      }

      return (
        <Tooltip
          destroyTooltipOnHide={false}
          overlayClassName="eds-rc-tooltip logs-table-tooltip over-modal"
          placement="top"
          trigger={['NOT_ASKED', 'UNAVAILABLE'].includes(answerType) ? ['hover'] : []}
          overlay={resolvedAnswer}
        >
          <span>{shortAnswer}</span>
        </Tooltip>
      );
    }

    const answerValueToShow = resolveAnswerValueToShow(answer);
    if (isAnswerEdited && !isEmpty(answer)) {
      return (
        <Tooltip
          destroyTooltipOnHide={false}
          overlayClassName="eds-rc-tooltip over-modal"
          placement="top"
          trigger={answerValueToShow?.length > 30 ? ['hover'] : []}
          overlay={answerValueToShow}
        >
          <div className="edited-answer-layout">
            <div className="change-bullet">{answerValueToShow}</div>
          </div>
        </Tooltip>
      );
    } else {
      return <CustomCell longAnswer={answerValueToShow} shortAnswer={answerValueToShow} />;
    }
  }

  function resolveAnswerValueToShow(answer) {
    if (FILE_TYPES.includes(answer.inputType)) {
      return <FileView files={answer.fileValue.files} />;
    } else {
      return answer.stringValue;
    }
  }
}

TableCellAnswer.propTypes = {
  answer: PropTypes.object,
  isAnswerEdited: PropTypes.bool
};
