import { useRef, useState } from 'react';
import { IconButton } from '@mui/material';

import { iconButtonStyles } from '../../../components/pages/finance/NewInvoice/CreateInvoice/SendMailModal/EditorPlagins/ToolbarPlugin';
import useOutsideClickDetector from '../../hooks/useOutsideClickDetector';

import { ColorPicker } from './ColorPicker';

import './IconWithColorPicker.scss';

export const IconWithColorPicker = ({ Icon, color, onChange, ...restProps }) => {
  const menuRef = useRef();
  useOutsideClickDetector(menuRef, () => {
    setMenuOpen(false);
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };
  return (
    <div className="icon-with-color-picker" ref={menuRef}>
      <IconButton sx={iconButtonStyles} onClick={handleToggleMenu} {...restProps}>
        <Icon />
      </IconButton>
      {menuOpen && <ColorPicker onChange={onChange} color={color} />}
    </div>
  );
};
