import { flatMap, forEach, reduce } from 'lodash/collection';

export function getChildParentsCountMap(formValidationRules) {
  const childParentMap = {};
  const childParentsCountMap = {};

  Object.values(formValidationRules).forEach(rule => {
    flatMap(Object.values(rule.targetIdentifiersMap)).forEach(target => {
      if (childParentMap[target]) {
        childParentMap[target] = [...childParentMap[target], rule.sourceIdentifier];
      } else {
        childParentMap[target] = [rule.sourceIdentifier];
      }
    });
  });

  const allConditionalChildren = Object.keys(childParentMap);

  function countParents(original, child) {
    const parents = childParentMap[child];

    parents.forEach(parent => {
      if (allConditionalChildren.includes(parent)) {
        childParentsCountMap[original] += 1;
        countParents(original, parent);
      }
    });
  }

  allConditionalChildren.forEach(child => {
    childParentsCountMap[child] = 1;
    countParents(child, child);
  });

  return childParentsCountMap;
}

export function getChildParentsCountMapNew(formValidationRules) {
  const childParentsCountMap = {};

  const childParentMap = reduce(
    formValidationRules,
    function(accumulator, rule, triggerId) {
      forEach(
        flatMap(rule.conditionalLogicFieldsConfigs, function({ questions }) {
          return questions;
        }),
        function(targetId) {
          if (accumulator[targetId]) {
            accumulator[targetId].push(triggerId);
          } else {
            accumulator[targetId] = [triggerId];
          }
        }
      );
      return accumulator;
    },
    {}
  );

  const allConditionalChildren = Object.keys(childParentMap);

  function countParents(original, child) {
    const parents = childParentMap[child];

    parents.forEach(parent => {
      if (allConditionalChildren.includes(parent)) {
        childParentsCountMap[original] += 1;
        countParents(original, parent);
      }
    });
  }

  allConditionalChildren.forEach(child => {
    childParentsCountMap[child] = 1;
    countParents(child, child);
  });

  return childParentsCountMap;
}
