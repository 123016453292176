import { isEmpty, isEqual } from 'lodash/lang';

import { FILE_TYPES } from '../../../../../constants/inputTypes';

import { PS_ANSWERED, PS_NOT_ASKED, PS_UNAVAILABLE } from './TableItemGroup/constants';

export function getChangedData(labelList, originalLabelData) {
  if (!isEmpty(labelList)) {
    return labelList.flatMap((field, index) => {
      if (fieldDataWasChanged(originalLabelData, field, index)) {
        const originalLabelRecord = originalLabelData[index];
        let originalValue = originalLabelRecord.inputValue,
          newValue = field.inputValue;
        if (FILE_TYPES.includes(originalLabelRecord.attributeType)) {
          originalValue = originalLabelRecord.fileList.map(({ fileName }) => fileName).join('; ');
          newValue = field.fileList?.map(({ fileName, name }) => fileName || name).join('; ');
        }
        return {
          name: field.updatedQuestion ? field.updatedQuestion : field.question,
          styledFieldName: field.styledFieldName,
          original: originalValue,
          new: newValue || '',
          id: field.uniqueIdentifier,
          fieldType: field.inputType
        };
      }
      return [];
    });
  }
  return null;
}

export function findQuestionsWithChangedAnswers(inputs, originalLabelData) {
  return getChangedData(inputs?.labelList || [], originalLabelData);
}

function fieldDataWasChanged(originalLabelData, field, index) {
  const originalLabelRecord = originalLabelData[index],
    { ansStatus, inputType, inputValue, fileList } = field,
    originalFileList = originalLabelRecord?.fileList,
    originalInputValue = originalLabelRecord?.inputValue;
  if (ansStatus === PS_ANSWERED && FILE_TYPES.includes(inputType) && compareInputsFiles(originalFileList, fileList)) {
    return true;
  } else if (
    (ansStatus === PS_NOT_ASKED || ansStatus === PS_UNAVAILABLE) &&
    FILE_TYPES.includes(inputType) &&
    compareInputsFiles(originalFileList, fileList)
  ) {
    return true;
  }

  /*
   * Condition 1: Data was saved already, and the data value has changed
   */
  if (originalInputValue && originalLabelData !== '--' && inputValue !== originalInputValue) {
    return true;
  } else if (
    /*
     * Condition 2: Data was not saved but marked 'Not Asked' or 'Not Available'
     */
    !originalInputValue &&
    inputValue &&
    (ansStatus === PS_NOT_ASKED || (ansStatus === PS_UNAVAILABLE && !FILE_TYPES.includes(inputType)))
  ) {
    return true;
  }
  return false;
}

function compareInputsFiles(original, changed) {
  if (!original && !changed) {
    return false;
  } else if (isEmpty(original) && isEmpty(changed)) {
    return false;
  } else if (original?.length !== changed?.length) {
    return true;
  } else {
    return !isEqual(original?.sort(), changed?.sort());
  }
}

export function isAnyFieldDataUnsaved(labelList, originalLabelData) {
  if (!isEmpty(labelList)) {
    return labelList.some((field, index) => isFieldDataUnsaved(originalLabelData, field, index));
  }

  return false;
}

function isFieldDataUnsaved(originalLabelData, field, index) {
  const originalLabelRecord = originalLabelData[index],
    { inputType, inputValue, fileList } = field,
    originalFileList = originalLabelRecord?.fileList,
    originalInputValue = originalLabelRecord?.inputValue;

  if (FILE_TYPES.includes(inputType)) {
    // new file being added || check for differences in file list
    return inputValue || compareInputsFiles(originalFileList, fileList);
  } else if (inputType === 'radio' && !inputValue) {
    // if radio and toggle is in middle position
    // A radio value for an unanswered state (neither yes or no)
    // can be either '', undefined, or the number 0.
    // Lets cast values to boolean.
    return !!inputValue !== !!originalInputValue;
  }

  return inputValue !== originalInputValue;
}
