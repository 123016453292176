import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { updateStudyEnrollmentsAction } from '../../../../../actions/finance/forecasting/forecastingActions';
import SaveStudyEnrollment from '../SaveStudyEnrollment/SaveStudyEnrollment';
import StudyEnrollmentPredictionValueListInputs from '../StudyEnrollmentPredictionValueListInputs/StudyEnrollmentPredictionValueListInputs';
import StudyEnrollmentPreviousPredictionValueList from '../StudyEnrollmentPreviousPredictionValueList/StudyEnrollmentPreviousPredictionValueList';
import { compareEnrollmentsPredictionsByMonth } from '../studyEnrollmentService';

class StudyEnrollmentListItem extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  handleEnrollmentValueChange = event => {
    const nextYear = parseInt(event.target.name.split(' ')[0]);
    const nextMonth = parseInt(event.target.name.split(' ')[1]);
    const nextEpv = { value: parseInt(event.target.value) };
    const nextEnrollmentPredictionValues = this.state.enrollment.enrollmentPredictionValues.map(epv => {
      if (epv.month === nextMonth && epv.year === nextYear) {
        return { ...epv, ...nextEpv };
      }
      return epv;
    });

    this.props.updateEnrollmentState({
      ...this.state.enrollment,
      enrollmentPredictionValues: nextEnrollmentPredictionValues
    });
  };

  static getDerivedStateFromProps(props) {
    return { ...props };
  }

  getExpectedConsentCount() {
    return this.state.enrollment.expectedCountSiv || this.state.enrollment.expectedCountPsv;
  }

  isEnrollmentPredictionVisible() {
    return this.getExpectedConsentCount() > 0 && this.state.enrollment.enrollmentPredictionValues.length > 0;
  }

  render() {
    if (
      !moment([this.state.enrollment.year, this.state.enrollment.month]).isAfter(
        moment(this.state.enrollment.periodEnd)
      ) ||
      this.state.enrollment.enrollmentPredictionValues.length === 0
    ) {
      const priorMonth = this.state.enrollment.month - 1 === -1 ? 11 : this.state.enrollment.month - 1;
      const priorMonthsYear =
        this.state.enrollment.month - 1 === -1 ? this.state.enrollment.year - 1 : this.state.enrollment.year;
      const priorMonthPredictedValue =
        this.state.enrollment?.previousEnrollment?.enrollmentPredictionValues.find(
          prediction => prediction.month === priorMonth && prediction.year === priorMonthsYear
        )?.value || 0;
      return (
        <div className="my-3">
          <div className="card">
            <div className="card-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-9">
                    <h5 className="card-title">
                      Study Enrollment |{' '}
                      <span className="text-muted bluetxt pt-1"> {this.state.enrollment.study.studyName}</span> |{' '}
                      <span className="text-muted bluetxt pt-1"> {this.state.enrollment.site.siteName}</span>
                    </h5>
                  </div>
                  <div className="col-3">
                    {this.isEnrollmentPredictionVisible() && <SaveStudyEnrollment enrollment={this.state.enrollment} />}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="enrollment-font-size">
                      Expected Consent{this.state.enrollment.expectedCountSiv === 0 ? ' (PSV)' : ' (SIV)'}:{' '}
                    </span>
                    <span className="enrollment-bold-font-size">
                      {this.getExpectedConsentCount() === 0 ? 'Not defined' : this.getExpectedConsentCount()}
                    </span>
                  </div>
                  <div className="col-6">
                    {this.state.enrollment.periodStart && this.state.enrollment.periodEnd && (
                      <span className="enrollment-font-size">
                        Enrolled Period: {moment(this.state.enrollment.periodStart).format('MMM YYYY')} -{' '}
                        {moment(this.state.enrollment.periodEnd).format('MMM YYYY')}
                      </span>
                    )}
                    {(!this.state.enrollment.periodStart || !this.state.enrollment.periodEnd) && (
                      <span className="enrollment-font-size">Enrolled Period: Not defined</span>
                    )}
                  </div>
                  <div className="col-6">
                    <span className="enrollment-font-size">
                      Expected Enrollments{this.state.enrollment.expectedEnrollmentSiv === 0 ? ' (PSV)' : ' (SIV)'}:{' '}
                    </span>
                    <span className="enrollment-bold-font-size">
                      {this.state.enrollment.expectedEnrollmentSiv || this.state.enrollment.expectedEnrollmentPsv}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="enrollment-font-size">Consented: </span>
                    <span className="enrollment-bold-font-size">{this.state.enrollment.enrolledCount}</span>
                  </div>
                  <div className="col-6">
                    <span className="enrollment-font-size">
                      Expected Completion{this.state.enrollment.completeExpectedSiv === 0 ? ' (PSV)' : ' (SIV)'}:{' '}
                    </span>
                    <span className="enrollment-bold-font-size">
                      {this.state.enrollment.completeExpectedSiv || this.state.enrollment.completeExpectedPsv}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="enrollment-font-size">
                      Time of Prediction:{' '}
                      {moment([this.state.enrollment.year, this.state.enrollment.month]).format('MMMM')}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="enrollment-font-size">
                      Remaining:{' '}
                      <span className="bold">
                        {(this.state.enrollment.expectedCountSiv || this.state.enrollment.expectedCountPsv) -
                          this.state.enrollment.enrolledCount}
                      </span>
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="enrollment-font-size">
                      {(priorMonth || priorMonth === 0) && priorMonthsYear
                        ? moment([priorMonthsYear, priorMonth]).format('MMMM')
                        : 'Prior Month'}
                      {' Prediction'}: {priorMonthPredictedValue}
                    </span>
                  </div>
                  <div className="col-12">
                    <span className="enrollment-font-size">Withdrawn: </span>
                    <span className="enrollment-bold-font-size">{this.state.enrollment.withdrawn}</span>
                  </div>
                  <div className="col-12">
                    <span className="enrollment-font-size">Screen Failed: </span>
                    <span className="enrollment-bold-font-size">{this.state.enrollment.screenFailed}</span>
                  </div>

                  {this.isEnrollmentPredictionVisible() && (
                    <div className="col-12">
                      <StudyEnrollmentPredictionValueListInputs
                        enrollmentPredictionValues={this.state.enrollment.enrollmentPredictionValues.sort(
                          compareEnrollmentsPredictionsByMonth
                        )}
                        currentMonth={moment()
                          .set({
                            year: this.state.enrollment.year,
                            month: this.state.enrollment.month
                          })
                          .startOf('month')}
                        ssuDateEnd={this.state.enrollment.periodEnd}
                        handleEnrollmentValueChange={this.handleEnrollmentValueChange}
                      />

                      {this.state.enrollment.previousEnrollment ? (
                        <StudyEnrollmentPreviousPredictionValueList
                          enrollmentPredictionValues={this.state.enrollment.previousEnrollment.enrollmentPredictionValues.sort(
                            compareEnrollmentsPredictionsByMonth
                          )}
                          currentMonth={moment()
                            .set({
                              year: this.state.enrollment.year,
                              month: this.state.enrollment.month
                            })
                            .startOf('month')}
                          ssuDateEnd={this.state.enrollment.periodEnd}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                  {this.state.enrollment.enrollmentPredictionValues.length === 0 && (
                    <div className="col-12">
                      <span className="enrollment-font-size">Study start, end is not specified.</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateEnrollmentState: studyEnrollment => {
      dispatch(updateStudyEnrollmentsAction(studyEnrollment));
    }
  };
};

export default connect(null, mapDispatchToProps)(StudyEnrollmentListItem);
