import React, { useEffect, useState } from 'react';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';
import { sortBy } from 'lodash/collection';
import { isEqual } from 'lodash/lang';
import moment from 'moment';

import Icon from '../../../../../common/general/Icon';
import { useActiveTask } from '../../../../../store/activeTask';
import { TASKS_TYPES } from '../../../../root/Container/Layout/Tasks/Task/taskConstants';
import { MILESTONE_TYPES } from '../../CalendarEventType';

import EventTypeIndicator from './EventTypeIndicator';

import './AllDayEvents.scss';

const milestoneEventsTopPositionOffset = 300;

export default function AllDayEvents({ events, userPreferences, displayedDate, style, onSelectEvent, selectedEvent }) {
  const [allDates, setAllDates] = useState([]);
  const [weekLength, setWeekLength] = useState(0);
  const [maxDropdownHeight, setMaxDropdownHeight] = useState(window.innerHeight - milestoneEventsTopPositionOffset);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const activeTask = useActiveTask();
  useEffect(() => {
    function handleResize() {
      setMaxDropdownHeight(window.innerHeight - milestoneEventsTopPositionOffset);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (userPreferences.view === 'week' || userPreferences.view === 'work_week' || userPreferences.view === 'day') {
      const tempWeekLength = userPreferences.view === 'work_week' ? 5 : userPreferences.view === 'week' ? 7 : 1;
      const tempAllDates = [];

      const startOfWeek =
        userPreferences.view !== 'day' ? moment(displayedDate).startOf('week') : moment(displayedDate);
      if (tempWeekLength === 5) {
        startOfWeek.add(1, 'd');
      }
      setWeekLength(tempWeekLength);

      for (let i = 0; i < tempWeekLength; i++) {
        tempAllDates.push(
          startOfWeek
            .clone()
            .add(i, 'd')
            .format('YYYY-MM-DD')
        );
      }
      if (!isEqual(allDates, tempAllDates)) {
        setAllDates(tempAllDates);
      }
    }
  }, [userPreferences, displayedDate, allDates]);

  const isSelectedEvent = event => {
    return selectedEvent?.id === event?.id || activeTask.taskId === event?.taskId;
  };

  const getEventsPerDate = (types, typeString, date, dateField) => {
    const filteredEvents = sortBy(
      events.filter(event => event[dateField] && event[dateField] === date && types.includes(event.type)),
      ['subject', 'id']
    );
    const id = `${typeString}_${date}`;
    const event = filteredEvents?.length === 1 ? filteredEvents[0] : null;
    return (
      <>
        {filteredEvents?.length > 1 && (
          <Menu
            onClick={e => (e.keepOpen = true)}
            className={cx({
              open: activeMenuItem === id && selectedEvent && selectedEvent.start === date
            })}
            styles={{ maxHeight: maxDropdownHeight, overflow: 'auto' }}
            menuButton={
              <MenuButton
                onClick={() => {
                  if (activeMenuItem === id) {
                    setActiveMenuItem(null);
                  } else {
                    setActiveMenuItem(id);
                  }
                }}
              >
                <span>{`${filteredEvents?.length} ${typeString}`}</span>
                <Icon>arrow_drop_down</Icon>
              </MenuButton>
            }
          >
            {filteredEvents.map(event => (
              <MenuItem
                key={event.id}
                onClick={() => onSelectEvent(event)}
                className={cx({ active: isSelectedEvent(event) })}
              >
                <EventTypeIndicator event={event} className="mr-2" />
                {event.subject}
              </MenuItem>
            ))}
          </Menu>
        )}
        {event && (
          <div
            onClick={() => onSelectEvent(event)}
            style={{ background: event.color.main, color: 'white' }}
            className={cx('event', { active: isSelectedEvent(event) })}
          >
            {event.subject}
          </div>
        )}
      </>
    );
  };
  const isChrome = window.navigator.userAgent.indexOf('Chrome') !== -1;
  return (
    <div className={`milestone-events-for-week ${isChrome ? 'chrome' : ''}`} style={style}>
      <div className="milestone-events-container">
        {allDates.map((date, idx) => (
          <div
            className={cx('day-container ', userPreferences.view === 'day' && 'one-day')}
            key={idx}
            style={{ width: `${100 / weekLength}%`, left: `${(100 / weekLength) * idx}%` }}
          >
            {getEventsPerDate(MILESTONE_TYPES, 'milestones', date, 'milestoneDate')}
            {getEventsPerDate(TASKS_TYPES, 'tasks', date, 'dueDate')}
          </div>
        ))}
      </div>
    </div>
  );
}
