/**
*
* @summary This file is use under Setup -> Protocol -> Element Setup
            File includes element setup information
* @author Mangesh Pimprikar,Ankur Vishwakarma,Poonam Banode,Irfan Bagwan
*
*/
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { some } from 'lodash/collection';
import { isArray, isEqual } from 'lodash/lang';

import { FormBuilderApi, ItemGroupTemplatesApi, ProtocolApi, ProtocolItemGroupTemplatesApi } from '../../../../../api';
import common from '../../../../../common/common';
import Section from '../../../../../common/data-display/Section/Section';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import {
  DATA_SAVED,
  DUPLICATE_PREDEFINED_COLUMN_VALUES_NOT_ALLOWED,
  FIELD_REQUIRED_FOR_TABLE_TYPE_ERROR,
  FILL_REQUIRED,
  SEQUENCE_BLANK_ERROR,
  SOMETHING_WENT_WRONG,
  TEMPLATE_CREATED,
  UNIQUE_SEQUENCE_ERROR
} from '../../../../../constants/notificationMessages';
import { onRequestDefaultError } from '../../../../../services/handlers';
import { generateUrlByKey, withCurrentRoute } from '../../../../root/router';
import { withNavigate, withParams } from '../../../../root/router/legacyComponentCompatability';
import { mapItemGroupDomainResponseToPreviewDto } from '../elementDomainToPreviewDtoMapperService';

import { createConditionFieldAnswerNames } from './ConditionalLogicSetupModal/services';
import IcfSetup from './ICF/IcfSetup';
import { ECKL, EPS, EPSTP, EREM, EREVPI, EREVSM } from './itemGroupsConstants/domainCodes';
import { CUSTOM, OPTIONAL } from './itemGroupsConstants/itemGroupFields';
import { LOGS, PROCEDURE, TABLE } from './itemGroupsConstants/itemGroupTypes';
import { ItemGroupDetails } from './ItemGroupSetup/ItemGroupDetails/ItemGroupDetails';
import { ItemGroupDomainConfiguration } from './ItemGroupSetup/ItemGroupDomainConfiguration';
import { ItemGroupPreviewOpen } from './ItemGroupSetup/ItemGroupPreviewOpen';
import { ItemGroupSetupContent } from './ItemGroupSetup/ItemGroupSetupContent/ItemGroupSetupContent';
import { getCombinedList } from './ItemGroupSetup/ItemGroupSetupContent/ItemGroupSetupService';
import { calculateCodeDefinitionChangesFromNewTerminologyVersion } from './ItemGroupTemplate/ItemGroupTemplateService';
import ValidateCodeDefinitionChangesModal from './ItemGroupTemplate/ValidateCodeDefinitionChangesModal';
import { DirectionSetup } from './LogCheckSetup/DirectionSetup';
import { LogCheckSetupNew } from './LogCheckSetup/LogCheckSetupNew';
import { TABLE_SETUP } from './TableSetup/TableSetupConstants';
import { resolveDefaultLogCheckOptions } from './defaultLogCheckOptions';
import {
  checkForDuplicate,
  getMaxSequenceCounter,
  getPredefineColumnValues,
  toFormMainElementParameters
} from './itemGroupSetupService';
import { addConditionFieldToEachCustomItemWithConfiguration } from './ProtocolItemGroupFieldConfigurationService';

import 'react-datetime/css/react-datetime.css';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-accessible-accordion/dist/minimal-example.css';
import './ElementSetupNew.scss';

const SELECT = 'Select';

class ElementSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      inputs: [],
      dataSaveObj: {},
      repeatForm: 1,
      repeatFormLabel: 1,
      domain: '',
      domainConditionalLogic: {},
      elementName: '',
      domainElement: '',
      elementIcon: null,
      iconList: [],
      initDomainValue: null,
      initElementIcon: null,
      customDomainList: [],
      showPreviewMsg: '',
      checked: false,
      showPreviewFormPopUpData: false,
      displayTableType: false,
      hideIsTableOptions: false,
      predefinedColumnValues: [],
      predefinedColumnName: '',
      protocolId: this.props.currentRoute.params.protocolIdentity,
      studyName: '',
      protocolName: '',
      protocolVersion: '',
      logCheckInstruction: '',
      logCheckOptions: [],
      logCheckTemplate: { instruction: '', rows: [] },
      informedConsentLanguage: '',
      informedConsentType: '',
      sequenceCounter: 0,
      combinedLabelList: [],
      terminologyVersionGroupNumber: null,
      isGroupAssign: false,
      directions: null
    };
    const { name } = props?.currentRoute;
    this.domainArray = [];
    this.previewDataList = {};
    this.elementIdFromEdit = '';
    this.isAnyItemQuestionCheck = [];
    this.logTypeDataArray = [];

    this.editMode = name.includes('Edit') || name.includes('Copy');

    this.isItemGroupTemplateSetup = name.includes('Item Group Template Setup');
    this.isTemplateSetup = name === 'Template Setup';
    this.isTemplateEdit = name === 'Template Edit';
    this.isTemplateCopy = name === 'Template Copy';
    this.isItemGroupTemplate = this.isTemplateSetup || this.isTemplateEdit || this.isTemplateCopy;
    this.onSaveData = this.onSaveData.bind(this);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(this.props.currentRoute.params.element_identifier, prevProps.currentRoute.params.element_identifier)) {
      this.getItemGroupData();
    }
    if (
      !isEqual(this.props.currentRoute.params.itemGroupTemplateId, prevProps.currentRoute.params.itemGroupTemplateId)
    ) {
      this.getItemGroupTemplate();
    }
    if (this.state.elementIcon === TABLE_SETUP) {
      const selectedLogsForTableSetup = this.state.iconList.filter(e => e.name === this.state.elementIcon);
      const itemGroupSetupParameters = {
        domainId: this.state.domainId,
        elementName: this.state.elementName,
        selectedType: {
          name: selectedLogsForTableSetup[0].name,
          id: selectedLogsForTableSetup[0].id
        },
        terminologyVersionGroupNumber: this.state.terminologyVersionGroupNumber
      };
      this.props.navigate(
        generateUrlByKey(this.props.currentRoute.parent.key, this.props.currentRoute.params) + '/table-setup',
        { state: { itemGroupSetupParameters } }
      );
    }
    if (this.state.domainId && this.state.domainId !== prevState.domainId) {
      FormBuilderApi.getStandardConditionalLogicForDomain(this.state.domainId).then(domainConditionalLogic => {
        this.setState({
          domainConditionalLogic
        });
      });
    }
  }

  componentDidMount() {
    if (this.props.currentRoute.params.element_identifier) {
      this.getItemGroupData();
    }
    if (this.props.currentRoute.params.itemGroupTemplateId) {
      this.getItemGroupTemplate();
    }

    ProtocolApi.getStudyProtocolBasicInfo(
      this.props.currentRoute.params.studyId,
      this.props.currentRoute.params.protocolIdentity
    ).then(res => {
      const { response: { studyName = '', protocolName = '', protocolVersion = '', isGroupAssign = false } = {} } =
        res.data || {};

      this.setState({ studyName, protocolName, protocolVersion, isGroupAssign });
    });
    const itemGroupSetupParameters = { ...this.props.currentRoute.state?.itemGroupSetupParameters };
    if (itemGroupSetupParameters?.selectedDomain) {
      this.setState({ initDomainValue: itemGroupSetupParameters.selectedDomain });
    }
    if (itemGroupSetupParameters?.elementName) {
      this.setState({ elementName: itemGroupSetupParameters.elementName });
    }
    if (itemGroupSetupParameters?.terminologyVersionGroupNumber) {
      this.setState({ terminologyVersionGroupNumber: itemGroupSetupParameters.terminologyVersionGroupNumber });
    }
  }

  updateTerminologyVersion(version) {
    if (version?.number !== this.state.terminologyVersionGroupNumber) {
      this.setState({ terminologyVersionGroupNumber: version?.number }, function() {
        if (this.state.domain) {
          this.getTemplateData(this.state.domainId, true);
        }
      });
    }
  }

  processRequest({ data }) {
    addConditionFieldToEachCustomItemWithConfiguration([data]);
    const mainElementParameters = toFormMainElementParameters(data, this.isItemGroupTemplateSetup, this.isTemplateCopy);
    this.setState(
      {
        ...mainElementParameters
      },
      this.receivingCallback
    );
  }

  getItemGroupData() {
    const urlParams = this.dataFromUrl();
    ProtocolItemGroupTemplatesApi.getActiveElementDomain(urlParams).then(
      this.processRequest.bind(this),
      onRequestDefaultError
    );
  }

  getItemGroupTemplate() {
    const urlParams = this.dataFromUrl();
    ItemGroupTemplatesApi.getItemGroupTemplate(urlParams.itemGroupTemplateId).then(
      this.processRequest.bind(this),
      onRequestDefaultError
    );
  }

  changeSequenceCounter = sequenceCounter => {
    this.setState({ sequenceCounter });
  };

  receivingCallback() {
    this.domainArray = this.state.domainElement && this.state.domainElement.split('-');
    const prevSeqSt = this.state.inputs[0];
    const sequenceCounter = getMaxSequenceCounter(prevSeqSt?.optional, prevSeqSt?.custom);
    this.changeSequenceCounter(sequenceCounter);
    this.getPreviewViewData(prevSeqSt);
    if (this.state.domain) {
      this.updateOptionalFieldsDefaultValues();
    }
  }

  updateOptionalFieldsDefaultValues() {
    let inputs = { ...this.state.inputs[0] };
    const itemDefinitionList = inputs?.optional[0]?.itemDefinitionList;
    if (itemDefinitionList?.length > 0) {
      this.state.terminologyVersionGroupNumber &&
        FormBuilderApi.getStandardDomainFormForTerminologyVersionGroup(
          this.state.domainId,
          this.state.terminologyVersionGroupNumber
        ).then(({ data }) => {
          const optionalFieldsMap = {};
          data?.optional[0]?.itemDefinitionList?.forEach(function(itemDefinition) {
            Object.assign(optionalFieldsMap, {
              [itemDefinition.name]: itemDefinition
            });
          });
          itemDefinitionList.forEach(itemDefinition => {
            const optionalFieldsMapElement = optionalFieldsMap[itemDefinition.name];
            Object.assign(itemDefinition, {
              ctListName: itemDefinition.ctListName ? itemDefinition.ctListName : optionalFieldsMapElement.ctListName,
              isExtensible: optionalFieldsMapElement?.isExtensible
            });
          });
          this.updateInputsAndPreviewData([inputs]);
        });
    }
  }

  onDataTypeChange = option => {
    if (option && option?.domainName !== SELECT && !this.editMode && !this.isItemGroupTemplateSetup) {
      this.setState(
        {
          domainId: option.domainIdentifier,
          showForm: true,
          dataSaveObj: {},
          domain: option.domainCode,
          domainElement: option.domainName,
          repeatFormLabel: 1,
          initElementIcon: { name: SELECT },
          showPreviewMsg: '',
          predefinedColumnName: '',
          predefinedColumnValues: '',
          initDomainValue: option
        },
        s => {
          this.domainArray = this.state.domainElement && this.state.domainElement.split('-');
        }
      );
      this.setElementIconList(option, () => {
        const defaultLogCheckOptions = resolveDefaultLogCheckOptions(this.state.domain);
        this.setState(prevState => ({
          logCheckOptions: cloneDeep(defaultLogCheckOptions),
          logCheckTemplate: {
            instruction: 'Please select an option below:',
            rows: cloneDeep(defaultLogCheckOptions)
          }
        }));
      });
      if (option.domainCode === EREVPI || option.domainCode === EREVSM) {
        this.setState({
          inputs: [],
          showForm: false,
          showPreviewMsg: 'No preview for this element',
          domain: option.domainCode,
          domainElement: option.domainName
        });

        this.getPreviewViewData();
      } else {
        this.setState({ inputs: [] });
        this.previewDataList = {};
        if (this.isItemGroupTemplate) {
          this.getTemplateData(option.domainIdentifier, false);
        } else {
          this.getFormJson(option.domainIdentifier);
        }
      }
    }
  };

  setElementIconList = (option, callback) => {
    const elementIconFormatedArray = option?.elementIcon && option.elementIcon.split(',');
    this.setState(
      {
        iconList:
          elementIconFormatedArray?.length > 0
            ? elementIconFormatedArray.map((item, index) => {
                return {
                  id: item + index,
                  name: item
                };
              })
            : []
      },
      () => {
        const itemGroupSetupParameters = { ...this.props.currentRoute.state?.itemGroupSetupParameters };
        const initialType = {
          name: itemGroupSetupParameters?.selectedType?.name,
          id: itemGroupSetupParameters?.selectedType?.id
        };
        if (
          itemGroupSetupParameters?.selectedType &&
          itemGroupSetupParameters?.selectedType?.name !== TABLE_SETUP &&
          some(this.state.iconList, initialType)
        ) {
          this.setState(
            {
              initElementIcon: initialType,
              elementIcon: itemGroupSetupParameters.selectedType.name
            },
            () => callback(initialType)
          );
        } else {
          const initElementIcon =
            option.domainName !== CUSTOM ? this.state.iconList && this.state.iconList[0] : undefined;
          this.setState(
            {
              initElementIcon: initElementIcon
            },
            () => callback(initElementIcon)
          );
        }
      }
    );
  };

  getFormJson = domainIdentifier => {
    const protocol_identifier = this.props?.currentRoute?.params?.protocolIdentity;
    FormBuilderApi.getStandardDomainForProtocol({ domainid: domainIdentifier, protocolid: protocol_identifier }).then(
      res => this.processOfRequest.bind(this)(res?.data?.response),
      onRequestDefaultError
    );
  };

  getTemplateData = (domainIdentifier, isTerminologyVersionChanged) => {
    this.state.terminologyVersionGroupNumber &&
      FormBuilderApi.getStandardDomainFormForTerminologyVersionGroup(
        domainIdentifier,
        this.state.terminologyVersionGroupNumber
      ).then(({ data }) => {
        const changedCodeDefinitionsMap = isTerminologyVersionChanged
          ? calculateCodeDefinitionChangesFromNewTerminologyVersion(this.state.inputs[0], data)
          : new Map();
        if (changedCodeDefinitionsMap.size > 0) {
          const modalBox = ModalBoxes.open({
            component: (
              <ValidateCodeDefinitionChangesModal
                changedCodeDefinitionsMap={changedCodeDefinitionsMap}
                onConfirm={() => {
                  modalBox.close();
                  this.processOfRequest.bind(this)(data);
                }}
                onClose={() => {
                  modalBox.close();
                  this.setState({
                    terminologyVersionGroupNumber: this.state.inputs[0].terminologyVersionGroupNumber
                  });
                }}
              />
            )
          });
        } else {
          this.processOfRequest.bind(this)(data);
        }
      }, onRequestDefaultError);
  };

  processOfRequest(response) {
    if (this.isStipendItemGroupDomain(response?.domainCode)) {
      response.optional[0].itemDefinitionList = this.checkAllQuestions(response.optional[0].itemDefinitionList);
    }
    if (this.isTemplateCopy) {
      if (response?.domainCode !== 'ICF') {
        response.optional[0].itemDefinitionList = this.updateQuestionValuesFromNewTerminologyVersion(
          response.optional[0].itemDefinitionList
        );
      }
      response.custom = this.state.inputs[0]?.custom;
      response.predefinedColumnName = this.state.inputs[0]?.predefinedColumnName;
      response.predefinedColumnValues = this.state.inputs[0]?.predefinedColumnValues;
      if (this.state.terminologyVersionGroupNumber) {
        response.terminologyVersionGroupNumber = this.state.terminologyVersionGroupNumber;
      }
    }
    response
      ? this.setState({ inputs: [response] }, () => {
          const prevSeqSt = this.state.inputs[0];
          const sequenceCounter = getMaxSequenceCounter(prevSeqSt?.optional, prevSeqSt?.custom);
          this.changeSequenceCounter(sequenceCounter);
          this.getPreviewViewData(prevSeqSt);
        })
      : this.setState({ inputs: [] });
  }

  checkAllQuestions(itemDefinitionList) {
    return itemDefinitionList.map(itemDefinition => {
      return { ...itemDefinition, isChecked: true };
    });
  }

  updateQuestionValuesFromNewTerminologyVersion(newItemDefinitionList) {
    const currentItemDefinitions = this.state.inputs[0]?.optional[0]?.itemDefinitionList;
    newItemDefinitionList.forEach(newItem => {
      const currentItem = currentItemDefinitions.find(item => item.cdashAliasName === newItem.cdashAliasName);
      if (currentItem) {
        const { codeDefinationList } = newItem;
        Object.assign(newItem, currentItem);
        newItem.codeDefinationList = codeDefinationList;
      }
    });
    return newItemDefinitionList;
  }

  dataFromUrl() {
    const { element_identifier, studyId: study_identifier, itemGroupTemplateId } = this.props.currentRoute.params;
    return {
      study_identifier,
      element_identifier,
      itemGroupTemplateId
    };
  }

  prepareProtocolItemGroupFieldConfigurationList = () => {
    const allOptionalItems = this.state.inputs[0]?.optional.flatMap(e => e.itemDefinitionList) || [];
    const allCustomItems = this.state.inputs[0]?.custom.flatMap(e => e.customItemDefinationList) || [];
    return allOptionalItems
      .concat(allCustomItems)
      .filter(e => e?.fieldConfigurationResponse)
      .map(e => {
        let fieldResponse = e?.fieldConfigurationResponse;
        return {
          conditionFieldAnswerNames:
            createConditionFieldAnswerNames(fieldResponse?.whenToHide) || fieldResponse?.conditionFieldAnswerNames,
          isHideCondition: fieldResponse?.isHideCondition,
          conditionFieldName: fieldResponse?.conditionField?.name || fieldResponse?.conditionFieldName,
          conditionFieldSequence: fieldResponse?.conditionField?.sequence || fieldResponse?.conditionFieldSequence,
          customFieldSequence: e.sequence,
          customFieldName: e.name
        };
      });
  };

  saveAPIForElementSetup = keepEditing => {
    const parameter = {
      studyId: this.props.currentRoute.params.studyId,
      domainId: this.state.domainId,
      elementIcon: this.state.elementIcon,
      elementId: this.elementIdFromEdit
    };
    const data = this.previewDataList;
    data.elementIcon = this.state.elementIcon;
    data.elementName = this.state.elementName && this.state.elementName.trim();
    data.domainCode = this.state.domain;
    data.domainName = this.state.domainElement;

    const newArray = getPredefineColumnValues(this.state.inputs[0])
      .map(item => (item ? item.trim() : undefined))
      .join(',');
    data.predefinedColumnName =
      this.state.inputs[0] && this.state.inputs[0].predefinedColumnName
        ? this.state.inputs[0].predefinedColumnName
        : '';
    data.predefinedColumnValues = this.state.inputs[0] && this.state.inputs[0].predefinedColumnValues ? newArray : '';
    const protocolItemGroupFieldConfigurationList = this.prepareProtocolItemGroupFieldConfigurationList();
    const logCheck = this.state.isLogType ? this.state.logCheckTemplate : null;
    if (this.isItemGroupTemplate) {
      data.terminologyVersionGroupNumber = this.state.terminologyVersionGroupNumber;
      if (this.isTemplateEdit && !this.isTemplateCopy) {
        this.updateTemplate(
          this.props.currentRoute.params.itemGroupTemplateId,
          data,
          protocolItemGroupFieldConfigurationList,
          logCheck,
          this.state.directions
        );
      } else {
        this.saveTemplate(data, protocolItemGroupFieldConfigurationList, logCheck, this.state.directions);
      }
    } else {
      this.saveItemGroup(
        data,
        logCheck,
        protocolItemGroupFieldConfigurationList,
        parameter,
        this.state.directions,
        keepEditing
      );
    }
  };

  saveTemplate(data, templateFieldConfigurationRequestList, logCheck, directions) {
    const request = this.getRequest(data, logCheck, null, directions);
    ItemGroupTemplatesApi.saveItemGroupTemplate({ ...request, templateFieldConfigurationRequestList }).then(
      this.saveItemGroupTemplateCallback.bind(this),
      this.onSaveItemItemGroupErrorHandlew.bind(this)
    );
  }

  updateTemplate(id, data, templateFieldConfigurationRequestList, logCheck, directions) {
    const request = this.getRequest(data, logCheck, null, directions);
    ItemGroupTemplatesApi.updateItemGroupTemplate(id, { ...request, templateFieldConfigurationRequestList }).then(
      this.saveItemGroupTemplateCallback.bind(this),
      this.onSaveItemItemGroupErrorHandlew.bind(this)
    );
  }

  saveItemGroup(data, logCheck, protocolItemGroupFieldConfigurationList, parameter, directions, keepEditing) {
    if (this.state.domainElement !== CUSTOM) {
      const request = this.getRequest(data, logCheck, protocolItemGroupFieldConfigurationList, directions);
      FormBuilderApi.saveFormDomain(parameter, request).then(
        response => this.saveItemGroupCallback(response, keepEditing),
        this.onSaveItemItemGroupErrorHandlew.bind(this)
      );
    } else {
      const request = {
        customDomainMaster: getCustomDomainMasterWithPopulatedCustomItemDefinitionType(data),
        ...(logCheck ? { logCheck: logCheck } : {}),
        ...(protocolItemGroupFieldConfigurationList
          ? { protocolItemGroupFieldConfigurationList: protocolItemGroupFieldConfigurationList }
          : {}),
        ...(directions ? { directions: directions } : {})
      };
      FormBuilderApi.saveCustomDomain(parameter, request).then(
        res => {
          if (+res.status === 200 && +res.data.responsecode === 200) {
            this.redirectAfterSaveAndShowSuccessNotification(keepEditing, res.data.response);
          } else if (+res.data.responsecode === 400) {
            NotificationManager.error(res.data.custommessages && res.data.custommessages.elementName);
          } else {
            NotificationManager.error(SOMETHING_WENT_WRONG);
          }
        },
        error => {
          if (error.response && +error.response.status === 404) {
            NotificationManager.warning(
              error.response.data && error.response.data.apierror && error.response.data.apierror.message
            );
          } else {
            NotificationManager.error(SOMETHING_WENT_WRONG);
          }
        }
      );
    }
  }

  onSaveItemItemGroupErrorHandlew(error) {
    if (error.response && +error.response.status === 404) {
      NotificationManager.warning(
        error.response.data && error.response.data.apierror && error.response.data.apierror.message
      );
    }
    if (error.response && +error.response.status === 400) {
      NotificationManager.error(error.response.data && error.response.data.message);
    } else {
      NotificationManager.error(SOMETHING_WENT_WRONG);
    }
  }

  saveItemGroupCallback = (res, keepEditing) => {
    if (+res.status === 200 && +res.data.responsecode === 200) {
      this.redirectAfterSaveAndShowSuccessNotification(keepEditing, res.data.response);
    } else if (+res.data.responsecode === 400 && res.data.custommessages) {
      Object.keys(res.data.custommessages).forEach(messageName =>
        NotificationManager.error(res.data.custommessages && res.data.custommessages[messageName])
      );
    } else {
      NotificationManager.error(SOMETHING_WENT_WRONG);
    }
  };

  saveItemGroupTemplateCallback(res) {
    if (+res.status === 200) {
      this.redirectAfterSaveAndShowSuccessNotification();
    } else {
      NotificationManager.error(SOMETHING_WENT_WRONG);
    }
  }

  getRequest(data, logCheck, protocolItemGroupFieldConfigurationList, directions) {
    let informedConsentTemplate = {};
    if (data.domainCode === 'ICF') {
      informedConsentTemplate = {
        typeId: this.state.informedConsentType?.id,
        languageId: this.state.informedConsentLanguage?.id,
        protocolId: this.props.params?.protocolIdentity
      };
    }
    return {
      formDomainMaster: {
        ...data,
        type: this.state.domainElement !== CUSTOM ? OPTIONAL.toUpperCase() : CUSTOM.toUpperCase()
      },
      ...(informedConsentTemplate
        ? {
            informedConsentTemplate: informedConsentTemplate
          }
        : {}),
      ...(logCheck ? { logCheck: logCheck } : {}),
      ...(protocolItemGroupFieldConfigurationList
        ? { protocolItemGroupFieldConfigurationList: protocolItemGroupFieldConfigurationList }
        : {}),
      ...(directions ? { directions: directions } : {})
    };
  }

  redirectAfterSaveAndShowSuccessNotification(keepEditing, element_identifier) {
    NotificationManager.success(this.isTemplateSetup || this.isTemplateCopy ? TEMPLATE_CREATED : DATA_SAVED);
    this.redirectAfterSave(keepEditing, element_identifier);
  }

  redirectAfterSave(keepEditing = false, element_identifier) {
    const { navigate, currentRoute } = this.props;
    navigate(generateRedirectUrl(currentRoute, keepEditing, element_identifier));
  }

  validateLogCheck = () => {
    if (this.state.isLogType) {
      if (isEmpty(this.state.logCheckTemplate.instruction)) {
        return 'Instruction is required.';
      } else if (isArray(this.state.logCheckTemplate.rows) && this.state.logCheckTemplate.rows.length === 0) {
        return 'At least 1 log check option should be added.';
      }
    }
    return '';
  };

  onSaveData = keepEditing => {
    const {
      showForm,
      displayTableType,
      domain,
      elementIcon,
      domainElement,
      elementName,
      isLogType,
      inputs
    } = this.state;
    if (domainElement && domainElement !== SELECT && elementName && domainElement && elementIcon !== SELECT) {
      const getSortedBySequenceFields = common.getSortedBySequenceFields(this.previewDataList.labelList);
      const previewShowObj = {
        ...this.previewDataList,
        labelList: getSortedBySequenceFields
      };
      const isSequenceEmpty =
        previewShowObj &&
        previewShowObj.labelList &&
        previewShowObj.labelList.filter(item => {
          return item.sequence === '';
        });
      const valueArr =
        previewShowObj &&
        previewShowObj.labelList &&
        previewShowObj.labelList.map(function(item) {
          return item.sequence;
        });
      const isDuplicate =
        valueArr &&
        valueArr.some(function(item, idx) {
          return valueArr.indexOf(item) !== idx;
        });

      this.isAnyItemQuestionCheck =
        previewShowObj &&
        previewShowObj.labelList &&
        previewShowObj.labelList.filter(item => {
          return item.isChecked && item.isChecked === true;
        });

      this.logTypeDataArray =
        previewShowObj &&
        previewShowObj.labelList &&
        previewShowObj.labelList.filter(item => {
          return item.isTable && item.isTable === true;
        });

      //TODO perform validation in one place. In case No errors saveAPIForElementSetup. I am not able to read this.
      if (isSequenceEmpty && isSequenceEmpty.length > 0) {
        NotificationManager.error(SEQUENCE_BLANK_ERROR);
      } else if (!isDuplicate) {
        if (showForm) {
          if (this.isAnyItemQuestionCheck.length > 0) {
            if (isLogType || displayTableType) {
              const validationMessage = this.validateLogCheck();
              if (!isEmpty(validationMessage)) {
                NotificationManager.error(validationMessage);
              } else {
                if (this.logTypeDataArray.length > 0 || isLogType) {
                  if (displayTableType) {
                    if (inputs[0] && inputs[0].predefinedColumnName && inputs[0].predefinedColumnValues) {
                      const isDuplicatePredefinedValue = checkForDuplicate(inputs[0].predefinedColumnValues);
                      if (isDuplicatePredefinedValue) {
                        NotificationManager.error(DUPLICATE_PREDEFINED_COLUMN_VALUES_NOT_ALLOWED);
                      } else {
                        this.saveAPIForElementSetup(keepEditing === true);
                      }
                    } else {
                      NotificationManager.error(FIELD_REQUIRED_FOR_TABLE_TYPE_ERROR);
                    }
                  } else {
                    this.saveAPIForElementSetup(keepEditing === true);
                  }
                } else {
                  NotificationManager.error('Please select at least one item for Logs Type');
                }
              }
            } else if (elementIcon === 'Table') {
              if (inputs[0] && inputs[0].predefinedColumnName && inputs[0].predefinedColumnValues) {
                this.saveAPIForElementSetup(keepEditing === true);
              } else {
                NotificationManager.error(FIELD_REQUIRED_FOR_TABLE_TYPE_ERROR);
              }
            } else {
              this.saveAPIForElementSetup(keepEditing === true);
            }
          } else {
            if (domainElement === CUSTOM || domain === EREM || domain === ECKL) {
              NotificationManager.error('Please add at least one item to complete the form setup');
            } else {
              NotificationManager.error('Please select at least one item to complete the form setup');
            }
          }
        } else {
          this.saveAPIForElementSetup(keepEditing === true);
        }
      } else {
        NotificationManager.error(UNIQUE_SEQUENCE_ERROR);
      }
    } else {
      NotificationManager.error(FILL_REQUIRED);
    }
  };

  onChangeText = (option, type) => {
    if (option && option.name !== SELECT && !this.editMode) {
      if (type === 'icon') {
        this.resetTableViewListIfNecessary(option);
        this.setState({
          initElementIcon: option,
          elementIcon: option.name,
          displayTableType: option.name === TABLE,
          hideIsTableOptions:
            option.name === PROCEDURE ||
            this.isElligoPatientStatusDomain() ||
            this.isStipendItemGroupDomain(this.state.domain),
          isLogType: option.name === LOGS,
          predefinedColumnName: '',
          predefinedColumnValues: ''
        });
      }
    }
  };

  resetTableViewListIfNecessary(option) {
    return (
      option.name === PROCEDURE &&
      this.setState(prevState => {
        prevState.combinedLabelList.map(element => {
          if (element.isTable) {
            element.isTable = false;
          }
          return element;
        });
      })
    );
  }

  onChangeElementName = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getPreviewViewData = prevSt => {
    const formData = prevSt && JSON.parse(JSON.stringify(prevSt));
    if (formData) {
      mapItemGroupDomainResponseToPreviewDto(formData);
      this.previewDataList = formData;
    } else {
      this.previewDataList.labelList = [];
    }
    if (this.previewDataList.hasOwnProperty(OPTIONAL)) {
      delete this.previewDataList.optional;
    }
    if (this.previewDataList.hasOwnProperty(CUSTOM.toLowerCase())) {
      delete this.previewDataList.custom;
    }
  };

  onChangePredefinedFields = (e, type) => {
    const prevSt = this.state.inputs;
    if (type === 'name') {
      prevSt[0].predefinedColumnName = e.target.value;
    } else {
      prevSt[0].predefinedColumnValues = e.target.value && e.target.value.split(',');
    }
    this.setState({
      inputs: prevSt
    });
  };

  updateLogCheckInstruction = logCheckInstruction => {
    this.setState(prevState => ({
      logCheckTemplate: {
        ...prevState.logCheckTemplate,
        instruction: logCheckInstruction
      }
    }));
  };

  updateLogCheckOptions = logCheckOptions => {
    this.setState(prevState => ({
      logCheckTemplate: {
        ...prevState.logCheckTemplate,
        rows: logCheckOptions
      }
    }));
  };

  updateDirections = directionsOptions => {
    this.setState(prevState => ({
      ...prevState,
      directions: directionsOptions
    }));
  };

  render() {
    const {
      displayTableType,
      showForm,
      studyName,
      inputs,
      iconList,
      protocolName,
      domain,
      domainConditionalLogic,
      initElementIcon,
      logCheckOptions,
      elementIcon,
      elementName,
      logCheckTemplate,
      initDomainValue,
      isLogType,
      showPreviewMsg,
      hideIsTableOptions,
      sequenceCounter,
      domainId,
      terminologyVersionGroupNumber,
      isGroupAssign
    } = this.state;

    return (
      <div className="element-setup-page">
        <ItemGroupDetails
          isItemGroupTemplateSetup={this.isItemGroupTemplateSetup}
          isItemGroupTemplate={this.isItemGroupTemplate}
          protocolName={protocolName}
          studyName={studyName}
          onSaveData={this.onSaveData}
        />
        <Section>
          <ItemGroupDomainConfiguration
            isItemGroupTemplate={this.isItemGroupTemplate}
            isItemGroupTemplateSetup={this.isItemGroupTemplateSetup}
            isTemplateCopy={this.isTemplateCopy}
            editMode={this.editMode}
            elementName={elementName}
            iconList={iconList}
            initDomainValue={initDomainValue}
            initElementIcon={initElementIcon}
            onChangeElementName={this.onChangeElementName}
            onDataTypeChange={this.onDataTypeChange.bind(this)}
            onChangeText={this.onChangeText}
            updateTerminologyVersion={this.updateTerminologyVersion.bind(this)}
            terminologyVersionGroupNumber={terminologyVersionGroupNumber}
            isGroupAssign={isGroupAssign}
          />
          {showForm && (
            <ItemGroupPreviewOpen
              previewDataList={this.previewDataList}
              displayTableType={displayTableType}
              isLogType={isLogType}
              elementIcon={elementIcon}
              inputs={inputs}
              logCheckTemplate={logCheckTemplate}
              logTypeDataArray={this.logTypeDataArray}
              isAnyItemQuestionCheck={this.isAnyItemQuestionCheck}
              combinedLabelList={this.state.combinedLabelList}
              itemGroupName={this.state.elementName}
              directions={this.state.directions}
            />
          )}
          {this.getNoPreviewContent()}
          {isLogType && (
            <LogCheckSetupNew
              initialOptions={logCheckOptions}
              updateLogCheckOptions={this.updateLogCheckOptions}
              resetTrigger={domain}
            />
          )}
          {isLogType && (
            <DirectionSetup onChangeDirections={this.updateDirections} initialDirections={this.state.directions} />
          )}
          {domain === 'ICF' && (
            <IcfSetup
              informedConsentTemplate={this.state.informedConsentTemplate}
              onChangeLanguage={e => {
                this.setState({ informedConsentLanguage: e });
              }}
              onChangeType={e => {
                this.setState({ informedConsentType: e });
              }}
            />
          )}
          {!showPreviewMsg && showForm && (
            <ItemGroupSetupContent
              domainArray={this.domainArray}
              domain={domain}
              inputs={inputs}
              changeSequenceCounter={this.changeSequenceCounter}
              sequenceCounter={sequenceCounter}
              previewDataList={this.previewDataList}
              displayTableType={displayTableType}
              hideIsTableOptions={hideIsTableOptions}
              isElligoPatientStatusDomain={this.isElligoPatientStatusDomain()}
              onChangePredefinedFields={this.onChangePredefinedFields}
              updateInputsAndPreviewData={(originInputs, callback) =>
                this.updateInputsAndPreviewData(originInputs, callback)
              }
              editMode={this.editMode}
              isLogType={isLogType}
              domainId={domainId}
              domainConditionalLogic={domainConditionalLogic}
              combinedLabelList={this.state.combinedLabelList}
              updateState={callback => this.updateState(callback)}
              setCombinedLabelList={this.setCombinedLabelList}
              terminologyVersionGroupNumber={terminologyVersionGroupNumber}
            />
          )}
        </Section>
      </div>
    );
  }

  isElligoPatientStatusDomain() {
    return this.state.domain === EPS;
  }

  setCombinedLabelList = labelList => {
    if (labelList !== null && labelList !== undefined) {
      this.setState({ combinedLabelList: labelList });
    }
  };

  isStipendItemGroupDomain(domainCode) {
    return domainCode === EPSTP;
  }

  updateInputsAndPreviewData(originInputs, callback) {
    this.setState({ inputs: [...originInputs] }, () => callback && callback());
    getCombinedList(originInputs, false);
    this.getPreviewViewData(originInputs[0]);
  }

  updateState(callback) {
    // this magic rerender the page after changes of link state dependencies in child component
    this.setState(
      prevState => ({ ...prevState }),
      () => {
        callback && callback();
      }
    );
  }
  /**
   * Display block with information if preview is not available
   * Item groups like PI/SM review does not have preview option.
   */
  getNoPreviewContent() {
    if (this.state.showPreviewMsg) {
      return <div className="alert alert-info col-md-12 text-center">{this.state.showPreviewMsg}</div>;
    }
  }
}

function getCustomDomainMasterWithPopulatedCustomItemDefinitionType(data) {
  return {
    ...data,
    labelList: data.labelList.map(label => {
      return {
        ...label,
        customItemDefinationList: (label.customItemDefinationList || []).map(customItem => {
          return {
            ...customItem,
            type: CUSTOM.toUpperCase()
          };
        })
      };
    })
  };
}

function generateRedirectUrl(currentRoute, keepEditing, element_identifier) {
  const { name, key, parent, params } = currentRoute;

  if (keepEditing && element_identifier) {
    const nextPageKey =
      name === 'Item Group Edit'
        ? key
        : name === 'Item Group Setup'
        ? `${key}.Item Group Edit`
        : name === 'Item Group Template Setup'
        ? key.replace(name, '').concat('Item Group Setup.Item Group Edit')
        : null;
    if (nextPageKey) {
      return generateUrlByKey(nextPageKey, { ...params, element_identifier });
    }
  }

  return generateUrlByKey(parent.key, params);
}

const ElementSetupComponent = withCurrentRoute(withNavigate(withParams(ElementSetup)));
export default ElementSetupComponent;
