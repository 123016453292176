import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { isEmpty } from 'lodash/lang';
import moment from 'moment/moment';

import { FinBudgetExpenseFixedApi } from '../../../../../../api';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../../constants/dateFormat';
import { onRequestError } from '../../../../../../services/handlers';

import { ExpenseCommentInput } from './ExpenseCommentInput/ExpenseCommentInput';

import './AddFixedExpense.scss';

export const AddFixedExpense = ({ selectedSSU, setNewFixedExpenseData }) => {
  const [selectedDate, setSelectedDate] = useState(moment().utc(true));
  const [finTriggerExpenseFixed, setFinTriggerExpenseFixed] = useState([]);
  const [finBudgetSite, setFinBudgetSite] = useState(null);
  const [selectedFinTrigger, setSelectedFinTrigger] = useState(null);
  const [eventAmount, setEventAmount] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    setNewFixedExpenseData({
      date: selectedDate,
      finTriggerId: selectedFinTrigger?.id ?? null,
      comment: comment.trim()
    });
  }, [comment, selectedDate, selectedFinTrigger?.id, setNewFixedExpenseData]);

  useEffect(() => {
    setSelectedFinTrigger(null);
    setFinTriggerExpenseFixed([]);
    if (selectedDate?.isValid()) {
      FinBudgetExpenseFixedApi.findTriggersForActualBudget(
        selectedSSU.studyIdentifier,
        selectedSSU.siteIdentifier,
        selectedSSU.ssuIdentifier,
        selectedDate.format('YYYY-MM-DD')
      ).then(({ data: finTriggers }) => {
        setFinTriggerExpenseFixed(
          finTriggers.finTriggerExpenseFixed?.map(trigger => ({
            ...trigger,
            label: trigger.name
          })) ?? []
        );
        setFinBudgetSite(finTriggers.finBudgetSite);
      });
    }
  }, [selectedSSU, selectedDate]);

  useEffect(() => {
    if (selectedSSU?.ssuIdentifier && selectedFinTrigger && finBudgetSite?.finBudget?.id) {
      FinBudgetExpenseFixedApi.getExpenseFixedClientAmount(
        selectedSSU.ssuIdentifier,
        selectedFinTrigger.id,
        finBudgetSite.finBudget.id
      ).then(({ data }) => {
        setEventAmount(`$${data.toLocaleString()}`);
      }, onRequestError);
    } else {
      setEventAmount('');
    }
  }, [selectedSSU, selectedFinTrigger, finBudgetSite]);

  return (
    <div className="add-fixed-expense-container">
      <DesktopDatePicker
        label="Event Date"
        value={selectedDate}
        onChange={newValue => setSelectedDate(newValue)}
        format={DD_SLASH_MMM_SLASH_YYYY}
        slotProps={{
          textField: { variant: 'standard', required: true }
        }}
        localeText={{ fieldMonthPlaceholder: params => (params.contentType === 'letter' ? 'MMM' : 'MM') }}
        inputProps={{ 'data-testid': 'event-date-picker' }}
      />
      <Autocomplete
        noOptionsText={
          !isEmpty(finTriggerExpenseFixed) ? 'No Results Available' : 'No expenses available for this date'
        }
        options={finTriggerExpenseFixed}
        onChange={(event, value) => {
          setSelectedFinTrigger(value);
          setComment('');
        }}
        value={selectedFinTrigger}
        renderInput={params => (
          <TextField
            {...params}
            label="Expense - Fixed Event Name"
            variant="standard"
            required
            data-testid="expence-fixed-event-name"
          />
        )}
      />
      {selectedFinTrigger && (
        <>
          <TextField
            label="Amount"
            variant="standard"
            value={eventAmount}
            disabled
            inputProps={{ 'data-testid': 'amount-input' }}
          />
          <ExpenseCommentInput comment={comment} onChange={({ target: { value } }) => setComment(value)} />
        </>
      )}
    </div>
  );
};
