import React, { Component } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

import { StudyProtocolApi } from '../../../../../api';
import Select from '../../../../../common/data-entry/Select';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';

class CopyProtocol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useLatestTerminologyVersions: true,
      selectedStudy: null,
      studiesWithoutProtocol: []
    };
  }

  componentDidMount() {
    StudyProtocolApi.findAllWithoutProtocol().then(({ data: studiesWithoutProtocol }) => {
      this.setState({ studiesWithoutProtocol });
    });
  }

  handleStudyChange = copyToStudy => {
    this.setState({ selectedStudy: copyToStudy });
    this.props.handleStudyChange(copyToStudy);
  };

  setUseLatestTerminologyVersions = event => {
    this.setState({ useLatestTerminologyVersions: event.target.checked });
  };

  render() {
    const { useLatestTerminologyVersions, selectedStudy, studiesWithoutProtocol } = this.state;
    const { copyProtocol, fromStudy, protocolId } = this.props;

    return (
      <>
        <ModalBoxes.Header>Creating copy of protocol</ModalBoxes.Header>
        <ModalBoxes.Body>
          <FormControl sx={{ display: 'block', mx: -3 }}>
            <Select
              label="Destination study"
              clearSearchOnSelection
              dataSource={studiesWithoutProtocol}
              optionLabelKey="studyName"
              optionValueKey="uniqueIdentifier"
              onChange={this.handleStudyChange}
              searchable
              clearable={false}
              required
            />
            <FormControlLabel
              control={
                <Checkbox checked={useLatestTerminologyVersions} onChange={this.setUseLatestTerminologyVersions} />
              }
              label="Apply latest terminology versions"
            />
          </FormControl>
        </ModalBoxes.Body>
        <ModalBoxes.Footer>
          <div className="eds-btn-group">
            <ButtonGroup>
              <Button priority="medium" onClick={this.props.modalBox.close}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  copyProtocol(fromStudy, protocolId, useLatestTerminologyVersions);
                  this.props.modalBox.close();
                }}
                disabled={!selectedStudy}
              >
                Save
              </Button>
            </ButtonGroup>
          </div>
        </ModalBoxes.Footer>
      </>
    );
  }
}

CopyProtocol.className = 'copy-protocol-modal';
CopyProtocol.size = 'w350';

export default CopyProtocol;
