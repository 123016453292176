import React, { useMemo } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { some } from 'lodash/collection';
import { isNil } from 'lodash/lang';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import TextFieldResolver from '../../../../../../common/RichTextFormat/viewer/TextFieldResolver';

import ConfigurationTable from './ConfigurationTable/ConfigurationTable';
import HideAllFieldsConfiguration from './HideAllFieldsConfiguration/HideAllFieldsConfiguration';
import useConditionalLogicState from './useConditionalLogicState/useConditionalLogicState';
import { generateDataForSave, generateFieldConfigurationResponseByConditions } from './saveService';
import { doMainValidation, oldValidation } from './validationService';

export default function ConditionalLogicSetupModal({ modalBox, data, onSave }) {
  const { fieldLabel, styledFieldLabel, inputType, allAccessibleFields, isNewTable } = data;
  const {
    enabled,
    domainTargetFields,
    conditions,
    domainConditions,
    conditionsMap,
    domainConditionsMap,
    hideAllFieldsConfig,
    setEnabled,
    setConditions,
    setHideAllFieldsConfig
  } = useConditionalLogicState(data);

  const hasHideAllFields = useMemo(
    function() {
      return some(data.conditions, 'hideAllFields');
    },
    [data.conditions]
  );

  const hasDomainConditions = useMemo(
    function() {
      return data.domainConditions?.length > 0;
    },
    [data.domainConditions]
  );

  return (
    <>
      <ModalBoxes.Header>Edit Conditional Logic</ModalBoxes.Header>
      <ModalBoxes.Body>
        <HideAllFieldsConfiguration
          hideAllFieldsConfig={hideAllFieldsConfig}
          setHideAllFieldsConfig={setHideAllFieldsConfig}
        />
        <Typography variant="body1" gutterBottom>
          Hide/Show{' '}
          <b>
            <TextFieldResolver
              fieldType={inputType}
              fieldName={fieldLabel}
              styledFieldName={styledFieldLabel}
              className="condition-field"
            />
          </b>{' '}
          field based on condition(s)?
        </Typography>
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={enabled}
          exclusive
          fullWidth
          sx={{ width: 130 }}
          disabled={hasHideAllFields || hasDomainConditions}
          onChange={function(event, value) {
            if (isNil(value)) {
              return;
            }
            setEnabled(value);
          }}
        >
          <ToggleButton value={false}>No</ToggleButton>
          <ToggleButton value={true}>Yes</ToggleButton>
        </ToggleButtonGroup>
        {enabled && (
          <ConfigurationTable
            allAccessibleFields={allAccessibleFields}
            domainTargetFields={domainTargetFields}
            conditions={conditions}
            domainConditions={domainConditions}
            conditionsMap={conditionsMap}
            domainConditionsMap={domainConditionsMap}
            setConditions={setConditions}
            multiple={isNewTable}
            hasDomainConditions={hasDomainConditions}
          />
        )}
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            CANCEL
          </Button>
          <Button
            disabled={false}
            onClick={function() {
              doMainValidation(conditions, domainTargetFields).then(
                function() {
                  if (isNewTable) {
                    onSave(generateDataForSave(conditions, allAccessibleFields, hideAllFieldsConfig));
                    return;
                  }
                  if (oldValidation(conditions, allAccessibleFields, fieldLabel)) {
                    onSave(generateFieldConfigurationResponseByConditions(conditions, allAccessibleFields));
                  }
                },
                function(error) {
                  if (error?.message) {
                    NotificationManager.error(error?.message);
                  }
                }
              );
            }}
          >
            SAVE
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

ConditionalLogicSetupModal.className = 'conditional-logic-setup-modal';
ConditionalLogicSetupModal.size = 'w950';
