import { useContext } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';

import { SiteFinanceFilterContext } from '../../../SiteFinanceContext';

export const EventStatusSelector = () => {
  const { filterData, setFewFilterProperty } = useContext(SiteFinanceFilterContext);
  return (
    <Box display="flex" alignItems="center" marginTop="10px">
      <Typography sx={{ marginRight: '16px' }}>Event Status:</Typography>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={filterData.isAll}
              onChange={({ target: { checked } }) => {
                const newFilters = checked
                  ? { isAll: checked, isInvoiced: checked, isPaid: checked }
                  : { isAll: checked };
                setFewFilterProperty(newFilters);
              }}
            />
          }
          label="All"
          sx={{ marginBottom: 0 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filterData.isInvoiced}
              onChange={({ target: { checked } }) => {
                const newFilters = !checked ? { isAll: checked, isInvoiced: checked } : { isInvoiced: checked };
                setFewFilterProperty(newFilters);
              }}
            />
          }
          label="Invoiced"
          sx={{ marginBottom: 0 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filterData.isPaid}
              onChange={({ target: { checked } }) => {
                const newFilters = !checked ? { isAll: checked, isPaid: checked } : { isPaid: checked };
                setFewFilterProperty(newFilters);
              }}
            />
          }
          label="Paid"
          sx={{ marginBottom: 0 }}
        />
      </FormGroup>
    </Box>
  );
};
