import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { orderBy } from 'lodash';

import StatusApiService from '../../../../../../api/patient/PatientStatusApi';
import Select from '../../../../../../common/data-entry/Select/Select';
import TextArea from '../../../../../../common/data-entry/TextArea/TextArea';
import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../common/general/ButtonGroup';

import './PatientStatusChangeModal.scss';

const PatientStatusChangeModal = ({ modalBox, initialStatus, allowedStatusesPredicate, updateStatus }) => {
  const [statuses, setStatuses] = useState([]);

  const { handleSubmit, errors, control, watch, setValue } = useForm({
    defaultValues: { status: initialStatus, reason: null, comment: '' }
  });
  const status = watch('status');
  const reason = watch('reason');

  const update = ({ status, reason, comment }) => updateStatus(status, reason, comment);

  useEffect(() => {
    StatusApiService.getAllStatuses().then(({ data: allStatuses }) => {
      const initialStatusWithDropStatusFlag = allStatuses.find(s => s.name === initialStatus?.name);
      setValue('status', initialStatusWithDropStatusFlag);
      const statusesToChoseFrom = allowedStatusesPredicate ? allStatuses.filter(allowedStatusesPredicate) : allStatuses;
      setStatuses(orderBy(statusesToChoseFrom, [status => status.name.toLowerCase()], ['asc']));
    });
  }, [setValue, allowedStatusesPredicate, initialStatus]);

  const isPsfOtherReason = status?.id === 'PRE-SCREEN FAILED' && reason?.name === 'Other';
  const isCommentRequired = (status?.dropStatus && status?.id !== 'PRE-SCREEN FAILED') || isPsfOtherReason;

  return (
    <form id="change-status-from" onSubmit={handleSubmit(update)}>
      <ModalBoxes.Header>Withdraw patient from study</ModalBoxes.Header>
      <ModalBoxes.Body>
        <Controller
          as={Select}
          control={control}
          name={'status'}
          rules={{ required: true }}
          required={true}
          validationMessage={errors.status ? 'Status is required.' : undefined}
          className={'psc-fields select-status'}
          label="Status"
          clearSearchOnSelection={true}
          searchable
          clearable={false}
          closeOnSelectedOptionClick={true}
          deselectOnSelectedOptionClick={false}
          dataSource={statuses}
        />
        {status?.dropStatus && (
          <Controller
            data-testid="reason-select"
            as={Select}
            control={control}
            name={'reason'}
            rules={{ required: true }}
            required={true}
            validationMessage={errors.reason ? 'Reason is required.' : undefined}
            className={'psc-fields select-reason'}
            label="Reason"
            clearSearchOnSelection={true}
            clearable={false}
            searchable
            closeOnSelectedOptionClick={true}
            deselectOnSelectedOptionClick={false}
            dataSource={status?.reasons}
          />
        )}
        <Controller
          as={TextArea}
          control={control}
          rules={{ required: isCommentRequired }}
          required={isCommentRequired}
          validationMessage={errors.comment && isCommentRequired ? 'Comment is required.' : undefined}
          style={{ resize: 'none' }}
          rows={1}
          clearable
          label="Comment"
          name="comment"
        />
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            Cancel
          </Button>
          <Button form="change-status-from">Save</Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </form>
  );
};

PatientStatusChangeModal.className = 'patient-status-change-modal';
PatientStatusChangeModal.size = 'w650';

export default PatientStatusChangeModal;
