import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isArray, isEmpty, isEqual, isString, isUndefined } from 'lodash/lang';

import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { toCoins } from '../../../../../../../services/financial';
import { BUDGET_EVENT_NAME_LENGTH } from '../../budgetConstant';
import { calculateSiteAmount } from '../../services';
import BudgetEventControlButtons from '../BudgetEventControlButtons';
import { DIRECT_COST, PASS_THRU } from '../ExpenseType';
import { UpdatedBudgetEventForm } from '../UpdatedBudgetEventForm';

import './AddOrEditExpenseFixedBudgetEvent.scss';

const maxCount = Number.MAX_SAFE_INTEGER;

const AddOrEditExpenseFixedBudgetEvent = ({
  data,
  budgetEventType,
  vendors,
  managementFeePercent,
  onSave,
  modalBox,
  currentStudyBudget
}) => {
  const { groupId, name, overhead, withholding, managementFee, vendorId, eventRows, nonInvoiceable } = data || {};

  const initialVendorId = useMemo(() => vendorId || 'NOT_SELECTED', [vendorId]);

  const emptyRow = useMemo(
    () => ({
      id: null,
      countFrom: 1,
      countTo: maxCount,
      clientAmount: 0,
      siteAmount: 0,
      siteAmountType: PASS_THRU,
      vendorAmount: 0,
      vendorAmountType: null
    }),
    []
  );

  const initialState = useMemo(() => {
    const oldEmptySiteAmountTypeFilling = eventRows => {
      eventRows.filter(e => e.siteAmountType === null).forEach(e => (e.siteAmountType = PASS_THRU));
      return [...eventRows];
    };
    return {
      isEdit: false,
      groupId: groupId,
      name: name || '',
      overhead: !!overhead,
      withholding: !!withholding,
      managementFee: isUndefined(managementFee) ? +managementFeePercent !== 0 : managementFee,
      selectedVendorId: initialVendorId,
      eventRows: isArray(eventRows) ? oldEmptySiteAmountTypeFilling(eventRows) : [emptyRow],
      showOverlapsErrorMsg: isArray(eventRows) ? new Array(eventRows.length).fill(false) : [false],
      nonInvoiceable: !!nonInvoiceable
    };
  }, [
    emptyRow,
    eventRows,
    groupId,
    initialVendorId,
    managementFee,
    managementFeePercent,
    name,
    nonInvoiceable,
    overhead,
    withholding
  ]);

  const [modalState, setModalState] = useState(initialState);

  useEffect(() => {
    if (modalState.managementFee) {
      setModalState(prevState => {
        const updatedEventRows = prevState.eventRows.map(budgetEvent => ({
          ...budgetEvent,
          siteAmount: calculateSiteAmount(budgetEvent.clientAmount, managementFeePercent, true)
        }));
        return {
          ...prevState,
          eventRows: !isEqual(updatedEventRows, prevState.eventRows) ? updatedEventRows : prevState.eventRows
        };
      });
    }
  }, [modalState.managementFee, managementFeePercent, modalState.eventRows]);

  const checkOverlappedFields = useCallback(idx => {
    setModalState(prevState => {
      const currentRow = prevState.eventRows[idx];
      if (currentRow) {
        const isValidRange =
          currentRow.countFrom <= currentRow.countTo ||
          (idx === prevState.eventRows.length - 1 && currentRow.countTo === undefined);
        const noOverlaps = prevState.eventRows
          .filter((range, i) => i !== idx)
          .every(range => range.countTo < currentRow.countFrom || range.countFrom > currentRow.countTo);
        const isFromOneIfFirstRow = idx !== 0 || currentRow.countFrom === 1;
        const noPrevGap = idx === 0 || prevState.eventRows[idx - 1].countTo + 1 === currentRow.countFrom;
        const noNextGap =
          idx === prevState.eventRows.length - 1 || currentRow.countTo + 1 === prevState.eventRows[idx + 1].countFrom;

        const isValid = !(isValidRange && noOverlaps && isFromOneIfFirstRow && noPrevGap && noNextGap);
        return {
          ...prevState,
          showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.map((el, index) => (index === idx ? isValid : el))
        };
      } else {
        return {
          ...prevState,
          showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.map((el, index) => (index === idx ? false : el))
        };
      }
    });
  }, []);

  const handleAddEventRow = useCallback(() => {
    const newRows = modalState.eventRows.concat([
      modalState.selectedVendorId === 'NOT_SELECTED'
        ? emptyRow
        : {
            ...emptyRow,
            vendorAmountType: DIRECT_COST
          }
    ]);
    setModalState(prevState => ({
      ...prevState,
      eventRows: newRows,
      showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.concat([false])
    }));
    newRows.forEach((elem, index) => checkOverlappedFields(index));
  }, [checkOverlappedFields, emptyRow, modalState.eventRows, modalState.selectedVendorId]);

  const handleRemoveEventRow = useCallback(
    idx => () => {
      setModalState(prevState => ({
        ...prevState,
        eventRows: prevState.eventRows.filter((row, rowIdx) => idx !== rowIdx),
        showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.filter((item, itemIdx) => idx !== itemIdx)
      }));
      modalState.showOverlapsErrorMsg.forEach((elem, index) => checkOverlappedFields(index));
    },
    [checkOverlappedFields, modalState.showOverlapsErrorMsg]
  );

  const onChangeEventRow = useCallback(
    idx => evt => {
      const targetBaseName = evt.target.name.split('_')[0];
      setModalState(prevState => {
        const newEventRows = prevState.eventRows.map((eventRow, index) => {
          if (index === idx) {
            return {
              ...eventRow,
              [targetBaseName]:
                targetBaseName === 'countTo' && isUndefined(evt.target.value) ? maxCount : evt.target.value
            };
          }
          return eventRow;
        });
        return { ...prevState, eventRows: newEventRows };
      });
      if (targetBaseName === 'countFrom' || targetBaseName === 'countTo') {
        modalState.showOverlapsErrorMsg.forEach((elem, index) => checkOverlappedFields(index));
      }
    },
    [checkOverlappedFields, modalState.showOverlapsErrorMsg]
  );

  const revertOrSetUpVendorRadioButtons = useCallback(() => {
    setModalState(prevState => {
      const { eventRows, selectedVendorId } = prevState;
      if (selectedVendorId !== 'NOT_SELECTED' && eventRows.every(e => !e.vendorAmountType)) {
        const updatedEventRows = eventRows.map(e => ({ ...e, vendorAmountType: DIRECT_COST }));
        return { ...prevState, eventRows: updatedEventRows };
      } else if (selectedVendorId === 'NOT_SELECTED' && eventRows.some(e => !!e.vendorAmountType)) {
        const updatedEventRows = eventRows.map(e => ({ ...e, vendorAmountType: null }));
        return { ...prevState, eventRows: updatedEventRows };
      }
      return prevState;
    });
  }, []);

  const onChangeFormControls = useCallback(
    ({ target }) => {
      if (target.type === 'checkbox') {
        if (target.name === 'managementFee') {
          setModalState(prevState => ({
            ...prevState,
            managementFee: !prevState.managementFee,
            eventRows: prevState.managementFee
              ? prevState.eventRows.map(budgetEvent => ({
                  ...budgetEvent,
                  siteAmount: 0
                }))
              : prevState.eventRows
          }));
        } else {
          setModalState(prevState => ({ ...prevState, [target.name]: target.checked }));
        }
      } else {
        setModalState({
          ...modalState,
          [target.name]: target.value
        });
        revertOrSetUpVendorRadioButtons();
      }
    },
    [modalState, revertOrSetUpVendorRadioButtons]
  );

  const save = useCallback(() => {
    const { name, overhead, withholding, managementFee, selectedVendorId, nonInvoiceable } = modalState;
    const data = new Array(modalState.eventRows.length).fill().map((e, i) => {
      const curRow = modalState.eventRows[i];
      return {
        id: curRow.id,
        name: name.trim(),
        overhead,
        withholding,
        managementFee,
        nonInvoiceable,
        clientAmount: toCoins(curRow.clientAmount),
        siteAmount: toCoins(curRow.siteAmount),
        finVendorId: selectedVendorId !== 'NOT_SELECTED' ? vendors.find(v => v.id === selectedVendorId).id : null,
        vendorAmount: selectedVendorId !== 'NOT_SELECTED' ? toCoins(curRow.vendorAmount) : 0,
        type: budgetEventType,
        vendorAmountType: curRow.vendorAmountType,
        siteAmountType: curRow.siteAmountType,
        countFrom: curRow.countFrom,
        countTo: curRow.countTo === maxCount ? null : curRow.countTo
      };
    });
    return onSave(data, modalState.groupId);
  }, [budgetEventType, modalState, onSave, vendors]);

  const onSaveButton = useCallback(() => {
    save();
    modalBox.close();
  }, [modalBox, save]);

  const onSaveAndContinue = useCallback(() => {
    save().then(() => setModalState(initialState));
  }, [initialState, save]);

  const isEditPossible = useCallback(() => modalState.isEdit, [modalState.isEdit]);

  const isValidForm = useCallback(() => {
    let isValidated = false;
    const { name, selectedVendorId, showOverlapsErrorMsg } = modalState;
    for (const curRow of modalState.eventRows) {
      const { clientAmount, countFrom, vendorAmount, siteAmount } = curRow;
      isValidated =
        isString(name) &&
        name.trim() !== '' &&
        clientAmount >= 0 &&
        countFrom > 0 &&
        showOverlapsErrorMsg.every(curState => !curState) &&
        (!siteAmount || siteAmount >= 0) &&
        (selectedVendorId !== 'NOT_SELECTED' ? vendorAmount > 0 : true) &&
        name.length <= BUDGET_EVENT_NAME_LENGTH;
      if (!isValidated) {
        return false;
      }
    }
    return isValidated;
  }, [modalState]);

  return (
    <>
      <ModalBoxes.Body>
        <div className="bem-form container-fluid d-flex">
          <UpdatedBudgetEventForm
            isEventRanges={true}
            isEdit={isEditPossible}
            name={modalState.name}
            overhead={modalState.overhead}
            withholding={modalState.withholding}
            managementFee={modalState.managementFee}
            managementFeePercent={managementFeePercent}
            finVendors={vendors}
            selectedVendorId={modalState.selectedVendorId}
            budgetEventType={budgetEventType}
            currentStudyBudget={currentStudyBudget}
            eventRows={modalState.eventRows}
            maxCount={maxCount}
            showOverlapsErrorMsg={modalState.showOverlapsErrorMsg}
            onChangeFormControls={onChangeFormControls}
            onChangeEventRow={onChangeEventRow}
            handleAddEventRow={handleAddEventRow}
            handleRemoveEventRow={handleRemoveEventRow}
            nonInvoiceable={modalState.nonInvoiceable}
          />
        </div>
      </ModalBoxes.Body>
      <BudgetEventControlButtons
        isValidForm={isValidForm}
        onClose={modalBox.close}
        onSave={onSaveButton}
        onSaveAndContinue={isEmpty(data) ? onSaveAndContinue : null}
        currentStudyBudget={currentStudyBudget}
      />
    </>
  );
};

AddOrEditExpenseFixedBudgetEvent.title = 'Add Expense - Fixed budget event';
AddOrEditExpenseFixedBudgetEvent.className = 'add-expense-fixed-modal';

export default AddOrEditExpenseFixedBudgetEvent;
