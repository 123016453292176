import React from 'react';

import { CREDIT_MEMO, DEBIT_MEMO } from '../../../InvoiceTable/TableConstants';

import { Balance } from './InvoiceItems/Balance';
import { BalanceCreditDebit } from './InvoiceItems/BalanceCreditDebit';
import { DefaultItem } from './InvoiceItems/DefaultItem';
import { Expense } from './InvoiceItems/Expense';
import { Header } from './InvoiceItems/Header';
import { ReimbursementItem } from './InvoiceItems/ReimbursementItem';
import { SubHeader } from './InvoiceItems/SubHeader';
import { SubItem } from './InvoiceItems/SubItem';

export const InvoiceItemsList = ({ items, invoiceType }) => {
  return items.map((item, index) => {
    if (item?.typeHeader) {
      return <Header item={item} index={index} key={`${item.groupName}${index}`} />;
    }
    if (item?.typeSubHeader) {
      return <SubHeader item={item} key={`${item.subGroupName}${index}`} />;
    }
    if (['Expense - Fixed', 'Expense - Variable'].includes(item.budgetEventType)) {
      return <Expense item={item} key={item.id + index} />;
    }
    if (item.templateRowType === 'REIMBURSEMENT_ITEM') {
      return <ReimbursementItem item={item} key={item.id + index} />;
    }
    if (item?.typeBalance) {
      return [CREDIT_MEMO, DEBIT_MEMO].includes(invoiceType) ? (
        <BalanceCreditDebit item={item} key={item.balanceDue + index} />
      ) : (
        <Balance item={item} key={item.balanceDue + index} />
      );
    }
    if (item.typeSubItem) {
      return <SubItem item={item} key={item.id + index} />;
    }

    return <DefaultItem item={item} key={item.id + index} />;
  });
};
