import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isArray, isEmpty, isEqual, isString, isUndefined } from 'lodash/lang';

import { FinVendorApi, PatientStatusApi } from '../../../../../../../api';
import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { INVALID_DATA_LOADED } from '../../../../../../../constants/notificationMessages';
import { toCoins } from '../../../../../../../services/financial';
import { onRequestError } from '../../../../../../../services/handlers';
import { BUDGET_EVENT_NAME_LENGTH } from '../../budgetConstant';
import { calculateSiteAmount } from '../../services';
import BudgetEventControlButtons from '../BudgetEventControlButtons';
import { DIRECT_COST, PASS_THRU } from '../ExpenseType';
import { NumberInput } from '../help-component/NumberInput';
import { StatusChangeRows } from '../help-component/StatusChangeRows';

import './AddOrEditStatusChangeEventModal.scss';

const maxCount = Number.MAX_SAFE_INTEGER;

export const AddOrEditStatusChangeEventModal = ({
  data,
  isEditingForbidden,
  triggerType,
  modalBox,
  currentStudyBudget,
  budgetEventType,
  managementFeePercent,
  onSave
}) => {
  const {
    name,
    overhead,
    withholding,
    encounterOverride,
    eventRows,
    vendorId,
    groupId,
    patientStatusId,
    primaryStatusRatio,
    secondaryPatientStatusId,
    secondaryStatusRatio,
    managementFee,
    nonInvoiceable
  } = data || {};

  const initialVendorId = useMemo(() => vendorId || 'NOT_SELECTED', [vendorId]);

  const emptyRow = useMemo(() => {
    return {
      id: null,
      countFrom: 1,
      countTo: maxCount,
      clientAmount: 0,
      siteAmount: 0,
      vendorAmount: 0,
      vendorAmountType: null,
      siteAmountType: PASS_THRU
    };
  }, []);

  const initialState = useMemo(
    () => ({
      name: name || '',
      overhead: overhead || false,
      withholding: withholding || false,
      encounterOverride: encounterOverride || false,
      patientStatusId: patientStatusId ?? 'NOT_SELECTED',
      selectedVendorId: initialVendorId,
      secondaryPatientStatusId: secondaryPatientStatusId ?? 'NOT_SELECTED',
      primaryStatusRatio: primaryStatusRatio ?? 0,
      secondaryStatusRatio: secondaryStatusRatio ?? 0,
      groupId: groupId ?? null,
      eventRows: isArray(eventRows)
        ? eventRows.map(el => ({ ...el, siteAmountType: el.siteAmountType === null ? PASS_THRU : el.siteAmountType }))
        : [emptyRow],
      showOverlapsErrorMsg: isArray(eventRows) ? new Array(eventRows.length).fill(false) : [false],
      managementFee: isUndefined(managementFee) ? +managementFeePercent !== 0 : managementFee,
      nonInvoiceable: !!nonInvoiceable
    }),
    [
      name,
      overhead,
      withholding,
      encounterOverride,
      patientStatusId,
      initialVendorId,
      secondaryPatientStatusId,
      primaryStatusRatio,
      secondaryStatusRatio,
      groupId,
      eventRows,
      emptyRow,
      managementFee,
      managementFeePercent,
      nonInvoiceable
    ]
  );

  const [modalState, setModalState] = useState({
    ...initialState,
    statuses: [],
    finVendors: []
  });

  useEffect(() => {
    if (modalState.managementFee) {
      setModalState(prevState => {
        const updatedEventRows = prevState.eventRows.map(budgetEvent => ({
          ...budgetEvent,
          siteAmount: calculateSiteAmount(budgetEvent.clientAmount, managementFeePercent, true)
        }));
        return {
          ...prevState,
          eventRows: !isEqual(updatedEventRows, prevState.eventRows) ? updatedEventRows : prevState.eventRows
        };
      });
    }
  }, [modalState.managementFee, managementFeePercent, modalState.eventRows]);

  useEffect(() => {
    PatientStatusApi.getAllStatuses().then(({ data: statuses }) => {
      const normalizedStatuses = statuses.filter(status => status.id !== 'TRANSFERRING');
      setModalState(prevState => ({
        ...prevState,
        statuses: [
          {
            id: 'NOT_SELECTED',
            name: 'Select status'
          },
          ...normalizedStatuses
        ]
      }));
    }, onRequestError);

    FinVendorApi.findAllEnabled().then(res => {
      if (res && isArray(res.data)) {
        setModalState(prevState => ({
          ...prevState,
          finVendors: [{ id: 'NOT_SELECTED', name: 'Select vendor' }, ...res.data]
        }));
      } else {
        NotificationManager.error(INVALID_DATA_LOADED);
      }
    }, onRequestError);
  }, []);

  const checkOverlappedFields = useCallback(idx => {
    setModalState(prevState => {
      const currentRow = prevState.eventRows[idx];
      let isRowValid = true;
      if (currentRow) {
        const isFromEmpty = isUndefined(currentRow.countFrom);
        const isFromPositive = currentRow.countFrom > 0;
        const isFromLessOrEqualsTo = currentRow.countFrom <= currentRow.countTo;
        const rangesDoNotOverlap = prevState.eventRows
          .filter((range, i) => i !== idx)
          .every(range => range.countTo < currentRow.countFrom || range.countFrom > currentRow.countTo);
        isRowValid = !isFromEmpty && isFromPositive && isFromLessOrEqualsTo && rangesDoNotOverlap;
      }
      const updatedShowOverlapsErrorMsg = prevState.showOverlapsErrorMsg.map((item, index) =>
        index === idx ? !isRowValid : item
      );
      return { ...prevState, showOverlapsErrorMsg: updatedShowOverlapsErrorMsg };
    });
  }, []);

  const handleAddEventRow = useCallback(() => {
    const newRows = modalState.eventRows.concat([
      modalState.selectedVendorId === 'NOT_SELECTED'
        ? emptyRow
        : {
            ...emptyRow,
            vendorAmountType: DIRECT_COST
          }
    ]);
    setModalState(prevState => ({
      ...prevState,
      eventRows: newRows,
      showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.concat([false])
    }));
    newRows.forEach((elem, index) => checkOverlappedFields(index));
  }, [checkOverlappedFields, emptyRow, modalState.eventRows, modalState.selectedVendorId]);

  const handleRemoveEventRow = useCallback(
    idx => () => {
      setModalState(prevState => ({
        ...prevState,
        eventRows: prevState.eventRows.filter((row, rowIdx) => idx !== rowIdx),
        showOverlapsErrorMsg: prevState.showOverlapsErrorMsg.filter((item, itemIdx) => idx !== itemIdx)
      }));
      modalState.showOverlapsErrorMsg.forEach((elem, index) => checkOverlappedFields(index));
    },
    [checkOverlappedFields, modalState.showOverlapsErrorMsg]
  );

  const onChangeEventRow = useCallback(
    idx => evt => {
      const targetBaseName = evt.target.name.split('_')[0];
      setModalState(prevState => {
        const newEventRows = prevState.eventRows.map((eventRow, index) => {
          if (index === idx) {
            return {
              ...eventRow,
              [targetBaseName]:
                targetBaseName === 'countTo' && isUndefined(evt.target.value) ? maxCount : evt.target.value
            };
          }
          return eventRow;
        });
        return { ...prevState, eventRows: newEventRows };
      });
      if (targetBaseName === 'countFrom' || targetBaseName === 'countTo') {
        modalState.showOverlapsErrorMsg.forEach((elem, index) => checkOverlappedFields(index));
      }
    },
    [checkOverlappedFields, modalState.showOverlapsErrorMsg]
  );

  const revertOrSetUpVendorRadioButtons = useCallback(() => {
    setModalState(prevState => {
      const { eventRows, selectedVendorId } = prevState;
      if (selectedVendorId !== 'NOT_SELECTED' && eventRows.every(e => !e.vendorAmountType)) {
        const updatedEventRows = eventRows.map(e => ({ ...e, vendorAmountType: DIRECT_COST }));
        return { ...prevState, eventRows: updatedEventRows };
      } else if (selectedVendorId === 'NOT_SELECTED' && eventRows.some(e => !!e.vendorAmountType)) {
        const updatedEventRows = eventRows.map(e => ({ ...e, vendorAmountType: null }));
        return { ...prevState, eventRows: updatedEventRows };
      }
      return prevState;
    });
  }, []);

  const onChangeFormControls = useCallback(
    ({ target }) => {
      if (target.type === 'checkbox') {
        if (target.name === 'managementFee') {
          setModalState(prevState => ({
            ...prevState,
            managementFee: !prevState.managementFee,
            eventRows: prevState.managementFee
              ? prevState.eventRows.map(budgetEvent => ({
                  ...budgetEvent,
                  siteAmount: 0
                }))
              : prevState.eventRows
          }));
        } else {
          setModalState(prevState => ({ ...prevState, [target.name]: target.checked }));
        }
      } else {
        setModalState({
          ...modalState,
          [target.name]: target.value
        });
        revertOrSetUpVendorRadioButtons();
      }
    },
    [modalState, revertOrSetUpVendorRadioButtons]
  );

  const save = useCallback(() => {
    const {
      name,
      overhead,
      withholding,
      encounterOverride,
      finVendors,
      selectedVendorId,
      statuses,
      patientStatusId,
      secondaryPatientStatusId,
      primaryStatusRatio,
      secondaryStatusRatio,
      nonInvoiceable,
      managementFee
    } = modalState;
    const commonReqFields = {
      name,
      overhead,
      withholding,
      encounterOverride
    };
    if (selectedVendorId !== 'NOT_SELECTED') {
      commonReqFields.finVendorId = finVendors.find(v => v.id === selectedVendorId).id;
    } else {
      commonReqFields.finVendorId = null;
    }
    if (patientStatusId !== 'NOT_SELECTED') {
      const patientStatus = statuses.find(s => s.id === patientStatusId);
      commonReqFields.patientStatusId = patientStatus.id;
      commonReqFields.patientStatusName = patientStatus.name;
    }
    if (triggerType === 'RATIO_STATUS_CHANGE') {
      const secondaryPatientStatus = statuses.find(s => s.id === secondaryPatientStatusId);
      commonReqFields.secondaryPatientStatusId = secondaryPatientStatus.id;
      commonReqFields.primaryStatusRatio = primaryStatusRatio;
      commonReqFields.secondaryStatusRatio = secondaryStatusRatio;
    }
    const req = new Array(modalState.eventRows.length).fill().map((e, i) => {
      const curRow = modalState.eventRows[i];
      return {
        type: budgetEventType,
        ...commonReqFields,
        nonInvoiceable,
        id: curRow.id,
        managementFee,
        clientAmount: toCoins(curRow.clientAmount),
        siteAmount: toCoins(curRow.siteAmount),
        vendorAmount: selectedVendorId !== 'NOT_SELECTED' ? toCoins(curRow.vendorAmount) : 0,
        countFrom: curRow.countFrom,
        countTo: curRow.countTo === maxCount ? null : curRow.countTo,
        vendorAmountType: curRow.vendorAmountType,
        siteAmountType: curRow.siteAmountType
      };
    });
    return onSave(req, modalState.groupId);
  }, [budgetEventType, modalState, onSave, triggerType]);

  const onSaveButton = useCallback(() => {
    save();
    modalBox.close();
  }, [modalBox, save]);

  const onSaveAndContinue = useCallback(() => {
    save().then(() => setModalState(prevState => ({ ...prevState, ...initialState })));
  }, [initialState, save]);

  const isValidForm = useCallback(() => {
    let isValidated = false;
    const {
      name,
      selectedVendorId,
      patientStatusId,
      showOverlapsErrorMsg,
      secondaryPatientStatusId,
      primaryStatusRatio,
      secondaryStatusRatio
    } = modalState;
    for (const curRow of modalState.eventRows) {
      const { clientAmount, countFrom, vendorAmount, siteAmount } = curRow;
      isValidated =
        isString(name) &&
        name.trim() !== '' &&
        clientAmount >= 0 &&
        countFrom > 0 &&
        showOverlapsErrorMsg.every(curState => !curState) &&
        (!siteAmount || siteAmount >= 0) &&
        patientStatusId !== 'NOT_SELECTED' &&
        (selectedVendorId !== 'NOT_SELECTED' ? vendorAmount > 0 : true) &&
        name.length <= BUDGET_EVENT_NAME_LENGTH;
      if (!isValidated) {
        return false;
      }
    }
    if (isValidated && triggerType === 'RATIO_STATUS_CHANGE') {
      isValidated = secondaryPatientStatusId !== 'NOT_SELECTED' && primaryStatusRatio > 0 && secondaryStatusRatio > 0;
    }
    return isValidated;
  }, [modalState, triggerType]);

  const isVendorSelected = modalState.selectedVendorId !== 'NOT_SELECTED';

  const getRatioInputsGroup = () => {
    return (
      <div className="form-row col-12">
        <div className="col-4">{getPrimaryPatientStatusSelect()}</div>
        <div className="form-group col-2">
          <NumberInput
            value={modalState.primaryStatusRatio}
            onChange={onChangeFormControls}
            label="# Patients"
            required={true}
            disabled={isEditingForbidden}
            name="primaryStatusRatio"
          />
        </div>
        <div className="form-group col-4">
          <label htmlFor="secondary-status-select">Secondary Status</label>
          <select
            className="form-control reqfeild"
            id="secondary-status-select"
            name="secondaryPatientStatusId"
            value={modalState.secondaryPatientStatusId}
            disabled={isEditingForbidden}
            onChange={onChangeFormControls}
          >
            {modalState.statuses.map(s => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-2">
          <NumberInput
            value={modalState.secondaryStatusRatio}
            onChange={onChangeFormControls}
            label="# Patients"
            required={true}
            disabled={isEditingForbidden}
            name="secondaryStatusRatio"
          />
        </div>
      </div>
    );
  };

  const getPrimaryPatientStatusSelect = () => {
    return (
      <div className="form-group">
        <label htmlFor="bem-status-select">Status</label>
        <select
          className="form-control reqfeild"
          id="bem-status-select"
          name="patientStatusId"
          value={modalState.patientStatusId}
          onChange={onChangeFormControls}
          disabled={isEditingForbidden}
        >
          {modalState.statuses.map(s => (
            <option key={s.id} value={s.id}>
              {s.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const getVendorSelect = () => {
    return (
      <div className="form-group">
        <label htmlFor="bem-vendor-select">Vendor</label>
        <select
          className="form-control"
          id="bem-vendor-select"
          name="selectedVendorId"
          value={modalState.selectedVendorId}
          disabled={isEditingForbidden}
          onChange={onChangeFormControls}
        >
          {modalState?.finVendors.map(v => (
            <option key={v.id} value={v.id}>
              {v.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <>
      <ModalBoxes.Body>
        <div className="bem-form status-change-form d-flex container-fluid">
          <div className="form-row py-3">
            <div className="form-row py-0 col-12">
              <div className="form-group col-6">
                <label htmlFor="bem-event-name">Event Name</label>
                <input
                  id="bem-event-name"
                  type="text"
                  className="form-control reqfeild"
                  name="name"
                  value={modalState.name}
                  disabled={isEditingForbidden}
                  onChange={onChangeFormControls}
                />
                {modalState.name.length > 500 && (
                  <p className="event-name-length-notification">Event Name cannot exceed 500 characters</p>
                )}
              </div>
              <div className="col-6 pr-0">
                {triggerType === 'STATUS_CHANGE' && getPrimaryPatientStatusSelect()}
                {triggerType === 'RATIO_STATUS_CHANGE' && getVendorSelect()}
              </div>
            </div>
            <div className="form-row col-12 mb-3">
              <Checkbox.Group className="col-12" onChange={onChangeFormControls}>
                <Checkbox
                  id="bem-overhead"
                  name="overhead"
                  checked={modalState.overhead}
                  disabled={isEditingForbidden}
                  label="Overhead"
                />
                <Checkbox
                  id="bem-withholding"
                  name="withholding"
                  checked={modalState.withholding}
                  disabled={isEditingForbidden}
                  label="Withholding"
                />
                <Checkbox
                  label="Management Fee"
                  id="bem-management-fee"
                  name="managementFee"
                  checked={modalState.managementFee}
                  disabled={isEditingForbidden || +managementFeePercent === 0}
                />
                <Checkbox
                  id="bem-encounter-override"
                  name="encounterOverride"
                  disabled={isEditingForbidden || !modalState.encounterOverride}
                  checked={modalState.encounterOverride}
                  label="Encounter override"
                />
                <Checkbox
                  id="encounter-status-change-non-invoiceable"
                  name="nonInvoiceable"
                  disabled={isEditingForbidden}
                  checked={modalState.nonInvoiceable}
                  label="Non-Invoiceable"
                />
              </Checkbox.Group>
            </div>
            <div className="pr-0 col-4">{triggerType === 'STATUS_CHANGE' && getVendorSelect()}</div>
            {triggerType === 'RATIO_STATUS_CHANGE' && getRatioInputsGroup()}
            <div>
              <StatusChangeRows
                isVendorSelected={isVendorSelected}
                eventRows={modalState.eventRows}
                maxCount={maxCount}
                onChangeEventRow={onChangeEventRow}
                handleAddEventRow={handleAddEventRow}
                isEditingForbidden={isEditingForbidden}
                handleRemoveEventRow={handleRemoveEventRow}
                showOverlapsErrorMsg={modalState.showOverlapsErrorMsg}
                managementFee={modalState.managementFee}
              />
            </div>
          </div>
        </div>
      </ModalBoxes.Body>
      <BudgetEventControlButtons
        isValidForm={isValidForm}
        onClose={modalBox.close}
        onSave={onSaveButton}
        onSaveAndContinue={isEmpty(data) ? onSaveAndContinue : null}
        currentStudyBudget={currentStudyBudget}
      />
    </>
  );
};

AddOrEditStatusChangeEventModal.title = 'Status Change budget event';
AddOrEditStatusChangeEventModal.className = 'add-status-change-modal';
AddOrEditStatusChangeEventModal.size = 'w800';

export default AddOrEditStatusChangeEventModal;
