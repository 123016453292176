import React, { useEffect, useState } from 'react';

import { ProtocolApi } from '../../../../../../../api';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { PageInfoHeader } from '../../../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';
import ElementListModal from '../../ElementListPopup';

import './ItemGroupDetails.scss';

export function ItemGroupDetails({
  isItemGroupTemplate,
  isItemGroupTemplateSetup,
  protocolName,
  studyName,
  onSaveData
}) {
  const {
    parent,
    name,
    params: { studyId, protocolIdentity },
    key
  } = useCurrentRoute();

  const [protocolMetaInfo, setProtocolMetaInfo] = useState({});

  useEffect(() => {
    if (studyId && protocolIdentity) {
      ProtocolApi.getPreviewProtocol(studyId, protocolIdentity).then(res => {
        setProtocolMetaInfo(res.data);
      });
    }
  }, [studyId, protocolIdentity]);

  return (
    <PageInfoHeader
      objectRecordLabel={studyName}
      pageInfo={
        <PageInfoHeader.CollapsibleList>
          <PageInfoHeader.AdditionalInfo tooltip="Source Data Name">{protocolName}</PageInfoHeader.AdditionalInfo>
          <PageInfoHeader.AdditionalInfo tooltip="Version">{protocolMetaInfo.version}</PageInfoHeader.AdditionalInfo>
        </PageInfoHeader.CollapsibleList>
      }
      right={
        <ButtonGroup>
          <Button priority="low" to={navigateBack()}>
            Cancel
          </Button>
          {isItemGroupTemplate ? (
            <Button priority="high" onClick={onSaveData}>
              Save
            </Button>
          ) : (
            <>
              <Button priority="high" onClick={() => onSaveData(true)}>
                Save
              </Button>
              <Button priority="high" onClick={onSaveData}>
                Save and New
              </Button>
            </>
          )}
          {!isItemGroupTemplate && (
            <Button priority="medium" onClick={openElementListModal}>
              Existing Item Groups
            </Button>
          )}
        </ButtonGroup>
      }
    />
  );

  function navigateBack() {
    return generateUrlByKey(parent.key, { studyId, protocolIdentity });
  }

  function openElementListModal() {
    ModalBoxes.open({
      component: (
        <ElementListModal
          generateLink={(uniqueIdentifier, isNewTable) => {
            if (isItemGroupTemplateSetup) {
              const patch = isNewTable
                ? `${parent.key}.Table Setup.Table Edit`
                : `${parent.key}.Item Group Setup.Item Group Edit`;
              return generateUrlByKey(patch, {
                studyId,
                protocolIdentity,
                element_identifier: uniqueIdentifier
              });
            }
            if (name === 'Item Group Setup') {
              const patch = isNewTable ? `${parent.key}.Table Setup.Table Edit` : `${key}.Item Group Edit`;
              return generateUrlByKey(patch, {
                studyId,
                protocolIdentity,
                element_identifier: uniqueIdentifier
              });
            }
            if (parent.name === 'Encounter Setup') {
              const patch = isNewTable ? `${parent.key}.Table Edit` : key;
              return generateUrlByKey(patch, { studyId, protocolIdentity, element_identifier: uniqueIdentifier });
            }
            const patch = isNewTable ? `${parent.parent.key}.Table Setup.Table Edit` : key;
            return generateUrlByKey(patch, { studyId, protocolIdentity, element_identifier: uniqueIdentifier });
          }}
          studyId={studyId}
        />
      )
    });
  }
}
