import React, { Component } from 'react';
import ReactTable from 'react-table';
import { FormControlLabel } from '@mui/material';
import moment from 'moment';

import { StudyApi, StudySiteApi } from '../../../../../api';
import common from '../../../../../common/common';
import Checkbox from '../../../../../common/data-entry/InputSelectors/Checkbox';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { STDT_REIMBURSEMENT, STTG_CTMS } from '../../../../../constants/study/studyTypes';
import {
  EDIT_PRIMARY_STUDY_MANAGER_AND_INVESTIGATOR,
  MANAGE_SSU_REIMBURSEMENT_EMAIL_NOTIFICATION,
  MANAGE_SSU_STIPEND_OVERSIGHT
} from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { onRequestDefaultError } from '../../../../../services/handlers';
import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';
import { withParams } from '../../../../root/router/legacyComponentCompatability';
import AddPersonnelModal from '../AddPersonnel/AddPersonnel';
import LeadModal from '../LeadModal/LeadModal';

import ExpectedSuccessMetricsTable from './ExpectedSuccessMetrics/ExpectedSuccessMetricsTable';
import InformedConsentTable from './InformedConsentTable';

import 'react-table/react-table.css';
import 'react-datetime/css/react-datetime.css';
import './SiteDetails.scss';

class SiteDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteDetails: {},
      personnelDetailsColumns: [],
      primarySm: null,
      selectedSm: null,
      primarySmName: null,
      primaryInvestigator: null,
      selectedInvestigator: null,
      primaryInvestigatorName: null,
      isStudyTypeCTMS: false,
      isStudyReimbursementOption: false
    };
  }

  getColumns(isGroupAssignStudy) {
    return [
      {
        Header: 'Role',
        accessor: 'role',
        Cell: row => {
          return row.row.role ? <span className="cursor-pointer"> {row.row.role} </span> : '--';
        }
      },
      {
        id: 'group',
        Header: 'Group',
        accessor: 'groupAssign',
        sortable: true,
        show: isGroupAssignStudy,
        Cell: row => {
          return row.row.group ? <span> {row.row.group.name + ' (' + row.row.group.type + ')'} </span> : null;
        }
      },
      {
        id: 'Name',
        Header: 'Name',
        accessor: 'name',
        sortable: true,
        Cell: row => {
          return row.row._original ? <span> {row.row._original.name} </span> : '--';
        }
      },
      {
        Header: 'Start Date',
        accessor: 'start_date',
        sortable: true,
        Cell: row => {
          return row.row.start_date ? <span> {row.row.start_date} </span> : '--';
        },
        sortMethod: common.dateComparator
      },
      {
        Header: 'End Date',
        accessor: 'end_date',
        Cell: row => {
          return row.row.end_date ? <span> {row.row.end_date} </span> : '--';
        },
        sortMethod: common.dateComparator
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: row => {
          return row.row.status ? <span> {row.row.status} </span> : '--';
        }
      }
    ];
  }

  componentDidMount() {
    localStorage.setItem('fromMenu', false);
    let personnelDetailsColumns = this.getColumns(false);
    try {
      const data = this.props.params.siteName;
      const studyId = this.props.params.studyName;
      StudyApi.getSiteDetails(data).then(({ data: { response } }) => {
        const primarySm = response.primaryStudyManager ? response.primaryStudyManager : null;
        const primarySmName = primarySm ? primarySm.name : null;
        const primaryInvestigator = response.primaryInvestigator ? response.primaryInvestigator : null;
        const primaryInvestigatorName = primaryInvestigator ? primaryInvestigator.name : null;
        personnelDetailsColumns = this.getColumns(response.groupAssignStudy);
        this.setState({
          siteDetails: response,
          selectedSm: primarySm,
          selectedInvestigator: primaryInvestigator,
          primarySm,
          primarySmName,
          primaryInvestigator,
          primaryInvestigatorName,
          personnelDetailsColumns
        });
      }, onRequestDefaultError);
      StudyApi.getStudyTypes(studyId).then(res => {
        const isStudyTypeCTMS = res.data.type === STTG_CTMS;
        const isStudyReimbursementOption = res.data.typeParents
          .find(e => e.typeParent === STTG_CTMS)
          .types.find(e => e.name === STDT_REIMBURSEMENT)?.selected;
        this.setState({
          isStudyTypeCTMS,
          isStudyReimbursementOption
        });
      }, onRequestDefaultError);
    } catch (e) {}
  }
  onEditPersonnelRow = row => {
    const modalBox = ModalBoxes.open({
      component: (
        <AddPersonnelModal
          onSave={data => {
            const sendData = {};
            sendData.status = data.status === 'Inactive' ? 2 : 1;
            sendData.contact_number = data.contact_number;
            sendData.end_date = moment.isMoment(data.endDate)
              ? common.formatDate(data.endDate._d, 'DD/MMM/YYYY')
              : data.endDate;
            sendData.start_date = moment.isMoment(data.startDate)
              ? common.formatDate(data.startDate._d, 'DD/MMM/YYYY')
              : data.startDate;
            sendData.uniqueIdentifier = data.uniqueIdentifier;
            StudyApi.editPersonnelInSite(sendData).then(
              res => {
                const data = this.props.params.siteName;
                StudyApi.getSiteDetails(data).then(res => {
                  this.setState({ siteDetails: res.data && res.data.response }, () => {
                    modalBox.close();
                  });
                }, onRequestDefaultError);
              },
              error => {
                if (error.response && error.response.status === '404') {
                  NotificationManager.warning(
                    error.response.data && error.response.data.apierror && error.response.data.apierror.message
                  );
                } else {
                  onRequestDefaultError(error);
                }
              }
            );
          }}
          isEdit="true"
          editData={row}
          editId={row._original.uniqueIdentifier}
          studySiteID={this.props.params && this.props.params.siteName}
        />
      ),
      className: 'add-personnel-modal'
    });
  };

  onRowClick = (state, rowInfo, column) => {
    return {
      onClick: e => {
        rowInfo.row._original.isAccessible && this.onEditPersonnelRow(rowInfo && rowInfo.row);
      }
    };
  };

  handleLeadSmChange = sm => {
    this.setState({ selectedSm: sm });
  };

  prepareLeadList = (authorityIdentifier, currentLead) => {
    const preparedList = this.state.siteDetails.sitePersonnels
      .filter(it => it.authority_identifier === authorityIdentifier)
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    if (currentLead) {
      const unassignedSelect = {
        name: 'Unassigned'
      };
      preparedList.unshift(unassignedSelect);
    }
    return preparedList;
  };

  saveLeadSm = () => {
    const studyManagerId = this.state.selectedSm ? this.state.selectedSm.personnelIdentifier : null;
    const primaryStudyManager = { ssuId: this.state.siteDetails.unique_identifier, personnelId: studyManagerId };

    StudySiteApi.setPrimarySM(primaryStudyManager).then(resp => {
      const siteId = this.props.params.siteName;
      StudyApi.getSiteDetails(siteId).then(({ data: { response } }) => {
        const primarySm = response.primaryStudyManager ? response.primaryStudyManager : null;
        const primarySmName = primarySm ? primarySm.name : null;
        this.setState({
          siteDetails: response,
          primarySm,
          primarySmName
        });
      }, onRequestDefaultError);
    });
  };

  openLeadSmModal = () => {
    const smList = this.prepareLeadList('DS-AUTH-000001', this.state.primarySm);
    ModalBoxes.open({
      title: 'Lead Study Manager',
      component: (
        <LeadModal
          leadLabel="Study Manager"
          leadList={smList}
          currentLead={this.state.primarySm}
          handleLeadChange={this.handleLeadSmChange}
          saveLead={this.saveLeadSm}
        />
      )
    });
  };

  handleLeadInvestigatorChange = pi => {
    this.setState({ selectedInvestigator: pi });
  };

  saveLeadInvestigator = () => {
    const piId = this.state.selectedInvestigator ? this.state.selectedInvestigator.personnelIdentifier : null;
    const pi = { ssuId: this.state.siteDetails.unique_identifier, personnelId: piId };

    StudySiteApi.setPrimaryInvestigator(pi).then(resp => {
      const siteId = this.props.params.siteName;
      StudyApi.getSiteDetails(siteId).then(({ data: { response } }) => {
        const primaryInvestigator = response.primaryInvestigator ? response.primaryInvestigator : null;
        const primaryInvestigatorName = primaryInvestigator ? primaryInvestigator.name : null;
        this.setState({
          siteDetails: response,
          primaryInvestigator,
          primaryInvestigatorName
        });
      }, onRequestDefaultError);
    });
  };

  openLeadInvestigatorModal = () => {
    const piList = this.prepareLeadList('DS-AUTH-000002', this.state.primaryInvestigator);
    ModalBoxes.open({
      title: 'Lead Investigator',
      component: (
        <LeadModal
          leadLabel="Investigator"
          leadList={piList}
          currentLead={this.state.primaryInvestigator}
          handleLeadChange={this.handleLeadInvestigatorChange}
          saveLead={this.saveLeadInvestigator}
        />
      )
    });
  };

  updateReimbursementEmailOptIn = () => {
    const emailOptIn = !this.state.siteDetails?.reimbursementEmailOptIn;
    StudySiteApi.updateReimbursementEmailOptIn(this.props.params.siteName, emailOptIn).then(({ data }) => {
      this.setState(state => {
        return { siteDetails: { ...state.siteDetails, reimbursementEmailOptIn: data } };
      });
    });
  };

  updateStipendOversightOption = () => {
    const stipendOversight = !this.state.siteDetails?.stipendOversight;
    StudySiteApi.updateStipendOversightOption(this.props.params.siteName, stipendOversight).then(({ data }) => {
      this.setState(state => {
        return { siteDetails: { ...state.siteDetails, stipendOversight: data } };
      });
    });
  };

  render({ props, state } = this) {
    const { siteDetails } = state;
    const { isStudyTypeCTMS } = state;
    const { isStudyReimbursementOption } = state;
    const { studyName } = props.params;
    return (
      <>
        <PageInfoHeader
          objectRecordLabel={siteDetails.name}
          pageInfo={
            <PageInfoHeader.AdditionalInfo tooltip="Study">{siteDetails.study_name}</PageInfoHeader.AdditionalInfo>
          }
        />
        <section className="siteDetails footpadb iefixflex">
          <div className="p-3 m-0 border row justify-content-between">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="site">Name</label>
                  <input className="form-control" value={siteDetails.name || ''} disabled />
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Status</label>
                  <input className="form-control" value={siteDetails.status || ''} disabled />
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Pre-Study Visit</label>
                  <input className="form-control" disabled value={siteDetails.psvDate || ''} />
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Site Initiation Visit</label>
                  <input className="form-control" disabled value={siteDetails.sivDate || ''} />
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Sponsor Green Light</label>
                  <input className="form-control" disabled value={siteDetails.grnDate || ''} />
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Award</label>
                  <input className="form-control" disabled value={siteDetails.awardDate || ''} />
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Contract Executed</label>
                  <input className="form-control" disabled value={siteDetails.contractExecutedDate || ''} />
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Lead Investigator</label>
                  <div className="input-group">
                    <span className="col-9 mr-0 p-0">
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.primaryInvestigatorName ? this.state.primaryInvestigatorName : ''}
                        disabled
                      />
                    </span>
                    <span className="col-3 pt-1 pr-0">
                      {userHasAccessTo(EDIT_PRIMARY_STUDY_MANAGER_AND_INVESTIGATOR) ? (
                        <i className="glyphicon glyphicon-edit pr-2 editicn" onClick={this.openLeadInvestigatorModal} />
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Lead Study Manager</label>
                  <div className="input-group">
                    <span className="col-9 mr-0 p-0">
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.primarySmName ? this.state.primarySmName : ''}
                        disabled
                      />
                    </span>
                    <span className="col-3 pt-1 pr-0">
                      {userHasAccessTo(EDIT_PRIMARY_STUDY_MANAGER_AND_INVESTIGATOR) ? (
                        <i className="glyphicon glyphicon-edit pr-2 editicn" onClick={this.openLeadSmModal} />
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {isStudyTypeCTMS && isStudyReimbursementOption && (
                <div className="col-2">
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        checked={siteDetails?.reimbursementEmailOptIn}
                        disabled={!userHasAccessTo(MANAGE_SSU_REIMBURSEMENT_EMAIL_NOTIFICATION)}
                        onChange={this.updateReimbursementEmailOptIn}
                      />
                    }
                    label="Reimbursement Status Email Opt-In"
                    labelPlacement="top"
                    sx={{ alignItems: 'start' }}
                    componentsProps={{
                      typography: {
                        sx: { fontSize: '.9rem' }
                      }
                    }}
                  />
                </div>
              )}
              {isStudyTypeCTMS && (
                <div className="col-2">
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox
                        checked={siteDetails?.stipendOversight}
                        disabled={!userHasAccessTo(MANAGE_SSU_STIPEND_OVERSIGHT)}
                        onChange={this.updateStipendOversightOption}
                      />
                    }
                    label="Stipend Oversight"
                    labelPlacement="top"
                    sx={{ alignItems: 'start' }}
                    componentsProps={{
                      typography: {
                        sx: { fontSize: '.9rem' }
                      }
                    }}
                  />
                </div>
              )}
              <div className="col-xs-12 col-md-12 col-lg-12" />
              <div className="col-md-4 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Address Line 1</label>
                  <input type="text" className="form-control" value={siteDetails.address1 || ''} disabled />
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">State</label>
                  <div className="input-group">
                    <input type="text" className="form-control" value={siteDetails.state || ''} disabled />
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">City</label>
                  <div className="input-group">
                    <input type="text" className="form-control" value={siteDetails.city || ''} disabled />
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">ZIP Code</label>
                  <div className="input-group">
                    <input type="text" className="form-control" value={siteDetails.zipCode || ''} disabled />
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Elligo Phone</label>
                  <div className="input-group">
                    <input type="text" className="form-control" value={siteDetails.elligoPhone || ''} disabled />
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12" />
              <div className="col-md-4 col-half-offset">
                <div className="form-group">
                  <label>Address Line 2</label>
                  <input type="text" className="form-control" value={siteDetails.address2 || ''} disabled />
                </div>
              </div>
              <div className="col-md-2 col-half-offset">
                <div className="form-group">
                  <label htmlFor="site">Phone</label>
                  <div className="input-group">
                    <input type="text" className="form-control" value={siteDetails.phone || ''} disabled />
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12" />
            </div>
            <InformedConsentTable ssuId={siteDetails.unique_identifier} studyId={studyName} />
            <div className="row col-12 px-0 pt-1 pb-3 m-0">
              <div className="col-md-12 row m-0 p-0 mb-2 justify-content-between border-bottom-dotted pb-0">
                <h5 className="mt-2 col-md-8 col-sm-auto p-0 c-p ">Personnel Details</h5>
              </div>
              <div className="col-12 border px-0 site-info-table">
                <ReactTable
                  data={siteDetails.sitePersonnels}
                  columns={state.personnelDetailsColumns}
                  minRows={1}
                  multiSort
                  className="table activity-table table-responsive-sm m-0"
                  showPagination
                  nextText=">>"
                  previousText="<<"
                  defaultPageSize={10}
                  noDataText="No Record Found"
                  getTrProps={this.onRowClick}
                  sortable
                />
              </div>
            </div>
            <ExpectedSuccessMetricsTable siteDetails={siteDetails} />
          </div>
        </section>
      </>
    );
  }
}

export default withParams(SiteDetails);
