import { enrollmentRouterConfig } from './Enrollment/routerConfig';
import { ledgerRouterConfig } from './Ledger/routerConfig';
import { newInvoiceRouterConfig } from './NewInvoice/routerConfig';
import { patientStipendWorklistRouterConfig } from './PatientStipendWorklist/routerConfig';
import { projectedRevenueRouterConfig } from './ProjectedRevenue/routerConfig';
import { reimbursementRouterConfig } from './ReimbursementWorklist/routerConfig';
import { revenueRouterConfig } from './Revenue/routerConfig';
import { siteFinanceRouterConfig } from './SiteFinance/routerConfig';
import { sitePaymentRouterConfig } from './SitePayments/routerConfig';
import { travelRequestsWorklistRouterConfig } from './TravelRequestsWorklist/routerConfig';

export const financeRouterConfig = [
  ledgerRouterConfig,
  revenueRouterConfig,
  newInvoiceRouterConfig,
  sitePaymentRouterConfig,
  enrollmentRouterConfig,
  projectedRevenueRouterConfig,
  reimbursementRouterConfig,
  travelRequestsWorklistRouterConfig,
  patientStipendWorklistRouterConfig,
  siteFinanceRouterConfig
];
