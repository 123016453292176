import React, { useCallback } from 'react';
import { Typography } from '@mui/material';

import SiteCreditApi from '../../../../../api/finance/SiteCreditApi';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../../constants/notificationMessages';

export const ReverseSiteCreditApplicationModal = ({
  modalBox,
  historyItemIds,
  creditItemSiteId,
  creditType,
  applyFilter,
  setCreditApplicationHistory,
  message
}) => {
  const onReverse = useCallback(async () => {
    try {
      if (!Array.isArray(historyItemIds)) {
        await SiteCreditApi.reverseSiteCredit(historyItemIds);
      } else {
        await SiteCreditApi.reverseAllSiteCredits(historyItemIds);
      }
      modalBox.close();
      applyFilter();

      try {
        const { data } = await SiteCreditApi.getHistory(creditItemSiteId, creditType);
        setCreditApplicationHistory(data);
      } catch {
        NotificationManager.error('Failed to fetch updated history');
      }

      NotificationManager.success('Application successfully reversed');
    } catch {
      NotificationManager.error(SOMETHING_WENT_WRONG);
    }
  }, [applyFilter, creditItemSiteId, creditType, historyItemIds, modalBox, setCreditApplicationHistory]);

  return (
    <>
      <ModalBoxes.Body>
        <Typography variant="body1">{message}</Typography>
      </ModalBoxes.Body>
      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="low" onClick={modalBox.close}>
            No
          </Button>
          <Button priority="high" onClick={onReverse}>
            Yes
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
};
