import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

import { scBlue100 } from '../../../../../../constants/systemColors';
import { generateUrlByKey } from '../../../../../root/router';
import { DefaultFinanceCell } from '../../../shared/FinanseTableMUI/DefaultFinanceCell';

export const SubjectIdCell = ({ row }) => {
  const url =
    row?.patientUniqueIdentifier && row?.ssuPatientId
      ? generateUrlByKey('Patients.Patient Profile.Patient Studies', {
          patientId: row?.patientUniqueIdentifier,
          ssuPatientId: row?.ssuPatientId
        })
      : null;
  return url ? (
    <Link sx={{ color: scBlue100 }} component={ReactRouterLink} to={url} underline="none">
      {row.subjectId ? row.subjectId : 'No Subject ID'}
    </Link>
  ) : (
    <DefaultFinanceCell value={row.subjectId} />
  );
};
