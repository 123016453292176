import React, { useContext, useEffect, useMemo, useState } from 'react';
import uuid from 'uuid/v4';

import { FormBuilderApi } from '../../../../../../../api';
import ModalBoxes from '../../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Icon from '../../../../../../../common/general/Icon';
import NotificationManager from '../../../../../../../common/notifications/NotificationManager';
import { TableSetupContext } from '../../../../../../../contexts/TableSetupContext';
import { collectConditionalLogic } from '../../../../services/conditionalLogicUtils';
import { MOCKED_COMMENT_FIELD } from '../TableSetupConstants';

import TablePreviewModal from './TablePleviewModal/TablePreviewModal';

import './TablePreview.scss';

const TablePreview = () => {
  const tableSetupContext = useContext(TableSetupContext);

  const domainIdentifier = tableSetupContext.selectedDomain.domainIdentifier;

  const [standardConditionalLogic, setStandardConditionalLogic] = useState({});

  const generalFieldsWithId = useMemo(() => {
    return tableSetupContext.generalFields.map(field => {
      return {
        ...field,
        protocolItemDefinitionId: uuid()
      };
    });
  }, [tableSetupContext.generalFields]);

  const fieldListWithId = useMemo(() => {
    return tableSetupContext.fieldList.map(field => ({ ...field, protocolItemDefinitionId: uuid() }));
  }, [tableSetupContext.fieldList]);

  useEffect(() => {
    FormBuilderApi.getStandardConditionalLogicForDomain(domainIdentifier)
      .then(function(conditionalLogic) {
        setStandardConditionalLogic(conditionalLogic);
      })
      .catch(function(error) {
        NotificationManager.error(error);
      });
  }, [domainIdentifier]);

  return (
    <div className="preview-wrapper">
      <h5 className="preview-header">Item Setup</h5>
      <div className="preview-button-wrapper" onClick={openNewTablePreview}>
        <Icon className="add-btn-preview">add</Icon>
        <span className="">ItemGroup Preview</span>
      </div>
    </div>
  );

  function openNewTablePreview() {
    const generalSnapshotAnswers = prepareFieldsToPreview(generalFieldsWithId).map(field => ({
      ...field,
      protocolTableGeneralFieldId: uuid()
    }));

    const tableFieldAnswers = prepareFieldsToPreview(fieldListWithId).map(field => ({
      ...field,
      linkedRowId: null,
      term: null
    }));

    const tableItemGroupRows = tableSetupContext.labelList.map(label => ({
      rowId: null,
      label: {
        labelId: label?.labelId || label.id,
        name: label.name,
        sequence: label.sequence
      },
      answers: tableFieldAnswers.map(answer => ({ ...answer }))
    }));

    const encounterTableItemGroupForPreview = {
      layout: tableSetupContext.questionLayout,
      itemGroupName: tableSetupContext.itemGroupName,
      title: tableSetupContext.tableTitle,
      generalSnapshotAnswers: [
        ...generalSnapshotAnswers,
        { ...MOCKED_COMMENT_FIELD, sequence: tableSetupContext.generalFields.length + 1 }
      ],
      tableItemGroupRows: tableItemGroupRows,
      type: tableSetupContext.selectedType.name,
      domain: tableSetupContext.selectedDomain.domainName
    };

    const conditionalLogicAndRequiredFieldsValidation = collectConditionalLogic(
      standardConditionalLogic,
      [...generalFieldsWithId, ...fieldListWithId],
      'protocolItemDefinitionId'
    );

    ModalBoxes.open({
      component: (
        <TablePreviewModal
          encounterTableItemGroupForPreview={encounterTableItemGroupForPreview}
          conditionalLogicAndRequiredFieldsValidation={conditionalLogicAndRequiredFieldsValidation}
        />
      )
    });
  }
};

function prepareFieldsToPreview(fields) {
  return fields
    .filter(field => field.isChecked)
    .map(element => ({
      protocolItemDefinitionId: element.protocolItemDefinitionId,
      performedStatus: 'NOT_ANSWERED',
      fieldComment: null,
      reasonForDataChange: null,
      fieldValue: element.inputValue || null,
      requiredAttention: false,
      investigatorField: element.investigatorField,
      fieldType: element.inputType,
      fileList: null,
      filesResponse: null,
      fieldName: element.updatedQuestion || element.question,
      styledFieldName: element?.styledFieldName,
      sequence: element.sequence,
      codeDefinition: element.codeDefinationList.map(code => ({ ...code, uniqueIdentifier: uuid() })),
      cdashAliasName: element.cdashAliasName
    }));
}

export default TablePreview;
