import React, { useRef, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';

import Button from '../../../../../../../common/general/Button';
import Icon from '../../../../../../../common/general/Icon';

import { stopEventAnd } from './PatientContentTabs';

export default function InstructionItem({
  id,
  instruction,
  canUserAddInstructions,
  setDeleteInstructionCandidateId,
  editInstruction
}) {
  const anchorEl = useRef(null);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="patient-instructions-item">
      <span>{instruction}</span>
      {canUserAddInstructions && (
        <React.Fragment>
          <Button ref={anchorEl} priority="low" size="h40" id="more-options" onClick={handleClick}>
            <Icon>more_vert</Icon>
          </Button>
          <Menu anchorEl={anchorEl.current} open={open} onClose={handleClose}>
            <MenuItem
              onClick={stopEventAnd(() => {
                handleClose();
                editInstruction(instruction, id);
              })}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={stopEventAnd(() => {
                handleClose();
                setDeleteInstructionCandidateId(id);
              })}
            >
              Delete
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </div>
  );
}
