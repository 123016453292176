import { map } from 'lodash/collection';

export const STP_PROJECTED = 'PROJECTED';
export const STP_IN_REVIEW = 'IN_REVIEW';
export const STP_PENDING = 'PENDING';
export const STP_REVENUE = 'REVENUE';
export const STP_BILLED = 'BILLED';
export const STP_INVOICED = 'INVOICED';
export const STP_RECEIVED = 'RECEIVED';
export const STP_PAID = 'PAID';
export const STP_INACTIVE = 'INACTIVE';
export const STP_ERROR = 'ERROR';
export const STP_PAYMENT_FAILED = 'PAYMENT_FAILED';
export const STP_APPROVED = 'APPROVED';
export const STP_CANCELED = 'CANCELED';
export const STP_DENIED = 'DENIED';

export const stipendStatusMap = {
  [STP_PROJECTED]: 'Projected',
  [STP_IN_REVIEW]: 'In Review',
  [STP_PENDING]: 'Pending',
  [STP_REVENUE]: 'Revenue',
  [STP_BILLED]: 'Billed',
  [STP_INVOICED]: 'Invoiced',
  [STP_RECEIVED]: 'Received',
  [STP_PAID]: 'Paid',
  [STP_INACTIVE]: 'Inactive',
  [STP_ERROR]: 'Error',
  [STP_PAYMENT_FAILED]: 'Payment failed',
  [STP_APPROVED]: 'Approved',
  [STP_CANCELED]: 'Canceled',
  [STP_DENIED]: 'Denied'
};

export const stipendStatuses = map(stipendStatusMap, function(name, id) {
  return { id, name };
});

export const INITIAL_FILTERS_STATE = {
  statuses: {
    source: [...stipendStatuses],
    value: [{ id: STP_IN_REVIEW, name: stipendStatusMap[STP_IN_REVIEW] }]
  },
  ssu: {
    studySites: [],
    studies: [],
    sites: [],
    selectedStudyMap: {},
    selectedSiteMap: {},
    selectedStudies: [],
    selectedSites: []
  }
};
