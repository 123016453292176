import { difference, uniq } from 'lodash/array';
import { find, flatMap } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import { has, mapValues } from 'lodash/object';
import moment from 'moment';
import {
  normalizeFieldValue,
  shouldTargetFieldsBeVisible
} from 'services/item-group/conditional-logic/conditionalLogicUtils';

import { isReadOnlyField } from '../../../setup/shared/ElementSetupNew/itemGroupSetupService';

import { PS_ANSWERED, PS_NOT_ANSWERED, PS_NOT_ASKED, PS_UNAVAILABLE } from './TableItemGroup/constants';

export function prepareFormValidationRules(fullResponse) {
  if (isEmpty(fullResponse) || isEmpty(fullResponse.formValidation)) {
    return {};
  }

  const { formValidation } = fullResponse;

  const isNewAlgorithm = isNewAlgorithmForConditionalQuestions(fullResponse);
  /*TODO: change data structure*/
  return mapValues(formValidation, function({ sourceIdentifier, required, conditionalLogicFieldsConfigs }) {
    return {
      sourceIdentifier,
      required,
      isNewAlgorithm,
      conditionalLogicFieldsConfigs
    };
  });
}

export function calculateHiddenFieldIds(jsonForSave, formValidationRules) {
  return uniq(
    flatMap(jsonForSave, function({ elementEncounterIdentifier, attributeValue }) {
      return getHiddenTargetFieldIdsByTriggerFieldValue(
        formValidationRules,
        elementEncounterIdentifier,
        attributeValue
      );
    })
  );
}

export function recursivelyCollectVisibleAndHiddenFieldIdsByTriggerField(
  formValidationRules,
  triggerFieldId,
  triggerFieldValue
) {
  const accumulatedIdsToHide = [],
    accumulatedIdsToShow = [],
    idsStackToHide = [],
    idsStackToShow = [];

  let targetFieldId = triggerFieldId;

  while (targetFieldId) {
    const targetFieldValue = targetFieldId === triggerFieldId ? triggerFieldValue : null;

    idsStackToHide.push(
      ...getHiddenTargetFieldIdsByTriggerFieldValue(formValidationRules, targetFieldId, targetFieldValue)
    );

    idsStackToShow.push(
      ...getVisibleTargetFieldIdsByTriggerFieldValue(formValidationRules, targetFieldId, targetFieldValue)
    );

    targetFieldId = idsStackToHide.shift();

    if (targetFieldId) {
      accumulatedIdsToHide.push(targetFieldId);
      continue;
    }

    targetFieldId = idsStackToShow.shift();

    if (targetFieldId) {
      accumulatedIdsToShow.push(targetFieldId);
    }
  }

  return [accumulatedIdsToHide, difference(accumulatedIdsToShow, accumulatedIdsToHide)];
}

export function getHiddenTargetFieldIdsByTriggerFieldValue(formValidationRules, triggerFieldId, triggerFieldValue) {
  return collectTargetFieldIds(formValidationRules, triggerFieldId, triggerFieldValue, true);
}

export function getVisibleTargetFieldIdsByTriggerFieldValue(formValidationRules, triggerFieldId, triggerFieldValue) {
  return collectTargetFieldIds(formValidationRules, triggerFieldId, triggerFieldValue, false);
}

export function collectTargetFieldIds(formValidationRules, triggerFieldId, triggerFieldValue, inverted) {
  const triggerFieldConditionalLogic = formValidationRules[triggerFieldId];

  if (isEmpty(triggerFieldConditionalLogic)) {
    return [];
  }

  const { conditionalLogicFieldsConfigs, isNewAlgorithm } = triggerFieldConditionalLogic;

  const normalizedTriggerFieldValue = normalizeFieldValue(triggerFieldValue);

  return flatMap(conditionalLogicFieldsConfigs, function(condition) {
    const targetFieldsShouldBeVisible = shouldTargetFieldsBeVisible(
      condition,
      normalizedTriggerFieldValue,
      isNewAlgorithm
    );

    const isConditionSatisfied = inverted ? !targetFieldsShouldBeVisible : targetFieldsShouldBeVisible;

    if (isConditionSatisfied) {
      return condition.questions;
    }

    return [];
  });
}

export function isShownNotFilledConditionsExist(jsonForSave, inputs, formValidationRules) {
  return jsonForSave.some(field => {
    const condTrigger = formValidationRules[field.elementEncounterIdentifier];

    if (!condTrigger) {
      return false;
    }

    const isRequiredField = condTrigger?.required;
    const currentFieldType = inputs?.labelList.find(({ uniqueIdentifier }) => {
        return uniqueIdentifier === field.elementEncounterIdentifier;
      })?.type,
      isCustomField = currentFieldType === 'CUSTOM';
    return isRequiredField && !isCustomField && field.show && (!field.attributeValue || field.attributeValue === 'NA');
  });
}

export function fieldAffectsOnConditionalLogic(formValidationRules, triggerFieldId) {
  if (isEmpty(formValidationRules) || isEmpty(triggerFieldId)) {
    return false;
  }
  return has(formValidationRules, triggerFieldId);
}

export function fieldIsRequired(formValidationRules, triggerFieldId) {
  if (isEmpty(formValidationRules) || isEmpty(triggerFieldId)) {
    return false;
  }
  return formValidationRules[triggerFieldId]?.required;
}

/**
 TODO: remove of fix this implementation after all Item Groups are correctly configured
 This method is sort of dirty hack.
 New Algorithm should be used only for items that were correctly configured after release 3.5 so everything will work after release 3.6 "correctly configured" means that every CUSTOM field has a conditional parent
 **/
/*TODO: rename*/
export function isNewAlgorithmForConditionalQuestions(fullResponse) {
  if (isEmpty(fullResponse) || isEmpty(fullResponse.formDefinition) || isEmpty(fullResponse.formValidation)) {
    return true;
  }

  const { formDefinition, formValidation } = fullResponse;

  let conditionalLogicForAllCustomFieldsIsConfiguredInProtocol = false;

  const allConditionalChildren = Object.values(formValidation).flatMap(rule =>
    flatMap(rule.conditionalLogicFieldsConfigs, ({ questions }) => questions)
  );

  if (formDefinition.itemDefinitionList) {
    const allCustomItems = formDefinition.itemDefinitionList.filter(e => e.isChecked && e.type === 'CUSTOM');
    conditionalLogicForAllCustomFieldsIsConfiguredInProtocol = allCustomItems.every(e =>
      allConditionalChildren.includes(e.uniqueIdentifier)
    );
  }
  const dateIsAfterRelease3dot5 = moment(formDefinition.lastModifiedOn).isAfter(moment('2022/09/06', 'YYYY/MM/DD'));
  const dateIsAfterRelease4 = moment(formDefinition.lastModifiedOn).isAfter(moment('2023/01/04', 'YYYY/MM/DD'));
  return dateIsAfterRelease4
    ? true
    : conditionalLogicForAllCustomFieldsIsConfiguredInProtocol && dateIsAfterRelease3dot5;
}

export function recalculateVisibility(label, jsonForSave, [idsToHide, idsToShow]) {
  const { uniqueIdentifier } = label;
  if (idsToHide.includes(uniqueIdentifier)) {
    const field = find(jsonForSave, ['elementEncounterIdentifier', uniqueIdentifier]);
    handleToHideLogic(jsonForSave, field, label);
    return;
  }
  if (idsToShow.includes(uniqueIdentifier)) {
    const field = find(jsonForSave, ['elementEncounterIdentifier', uniqueIdentifier]);
    handleToShowLogic(jsonForSave, field);
  }
}

function handleToHideLogic(jsonForSave, field, label) {
  const isReadOnly = isReadOnlyField(label.inputType);
  if (!isReadOnly) {
    label.inputValue = '';
  }

  label.ansStatus = field ? field.performedStatus : PS_NOT_ANSWERED;

  if (!field) {
    return;
  }

  field.show = false;

  if (isReadOnly) {
    field.attributeValue = label.inputValue;
  } else {
    field.attributeValue = '';
  }

  if (label.inputType === 'label') {
    return;
  }

  field.isRequireAttention = false;
  field.include = false;
  field.answerStatus = 1;
  field.comments = '';
  field.performedStatus = field.performedStatus || PS_NOT_ANSWERED;
}

function handleToShowLogic(jsonForSave, field) {
  if (!field) {
    return;
  }
  field.show = true;
  if ([PS_NOT_ASKED, PS_UNAVAILABLE].includes(field.performedStatus)) {
    field.performedStatus = PS_NOT_ANSWERED;
  }
}

export function retainOldStatus(label) {
  if (isReadOnlyField(label?.inputType)) {
    label.ansStatus = PS_ANSWERED;
    label.inputValue = label.inputValue ? label.inputValue : label.inputType;
    return;
  }

  if (label.hasOwnProperty('ansStatus') && label.ansStatus) {
    if (label.hasOwnProperty('inputValue') && isEmpty(label.inputValue)) {
      label.ansStatus = PS_NOT_ANSWERED;
    }
    if (
      label.hasOwnProperty('codeDefinationList') &&
      Object.keys(label.codeDefinationList).length > 0 &&
      label.codeDefinationList?.[0]?.inputType === 'radio' &&
      (label.inputValue === 0 || label.inputValue === '')
    ) {
      label.ansStatus = PS_NOT_ANSWERED;
    }
    return;
  }

  label.ansStatus = PS_NOT_ANSWERED;
}
