import { useMemo } from 'react';
import { filter, includes } from 'lodash/collection';

import useDeepCompareMemoize from '../../../../../../../common/hooks/useDeepCompareMemoize';
import { calculateHiddenFieldIds } from '../services/conditionalLogicServices';
import { collectAllQuestionsField } from '../services/fieldsServices';

/*TODO: Need to replace 'Fields' with 'Field.'*/
export default function useRequiredAndHiddenFieldsIds(
  encounterTableItemGroup,
  originConditionalLogicAndRequiredFieldsMaps
) {
  return useDeepCompareMemoize(
    useMemo(
      function() {
        const allQuestions = collectAllQuestionsField(encounterTableItemGroup);
        const [originRequiredFieldIds, originConditionalLogicMap] = originConditionalLogicAndRequiredFieldsMaps;
        const hiddenFieldIds = calculateHiddenFieldIds(allQuestions, originConditionalLogicMap);
        const requiredFieldsIds = filter(originRequiredFieldIds, fieldUid => !includes(hiddenFieldIds, fieldUid));
        return [requiredFieldsIds, hiddenFieldIds];
      },
      [encounterTableItemGroup, originConditionalLogicAndRequiredFieldsMaps]
    )
  );
}
