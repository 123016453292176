import { useEffect } from 'react';
import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $insertNodes } from 'lexical';

export default function OnLoadEditorContentPlugin({ htmlString }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!htmlString) {
      return;
    }
    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlString, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      $getRoot()
        .clear()
        .select();
      $insertNodes(nodes);
    });
  }, [editor, htmlString]);

  return null;
}
