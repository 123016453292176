import axios from 'axios';

const ROOT = `api/v1/stipend-worklists`;

const PatientStipendWorklistApi = {
  getStudySites() {
    return axios.get(`${ROOT}/study-sites`, { hideLoader: true });
  },
  getRows(request) {
    return axios.post(
      `${ROOT}`,
      { ...request },
      {
        hideLoader: true
      }
    );
  },
  updateRow(ledgerEventId, data) {
    return axios.patch(`${ROOT}/rows/${ledgerEventId}`, data, {
      hideLoader: true
    });
  },
  updateWorklistRowsStatus(status, ledgerEventIds) {
    return axios.patch(`${ROOT}/rows/statuses/${status}`, ledgerEventIds);
  }
};

export default PatientStipendWorklistApi;
