import React from 'react';
import cx from 'classnames';
import { isNaN, isNil } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import './Amount.scss';

export function Amount(props) {
  const {
    showDollarSign,
    highlightIntegerValue,
    highlightFractionalValue,
    coinsAmount,
    className,
    customCoinsFormat,
    defaultValue,
    defaultZeroValue,
    testId
  } = props;

  function getContent() {
    if (isNil(coinsAmount) || isNaN(coinsAmount)) {
      return defaultValue;
    }
    if (coinsAmount === 0 && (defaultZeroValue || defaultZeroValue === '' || defaultZeroValue === 0)) {
      return defaultZeroValue;
    }
    const amountIsNegative = coinsAmount < 0;
    const splitAmount = formatAndCreateArrayOfBeforeAndAfterDotsValue();

    return (
      <React.Fragment>
        {amountIsNegative && '('}
        <span className={cx({ 'eds-highlight-integer': highlightIntegerValue })}>
          {showDollarSign && '$'}
          {splitAmount[0]}
        </span>
        <span className={cx({ 'eds-highlight-fractional': highlightFractionalValue })}>{`.${splitAmount[1]}`}</span>
        {amountIsNegative && ')'}
      </React.Fragment>
    );
  }

  return (
    <div className={cx('eds-amount', className)} data-testid={testId}>
      {getContent()}
    </div>
  );

  function formatAndCreateArrayOfBeforeAndAfterDotsValue() {
    function formatAmount() {
      if (customCoinsFormat) {
        return customCoinsFormat(Math.abs(coinsAmount));
      }
      const amount = Math.abs(coinsAmount);
      return (amount / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const formattedAmount = formatAmount();
    return formattedAmount.split('.');
  }
}
Amount.defaultProps = {
  defaultValue: ''
};

Amount.propTypes = {
  showDollarSign: PropTypes.bool,
  highlightIntegerValue: PropTypes.bool,
  highlightFractionalValue: PropTypes.bool,
  coinsAmount: PropTypes.number,
  format: PropTypes.func,
  defaultZeroValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
