import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TextNode } from 'lexical';

import { ExtendedTextNode } from '../../../../../../common/inputTextFormat/ExtendedTextNode/ExtendedtextNode';

import { InitialContentPlugin } from './EditorPlagins/InitialContentPlugin';
import { MaxLengthPlugin } from './EditorPlagins/MaxLengthPlagin';
import { ToHTMLPlugin } from './EditorPlagins/ToHTMLPlugin';
import ToolbarPlugin from './EditorPlagins/ToolbarPlugin';

import './Editor.scss';

const editorConfig = {
  theme: {
    ltr: 'ltr',
    rtl: 'rtl',
    placeholder: 'editor-placeholder',
    paragraph: 'editor-paragraph',
    quote: 'editor-quote',
    list: {
      nested: {
        listitem: 'editor-nested-listitem'
      },
      ol: 'editor-list-ol',
      ul: 'editor-list-ul',
      listitem: 'editor-listitem'
    },
    image: 'editor-image',
    link: 'editor-link',
    text: {
      bold: 'editor-text-bold',
      italic: 'editor-text-italic',
      overflowed: 'editor-text-overflowed',
      hashtag: 'editor-text-hashtag',
      underline: 'editor-text-underline',
      strikethrough: 'editor-text-strikethrough',
      underlineStrikethrough: 'editor-text-underlineStrikethrough',
      code: 'editor-text-code'
    }
  },
  nodes: [
    ExtendedTextNode,
    { replace: TextNode, with: node => new ExtendedTextNode(node.__text, node.__key) },
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    AutoLinkNode,
    LinkNode
  ]
};

export default function Editor({ onChange, initialText }) {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container" data-testid={'text-editor'}>
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={() => <div />}
          />
          <InitialContentPlugin initialText={initialText} />
          <ToHTMLPlugin onChange={onChange} />
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
          <HistoryPlugin />
          <MaxLengthPlugin maxLength={2000} />
        </div>
      </div>
    </LexicalComposer>
  );
}
