import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { reverse } from 'lodash/array';
import { every, flatMap, groupBy, reduce, size } from 'lodash/collection';

import Checkbox from '../../../../../../../common/data-entry/InputSelectors/Checkbox';
import TextFieldResolver from '../../../../../../../common/RichTextFormat/viewer/TextFieldResolver';
import { TEXT_AREA } from '../../../../../../../constants/inputTypes';
import { scYellow100 } from '../../../../../../../constants/systemColors';
import { useCurrentUser } from '../../../../../../root/Container/CurrentUserContainer';
import { isReadOnlyField } from '../../../../../setup/shared/ElementSetupNew/itemGroupSetupService';
import InvestigatorFieldIcon from '../../InvestigatorFields/InvestigatorFieldIcon/InvestigatorFieldIcon';
import InvestigatorForbiddenTooltip from '../../InvestigatorFields/InvestigatorForbiddenTooltip/InvestigatorForbiddenTooltip';
import { isInvestigatorForbiddenField } from '../../InvestigatorFields/services/investigatorFieldService';
import { LAYOUT_HORIZONTAL, LAYOUT_VERTICAL, performedStatusLabelsMap } from '../constants';
import { collectTableQuestionsField } from '../services/fieldsServices';
import { useTableItemGroupActions, useTableItemGroupState } from '../TableItemGroupContext/TableItemGroupContext';
import TableItemGroupField from '../TableItemGroupField/TableItemGroupField';

import './TableItemGroupResults.scss';

const layoutResolverMap = {
  [LAYOUT_HORIZONTAL]: {
    keyX: 'fieldLabel',
    keyY: 'fieldName',
    ComponentX: TableItemGroupResultsLabel,
    ComponentY: TableItemGroupResultsName
  },
  [LAYOUT_VERTICAL]: {
    keyX: 'fieldName',
    keyY: 'fieldLabel',
    ComponentX: TableItemGroupResultsName,
    ComponentY: TableItemGroupResultsLabel
  }
};

const fieldNameMap = {
  reminder: 'Reminder',
  textBlockLong: 'Text Block Long'
};

export default function TableItemGroupResults() {
  const { encounterTableItemGroup, hiddenFieldsIds, requiredFieldsIds } = useTableItemGroupState();
  const { tableItemGroupRows, layout, title } = encounterTableItemGroup;
  const user = useCurrentUser();

  const { keyX, keyY, ComponentX, ComponentY } = useMemo(
    function() {
      return layoutResolverMap[layout];
    },
    [layout]
  );

  const [xAxis, yAxis] = useMemo(
    function() {
      const axis = reduce(
        tableItemGroupRows,
        function(accumulator, row, index) {
          const [labels, names] = accumulator;
          labels.push(row.label.name);
          if (index === 0) {
            names.push(
              ...row.answers.map(function(field) {
                return field.fieldName;
              })
            );
          }
          return accumulator;
        },
        [[], []]
      );

      return keyX === 'fieldName' ? reverse(axis) : axis;
    },
    [keyX, tableItemGroupRows]
  );

  const allTableQuestionsField = useMemo(
    function() {
      return collectTableQuestionsField(tableItemGroupRows);
    },
    [tableItemGroupRows]
  );

  const groupedTableQuestionsFields = useMemo(
    function() {
      return groupBy(allTableQuestionsField, keyY);
    },
    [keyY, allTableQuestionsField]
  );

  const columnsCount = useMemo(() => size(xAxis), [xAxis]);

  const allIsHidden = useMemo(
    function() {
      return every(allTableQuestionsField, function({ fieldUid }) {
        return hiddenFieldsIds.includes(fieldUid);
      });
    },
    [allTableQuestionsField, hiddenFieldsIds]
  );

  const requiredCheck = useCallback(
    function(key, fieldUid) {
      if (key === 'fieldLabel') {
        return false;
      }
      return requiredFieldsIds.includes(fieldUid);
    },
    [requiredFieldsIds]
  );

  if (allIsHidden) {
    return null;
  }

  return (
    <>
      <div className="table-item-group-section-headline">Results</div>
      <div className="table-item-group-results" style={{ gridTemplateColumns: `repeat(${columnsCount + 1}, auto)` }}>
        <div className="table-item-group-results-name table-item-group-results-y">{title}</div>
        {xAxis.map(function(xLabel) {
          const field = allTableQuestionsField.find(function(field) {
            return field[keyX] === xLabel;
          });
          const isLabel = keyX === 'fieldLabel';
          const isInvestigatorFieldForbidden =
            !isLabel && isInvestigatorForbiddenField(field.investigatorField, user.activeRole);

          const text = !isLabel ? fieldNameMap[field.fieldType] || field[keyX] : field[keyX];

          return (
            <ComponentX
              key={field.fieldUid}
              className={cx(`table-item-group-results-x`, isInvestigatorFieldForbidden ? 'forbidden-field' : '')}
              required={requiredCheck(keyX, field.fieldUid)}
            >
              <InvestigatorForbiddenTooltip isInvestigatorFieldForbidden={isInvestigatorFieldForbidden}>
                <div className={'table-item-group-results-x-name'}>
                  {field.investigatorField && !isLabel && <InvestigatorFieldIcon />}
                  <TextFieldResolver
                    fieldType={field.fieldType}
                    fieldName={text}
                    styledFieldName={field.styledFieldName}
                    allowedStyledQuestions={[TEXT_AREA]}
                  />
                </div>
              </InvestigatorForbiddenTooltip>
            </ComponentX>
          );
        })}
        {flatMap(yAxis, function(rowLabel) {
          const rowFields = groupedTableQuestionsFields[rowLabel];
          const isLabel = keyY === 'fieldLabel';
          const text = !isLabel ? fieldNameMap[rowFields[0].fieldType] || rowFields[0][keyY] : rowFields[0][keyY];
          const isInvestigatorFieldForbidden =
            !isLabel && isInvestigatorForbiddenField(rowFields[0].investigatorField, user.activeRole);

          return [
            <ComponentY
              key={rowLabel}
              className="table-item-group-results-y"
              required={requiredCheck(keyY, rowFields[0].fieldUid)}
            >
              <InvestigatorForbiddenTooltip isInvestigatorFieldForbidden={isInvestigatorFieldForbidden}>
                <div className={'table-item-group-results-y-name'}>
                  {rowFields[0].investigatorField && !isLabel && <InvestigatorFieldIcon />}
                  {text}
                </div>
              </InvestigatorForbiddenTooltip>
            </ComponentY>,
            ...rowFields.map(function(field) {
              const { fieldUid } = field;
              return (
                <div key={fieldUid}>
                  {hiddenFieldsIds.includes(fieldUid) ? null : <TableItemGroupResultsField field={field} />}
                </div>
              );
            })
          ];
        })}
      </div>
    </>
  );
}

function TableItemGroupResultsField({ field }) {
  const { fieldUid, requiredAttention, fieldComment, performedStatus, fieldType, investigatorField } = field;
  const { isPreviewOnly } = useTableItemGroupState();
  const { requiredAttentionOnChange } = useTableItemGroupActions();
  const user = useCurrentUser();

  const isInvestigatorFieldForbidden = isInvestigatorForbiddenField(investigatorField, user.activeRole);
  return (
    <InvestigatorForbiddenTooltip isInvestigatorFieldForbidden={isInvestigatorFieldForbidden}>
      <div className="table-item-group-results-field">
        <div
          className={cx(`table-item-group-results-field-group`, isInvestigatorFieldForbidden ? 'forbidden-field' : '')}
        >
          <div>
            <TableItemGroupField field={field} readonly={isInvestigatorFieldForbidden} />
          </div>
          {!isReadOnlyField(fieldType) && (
            <div>
              <Checkbox
                disabled={isPreviewOnly || isInvestigatorFieldForbidden}
                color={scYellow100}
                onChange={function({ target }) {
                  requiredAttentionOnChange(target.checked, fieldUid);
                }}
                checked={requiredAttention}
              />
            </div>
          )}
        </div>

        <div className="table-item-group-general-field-comment">
          {fieldComment && `${performedStatusLabelsMap[performedStatus]}: ${fieldComment}`}
        </div>
      </div>
    </InvestigatorForbiddenTooltip>
  );
}

function TableItemGroupResultsName({ required = false, className, children }) {
  return (
    <div
      className={cx('table-item-group-results-name', className, {
        'required-field': required
      })}
    >
      <div>{children}</div>
    </div>
  );
}

function TableItemGroupResultsLabel({ className, children }) {
  return (
    <div className={cx('table-item-group-results-label', className)}>
      <div>{children}</div>
    </div>
  );
}
