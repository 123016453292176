import React, { useState } from 'react';
import cx from 'classnames';

import Button from '../../../../../../../../common/general/Button';
import Icon from '../../../../../../../../common/general/Icon';

import './RightPanel.scss';

export default function RightPanel({
  children,
  header,
  toggleClose,
  onSave,
  hideFooter,
  disableSaveButton,
  saveButtonText
}) {
  const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(false);

  return (
    <div className={cx('right-panel', { 'is-collapsed': isDrawerCollapsed })}>
      <Button priority="medium" size="h40" className="right-panel-collapse-button" onClick={toggleDrawerVisibility}>
        <Icon> {!isDrawerCollapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left'} </Icon>
      </Button>
      <div className="right-panel-container">
        <div className="right-panel-header">
          {header}
          <Button priority="low" size="h40" onClick={toggleClose}>
            <Icon>close</Icon>
          </Button>
        </div>
        <div className="right-panel-body">{children}</div>
        {!hideFooter && (
          <div className="right-panel-footer">
            <Button priority="medium" className="mr-2" onClick={toggleClose} data-testid="right-panel-cancel-button">
              Cancel
            </Button>
            <Button onClick={onSave} disabled={disableSaveButton} data-testid="right-panel-save-button">
              {saveButtonText || 'Save'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  function toggleDrawerVisibility() {
    setIsDrawerCollapsed(!isDrawerCollapsed);
  }
}
