import { useEffect } from 'react';
import MultiSelect from 'common/data-entry/MultiSelect/MultiSelect';

import { FRS_LOADED, FRS_READY, FRS_UNINITIALIZED } from '../../reducer';

import { initializeProcessor, setValueProcessor, syncCacheProcessor } from './processors';

export default function StudySiteMultiselect({ uniqKey, state, dispatch, getStudySites }) {
  const fState = state[uniqKey];
  const fCache = state.cache[uniqKey];
  const status = state.schema[uniqKey].status;
  const { studies, sites, selectedStudies, selectedSites } = fState || {};

  useEffect(() => {
    if (status !== FRS_UNINITIALIZED) {
      return;
    }
    getStudySites().then(function({ source: studySites }) {
      dispatch({
        type: 'PERFORM',
        processor: initializeProcessor,
        payload: {
          key: uniqKey,
          studySites
        }
      });
    });
  }, [uniqKey, dispatch, status, getStudySites]);

  useEffect(
    function() {
      if (status !== FRS_LOADED) {
        return;
      }

      dispatch({
        type: 'PERFORM',
        processor: syncCacheProcessor,
        payload: fCache
      });
    },
    [dispatch, uniqKey, status, fCache]
  );

  if (status !== FRS_READY) return null;

  return (
    <>
      <MultiSelect
        data-testid="study-filter"
        label="Study"
        disabled={false}
        dataSource={studies}
        onChange={function(values) {
          dispatch({
            type: 'PERFORM',
            processor: setValueProcessor,
            payload: {
              key: uniqKey,
              key2: 'Study',
              value: values
            }
          });
        }}
        value={selectedStudies}
        searchable={true}
        clearSearchOnSelection
        customOptionTemplateFunction={e => (
          <div>
            <div className="label">{e?.projectCode}</div>
            <div>{e?.name}</div>
          </div>
        )}
        className="task-ssu-multi-select-study"
        validate={false}
      />
      <MultiSelect
        data-testid="site-filter"
        label="Site"
        disabled={false}
        dataSource={sites}
        onChange={function(values) {
          dispatch({
            type: 'PERFORM',
            processor: setValueProcessor,
            payload: {
              key: uniqKey,
              key2: 'Site',
              value: values
            }
          });
        }}
        value={selectedSites}
        searchable={true}
        clearSearchOnSelection
        validate={false}
      />
    </>
  );
}
