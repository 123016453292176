import { useMemo, useReducer } from 'react';
import { isFunction } from 'lodash/lang';

import { generateCondition, generateConditionsMap } from '../services';

const SET_ENABLED = 'SET_ENABLED';
const SET_CONDITIONS = 'SET_CONDITIONS';
const SET_HIDE_ALL_FIELDS_CONFIG = 'SET_HIDE_ALL_FIELDS_CONFIG';

export default function useConditionalLogicState({
  enabled,
  conditions,
  domainConditions,
  domainConditionsMap,
  domainTargetFields,
  hideAllFieldsConfig
}) {
  const [state, dispatch] = useReducer(reducer, {
    enabled,
    conditions,
    domainConditions,
    conditionsMap: generateConditionsMap(conditions),
    domainConditionsMap,
    domainTargetFields,
    hideAllFieldsConfig
  });

  const actions = useMemo(
    function() {
      return {
        setEnabled(payload) {
          dispatch({
            type: SET_ENABLED,
            payload
          });
        },
        setConditions(payload) {
          dispatch({
            type: SET_CONDITIONS,
            payload
          });
        },
        setHideAllFieldsConfig(payload) {
          dispatch({
            type: SET_HIDE_ALL_FIELDS_CONFIG,
            payload
          });
        }
      };
    },
    [dispatch]
  );

  return { ...state, ...actions, dispatch };
}

function reducer(state, action) {
  const processedState = processState(state, action);
  return handleConditionsChange(state, processedState);
}

function processState(state, action) {
  const { type, payload } = action;
  if (type === SET_ENABLED) {
    const nextState = { ...state, enabled: payload };
    if (state.enabled !== payload) {
      nextState.conditions = payload ? [generateCondition()] : [];
    }
    return nextState;
  }
  if (type === SET_CONDITIONS) {
    if (isFunction(payload)) {
      return { ...state, conditions: payload(state.conditions) };
    }
    return { ...state, conditions: payload };
  }
  if (type === SET_HIDE_ALL_FIELDS_CONFIG) {
    if (isFunction(payload)) {
      return { ...state, hideAllFieldsConfig: payload(state.hideAllFieldsConfig) };
    }
    return { ...state, hideAllFieldsConfig: payload };
  }
  return state;
}

function handleConditionsChange(state, nextState) {
  if (state.conditions !== nextState.conditions) {
    nextState.conditionsMap = generateConditionsMap(nextState.conditions);
  }
  return nextState;
}
