import { useCallback, useContext, useMemo, useState } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { Box, Popover, Typography } from '@mui/material';

import { PARENT } from '../../NewInvoice/InvoiceTable/TableConstants';
import { SiteFinanceTableContext } from '../SiteFinanceContext';

import { QuickStatsPopover } from './QuickStatsPopover/QuickStatsPopover';
import { SiteFinanceInfoItem } from './SiteFinanceInfoItem/SiteFinanceInfoItem';
import { WithholdingAmountsPerAgedARPopover } from './WithholdingAmountsPerAgedARPopover/WithholdingAmountsPerAgedARPopover';
import {
  calculateUnpaidAmountForEvents,
  filterEventsByInvoiceDateRangeInDays,
  prepareDataForCalculation
} from './SiteFinanceInfoServices';

export const SiteFinanceInfo = () => {
  const { tableData: unpreparedData } = useContext(SiteFinanceTableContext);

  const tableData = useMemo(() => {
    return prepareDataForCalculation(unpreparedData);
  }, [unpreparedData]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const popoverProps = useMemo(() => {
    return {
      sx: { pointerEvents: 'none' },
      anchorEl: anchorEl,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      onClose: handlePopoverClose,
      disableRestoreFocus: true
    };
  }, [anchorEl, handlePopoverClose]);

  const totalEarned = useMemo(() => {
    return tableData.reduce((acc, curr) => acc + curr.invoiceAmount, 0);
  }, [tableData]);

  const totalInvoiced = useMemo(() => {
    return tableData
      .filter(ledgerEvent => !!ledgerEvent.invoiceDate)
      .reduce((acc, curr) => acc + curr.invoiceAmount, 0);
  }, [tableData]);

  const totalPayment = useMemo(() => {
    return tableData
      .filter(ledgerEvent => ['PAID', 'APPLIED'].includes(ledgerEvent.sitePaymentStatus))
      .reduce((acc, curr) => acc + curr.sitePaidAmount, 0);
  }, [tableData]);

  const parentEventsForEdgedAccountReceivable = useMemo(() => {
    return tableData.filter(ledgerEvent => {
      const isPaidStatus = ledgerEvent.reconcileTotalAmountPaid >= ledgerEvent.invoiceAmount;
      return ledgerEvent.sitePaymentType === PARENT && isPaidStatus === false && !!ledgerEvent.invoiceDate;
    });
  }, [tableData]);

  const unpaidInvoice = useMemo(() => {
    if (parentEventsForEdgedAccountReceivable.length === 0) {
      return 0;
    }
    return calculateUnpaidAmountForEvents([...parentEventsForEdgedAccountReceivable]);
  }, [parentEventsForEdgedAccountReceivable]);

  const unpaidInvoiceAmountByDateRange = useCallback(
    (start, end) => {
      if (parentEventsForEdgedAccountReceivable.length === 0) {
        return 0;
      }
      const filteredEvents = filterEventsByInvoiceDateRangeInDays(parentEventsForEdgedAccountReceivable, start, end);
      return calculateUnpaidAmountForEvents([...filteredEvents]);
    },
    [parentEventsForEdgedAccountReceivable]
  );

  const unpaidInvoiceAmountsByDateRange = useMemo(() => {
    return {
      unpaidInvoice030Days: unpaidInvoiceAmountByDateRange(0, 30),
      unpaidInvoice3060Days: unpaidInvoiceAmountByDateRange(30, 60),
      unpaidInvoice6090Days: unpaidInvoiceAmountByDateRange(60, 90),
      unpaidInvoice90Days: unpaidInvoiceAmountByDateRange(90, 0)
    };
  }, [unpaidInvoiceAmountByDateRange]);

  const {
    unpaidInvoice030Days,
    unpaidInvoice3060Days,
    unpaidInvoice6090Days,
    unpaidInvoice90Days
  } = unpaidInvoiceAmountsByDateRange;

  return (
    <Box sx={{ padding: '0 25px' }}>
      <Box paddingTop="8px">
        <Typography variant="h6" lineHeight="45px">
          Quick Stats
        </Typography>
        <Box
          display="flex"
          gap="16px"
          flexWrap="wrap"
          width="fit-content"
          paddingBottom="8px"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          id="quick-stats"
          data-testid="quick-stats"
        >
          <SiteFinanceInfoItem icon={<MonetizationOnIcon />} title="Total Earned (Gross):" amount={totalEarned} />
          <SiteFinanceInfoItem icon={<MonetizationOnIcon />} title="Total Invoiced:" amount={totalInvoiced} />
          <SiteFinanceInfoItem icon={<MonetizationOnIcon />} title="Total Payment:" amount={totalPayment} />
        </Box>
      </Box>
      <Popover {...popoverProps} open={anchorEl?.id === 'quick-stats'} data-testid="quck-stats-popover">
        <QuickStatsPopover tableData={tableData} />
      </Popover>
      <Box paddingTop="8px">
        <Typography variant="h6" lineHeight="45px">
          Aged Accounts Receivable
        </Typography>
        <Box
          display="flex"
          gap="16px"
          flexWrap="wrap"
          width="fit-content"
          paddingBottom="8px"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          id="aged-account-receivable"
          data-testid="aged-account-receivable"
        >
          <SiteFinanceInfoItem icon={<MoneyOffIcon />} title="Unpaid Invoice:" amount={unpaidInvoice} />
          <SiteFinanceInfoItem icon={<DateRangeIcon />} title="0-30 Days:" amount={unpaidInvoice030Days} />
          <SiteFinanceInfoItem icon={<DateRangeIcon />} title="30-60 Days:" amount={unpaidInvoice3060Days} />
          <SiteFinanceInfoItem icon={<DateRangeIcon />} title="60-90 Days:" amount={unpaidInvoice6090Days} />
          <SiteFinanceInfoItem icon={<DateRangeIcon />} title=">90 Days:" amount={unpaidInvoice90Days} />
        </Box>
      </Box>
      <Popover
        {...popoverProps}
        open={anchorEl?.id === 'aged-account-receivable'}
        data-testid="aged-account-receivable-popover"
      >
        <WithholdingAmountsPerAgedARPopover tableData={tableData} />
      </Popover>
    </Box>
  );
};
