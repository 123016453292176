import { uniq, uniqBy } from 'lodash/array';
import { flatMap, reduce, size } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import uuid from 'uuid/v4';

import { getAllCustomItems, getAllOptionalItems } from '../ProtocolItemGroupFieldConfigurationService';

import { CLT_HIDE, CLT_SHOW } from './constants';
import {
  collectFieldValueOptions,
  generateCondition,
  generateConditionsMap,
  getFieldByName,
  getFieldLabel
} from './services';

export function collectDataForNewTableIg(field, generalFields, fieldList, domainConditionalLogic) {
  const allFields = [...generalFields, ...fieldList],
    fieldConfigurationResponse = field.fieldConfigurationResponse;

  const hideAllFieldsConfig = getHideAllFieldsConfig(field);

  return getConditionalLogicSetupData(
    field,
    allFields,
    fieldConfigurationResponse,
    domainConditionalLogic,
    hideAllFieldsConfig,
    true
  );
}

export function collectDataForLegacyIg(field, allInputs, domainConditionalLogic) {
  const checkedOptionalItems = getAllOptionalItems(allInputs),
    customItems = getAllCustomItems(allInputs),
    allFields = [...checkedOptionalItems, ...customItems],
    fieldConfigurationResponse = field?.fieldConfigurationResponse ? [field.fieldConfigurationResponse] : null;

  return getConditionalLogicSetupData(
    field,
    allFields,
    fieldConfigurationResponse,
    domainConditionalLogic,
    null,
    false
  );
}

function getConditionalLogicSetupData(
  field,
  allFields,
  fieldConfigurationResponse,
  domainConditionalLogic,
  hideAllFieldsConfig,
  isNewTable
) {
  const fieldLabel = getFieldLabel(field);
  const conditions = collectConditions(allFields, fieldConfigurationResponse);
  const allAccessibleFields = collectAllAccessibleFields(field, allFields);
  const [domainTargetFields, domainConditions] = prepareDomainConfig(
    domainConditionalLogic,
    fieldLabel,
    allAccessibleFields
  );

  return {
    enabled: !isEmpty(conditions) || !isEmpty(domainConditions),
    fieldLabel,
    styledFieldLabel: field.styledFieldName,
    inputType: field.inputType,
    allAccessibleFields,
    conditions,
    domainTargetFields,
    domainConditions,
    domainConditionsMap: generateConditionsMap(domainConditions),
    hideAllFieldsConfig,
    isNewTable
  };
}

function collectAllAccessibleFields(field, allFields) {
  if (isEmpty(allFields)) {
    return [];
  }

  return allFields.filter(function({ isChecked, inputType, name, updatedQuestion, codeDefinationList }) {
    /* TODO: will be good to show this filed as disabled with duplication label */
    const hasDuplication = size(uniqBy(codeDefinationList, 'name')) !== size(codeDefinationList);
    if (name === field.name && updatedQuestion === field.updatedQuestion) {
      return false;
    }
    if (hasDuplication) {
      return false;
    }
    return isChecked && ['radio', 'dropdown', 'dropdownCT', 'multiselect', 'multiselectCT'].includes(inputType);
  });
}

function collectConditions(allFields, fieldConfigurationResponse) {
  if (isEmpty(fieldConfigurationResponse)) {
    return [];
  }

  return fieldConfigurationResponse.map(function(config) {
    const condition = generateCondition(config);
    const field = getFieldByName(allFields, condition.conditionFieldName);
    if (field && field.hideAllConfiguration) {
      condition.hideAllFields = true;
    }
    return condition;
  });
}

function getHideAllFieldsConfig(field) {
  const { inputType, sequence, hideAllConfiguration } = field;

  const hideAllFieldsConfig = {
    allowed: (inputType === 'radio' && sequence < 2) || !!hideAllConfiguration?.hideAllFieldsBelow,
    enabled: false,
    answer: null,
    options: null
  };

  if (hideAllFieldsConfig.allowed) {
    hideAllFieldsConfig.enabled = hideAllConfiguration?.hideAllFieldsBelow;
    hideAllFieldsConfig.answer = hideAllConfiguration?.hideAllFieldsBelowOnAnswer;
    hideAllFieldsConfig.options = collectFieldValueOptions(field);
  }

  return hideAllFieldsConfig;
}

function prepareDomainConfig(domainConditionalLogic, fieldLabel, allAccessibleFields) {
  return reduce(
    domainConditionalLogic,
    function(accumulator, { conditionalLogicFieldsConfigs, sourceIdentifier }) {
      if (sourceIdentifier === fieldLabel) {
        accumulator[0].push(
          ...uniq(
            flatMap(
              conditionalLogicFieldsConfigs,
              function({ questions }) {
                return questions;
              },
              []
            )
          )
        );
      }

      const field = getFieldByName(allAccessibleFields, sourceIdentifier);

      if (!field) {
        return accumulator;
      }

      const matchedConfigurations = conditionalLogicFieldsConfigs.filter(function({ questions }) {
        return questions.includes(fieldLabel);
      });

      const domainConditions = matchedConfigurations.map(function({ answers, isHideCondition }) {
        const preparedAnswers = reduce(
          field.codeDefinationList,
          function(accumulator, { decode, name }) {
            if (answers.includes(decode)) {
              return [...accumulator, name];
            }
            return accumulator;
          },
          []
        );
        return {
          conditionId: uuid(),
          conditionFieldName: sourceIdentifier,
          conditionType: isHideCondition ? CLT_HIDE : CLT_SHOW,
          conditionFieldAnswer: preparedAnswers,
          conditionFieldSequence: null
        };
      });

      accumulator[1].push(...domainConditions);

      return accumulator;
    },
    [[], []]
  );
}
