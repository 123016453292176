import React from 'react';

import { getNormalizedDate } from '../../ExpensesServices';

import { CommentSection } from './CommentSection/CommentSection';
import { ExpenseInfoItem } from './ExpenseInfoItem/ExpenseInfoItem';

export const FullFixedExpenseInformation = ({
  editCommentMode,
  editedComment,
  setEditedComment,
  setEditCommentMode,
  editedExpenseFixed,
  isUserHaveAccessToManageExpenses
}) => {
  return (
    <>
      <CommentSection
        editCommentMode={editCommentMode}
        editedComment={editedComment}
        setEditedComment={setEditedComment}
        comments={editedExpenseFixed?.comments}
        setEditCommentMode={setEditCommentMode}
        isUserHaveAccessToManageExpenses={isUserHaveAccessToManageExpenses}
      />
      <ExpenseInfoItem label="Event Date" text={getNormalizedDate(editedExpenseFixed?.date)} />
      <ExpenseInfoItem label="Revenue Date" text={getNormalizedDate(editedExpenseFixed?.elligoRevenueDate)} />
      <ExpenseInfoItem label="Fixed Expense - Event Name" text={editedExpenseFixed?.event || 'N/A'} />
      {isUserHaveAccessToManageExpenses && (
        <ExpenseInfoItem label="Amount" text={`$${editedExpenseFixed?.clientAmount.toLocaleString()}` || 'N/A'} />
      )}
      <ExpenseInfoItem label="Status" text={editedExpenseFixed?.eventStatus || 'N/A'} />
      <ExpenseInfoItem label="Added By" text={editedExpenseFixed?.addedBy || 'N/A'} />
    </>
  );
};
