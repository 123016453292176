import React, { useRef, useState } from 'react';
import cx from 'classnames';

import Button from '../../../../../../common/general/Button';
import Icon from '../../../../../../common/general/Icon';
import useOutsideClickDetector from '../../../../../../common/hooks/useOutsideClickDetector';

import './EncounterModificationMenu.scss';

export function EncounterModificationMenu({
  cancelEncounterAvailable,
  cancelEncounter,
  reopenEncounter,
  markItemGroupsNotPerformedAndNotAsked,
  notPerformAllItemsAvailable,
  showCloseEncounterOption,
  isUnexpectedEncounter
}) {
  const ddContainer = useRef(null);

  const [show, setShow] = useState(false);
  useOutsideClickDetector(ddContainer, () => {
    setShow(false);
  });

  return (
    <div
      ref={ddContainer}
      className={cx('ml-2 dropdown', {
        show: show
      })}
    >
      <Button
        priority="low"
        size="h40"
        id="more-options"
        onClick={function() {
          setShow(show => !show);
        }}
      >
        <Icon>more_vert</Icon>
      </Button>
      <div
        className={cx('dropdown-menu encounter-modification-dropdown-menu dropdown-menu-right border-0 py-0', {
          show: show
        })}
        role="menu"
        style={
          show
            ? {
                width: '250px',
                display: 'block',
                position: 'absolute',
                transform: 'translate3d(-208px, 40px, 0px)',
                top: '0px',
                left: ' 0px',
                willChange: 'transform'
              }
            : {}
        }
      >
        <button
          className="dropdown-item encounter-modification-dropdown-option py-1"
          onClick={() => {
            if (cancelEncounterAvailable) {
              cancelEncounter();
            } else {
              reopenEncounter();
            }
          }}
        >
          {(cancelEncounterAvailable && (
            <OptionWithHint label="Cancel Encounter" hint="For encounters created in error" />
          )) ||
            'Reopen Encounter'}
        </button>
        {!isUnexpectedEncounter && (
          <button
            className="dropdown-item encounter-modification-dropdown-option py-1"
            disabled={!notPerformAllItemsAvailable || !cancelEncounterAvailable || !showCloseEncounterOption}
            onClick={() => markItemGroupsNotPerformedAndNotAsked()}
          >
            <OptionWithHint label="Close Encounter" hint="Quickly complete all open procedures" />
          </button>
        )}
      </div>
    </div>
  );
}

function OptionWithHint({ label, hint }) {
  return (
    <div>
      <div>{label}</div>
      <div className="hint">{hint}</div>
    </div>
  );
}
