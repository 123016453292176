import React, { useMemo } from 'react';
import { sum } from 'lodash';
import { uniq } from 'lodash/array';

import PatientStipendWorklistApi from '../../../../../api/patient/PatientStipendWorklistApi';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { MANAGE_STIPEND_WORKLIST } from '../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../services/auth';
import { toBill } from '../../../../../services/financial';
import { onRequestError } from '../../../../../services/handlers';
import { STP_APPROVED, STP_DENIED } from '../constants';

import './SelectedItemsMenu.scss';

export const SelectedItemsMenu = ({ gridData, setGridData, selectedIds, setRowSelectionModel }) => {
  const selectedItems = useMemo(() => {
    return gridData.filter(row => selectedIds.includes(row.id));
  }, [gridData, selectedIds]);

  const selectedStudyName = useMemo(() => {
    return uniq(selectedItems.map(row => row.studyName));
  }, [selectedItems]);

  const selectedSitesCount = useMemo(() => {
    return uniq(selectedItems.map(row => row.siteName)).length;
  }, [selectedItems]);

  const selectedItemsAmount = useMemo(() => {
    return sum(selectedItems.map(row => row.totalAmount));
  }, [selectedItems]);

  function updateRowsStatusHandler(status) {
    PatientStipendWorklistApi.updateWorklistRowsStatus(status, selectedIds)
      .then(({ data }) => {
        if (status === STP_APPROVED) {
          NotificationManager.success('Stipends approved successfully');
        }
        if (status === STP_DENIED) {
          NotificationManager.success('Payment denied');
        }
        setGridData(function(state) {
          return state.map(function(r) {
            if (!selectedIds.includes(r.id)) return r;
            const updatedRow = data.find(d => d.id === r.id);
            return {
              ...r,
              ...updatedRow
            };
          });
        });
        setRowSelectionModel([]);
      })
      .catch(onRequestError);
  }

  function openUpdateRowsStatusModal(status) {
    ModalBoxes.confirm({
      title: `Confirm ${status === STP_APPROVED ? 'Approval' : 'Denial'} of Patient Stipend`,
      content: (
        <div>
          <b>
            You have selected {selectedIds.length} patient stipends to be {status} for the {selectedStudyName} study and{' '}
            {selectedSitesCount} sites. These stipends has a total amount of ${toBill(selectedItemsAmount)}
          </b>
          <br />
          <br />
          Do you wish to Continue?
        </div>
      ),
      confirmButton: 'Yes',
      cancelButton: 'No'
    }).then(
      () => {
        updateRowsStatusHandler(status);
      },
      () => {}
    );
  }

  return selectedItems?.length ? (
    <div className="patient-stipend-worklist-selected-items">
      <div>{`${selectedItems.length} stipend(s) selected`}</div>
      <div>
        {userHasAccessTo(MANAGE_STIPEND_WORKLIST) && (
          <>
            <Button priority="low" size="h28" onClick={() => openUpdateRowsStatusModal(STP_APPROVED)}>
              Approve
            </Button>
            <Button priority="low" size="h28" onClick={() => openUpdateRowsStatusModal(STP_DENIED)}>
              Deny
            </Button>
          </>
        )}
      </div>
    </div>
  ) : null;
};
