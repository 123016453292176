import { useEffect } from 'react';
import MultiSelect from 'common/data-entry/MultiSelect/MultiSelect';

import {
  initializeProcessor,
  setValueProcessor,
  syncCacheProcessor
} from 'components/eui/EuiFiltersContext/processors';
import { FRS_LOADED, FRS_READY, FRS_UNINITIALIZED } from 'components/eui/EuiFiltersContext/reducer';

export default function BasicMultiselect({ uniqKey, state, dispatch, getOptions = defaultGetOptions, label, ...rest }) {
  const fState = state[uniqKey];
  const fCache = state.cache[uniqKey];
  const status = state.schema[uniqKey].status;

  useEffect(
    function() {
      if (status === FRS_UNINITIALIZED) {
        getOptions().then(function(payload) {
          dispatch({
            type: 'PERFORM',
            processor: initializeProcessor,
            payload: {
              key: uniqKey,
              ...payload
            }
          });
        });
      }
    },
    [uniqKey, dispatch, status, getOptions]
  );

  useEffect(
    function() {
      if (status !== FRS_LOADED) {
        return;
      }

      dispatch({
        type: 'PERFORM',
        processor: syncCacheProcessor,
        payload: { [uniqKey]: fCache }
      });
    },
    [dispatch, uniqKey, status, fCache]
  );

  if (status !== FRS_READY) return null;

  const { source, value } = fState;
  return (
    <MultiSelect
      data-testid={rest['data-testid']}
      label={label}
      searchable
      clearSearchOnSelection
      dataSource={source}
      onChange={selectedOptions => {
        dispatch({
          type: 'PERFORM',
          processor: setValueProcessor,
          payload: {
            key: uniqKey,
            value: selectedOptions
          }
        });
      }}
      value={value}
      validate={false}
    />
  );
}

function defaultGetOptions() {
  return Promise.resolve();
}
