import { find, reduce } from 'lodash/collection';
import { isArray, isBoolean, isEmpty, isEqual } from 'lodash/lang';
import uuid from 'uuid/v4';

import { CLT_HIDE, CLT_SHOW } from './constants';

export function getFieldLabel({ updatedQuestion, question, name } = {}) {
  return updatedQuestion || question || name;
}

export function getFieldByName(allAccessibleFields, conditionFieldName) {
  return find(allAccessibleFields, ['name', conditionFieldName]);
}

export function generateCondition(config) {
  const isHideCondition = isBoolean(config?.isHideCondition) ? config?.isHideCondition : true;
  return {
    conditionId: uuid(),
    conditionFieldName: config?.conditionFieldName || config?.conditionField?.name || null,
    conditionType: isHideCondition ? CLT_HIDE : CLT_SHOW,
    conditionFieldAnswer: config?.conditionFieldAnswerNames || null,
    conditionFieldSequence: config?.conditionFieldSequence || null
  };
}

export function generateConditionsMap(conditions) {
  return reduce(
    conditions,
    function(accumulator, condition) {
      accumulator[condition.conditionFieldName] = condition;
      return accumulator;
    },
    {}
  );
}

export function collectFieldValue(allAccessibleFields, conditionFieldName) {
  if (isEmpty(allAccessibleFields) || !conditionFieldName) {
    return null;
  }

  const field = getFieldByName(allAccessibleFields, conditionFieldName);

  if (!field) {
    return null;
  }

  return {
    type: field.inputType,
    options: collectFieldValueOptions(field)
  };
}

export function collectFieldValueOptions(field) {
  if (!field) return null;
  return field.codeDefinationList.map(e => {
    return { name: e.name, value: e.name };
  });
}

export function compareConditionFieldAnswerNames(a, b) {
  return isEqual(a, b);
}

export function conditionFieldAnswerNamesIncludes(answerNames, name) {
  if (isEmpty(answerNames) || isEmpty(name)) {
    return false;
  }
  return answerNames.includes(name);
}

export function createConditionFieldAnswerNames(name) {
  if (isEmpty(name)) {
    return null;
  }
  if (isArray(name)) {
    return name;
  }
  return [name];
}

export function extractConditionFieldAnswerNames(answerNames) {
  if (isEmpty(answerNames)) {
    return null;
  }
  return answerNames.toString();
}
