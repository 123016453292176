import React from 'react';
import { Button } from '@mui/material';
import { isFunction } from 'lodash/lang';
import * as PropTypes from 'prop-types';

import ModalBoxes from '../../../../../../common/feedback/ModalBoxes/ModalBoxes';
import { MANAGE_BUDGETS } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';

function BudgetEventControlButtons({ onClose, onSave, isValidForm, currentStudyBudget, onSaveAndContinue }) {
  const isEditingForbidden =
    (currentStudyBudget && !currentStudyBudget.editPossibility) || !userHasAccessTo(MANAGE_BUDGETS);
  const buttonsStyles = { textTransform: 'none', lineHeight: 'unset' };
  return (
    <ModalBoxes.Footer>
      <Button sx={buttonsStyles} id="bem-cancel" onClick={onClose}>
        {isEditingForbidden ? 'Close' : 'Cancel'}
      </Button>
      {!isEditingForbidden && (
        <>
          {isFunction(onSaveAndContinue) && (
            <Button
              sx={buttonsStyles}
              id="bem-save"
              onClick={onSaveAndContinue}
              disabled={!isValidForm()}
              data-testid="save-and-add-another"
            >
              Save and Add Another
            </Button>
          )}
          <Button sx={buttonsStyles} variant="contained" id="bem-save" onClick={onSave} disabled={!isValidForm()}>
            Save
          </Button>
        </>
      )}
    </ModalBoxes.Footer>
  );
}

BudgetEventControlButtons.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isValidForm: PropTypes.func,
  currentStudyBudget: PropTypes.object
};

export default BudgetEventControlButtons;
