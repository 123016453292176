import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import { sortBy } from 'lodash';
import { first } from 'lodash/array';
import { isEmpty } from 'lodash/lang';

import { ProtocolApi, StudyApi, StudyProtocolApi } from '../../../../api';
import RoundIconButton from '../../../../common/buttons/RoundIconButton';
import Common from '../../../../common/common';
import Select from '../../../../common/data-entry/Select';
import ModalBoxes from '../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../common/general/Button';
import ButtonGroup from '../../../../common/general/ButtonGroup';
import Icon from '../../../../common/general/Icon';
import NotificationManager from '../../../../common/notifications/NotificationManager';
import StyledTooltip from '../../../../common/widgets/StyledToolTip';
import { PROTOCOL_COPIED_TO_STUDY } from '../../../../constants/notificationMessages';
import {
  COPY_PROTOCOL_VERSION,
  MANAGE_PROTOCOL_VERSIONS,
  VIEW_PROTOCOL_VERSIONS_AUDIT_TRAIL
} from '../../../../constants/userOperations';
import { withFilterContext } from '../../../../contexts/filters/FiltersContext';
import { pendoTrackDefaultSortingChange } from '../../../../pendo/PendoUtils';
import { userHasAccessTo } from '../../../../services/auth';
import { onRequestError } from '../../../../services/handlers';
import { PageInfoHeader } from '../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey } from '../../../root/router';

import AuditTrailPopup from './AuditTrailPopup/AuditTrailPopup';
import CopyProtocol from './CopyProtocol/CopyProtocol';
import { ACTIVE, RETIRED } from './ProtocolStatuses';

import 'react-table/react-table.css';
import './ProtocolDetails.scss';

const FILTER_KEY = 'setupProtocol';
const PROTOCOL_TABLE_DATA = 'PATIENTS_TABLE_DATA';
class ProtocolDetails extends Component {
  versionDetailsColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: row => {
        const url = this.generateUrl(row);
        return !userHasAccessTo(MANAGE_PROTOCOL_VERSIONS) && row.original.status === 'Draft' ? (
          row.row.name
        ) : (
          <NavLink to={url} title={row.row.name}>
            {row.row.name}{' '}
          </NavLink>
        );
      }
    },
    {
      Header: 'Versions',
      accessor: 'version',
      width: 100,
      Cell: row => {
        const url = this.generateUrl(row);
        const rowVersionWithLink = row.row.version ? <NavLink to={url}>{row.row.version} </NavLink> : '--';
        return !userHasAccessTo(MANAGE_PROTOCOL_VERSIONS) && row.original.status === 'Draft'
          ? row.row.version
          : rowVersionWithLink;
      },
      sortMethod: (a, b) => {
        if (a.versionId < b.versionId) {
          return -1;
        }
        if (a.versionId > b.versionId) {
          return 1;
        }
        return 0;
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 100,
      Cell: row => {
        return row.original.status ? <span>{row.original.status}</span> : '--';
      }
    },
    {
      Header: 'Publish Date',
      accessor: 'publishDate',
      width: 120,
      Cell: row => {
        return row.original.publishDate ? <span>{Common.formatDate(row.original.publishDate)}</span> : '--';
      },
      sortMethod: Common.dateComparator
    },
    {
      Header: 'Terminology Versions',
      accessor: 'terminologyVersionSet',
      Cell: row => {
        if (row.original.terminologyVersionSet && row.original.terminologyVersionSet.length) {
          return row.original.terminologyVersionSet.map(e => <div key={e.id}>{`${e.type} (${e.version})`}</div>);
        }
        return '--';
      }
    },
    {
      Header: 'Last Updated By',
      accessor: 'performUser',
      Cell: row => {
        return row.original.performUser ? <span>{row.original.performUser}</span> : '--';
      }
    },
    {
      Header: 'Comments',
      accessor: 'comment',
      Cell: row => {
        return (
          <div className="comments-column">
            <StyledTooltip
              className="tooltip-note-value"
              overlay={<div className="note-value">{row.original.comment}</div>}
              placement="top"
            >
              <div className="value-label">{row.original.comment}</div>
            </StyledTooltip>
          </div>
        );
      }
    },
    {
      Header: 'Actions',
      minWidth: 57,
      maxWidth: 90,
      Cell: ({ original: { studyIdentifier, uniqueIdentifier, status } }) => {
        return (
          <div className="eds-btn-group">
            {userHasAccessTo(COPY_PROTOCOL_VERSION) && (
              <RoundIconButton
                onClick={() => {
                  this.openCopyProtocol(studyIdentifier, uniqueIdentifier);
                }}
                title="Copy"
              >
                file_copy
              </RoundIconButton>
            )}
            {userHasAccessTo(MANAGE_PROTOCOL_VERSIONS) && status === 'Draft' ? (
              <RoundIconButton
                onClick={() => {
                  this.openDeleteConfirmWindow(studyIdentifier, uniqueIdentifier);
                }}
                title="Delete"
              >
                delete
              </RoundIconButton>
            ) : (
              ''
            )}
          </div>
        );
      }
    }
  ];

  generateUrl(row) {
    const params = {
      studyId: row.original.studyIdentifier,
      protocolIdentity: row.original.uniqueIdentifier
    };
    if (row.original.status === ACTIVE || row.original.status === RETIRED) {
      return generateUrlByKey('Setup Protocol.Source Data Preview', params);
    } else {
      return generateUrlByKey('Setup Protocol.Source Data Setup', params);
    }
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      studyListItems: [],
      studyVersionData: [],
      hasAlreadyDraft: false,
      studyTo: null,
      pageSize: sessionStorage.getItem(PROTOCOL_TABLE_DATA)
        ? JSON.parse(sessionStorage.getItem(PROTOCOL_TABLE_DATA))?.pageSize
        : 25 //oneOf([25, 50, 100])
    };
  }

  // get protocolversion list
  getStudyProtocolLists(data, selectedObj) {
    ProtocolApi.getProtocols(data).then(
      res => {
        if (res.data.responsecode === 200 && res.data.status === 'success' && typeof res.data.response === 'object') {
          res.data &&
            res.data.response.forEach((vx, ix) => {
              vx.studyName = this.getSelectedStudy().name;
            });

          this.setState({ studyVersionData: res.data.response });
          const filterDraftDt =
            res.data.response &&
            res.data.response.filter(o => {
              return o.status === 'Draft';
            });
          filterDraftDt.length > 0
            ? this.setState({ hasAlreadyDraft: true })
            : this.setState({ hasAlreadyDraft: false });
          this.renderDraftButton();
        } else {
          this.setState({ studyVersionData: [], hasAlreadyDraft: false });
        }

        if (this.props.filters.studyUniqueIdentifier !== selectedObj.uniqueIdentifier) {
          this.props.setFilterByKey(FILTER_KEY, { studyUniqueIdentifier: selectedObj.uniqueIdentifier });
        }
      },
      error => {
        this.props.setFilterByKey(FILTER_KEY, { studyUniqueIdentifier: selectedObj.uniqueIdentifier });
        this.setState({ studyVersionData: [], hasAlreadyDraft: false });
      }
    );
  }

  siteStudyChangedHandler = selectedObj => {
    if (selectedObj) {
      const data = selectedObj.id;
      this.getStudyProtocolLists(data, selectedObj);
    }
  };

  getListsForDraft(studyData) {
    ProtocolApi.getProtocols(studyData).then(
      res => {
        if (res.data.responsecode === 200 && res.data.status === 'success' && typeof res.data.response === 'object') {
          res.data &&
            res.data.response.forEach((vx, ix) => {
              vx.studyName = this.getSelectedStudy().name;
            });
          this.setState({ studyVersionData: res.data.response });
          const filterDraftDt =
            res.data &&
            res.data.response.filter(o => {
              return o.status === 'Draft';
            });
          filterDraftDt.length > 0
            ? this.setState({ hasAlreadyDraft: true })
            : this.setState({ hasAlreadyDraft: false });
        }
      },
      error => {
        this.setState({ studyVersionData: [] });
        this.setState({ hasAlreadyDraft: false });
      }
    );
  }

  addNewDraft() {
    //TODO replace with Unique Identifier
    const data = this.getSelectedStudy().id;
    ProtocolApi.createDraftProtocolForStudy(data)
      .then(() => {
        this.getListsForDraft(data);
      })
      .catch(error => {
        NotificationManager.error(error.response.data.message);
      });
  }

  renderDraftButton() {
    if (this.getSelectedStudy() && userHasAccessTo(MANAGE_PROTOCOL_VERSIONS)) {
      return !this.state.hasAlreadyDraft ? (
        <Button size="h28" priority="high" onClick={this.addNewDraft.bind(this)}>
          <Icon>add</Icon>
          Draft
        </Button>
      ) : (
        ''
      );
    }
  }

  componentDidMount() {
    StudyApi.populateStudyList().then(res => {
      if (res.data.responsecode === 200 && res.data.status === 'success' && typeof res.data.response === 'object') {
        res &&
          res.data.response.forEach(d => {
            d.id = d.uniqueIdentifier;
            d.name = d.studyName;
          });
        this.setState({ studyListItems: res.data.response });
      }
    });
  }

  onAuditTrailClick = () => {
    ModalBoxes.open({
      title: 'Audit Trail',
      component: <AuditTrailPopup selectedStudy={this.getSelectedStudy().id} />
    });
  };

  handleStudyChange = studyTo => {
    this.setState({ studyTo });
  };

  copyProtocol = (fromStudy, protocolId, useLatestTerminologyVersions) => {
    const {
      studyTo: { uniqueIdentifier: toStudy, studyName }
    } = this.state;
    StudyProtocolApi.copy(protocolId, fromStudy, toStudy, useLatestTerminologyVersions).then(res => {
      NotificationManager.success(`${PROTOCOL_COPIED_TO_STUDY} ${studyName}`);
    }, onRequestError);
  };

  openCopyProtocol = (fromStudy, protocolId) => {
    ModalBoxes.open({
      component: (
        <CopyProtocol
          handleStudyChange={this.handleStudyChange}
          copyProtocol={this.copyProtocol}
          fromStudy={fromStudy}
          protocolId={protocolId}
        />
      )
    });
  };

  deleteDraftProtocol = (studyId, uniqueIdentifier) => {
    ProtocolApi.deleteDraftProtocol(studyId, uniqueIdentifier).then(() => {
      ProtocolApi.getProtocols(this.getSelectedStudy().id).then(({ data: { response } }) => {
        let studyVersionData = [];
        if (Array.isArray(response)) {
          studyVersionData = response.map(vx => {
            return { ...vx, ...{ studyName: this.getSelectedStudy().name } };
          });
        }
        this.setState({ studyVersionData: studyVersionData, hasAlreadyDraft: false });
      });
    }, onRequestError);
  };

  openDeleteConfirmWindow = (studyId, uniqueIdentifier) => {
    ModalBoxes.confirm({
      content: 'Are you sure you want to delete this record?',
      confirmButton: 'Yes',
      cancelButton: 'No'
    }).then(
      () => {
        this.deleteDraftProtocol(studyId, uniqueIdentifier);
      },
      () => {}
    );
  };

  getSelectedStudy() {
    if (isEmpty(this.state.studyListItems)) {
      return null;
    }

    const uniqueIdentifier = this.props.filters.studyUniqueIdentifier;
    if (isEmpty(uniqueIdentifier)) {
      return first(this.state.studyListItems);
    }

    return this.state.studyListItems.find(s => s.uniqueIdentifier === uniqueIdentifier);
  }

  render() {
    return (
      <div className="footpadb">
        <PageInfoHeader
          right={
            <ButtonGroup>
              {this.renderDraftButton()}
              {userHasAccessTo(VIEW_PROTOCOL_VERSIONS_AUDIT_TRAIL) && (
                <Button size="h28" priority="medium" onClick={this.onAuditTrailClick}>
                  Audit
                </Button>
              )}
            </ButtonGroup>
          }
        >
          <div className="general-header-group-container general-header-wrapper">
            <Select
              dataSource={this.state.studyListItems ? sortBy(this.state.studyListItems, ['name']) : []}
              value={this.getSelectedStudy()}
              onChange={this.siteStudyChangedHandler}
              label="Study"
              searchable={true}
              closeOnSelectedOptionClick={false}
              deselectOnSelectedOptionClick={false}
              clearable={false}
              validate={false}
            />
          </div>
        </PageInfoHeader>
        <section className="border mt-2">
          <div className="row col-12 m-0 px-3 mt-3">
            <h5 className=" c-p">Source Data Version Details</h5>
            <div className="col-12 justify-content-between border-bottom-dotted m-0 px-0" />
          </div>
          <div className="row px-3 pt-2 pb-3 m-0">
            <div className="col-12 table-border p-0">
              <ReactTable
                data={this.state.studyVersionData}
                columns={this.versionDetailsColumns}
                minRows={1}
                onSortedChange={pendoTrackDefaultSortingChange}
                multiSort
                onPageSizeChange={pageSize => {
                  this.setState({ pageSize: pageSize });
                  sessionStorage.setItem(PROTOCOL_TABLE_DATA, JSON.stringify({ pageSize: pageSize }));
                }}
                className="table activity-table table-responsive-sm m-0 setup-protocol-table"
                showPagination
                noDataText="No Record Found"
                nextText=">>"
                previousText="<<"
                pageSizeOptions={[25, 50, 100]}
                defaultPageSize={this.state.pageSize}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withFilterContext(FILTER_KEY, ProtocolDetails);
