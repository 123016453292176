import React from 'react';
import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import cx from 'classnames';
import { TextNode } from 'lexical';

import { ExtendedTextNode } from '../../inputTextFormat/ExtendedTextNode/ExtendedtextNode';

import { OnCharsCountPlugin } from './plugins/OnCharsCountPlugin';
import OnEditorChangePlugin from './plugins/OnEditorChangePlugin';
import OnLoadEditorContentPlugin from './plugins/OnLoadEditorContentPlugin';
import { RichTextEditorToolbarPlugin } from './plugins/RichTextEditorToolbarPlugin';

import './RichTextEditor.scss';

const baseConfig = {
  namespace: 'RichTextEditor',
  theme: {
    list: {
      nested: {
        listitem: 'text-editor-nested-listitem'
      },
      ol: 'text-editor-list-ol',
      ul: 'text-editor-list-ul',
      listitem: 'text-editor-listitem'
    },
    ltr: 'editor-ltr',
    rtl: 'editor-rtl',
    paragraph: 'text-editor-paragraph',
    placeholder: 'text-editor-placeholder',
    text: {
      bold: 'text-editor-text-bold',
      italic: 'text-editor-text-italic',
      strikethrough: 'text-editor-text-strikethrough',
      underline: 'text-editor-text-underline',
      underlineStrikethrough: 'text-editor-text-underlineStrikethrough'
    }
  },
  nodes: [
    ExtendedTextNode,
    { replace: TextNode, with: node => new ExtendedTextNode(node.__text, node.__key) },
    ListNode,
    ListItemNode
  ],
  editable: true,
  onError: err => console.log(err)
};

export function RichTextEditor({ className, onChange, value }) {
  return (
    <LexicalComposer initialConfig={baseConfig}>
      <div className={cx('text-editor-container', className)}>
        <RichTextEditorToolbarPlugin />
        <div className="text-editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="text-editor-input" />}
            placeholder={() => <div />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnLoadEditorContentPlugin htmlString={value} />
          <OnEditorChangePlugin onValueChange={onChange} />
          <OnCharsCountPlugin maxSize={3000} />
          <ListPlugin />
          <HistoryPlugin />
        </div>
      </div>
    </LexicalComposer>
  );
}
