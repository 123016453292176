import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { difference, uniqBy } from 'lodash/array';

import { FinInvoiceApi } from '../../../../../../api';
import Button from '../../../../../../common/general/Button';
import NotificationManager from '../../../../../../common/notifications/NotificationManager';
import { SOMETHING_WENT_WRONG } from '../../../../../../constants/notificationMessages';
import { MANAGE_INVOICE } from '../../../../../../constants/userOperations';
import { userHasAccessTo } from '../../../../../../services/auth';
import { InvoiceContext } from '../../NewInvoiceContext';
import { CREDIT_MEMO, DEBIT_MEMO, PARENT } from '../TableConstants';

import './SelectedItemsMenu.scss';

export const SelectedItemsMenu = () => {
  const { getDataForCreateInvoice, selectedEventIds, ledgerEvents } = useContext(InvoiceContext);

  const [isConfigurationInvalid, setIsConfigurationInvalid] = useState(true);

  const navigate = useNavigate();

  const selectedEvents = useMemo(
    () => ledgerEvents.filter(ledgerEvent => selectedEventIds.includes(ledgerEvent.tableId)),
    [ledgerEvents, selectedEventIds]
  );

  const countOfSelectedInvoicesWithoutDateOrNumber = useMemo(
    () => selectedEvents.filter(e => !e.invoiceDate || !e.invoiceNumber).length,
    [selectedEvents]
  );
  const allUniqueSSUsForSelectedInvoices = useMemo(() => uniqBy(selectedEvents, e => e.ssuId), [selectedEvents]);
  const allUniqueClientsForSelectedInvoices = useMemo(() => uniqBy(selectedEvents, e => e.clientName), [
    selectedEvents
  ]);
  const parentIdsForSelectedWithholdingEvents = useMemo(
    () =>
      selectedEventIds
        .filter(tableId => {
          return tableId.search(/-WH$/) !== -1;
        })
        .map(el => el.replace(/-WH$/, '')),
    [selectedEventIds]
  );
  const allSelectedWithholdingEventsHasSelectedParent = useMemo(() => {
    return difference(parentIdsForSelectedWithholdingEvents, selectedEventIds).length === 0;
  }, [parentIdsForSelectedWithholdingEvents, selectedEventIds]);

  const selectedCMDMEvents = useMemo(
    () => selectedEvents.filter(el => [CREDIT_MEMO, DEBIT_MEMO].includes(el.invoiceType)),
    [selectedEvents]
  );
  const eventNumbersForSelectedCMDMEvents = useMemo(() => selectedCMDMEvents.map(el => el.eventNumber), [
    selectedCMDMEvents
  ]);
  const parentsForSelectedCMDMEvents = useMemo(
    () =>
      ledgerEvents.filter(
        el => eventNumbersForSelectedCMDMEvents.includes(el.eventNumber) && el.invoiceType === PARENT
      ),
    [eventNumbersForSelectedCMDMEvents, ledgerEvents]
  );
  const selectedParentEvents = useMemo(() => selectedEvents.filter(el => el.invoiceType === PARENT), [selectedEvents]);

  const allSelectedCMDMHasSelectedParent = useMemo(
    () =>
      difference(
        parentsForSelectedCMDMEvents.map(el => el.eventId),
        selectedParentEvents.map(el => el.eventId)
      ).length === 0 || parentsForSelectedCMDMEvents.length === 0,
    [parentsForSelectedCMDMEvents, selectedParentEvents]
  );

  const allSelectedEventsAreWithholdingAndHasParentWithTheSameInvoice = useMemo(() => {
    const allEventsAreWithholding = isEmpty(selectedEventIds.find(tableId => tableId.search(/-WH$/) === -1));
    const allParentsWithUniqueInvoiceNumber = uniqBy(
      ledgerEvents.filter(event => parentIdsForSelectedWithholdingEvents.includes(event.tableId)),
      'invoiceNumber'
    );
    return (
      allEventsAreWithholding &&
      allParentsWithUniqueInvoiceNumber.length === 1 &&
      !!allParentsWithUniqueInvoiceNumber[0].invoiceNumber
    );
  }, [ledgerEvents, parentIdsForSelectedWithholdingEvents, selectedEventIds]);

  const CMDMValidation = useMemo(() => {
    const selectedCMOrDMInvoice = [CREDIT_MEMO, DEBIT_MEMO].includes(selectedEvents[0]?.invoiceType);
    const selectedEventParentHasInvoice = !!ledgerEvents.find(
      ledgerEvent => ledgerEvent.eventNumber === selectedEvents[0]?.eventNumber && ledgerEvent.adjustmentSequence === 0
    )?.invoiceNumber;
    if (selectedEvents.length === 1 && selectedCMOrDMInvoice) {
      return !selectedEventParentHasInvoice;
    } else {
      return selectedEvents.length > 1 && !allSelectedCMDMHasSelectedParent;
    }
  }, [allSelectedCMDMHasSelectedParent, ledgerEvents, selectedEvents]);

  const parentsForSelectedWHEvents = useMemo(
    () => ledgerEvents.filter(el => parentIdsForSelectedWithholdingEvents.includes(el.tableId)),
    [ledgerEvents, parentIdsForSelectedWithholdingEvents]
  );

  useEffect(() => {
    if (
      ((isEmpty(parentsForSelectedWHEvents) ||
        parentsForSelectedWHEvents.length !== parentIdsForSelectedWithholdingEvents.length) &&
        !isEmpty(selectedEventIds.filter(tableId => tableId.search(/-WH$/) !== -1))) ||
      ((isEmpty(parentsForSelectedCMDMEvents) ||
        parentsForSelectedCMDMEvents.length !== uniqBy(selectedCMDMEvents, 'eventNumber').length) &&
        !isEmpty(selectedCMDMEvents))
    ) {
      const requestData = selectedEvents.map(ledgerEvent => ({
        eventNumber: ledgerEvent.eventNumber,
        invoiceType: ledgerEvent.invoiceType,
        budgetEventType: ledgerEvent.budgetEventType
      }));
      FinInvoiceApi.getSelectedLedgerEventValidation(requestData)
        .then(({ data }) => setIsConfigurationInvalid(!data))
        .catch(() => NotificationManager.error(SOMETHING_WENT_WRONG));
    } else {
      setIsConfigurationInvalid(
        !selectedEvents.every(item => !item.invoiceNumber) ||
          allUniqueSSUsForSelectedInvoices.length !== 1 ||
          allUniqueClientsForSelectedInvoices.length !== 1 ||
          (!allSelectedWithholdingEventsHasSelectedParent &&
            !allSelectedEventsAreWithholdingAndHasParentWithTheSameInvoice) ||
          CMDMValidation
      );
    }
  }, [
    CMDMValidation,
    allSelectedEventsAreWithholdingAndHasParentWithTheSameInvoice,
    allSelectedWithholdingEventsHasSelectedParent,
    allUniqueClientsForSelectedInvoices.length,
    allUniqueSSUsForSelectedInvoices.length,
    ledgerEvents,
    parentIdsForSelectedWithholdingEvents,
    parentsForSelectedCMDMEvents,
    parentsForSelectedWHEvents,
    selectedCMDMEvents,
    selectedEventIds,
    selectedEvents
  ]);

  return selectedEvents.length ? (
    <div className="invoice-selected-results-MUI">
      <div>{`${selectedEvents.length} Event IDs selected`}</div>
      <div>
        {userHasAccessTo(MANAGE_INVOICE) && (
          <>
            <Button
              priority="low"
              size="h28"
              onClick={() => {
                getDataForCreateInvoice(selectedEventIds);
              }}
              disabled={isConfigurationInvalid}
            >
              Create Invoice
            </Button>
            <Button
              priority="low"
              size="h28"
              onClick={() => {
                navigate('/invoice/reconcile', {
                  state: {
                    reconcileIds: selectedEvents.map(item => ({ eventId: item.eventId, invoiceType: item.invoiceType }))
                  }
                });
              }}
              disabled={
                allUniqueSSUsForSelectedInvoices.length !== 1 || countOfSelectedInvoicesWithoutDateOrNumber !== 0
              }
            >
              Reconcile
            </Button>
          </>
        )}
      </div>
    </div>
  ) : null;
};
