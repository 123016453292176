export function getSsuMilestoneDate(SSU, milestoneCode) {
  const startupRecord = SSU.studySiteStartups.filter(
    st => st.studySiteStartupCode.code === milestoneCode && st.status === 'Active'
  )[0];
  return startupRecord ? startupRecord.milestoneDate : undefined;
}

export function getSSUStartDate(SSU) {
  return getSsuMilestoneDate(SSU, 'SGL');
}

export function getSSUEndDate(SSU) {
  return getSsuMilestoneDate(SSU, 'LPI');
}
