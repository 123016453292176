import { PageInfoHeader } from '../../../../PageInfoHeader/PageInfoHeader';

import { SiteFinanceFilters } from './SiteFinanceFilters/SiteFinanceFilters';

export const SiteFinanceHeader = () => {
  return (
    <PageInfoHeader>
      <SiteFinanceFilters />
    </PageInfoHeader>
  );
};
