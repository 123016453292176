import { isEmpty } from 'lodash';

export const getSsuIdsByStudyIdsAndSiteIds = (ssus, studyIds, siteIds) => {
  if (!isEmpty(studyIds) && !isEmpty(siteIds)) {
    return ssus
      .filter(ssu => siteIds.includes(ssu.site.uniqueIdentifier))
      .filter(ssu => studyIds.includes(ssu.study.uniqueIdentifier))
      .map(ssu => ssu.uniqueIdentifier);
  }
  if (!isEmpty(studyIds) && isEmpty(siteIds)) {
    return ssus.filter(ssu => studyIds.includes(ssu.study.uniqueIdentifier)).map(ssu => ssu.uniqueIdentifier);
  }
  if (isEmpty(studyIds) && !isEmpty(siteIds)) {
    return ssus.filter(ssu => siteIds.includes(ssu.site.uniqueIdentifier)).map(ssu => ssu.uniqueIdentifier);
  }
  return ssus.map(ssu => ssu.uniqueIdentifier);
};
