import { SiteFinanceHeader } from './SiteFinanceHeader/SiteFinanceHeader';
import { SiteFinanceInfo } from './SiteFinanceInfo/SiteFinanceInfo';
import { SiteFinanceTable } from './SiteFinanceTable/SiteFinanceTable';
import { withSiteFinanceContext } from './SiteFinanceContext';

import './SiteFinance.scss';

const SiteFinance = () => {
  return (
    <div className="site-finance-page">
      <SiteFinanceHeader />
      <SiteFinanceInfo />
      <SiteFinanceTable />
    </div>
  );
};

export default withSiteFinanceContext(SiteFinance);
