import {
  prescreenFailedStatus,
  prescreenFailedStatuses,
  prescreenStatuses
} from '../../WorklistHeader/WorklistFilters/WorklistFilterConstants';

import { AutocompleteFilterSelector } from './AutocompleteFilterSelector';

export const chartReviewOrOutreachFilterOperators = [
  {
    label: 'is',
    value: 'is',
    getApplyFilterFn: filterItem => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return ({ value }) => {
        return value === filterItem.value.label;
      };
    },
    InputComponent: props => (
      <AutocompleteFilterSelector
        options={[
          { label: 'Not Started', id: '0' },
          { label: 'Completed', id: '1' },
          { label: 'In Progress', id: '2' }
        ]}
        {...props}
      />
    )
  }
];

export const patientStatusFilterOperators = [
  {
    label: 'is',
    value: 'is',
    getApplyFilterFn: filterItem => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return ({ value }) => {
        return value === filterItem.value.label;
      };
    },
    InputComponent: props => (
      <AutocompleteFilterSelector
        options={[
          { label: 'Identified', id: '0' },
          { label: 'Pre-screening', id: '1' },
          { label: 'Pre-screened', id: '2' },
          { label: 'Pre-screen Failed', id: '3' }
        ]}
        {...props}
      />
    )
  }
];

export const prescreenStatusFilterOperators = [
  {
    label: 'is',
    value: 'is',
    getApplyFilterFn: filterItem => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return ({ value }) => {
        if (prescreenFailedStatuses.includes(value)) {
          return filterItem.value.label === 'Pre-screen Failed';
        }
        return value === filterItem.value.label;
      };
    },
    InputComponent: props => (
      <AutocompleteFilterSelector
        options={[...prescreenStatuses, prescreenFailedStatus].map(item => ({ ...item, label: item.name }))}
        {...props}
      />
    )
  }
];
