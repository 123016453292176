import { isArray, isEmpty, isNull } from 'lodash/lang';
import uuid from 'uuid/v4';

import { isReadOnlyField } from '../../../../../setup/shared/ElementSetupNew/itemGroupSetupService';
import { isInvestigatorForbiddenField } from '../../InvestigatorFields/services/investigatorFieldService';
import { PS_NOT_ANSWERED, PS_NOT_ASKED, PS_UNAVAILABLE } from '../constants';

export function updateQuestionsField(data, iteratee, predicate = () => true) {
  if (isEmpty(data)) {
    return data;
  }
  const { generalSnapshotAnswers, tableItemGroupRows } = data;
  return {
    ...data,
    generalSnapshotAnswers: generalSnapshotAnswers.map(function(field) {
      return predicate(field) ? iteratee(field) : field;
    }),
    tableItemGroupRows: tableItemGroupRows.map(function(row) {
      return {
        ...row,
        answers: row.answers.map(function(field) {
          return predicate(field) ? iteratee(field) : field;
        })
      };
    })
  };
}

export function updateQuestionsFieldWithUid(data) {
  return updateQuestionsField(data, function(field) {
    const fieldUid = uuid();
    return {
      ...field,
      fieldUid
    };
  });
}

export function collectAllQuestionsField(data) {
  if (isEmpty(data)) {
    return [];
  }
  const { generalSnapshotAnswers, tableItemGroupRows } = data;
  return [
    ...generalSnapshotAnswers.map(field => ({ ...field, fieldLabel: null })),
    ...collectTableQuestionsField(tableItemGroupRows)
  ];
}

export function collectTableQuestionsField(tableItemGroupRows) {
  return tableItemGroupRows.flatMap(function({ answers, label: { name } }) {
    return answers.map(field => ({ ...field, fieldLabel: name }));
  });
}

export function clearHiddenField(field, hiddenFieldsIds) {
  if (hiddenFieldsIds.includes(field.fieldUid)) {
    if (field.fieldType === 'label') {
      return {
        ...field,
        performedStatus: PS_UNAVAILABLE
      };
    }
    return {
      ...field,
      performedStatus: PS_UNAVAILABLE,
      requiredAttention: false,
      fieldComment: null,
      fieldValue: null,
      fileList: null,
      filesResponse: null
    };
  }

  if (
    isNull(field.fieldValue) &&
    isNull(field.fieldComment) &&
    [PS_NOT_ASKED, PS_UNAVAILABLE].includes(field.performedStatus)
  ) {
    return {
      ...field,
      performedStatus: PS_NOT_ANSWERED
    };
  }

  return field;
}

export function answerForDisplay(answer) {
  if (isNull(answer)) {
    return '--';
  }
  if (isArray(answer)) {
    return answer.join(', ');
  }
  return answer;
}

export function canFieldBeHighlightedForReview(field, hiddenFieldsIds, userRole) {
  const { investigatorField, fieldType, fieldName, fieldUid } = field;
  return (
    fieldName !== 'Comment' &&
    !isInvestigatorForbiddenField(investigatorField, userRole) &&
    !isReadOnlyField(fieldType) &&
    !hiddenFieldsIds.includes(fieldUid)
  );
}
