import React, { Component } from 'react';

import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import { RichTextEditor } from '../../../../../common/RichTextFormat/editor/RichTextEditor';
import { resolveStyledFieldName } from '../../../../../common/RichTextFormat/viewer/TextFieldResolver';

import 'react-table/react-table.css';

class EditCustomFieldModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originCustomFieldName: props.originalCustomFieldName,
      updatedCustomFieldName: props.originalCustomFieldName,
      originalStyledFieldName: props.styledFieldName,
      updatedStyledFieldName: props.styledFieldName,
      isStyled: props.isStyled
    };
  }

  onClose = () => {
    this.props.modalBox.close();
  };

  onChangeCustomFieldName = e => {
    if (e.target.value?.length <= 2000) {
      this.setState({
        updatedCustomFieldName: e.target.value
      });
    }
  };

  onChangeStyledCustomFieldName = (htmlString, rawTextString) => {
    if (this.state.isStyled) {
      this.setState({
        updatedCustomFieldName: rawTextString,
        updatedStyledFieldName: htmlString
      });
    }
  };

  updateItem = () => {
    this.props.onSave({
      updatedCustomFieldName: this.state.updatedCustomFieldName,
      updatedStyledFieldName: this.state.updatedStyledFieldName
    });
    this.onClose();
  };

  isFieldNameNotUpdated() {
    if (!this.state.updatedCustomFieldName.trim()) {
      return true;
    }
    if (this.state.isStyled) {
      return this.state.originalStyledFieldName === this.state.updatedStyledFieldName;
    } else {
      return this.state.originCustomFieldName === this.state.updatedCustomFieldName;
    }
  }

  render() {
    return (
      <>
        <ModalBoxes.Header>Edit Custom Field</ModalBoxes.Header>
        <ModalBoxes.Body>
          <div className="col-12 form-row p-0 m-0 mt-3">
            <label htmlFor="updatedCustomFieldName">New Custom Field Name</label>
            {this.state.isStyled ? (
              <RichTextEditor
                className={'regular-item-group'}
                onChange={(htmlString, rawTextString) => this.onChangeStyledCustomFieldName(htmlString, rawTextString)}
                value={resolveStyledFieldName(this.state.originalStyledFieldName, this.state.originCustomFieldName)}
              />
            ) : (
              <textarea
                rows="1"
                id="updatedCustomFieldName"
                value={this.state.updatedCustomFieldName}
                onChange={event => this.onChangeCustomFieldName(event)}
                className=" form-control"
              />
            )}
          </div>
        </ModalBoxes.Body>
        <ModalBoxes.Footer>
          <ButtonGroup>
            <Button priority="medium" onClick={this.onClose}>
              Cancel
            </Button>
            <Button onClick={() => this.updateItem()} disabled={this.isFieldNameNotUpdated()}>
              Update
            </Button>
          </ButtonGroup>
        </ModalBoxes.Footer>
      </>
    );
  }
}

EditCustomFieldModal.className = 'element-setup-modals';
EditCustomFieldModal.size = 'w650';

export default EditCustomFieldModal;
