import { Box, Typography } from '@mui/material';
import { isUndefined } from 'lodash/lang';

import { scGray60 } from '../../../../../../constants/systemColors';
import { Amount } from '../../../shared/amount-view/Amount/Amount';

export const SiteFinanceInfoItem = ({ icon, title, amount, value }) => {
  return (
    <Box sx={{ color: scGray60 }} display="flex" alignItems="center">
      {icon}
      <Typography padding="0 8px">{title}</Typography>
      {!isUndefined(value) && isUndefined(amount) ? (
        <Typography>{value}</Typography>
      ) : (
        <Amount showDollarSign coinsAmount={amount} />
      )}
    </Box>
  );
};
