import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { isNil } from 'lodash/lang';

export default function ToggleField({ fieldValue, condition, updateCondition, disabled }) {
  const { options } = fieldValue;
  const { conditionFieldAnswer } = condition;
  return (
    <ToggleButtonGroup
      fullWidth
      size="small"
      color="primary"
      value={conditionFieldAnswer?.[0] || null}
      exclusive
      disabled={disabled}
      onChange={function(event, conditionFieldAnswer) {
        if (isNil(conditionFieldAnswer)) {
          return;
        }
        updateCondition({
          conditionFieldAnswer: [conditionFieldAnswer]
        });
      }}
    >
      {options.map(function({ name, value }) {
        return (
          <ToggleButton key={value} value={value}>
            {name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
