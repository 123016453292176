import axios from 'axios';

const ROOT = 'api/v1/ssu-startup';

const StudySiteStartupApi = {
  getAllStartupCodes() {
    return axios.get(`${ROOT}/startup-codes`);
  },
  getAllMilestonesForSsu(ssuId) {
    return axios.get(`${ROOT}/milestones?id=${ssuId}`);
  },
  getMilestoneForSSu(ssuIds) {
    return axios.post(`${ROOT}/study-site-milestones`, ssuIds);
  },
  saveMilestones(data) {
    return axios.post(`${ROOT}/save-milestones`, data);
  }
};

export default StudySiteStartupApi;
