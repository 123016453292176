import axios from 'axios';

const ROOT = 'api/v1/finance/site-finance';

const SiteFinanceApi = {
  getSiteFinanceEvents(filters) {
    return axios.post(`${ROOT}`, filters);
  },
  getLifetimeAmounts(filters) {
    return axios.post(`${ROOT}/fin-overview`, filters);
  }
};

export default SiteFinanceApi;
