import { studyEnrollmentRouterConfig } from './Enrollment/routerConfig';
import { studyExpenseRouterConfig } from './ExpenseFixed/routerConfig';
import { studyDetailsRouterConfig } from './StudyDetails/routerConfig';
import { timelineRouterConfig } from './Timeline/routerConfig';

export const studyRouterConfig = [
  studyDetailsRouterConfig,
  timelineRouterConfig,
  studyEnrollmentRouterConfig,
  studyExpenseRouterConfig
];
