import React from 'react';

import { toFormattedFinanceBill } from '../../../../../../../../services/financial';
import { stylesContent } from '../../EncounterInvoiceTemplateStyles';

export const SubItem = ({ item }) => {
  const rate = item.reimbursementEventSuffix
    ? ` ${item.reimbursementEventSuffix}`
    : `: Rate ${toFormattedFinanceBill(item.invoiceAmount)}`;
  return (
    <div style={stylesContent.invoiceSubItem} className="row-item" data-testid="ledger-event-item">
      {`${item.name}${rate}`}
    </div>
  );
};
