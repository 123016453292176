import { SITE_FINANCE } from '../../../../constants/accessToPages';

import SiteFinance from './SiteFinance';

export const siteFinanceRouterConfig = {
  name: 'Site Finance',
  path: '/site-finance',
  rootBreadcrumbName: 'Finance',
  component: SiteFinance,
  access: [SITE_FINANCE]
};
