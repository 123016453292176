import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { filter, flatMap, forEach, orderBy } from 'lodash/collection';
import { cloneDeep, isEmpty } from 'lodash/lang';

import { FormBuilderApi } from '../../../../../api';
import Icon from '../../../../../common/general/Icon';
import TextFieldResolver from '../../../../../common/RichTextFormat/viewer/TextFieldResolver';
import { getChildParentsCountMapNew } from '../../../patient-source/shared/EncounterDescNew/ConditionalLogicHelperService';
import {
  fieldAffectsOnConditionalLogic,
  getHiddenTargetFieldIdsByTriggerFieldValue,
  prepareFormValidationRules
} from '../../../patient-source/shared/EncounterDescNew/conditionalLogicServices.js';
import { collectConditionalLogic } from '../../services/conditionalLogicUtils';
import GenerateHTML from '../GenerateHtml/GenerateHtml';

function ItemGroupFormPreview({ formData, dynamicClassesForFieldName, customClassObject }) {
  const labelList = formData?.labelList;
  const domainIdentifier = formData?.domainIdentifier;
  const [inputValuesMap, setInputValuesMap] = useState({});
  const [standardConditionalLogic, setStandardConditionalLogic] = useState({});

  useEffect(() => {
    if (!domainIdentifier) {
      return;
    }
    FormBuilderApi.getStandardConditionalLogicForDomain(domainIdentifier).then(function(conditionalLogic) {
      setStandardConditionalLogic(conditionalLogic);
    });
  }, [domainIdentifier]);

  const { filteredAndSortedLabelList, formValidationRules, childParentsCountMap } = useMemo(
    function() {
      const filteredAndSortedLabelList = cloneDeep(orderBy(filter(labelList, 'isChecked'), 'sequence', 'asc'));
      const formValidation = collectConditionalLogic(standardConditionalLogic, filteredAndSortedLabelList, 'key');
      const formValidationRules = prepareFormValidationRules({ formValidation });
      const childParentsCountMap = getChildParentsCountMapNew(formValidationRules);

      return {
        filteredAndSortedLabelList,
        formValidationRules,
        childParentsCountMap
      };
    },
    [standardConditionalLogic, labelList]
  );

  return (
    <React.Fragment>
      {!isEmpty(filteredAndSortedLabelList) &&
        filteredAndSortedLabelList
          .filter(item => validateIfRowIsVisible(item))
          .map((item, idx) => {
            return (
              <div key={idx} className={'item-group-field-row'}>
                {item.inputType !== 'reminder' && item.inputType !== 'checkbox' && (
                  <label
                    className={cx('d-flex', dynamicClassesForFieldName(item))}
                    style={{
                      paddingLeft: childParentsCountMap[item.key] ? childParentsCountMap[item.key] * 24 + 'px' : 0,
                      fontWeight: fieldAffectsOnConditionalLogic(formValidationRules, item.key) ? 'bold' : 'normal'
                    }}
                  >
                    {item.investigatorField === true && (
                      <Icon className={'investigator-field-icon'}>medical_services</Icon>
                    )}
                    <TextFieldResolver
                      fieldType={item.inputType}
                      fieldName={item.updatedQuestion ? item.updatedQuestion : item.question}
                      styledFieldName={item.styledFieldName}
                    />
                  </label>
                )}
                <GenerateHTML
                  labelText={item.label}
                  data={item}
                  forPreview={true}
                  key={item.key}
                  formId={formData.formId}
                  formLabelId={formData.formLableId}
                  customClassObject={customClassObject}
                  itemDefIndex={idx}
                  onChangeRadioHandler={(value, question, decodedVal) => {
                    item.inputValue = value;
                    setInputValuesMap(function(prevState) {
                      const nextState = {
                        ...prevState,
                        [question.key]: decodedVal
                      };

                      const ids = resetConditionalQuestions(item, decodedVal);

                      forEach(ids, id => delete nextState[id]);

                      return nextState;
                    });
                  }}
                  onChangeDropDownHandler={function(options, formLabelId, oid, question) {
                    const decodedVal = getDropDownValue(options, question);
                    item.inputValue = decodedVal;
                    setInputValuesMap(function(prevState) {
                      const nextState = {
                        ...prevState,
                        [question.key]: decodedVal
                      };

                      const ids = resetConditionalQuestions(item, decodedVal);

                      forEach(ids, id => delete nextState[id]);

                      return nextState;
                    });
                  }}
                  fromWhichPage="popUp"
                />
              </div>
            );
          })}
    </React.Fragment>
  );

  function validateIfRowIsVisible(item) {
    const hiddenFieldIds = flatMap(formValidationRules, function(rule, ruleKey) {
      return getHiddenTargetFieldIdsByTriggerFieldValue(formValidationRules, ruleKey, inputValuesMap[ruleKey]);
    });
    return !hiddenFieldIds.includes(item.key);
  }

  function resetConditionalQuestions(parentItem, value) {
    const ids = getHiddenTargetFieldIdsByTriggerFieldValue(formValidationRules, parentItem.key, value);

    const childIds = flatMap(ids, function(id) {
      const childItemToReset = filteredAndSortedLabelList.find(label => label.key === id);
      if (!childItemToReset) {
        return [];
      }
      const childValue = childItemToReset.inputType === 'radio' ? 0 : '';
      childItemToReset.inputValue = childValue;
      if (fieldAffectsOnConditionalLogic(formValidationRules, id)) {
        return resetConditionalQuestions(childItemToReset, childValue);
      }
    });

    return [...ids, ...childIds];
  }
}

export default ItemGroupFormPreview;

function getDropDownValue(options, questionData) {
  // for multiselect
  if (
    questionData.hasOwnProperty('codeDefinationList') &&
    questionData.codeDefinationList.length > 0 &&
    questionData.codeDefinationList[0].inputType === 'multiselect'
  ) {
    let mltOpt = '';
    options &&
      options.length &&
      options.forEach(mobj => {
        mltOpt += `${mobj.decode || mobj.name ? mobj.decode || mobj.name : ''}$$###$$`;
      });

    questionData.inputValue = mltOpt;
  }
  // for singleselect drop down
  if (
    questionData.hasOwnProperty('codeDefinationList') &&
    questionData.codeDefinationList.length > 0 &&
    questionData.codeDefinationList[0].inputType === 'dropdown'
  ) {
    questionData.inputValue = options && (options.decode || options.name) ? options.decode || options.name : '';
  }

  if (questionData.hasOwnProperty('measurementDefinitionList') && questionData.measurementDefinitionList.length > 0) {
    questionData.inputValue = options && (options.decode || options.name) ? options.decode || options.name : '';
  }
  return questionData.inputValue;
}
