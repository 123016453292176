import React, { useContext, useEffect, useMemo } from 'react';

import { ProtocolApi } from '../../../../../../../api';
import Button from '../../../../../../../common/general/Button';
import ButtonGroup from '../../../../../../../common/general/ButtonGroup';
import { TableSetupContext } from '../../../../../../../contexts/TableSetupContext';
import { PageInfoHeader } from '../../../../../../PageInfoHeader/PageInfoHeader';
import { generateUrlByKey, useCurrentRoute } from '../../../../../../root/router';

const ItemGroupsDetailsTableSetup = () => {
  const tableSetupContext = useContext(TableSetupContext);
  const {
    params: { studyId, protocolIdentity },
    name,
    parent
  } = useCurrentRoute();

  useEffect(() => {
    ProtocolApi.getStudyProtocolBasicInfo(studyId, protocolIdentity).then(({ data }) => {
      tableSetupContext.setProtocolName(data.response.protocolName);
      tableSetupContext.setStudyName(data.response.studyName);
      tableSetupContext.setProtocolVersion(data.response.protocolVersion);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateBack = useMemo(() => {
    if (tableSetupContext.isTemplateMode && name !== 'Item Group Table Template Setup') {
      return generateUrlByKey('Item Group Templates');
    }
    return generateUrlByKey(
      parent.name === 'Encounter Setup' ? parent.key : 'Setup Protocol.Source Data Setup.Item Groups List',
      { studyId, protocolIdentity }
    );
  }, [name, parent.key, parent.name, protocolIdentity, studyId, tableSetupContext.isTemplateMode]);

  return (
    <PageInfoHeader
      objectRecordLabel={name === 'Item Group Table Template Setup' && tableSetupContext.studyName}
      pageInfo={
        name === 'Item Group Table Template Setup' && (
          <PageInfoHeader.CollapsibleList>
            <PageInfoHeader.AdditionalInfo tooltip="Source Data Name">
              {tableSetupContext.protocolName}
            </PageInfoHeader.AdditionalInfo>
            <PageInfoHeader.AdditionalInfo tooltip="Version">
              {tableSetupContext.protocolVersion}
            </PageInfoHeader.AdditionalInfo>
          </PageInfoHeader.CollapsibleList>
        )
      }
      right={
        <ButtonGroup>
          <Button priority="low" to={navigateBack}>
            Cancel
          </Button>
          {tableSetupContext.isTemplateMode && name !== 'Item Group Table Template Setup' ? (
            <Button priority="high" onClick={tableSetupContext.saveTable}>
              Save
            </Button>
          ) : (
            <>
              <Button priority="high" onClick={() => tableSetupContext.saveTable(true)}>
                Save
              </Button>
              <Button priority="high" onClick={tableSetupContext.saveTable}>
                Save and New
              </Button>
            </>
          )}
        </ButtonGroup>
      }
    />
  );
};

export default ItemGroupsDetailsTableSetup;
