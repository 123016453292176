import React from 'react';
import { isEmpty } from 'lodash/lang';

import { STYLED_INPUT_QUESTIONS } from '../../../constants/inputTypes';

import { StyledTextViewer } from './StyledTextViewer';

export default function TextFieldResolver({
  fieldType,
  fieldName,
  styledFieldName,
  className,
  allowedStyledQuestions = STYLED_INPUT_QUESTIONS
}) {
  if (allowedStyledQuestions.includes(fieldType) && !isEmpty(styledFieldName?.trim())) {
    return <StyledTextViewer className={className} value={styledFieldName} />;
  } else {
    return fieldName;
  }
}

export function resolveStyledFieldName(styledFieldName, originalFieldName) {
  return !isEmpty(styledFieldName?.trim()) ? styledFieldName : originalFieldName;
}
