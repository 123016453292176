import React, { useEffect, useMemo, useState } from 'react';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Button, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';

import SiteCreditApi from '../../../../../api/finance/SiteCreditApi';
import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import modalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import { DD_SLASH_MMM_SLASH_YYYY } from '../../../../../constants/dateFormat';
import { SOMETHING_WENT_WRONG } from '../../../../../constants/notificationMessages';
import { InfoItem } from '../../NewInvoice/InvoiceTable/DepositHistoryModal/DepositHistoryModal';
import { Amount } from '../../shared/amount-view/Amount/Amount';
import { resolveEventSuffix } from '../SitePaymentServices';
import {
  MULTIPLE_EVENT_REVERSE_MESSAGE,
  PAID,
  SINGLE_EVENT_REVERSE_MESSAGE,
  SITE_CREDIT,
  SITE_CREDIT_WH,
  SitePaymentStatus
} from '../SitePaymentsTable/SitePaymentsConstants';

import { ReverseSiteCreditApplicationModal } from './ReverseSiteCreditApplicationModal';

export const CreditApplicationHistoryModal = ({
  row: {
    eventDate,
    studyName,
    siteName,
    subjectId,
    encounterName,
    budgetEventType,
    eventName,
    itemGroupName,
    itemSiteId,
    sitePaymentType,
    sitePaymentStatus
  },
  applyFilter
}) => {
  const [creditApplicationHistory, setCreditApplicationHistory] = useState([]);
  useEffect(() => {
    SiteCreditApi.getHistory(itemSiteId, sitePaymentType)
      .then(({ data }) => {
        setCreditApplicationHistory(data);
      })
      .catch(() => {
        NotificationManager.error(SOMETHING_WENT_WRONG);
      });
  }, [itemSiteId, sitePaymentType]);

  const comment = useMemo(() => {
    const allComments = creditApplicationHistory.map(item => item.comment);
    return [...new Set(allComments)].filter(Boolean).join(', ');
  }, [creditApplicationHistory]);

  const isSiteCreditEvent = [SITE_CREDIT, SITE_CREDIT_WH].includes(sitePaymentType);
  const reversedHistoryItems = creditApplicationHistory.filter(item => item.isReversed || item.isReversal);
  const isReverseAllDisabled = useMemo(() => {
    return (
      reversedHistoryItems.length !== 0 ||
      sitePaymentStatus === PAID ||
      creditApplicationHistory.some(item => item.currentSitePaymentStatus === PAID)
    );
  }, [reversedHistoryItems.length, sitePaymentStatus, creditApplicationHistory]);
  const historyItemIdsForReversal = creditApplicationHistory
    .filter(item => !item.isReversed || !item.isReversal)
    .map(item => item.id);

  return (
    <>
      <ModalBoxes.Header>Site Credit Application History</ModalBoxes.Header>
      <ModalBoxes.Body>
        <div className="invoice-info-section">
          <div className="header">Info</div>
          <div className="info-items-wrapper">
            <InfoItem label="Event date" value={moment(eventDate).format('DD/MMM/YYYY')} />
            <InfoItem label="Study" value={studyName} />
            <InfoItem label="Site" value={siteName} />
            <InfoItem label="Subject ID" value={subjectId} />
            <InfoItem label="Encounter" value={encounterName} />
            <InfoItem label="Budget Event Type" value={budgetEventType} />
            <InfoItem label="Event Name" value={eventName} />
            <InfoItem label="Item Group" value={itemGroupName} />
            <InfoItem multiline label="Comment" value={comment} />
          </div>
        </div>
        <div className="credit-application-reverse-all-button">
          <div className="header">History</div>
          {isSiteCreditEvent && (
            <Button
              data-testid="reverse-all-button"
              disabled={isReverseAllDisabled}
              onClick={() =>
                handleReverse(
                  historyItemIdsForReversal,
                  itemSiteId,
                  sitePaymentType,
                  applyFilter,
                  setCreditApplicationHistory,
                  MULTIPLE_EVENT_REVERSE_MESSAGE
                )
              }
            >
              Reverse All
            </Button>
          )}
        </div>
        <div
          className={`history-wrapper credit-application ${!isSiteCreditEvent ? 'credit-application-no-reversal' : ''}`}
          data-testid="application-history-table"
        >
          <HeaderCell value="Study" />
          <HeaderCell value="Site" />
          <HeaderCell value="Event ID" />
          <HeaderCell value="Event Name" />
          <HeaderCell value="Credit Application Date" />
          <HeaderCell value="Amount Applied" />
          <HeaderCell value="Site Payment Variance" />
          <HeaderCell value="Site Payment Status" />
          <HeaderCell value="Applied By" />
          <HeaderCell value="Credit Application Reversal" />
          {isSiteCreditEvent && <HeaderCell value="Action" />}
          {creditApplicationHistory.map(item => (
            <HistoryRow
              key={item.id}
              item={item}
              creditEventSitePaymentStatus={sitePaymentStatus}
              creditItemSiteId={itemSiteId}
              creditType={sitePaymentType}
              applyFilter={applyFilter}
              setCreditApplicationHistory={setCreditApplicationHistory}
              showReversalButton={isSiteCreditEvent}
            />
          ))}
        </div>
      </ModalBoxes.Body>
    </>
  );
};

const HeaderCell = ({ value }) => {
  return (
    <div
      className={`history-table-header-item ${value === 'Action' ? 'pinned-action' : ''}`}
      data-testid="history-table-header-item"
    >
      {value}
    </div>
  );
};

const handleReverse = (
  historyItemId,
  creditItemSiteId,
  creditType,
  applyFilter,
  setCreditApplicationHistory,
  message
) => {
  modalBoxes.open({
    component: (
      <ReverseSiteCreditApplicationModal
        historyItemIds={historyItemId}
        creditItemSiteId={creditItemSiteId}
        creditType={creditType}
        applyFilter={applyFilter}
        setCreditApplicationHistory={setCreditApplicationHistory}
        message={message}
      />
    ),
    title: 'Reverse Site Credit Application',
    size: 'w500'
  });
};

const disableReverseButton = (historyItem, creditEventSitePaymentStatus) => {
  return (
    creditEventSitePaymentStatus === PAID ||
    historyItem.currentSitePaymentStatus === PAID ||
    [SITE_CREDIT, SITE_CREDIT_WH].includes(historyItem.sitePaymentType) ||
    historyItem.isReversal ||
    historyItem.isReversed
  );
};

const HistoryRow = ({
  item,
  creditEventSitePaymentStatus,
  creditItemSiteId,
  creditType,
  applyFilter,
  setCreditApplicationHistory,
  showReversalButton
}) => {
  return (
    <>
      <div data-testid="studyName">{item.studyName}</div>
      <div data-testid="siteName">{item.siteName}</div>
      <div data-testid="eventId">
        {item.adjustmentSequence === 0
          ? resolveEventSuffix(item.eventNumber, item.sitePaymentType)
          : resolveEventSuffix(`${item.eventNumber}.${item.adjustmentSequence}`, item.sitePaymentType)}
      </div>
      <div data-testid="eventName">{item.eventName}</div>
      <div data-testid="creditApplicationDate">
        {moment(item.creditApplicationDate).format(DD_SLASH_MMM_SLASH_YYYY)}
      </div>
      <Amount testId="appliedAmount" coinsAmount={item.appliedAmount} showDollarSign />
      <Amount testId="sitePaymentVariance" coinsAmount={item.sitePaymentVariance} showDollarSign />
      <div data-testid="sitePaymentStatus">{SitePaymentStatus[item.historicalSitePaymentStatus]}</div>
      <div data-testid="appliedBy">{item.appliedBy}</div>
      <div data-testid="isReversal">{item.isReversal ? 'Yes' : 'No'}</div>
      {showReversalButton && (
        <div className="pinned-action">
          <Tooltip title="Reverse Site Credit Application">
            <IconButton
              data-testid="reverse-icon"
              size="small"
              sx={{ height: '17px' }}
              color={disableReverseButton(item, creditEventSitePaymentStatus) ? 'disabled' : 'primary'}
              disabled={disableReverseButton(item, creditEventSitePaymentStatus)}
              onClick={() =>
                handleReverse(
                  item.id,
                  creditItemSiteId,
                  creditType,
                  applyFilter,
                  setCreditApplicationHistory,
                  SINGLE_EVENT_REVERSE_MESSAGE
                )
              }
            >
              <SettingsBackupRestoreIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </>
  );
};
