import React, { useCallback, useContext, useMemo } from 'react';
import { Box, Button } from '@mui/material';

import { StudyPCNSiteWorklistSelector } from '../../../../pre-screen/NewWorklist/WorklistHeader/WorklistFilters/StudyPCNSiteWorklistSelector/StudyPCNSiteWorklistSelector';
import { StartEndDateSelector } from '../../../NewInvoice/InvoiceTopSection/InvoiceFilter/StartEndDateSelector/StartEndDateSelector';
import { SiteFinanceFilterContext } from '../../SiteFinanceContext';

import { DateTypeSelector } from './DateTypeSelector/DateTypeSelector';
import { EventStatusSelector } from './EventStatusSelector/EventStatusSelector';

export const SiteFinanceFilters = () => {
  const { filterData, setFewFilterProperty, updatePage, resetFilters, ssus } = useContext(SiteFinanceFilterContext);

  const onStudyChange = useCallback(
    study => {
      if (study) {
        const { uniqueIdentifier, studyName, projectCode } = study;
        setFewFilterProperty({ studyId: uniqueIdentifier, studyName: studyName, projectCode: projectCode });
      } else {
        setFewFilterProperty({ studyId: null, studyName: null });
      }
    },
    [setFewFilterProperty]
  );

  const onPcnChange = useCallback(
    (study, sites) => {
      if (study) {
        const { uniqueIdentifier, studyName, projectCode } = study;
        setFewFilterProperty({ studyId: uniqueIdentifier, studyName: studyName, projectCode: projectCode });
        if (sites.length === 1 && !filterData.siteId) {
          const { uniqueIdentifier, siteName } = sites[0];
          setFewFilterProperty({ siteId: uniqueIdentifier, siteName: siteName });
        }
      } else {
        setFewFilterProperty({ studyId: null, studyName: null, projectCode: null });
      }
    },
    [filterData.siteId, setFewFilterProperty]
  );

  const onSiteChange = useCallback(
    site => {
      if (site) {
        const { uniqueIdentifier, siteName } = site;
        setFewFilterProperty({ siteId: uniqueIdentifier, siteName: siteName });
      } else {
        setFewFilterProperty({ siteId: null, siteName: null });
      }
    },
    [setFewFilterProperty]
  );

  const onChangeStartDate = useCallback(
    moment => {
      const startDateMoment = moment.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      setFewFilterProperty({ startDate: startDateMoment });
    },
    [setFewFilterProperty]
  );

  const onChangeEndDate = useCallback(
    moment => {
      const endDateMoment = moment.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
      setFewFilterProperty({ endDate: endDateMoment });
    },
    [setFewFilterProperty]
  );

  const startDate = useMemo(() => {
    return filterData?.startDate;
  }, [filterData]);

  const endDate = useMemo(() => {
    return filterData?.endDate;
  }, [filterData]);

  return (
    <div className="worklist-filters">
      <div className="general-header-wrapper">
        <StudyPCNSiteWorklistSelector
          onSiteChange={onSiteChange}
          onStudyChange={onStudyChange}
          onPcnChange={onPcnChange}
          studyId={filterData.studyId}
          siteId={filterData.siteId}
          pcnFlag={true}
          enableAutoCompleteSite={true}
          setFewFilterProperty={setFewFilterProperty}
          studySiteList={ssus}
        />
        <Box display="flex" gap="15px">
          <Button
            sx={{ textTransform: 'initial' }}
            variant="contained"
            size="large"
            onClick={() => updatePage()}
            data-testid="apply-filters-button"
          >
            Apply
          </Button>
          <Button
            sx={{ textTransform: 'initial' }}
            variant="outlined"
            size="large"
            onClick={resetFilters}
            data-testid="reset-filters-button"
          >
            Reset
          </Button>
        </Box>
      </div>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <StartEndDateSelector
          endDate={endDate}
          startDate={startDate}
          onChangeEndDate={onChangeEndDate}
          onChangeStartDate={onChangeStartDate}
        />
        <DateTypeSelector />
        <EventStatusSelector />
      </Box>
    </div>
  );
};
