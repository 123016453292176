import { useEffect } from 'react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';

export default function OnEditorChangePlugin({ onValueChange }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const htmlString = $generateHtmlFromNodes(editor, null);
        const rawTextString = $getRoot()
          .getTextContent()
          .trim();
        onValueChange(htmlString, rawTextString);
      });
    });
    return () => {
      removeUpdateListener();
    };
  }, [editor, onValueChange]);

  return null;
}
