import React, { useCallback, useMemo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, TableCell, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import cx from 'classnames';
import { size } from 'lodash/collection';
import { isNil } from 'lodash/lang';

import { CLT_HIDE, CLT_SHOW } from '../../constants';
import { collectFieldValue, getFieldByName } from '../../services';

import ConfigurationTableFieldSelect from './ConfigurationTableFieldSelect/ConfigurationTableFieldSelect';
import ConfigurationTableValueSelect from './ConfigurationTableValueSelect/ConfigurationTableValueSelect';

import './ConfigurationTableRow.scss';

export default function ConfigurationTableRow({
  condition,
  domainTargetFields,
  conditionsMap,
  domainConditionsMap,
  allAccessibleFields,
  setConditions,
  type,
  hasDomainConditions
}) {
  const { conditionId, conditionFieldName, conditionType } = condition;

  const accessibleFields = useMemo(
    function() {
      const conditionField = getFieldByName(allAccessibleFields, conditionFieldName);

      return allAccessibleFields.filter(function(field) {
        if (field === conditionField) {
          return true;
        }

        const { name } = field;

        if (domainTargetFields?.includes(name)) {
          return false;
        }
        return !conditionsMap[name] && !domainConditionsMap[name];
      });
    },
    [allAccessibleFields, conditionFieldName, conditionsMap, domainConditionsMap, domainTargetFields]
  );

  const updateCondition = useCallback(
    function(patch) {
      setConditions(function(state) {
        return state.map(function(condition) {
          if (condition.conditionId === conditionId) {
            return {
              ...condition,
              ...patch
            };
          }
          return condition;
        });
      });
    },
    [conditionId, setConditions]
  );

  const deleteCondition = useCallback(
    function() {
      setConditions(function(state) {
        return state.filter(function(condition) {
          return condition.conditionId !== conditionId;
        });
      });
    },
    [conditionId, setConditions]
  );

  const fieldValue = useMemo(
    function() {
      return collectFieldValue(accessibleFields, conditionFieldName);
    },
    [accessibleFields, conditionFieldName]
  );

  const multipleConditions = size(conditionsMap) > 1;

  const title =
    type === 'DOMAIN' ? 'Domain based configuration' : type === 'ALL' ? 'Full row based configuration' : null;

  const disabled = ['DOMAIN', 'ALL'].includes(type);

  return (
    <TableRow
      className={cx({
        'readonly-row': ['DOMAIN', 'ALL'].includes(type)
      })}
      title={title}
    >
      <TableCell>
        <ToggleButtonGroup
          fullWidth
          size="small"
          color="primary"
          value={conditionType}
          exclusive
          disabled={disabled}
          onChange={function(event, conditionType) {
            if (isNil(conditionType)) {
              return;
            }
            updateCondition({ conditionType });
          }}
        >
          <ToggleButton value={CLT_HIDE}>Hide</ToggleButton>
          <ToggleButton value={CLT_SHOW}>Show</ToggleButton>
        </ToggleButtonGroup>
      </TableCell>
      <TableCell>
        <ConfigurationTableFieldSelect
          accessibleFields={accessibleFields}
          condition={condition}
          updateCondition={updateCondition}
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        {fieldValue && (
          <ConfigurationTableValueSelect
            fieldValue={fieldValue}
            condition={condition}
            updateCondition={updateCondition}
            disabled={disabled}
          />
        )}
      </TableCell>
      <TableCell>
        {(multipleConditions || hasDomainConditions) && (
          <IconButton color="primary" onClick={deleteCondition} disabled={disabled}>
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
