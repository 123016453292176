import {
  ROLE_OPERATION_MANAGER,
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_SITE_FINANCIAL_COORDINATOR,
  ROLE_STUDY_MANAGER
} from './userRoles';

export const financeAccessRoles = [
  ROLE_OPERATION_MANAGER,
  ROLE_PRINCIPAL_INVESTIGATOR,
  ROLE_SITE_FINANCIAL_COORDINATOR,
  ROLE_STUDY_MANAGER
];
